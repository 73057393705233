import * as types from "./constants";

export function requestInitItch() {
    return {
        type: types.REQUEST_INIT_ITCH_AUTHORS
    };
}

export function succeedInitItch(response) {
    return {
        type: types.SUCCEED_INIT_ITCH_AUTHORS,
        response
    };
}

export function requestSearchItch(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_ITCH_AUTHORS,
        limit, offset
    };
}

export function succeedSearchItch(response) {
    return {
        type: types.SUCCEED_SEARCH_ITCH_AUTHORS,
        response
    };
}

export function succeedGetItchFilterData(response) {
    return {
        type: types.SUCCEED_GET_ITCH_AUTHORS_FILTER_DATA,
        response
    };
}

export function requestItchLastUpdate() {
    return {
        type: types.REQUEST_ITCH_AUTHORS_LAST_UPDATE
    };
}

export function succeedItchLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_ITCH_AUTHORS_LAST_UPDATE,
        lastUpdate
    };
}

export function requestItchAuthors() {
    return {
        type: types.REQUEST_EXPORT_ITCH_AUTHORS_GAMES
    };
}

export function succeedItchAuthors(response) {
    return {
        type: types.SUCCEED_EXPORT_ITCH_AUTHORS_GAMES,
        response
    };
}

export function setSelectedGenres(selectedGenres) {
    return {
        type: types.SET_SELECTED_GENRES_ITCH_AUTHORS,
        selectedGenres
    };
}

export function setSelectedLanguage(selectedLanguage) {
    return {
        type: types.SET_SELECTED_LANGUAGES_ITCH_AUTHORS,
        selectedLanguage
    };
}

export function setSelectedPlatform(selectedPlatform) {
    return {
        type: types.SET_SELECTED_PLATFORM_ITCH_AUTHORS,
        selectedPlatform
    };
}

export function setLanguageCount(languagesCount) {
    return {
        type: types.SET_LANGUAGE_COUNT_ITCH_AUTHORS,
        languagesCount
    };
}

export function setPrice(price) {
    return {
        type: types.SET_PRICE_ITCH_AUTHORS,
        price
    };
}

export function setReleased(released) {
    return {
        type: types.SET_ITCH_AUTHORS_RELEASED,
        released
    };
}

export function requestGetCountItchAuthors() {
    return {
        type: types.REQUEST_GET_COUNT_ITCH_AUTHORS,
    };
}

export function succeedGetCountItchAuthors(count) {
    return {
        type: types.SUCCEED_GET_COUNT_ITCH_AUTHORS,
        count
    };
}

export function setIsOnlyChosenLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_LANGUAGES_ITCH_AUTHORS,
        isOnlyChosenLanguages
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_ITCH_AUTHORS,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_ITCH_AUTHORS,
        isFiltersSelected
    };
}



