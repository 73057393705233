export const allCountry = [
    {id: "al", name: "Albania"},
    {id: "dz", name: "Algeria"},
    {id: "ao", name: "Angola"},
    {id: "ag", name: "Antigua and Barbuda"},
    {id: "ar", name: "Argentina"},
    {id: "am", name: "Armenia"},
    {id: "aw", name: "Aruba"},
    {id: "au", name: "Australia"},
    {id: "at", name: "Austria"},
    {id: "az", name: "Azerbaijan"},
    {id: "bs", name: "Bahamas"},
    {id: "bh", name: "Bahrain"},
    {id: "bd", name: "Bangladesh"},
    {id: "by", name: "Belarus"},
    {id: "be", name: "Belgium"},
    {id: "bz", name: "Belize"},
    {id: "bj", name: "Benin"},
    {id: "bo", name: "Bolivia"},
    {id: "ba", name: "Bosnia and Herzegovina"},
    {id: "bw", name: "Botswana"},
    {id: "br", name: "Brazil"},
    {id: "bg", name: "Bulgaria"},
    {id: "bf", name: "Burkina Faso"},
    {id: "kh", name: "Cambodia"},
    {id: "cm", name: "Cameroon"},
    {id: "ca", name: "Canada"},
    {id: "cv", name: "Cape Verde"},
    {id: "cl", name: "Chile"},
    {id: "cn", name: "China"},
    {id: "co", name: "Colombia"},
    {id: "cr", name: "Costa Rica"},
    {id: "ci", name: "Cote d' Ivore"},
    {id: "hr", name: "Croatia"},
    {id: "cy", name: "Cyprus"},
    {id: "cz", name: "Czech Republic"},
    {id: "dk", name: "Denmark"},
    {id: "do", name: "Dominican Republic"},
    {id: "ec", name: "Ecuador"},
    {id: "eg", name: "Egypt"},
    {id: "sv", name: "El Salvador"},
    {id: "ee", name: "Estonia"},
    {id: "fj", name: "Fiji"},
    {id: "fi", name: "Finland"},
    {id: "fr", name: "France"},
    {id: "ga", name: "Gabon"},
    {id: "de", name: "Germany"},
    {id: "gh", name: "Ghana"},
    {id: "gr", name: "Greece"},
    {id: "gt", name: "Guatemala"},
    {id: "gw", name: "Guinea-Bissau"},
    {id: "ht", name: "Haiti"},
    {id: "hn", name: "Honduras"},
    {id: "hk", name: "Hong Kong"},
    {id: "hu", name: "Hungary"},
    {id: "is", name: "Iceland"},
    {id: "in", name: "India"},
    {id: "id", name: "Indonesia"},
    {id: "ie", name: "Ireland"},
    {id: "il", name: "Israel"},
    {id: "it", name: "Italy"},
    {id: "jm", name: "Jamaica"},
    {id: "jp", name: "Japan"},
    {id: "jo", name: "Jordan"},
    {id: "kz", name: "Kazakhstan"},
    {id: "ke", name: "Kenya"},
    {id: "kw", name: "Kuwait"},
    {id: "kg", name: "Kyrgyzstan"},
    {id: "la", name: "Laos"},
    {id: "lv", name: "Latvia"},
    {id: "lb", name: "Lebanon"},
    {id: "li", name: "Liechtenstein"},
    {id: "lt", name: "Lithuania"},
    {id: "lu", name: "Luxembourg"},
    {id: "mk", name: "Macedonia"},
    {id: "my", name: "Malaysia"},
    {id: "ml", name: "Mali"},
    {id: "mt", name: "Malta"},
    {id: "mu", name: "Mauritius"},
    {id: "mx", name: "Mexico"},
    {id: "md", name: "Moldova"},
    {id: "ma", name: "Morocco"},
    {id: "mz", name: "Mozambique"},
    {id: "na", name: "Namibia"},
    {id: "np", name: "Nepal"},
    {id: "nl", name: "Netherlands"},
    {id: "an", name: "Netherlands Antilles"},
    {id: "nz", name: "New Zealand"},
    {id: "ni", name: "Nicaragua"},
    {id: "ne", name: "Niger"},
    {id: "ng", name: "Nigeria"},
    {id: "no", name: "Norway"},
    {id: "om", name: "Oman"},
    {id: "pk", name: "Pakistan"},
    {id: "pa", name: "Panama"},
    {id: "pg", name: "Papua New Guinea"},
    {id: "py", name: "Paraguay"},
    {id: "pe", name: "Peru"},
    {id: "ph", name: "Philippines"},
    {id: "pl", name: "Poland"},
    {id: "pt", name: "Portugal"},
    {id: "qa", name: "Qatar"},
    {id: "ro", name: "Romania"},
    {id: "ru", name: "Russia"},
    {id: "rw", name: "Rwanda"},
    {id: "sa", name: "Saudi Arabia"},
    {id: "sn", name: "Senegal"},
    {id: "rs", name: "Serbia"},
    {id: "sg", name: "Singapore"},
    {id: "sk", name: "Slovakia"},
    {id: "si", name: "Slovenia"},
    {id: "za", name: "South Africa"},
    {id: "kr", name: "South Korea"},
    {id: "es", name: "Spain"},
    {id: "lk", name: "Sri Lanka"},
    {id: "se", name: "Sweden"},
    {id: "ch", name: "Switzerland"},
    {id: "tw", name: "Taiwan"},
    {id: "tj", name: "Tajikistan"},
    {id: "tz", name: "Tanzania"},
    {id: "th", name: "Thailand"},
    {id: "tg", name: "Togo"},
    {id: "tt", name: "Trinidad and Tobago"},
    {id: "tn", name: "Tunisia"},
    {id: "tr", name: "Turkey"},
    {id: "tm", name: "Turkmenistan"},
    {id: "ug", name: "Uganda"},
    {id: "ua", name: "Ukraine"},
    {id: "ae", name: "United Arab Emirates"},
    {id: "gb", name: "United Kingdom"},
    {id: "us", name: "United States"},
    {id: "uy", name: "Uruguay"},
    {id: "uz", name: "Uzbekistan"},
    {id: "ve", name: "Venezuela"},
    {id: "vn", name: "Vietnam"},
    {id: "ye", name: "Yemen"},
    {id: "zm", name: "Zambia"},
    {id: "zw", name: "Zimbabwe"},
];