import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TableColumn extends Component {

    static propTypes = {
        column: PropTypes.object,
    };

    render() {
        const {column, addedWidth} = this.props;
        const {key, name, width, fixedColumn, headerClassName, headerValueWrapperFn} = column;

        const tableColumnStyle = fixedColumn ? {left: fixedColumn.left} : {};
        const tableContentColumnStyle = fixedColumn ? {width} : {width: width + addedWidth + 'px'};

        const tableColumnClassName = classNames("kraken-table-th", headerClassName, {
            "fixed-column": !!fixedColumn
        });

        const value = !!headerValueWrapperFn ? headerValueWrapperFn(key, name) : name;

        return (
            <th id={`column-${key}`} className={tableColumnClassName} style={tableColumnStyle}>
                <div className="kraken-table-th-content" style={tableContentColumnStyle}>
                    {value}
                </div>
            </th>
        );
    }
}

export default (TableColumn);
