export const REQUEST_INIT_MAGNET_SALES_CONTACTS = 'REQUEST_INIT_MAGNET_SALES_CONTACTS';
export const SUCCEED_INIT_MAGNET_SALES_CONTACTS = 'SUCCEED_INIT_MAGNET_SALES_CONTACTS';
export const REQUEST_SEARCH_MAGNET_SALES_CONTACTS = 'REQUEST_SEARCH_MAGNET_SALES_CONTACTS';
export const SUCCEED_SEARCH_MAGNET_SALES_CONTACTS = 'SUCCEED_SEARCH_MAGNET_SALES_CONTACTS';
export const REQUEST_REFRESH_MAGNET_SALES_CONTACTS = 'REQUEST_REFRESH_MAGNET_SALES_CONTACTS';
export const SUCCEED_REFRESH_MAGNET_SALES_CONTACTS = 'SUCCEED_REFRESH_MAGNET_SALES_CONTACTS';
export const SET_MAGNET_SALES_CONTACTS_SEARCH = 'SET_MAGNET_SALES_CONTACTS_SEARCH';
export const REQUEST_UPDATE_MAGNET_SALES_CONTACTS = 'REQUEST_UPDATE_MAGNET_SALES_CONTACTS';
export const SUCCEED_UPDATE_MAGNET_SALES_CONTACTS = 'SUCCEED_UPDATE_MAGNET_SALES_CONTACTS';
export const SET_STATUS_MAGNET_SALES_CONTACTS = 'SET_STATUS_MAGNET_SALES_CONTACTS';
export const SET_SELECTED_MAGNET_SALES_CONTACTS_STATUS = 'SET_SELECTED_MAGNET_SALES_CONTACTS_STATUS';
export const SET_SORT_MAGNET_SALES_CONTACTS = 'SET_SORT_MAGNET_SALES_CONTACTS';
export const CLEAR_FILTERS_MAGNET_CONTACTS_SALES = 'CLEAR_FILTERS_MAGNET_CONTACTS_SALES';
export const SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_SALES = 'SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_SALES';