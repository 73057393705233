import {setSortMeetToMatch, requestInitMeetToMatch, succeedInitMeetToMatch, requestSearchMeetToMatch, succeedSearchMeetToMatch} from "./actions";
import {getSortOrder} from "../../helpers/utils";
import {getMeetToMatchConferenceRequest} from "../../services/grpcBufServices";

export const initMeetToMatch = () =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatch: {limit, offset, search, sortOrder, sortField}} = getState();
        dispatch(requestInitMeetToMatch());
        const order = getSortOrder(sortOrder);
        const response = await getMeetToMatchConferenceRequest(token, limit, offset, search, order, sortField);
        dispatch(succeedInitMeetToMatch(response));
    };

export const searchMeetToMatch = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatch: {limit, offset, search, sortOrder, sortField}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchMeetToMatch(updateLimit, updateOffset));
        const order = getSortOrder(sortOrder);
        const response = await getMeetToMatchConferenceRequest(token, updateLimit, updateOffset, search, order, sortField);
        dispatch(succeedSearchMeetToMatch(response));
    };

export const setSortAsync = (order, sortField) =>
    async (dispatch, getState) => {
        dispatch(setSortMeetToMatch(order, sortField));
    };