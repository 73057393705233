import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {convertToArrayOfObjectsKeys} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    eventId: "",
    isFiltersSelected: false,

    attendeeTags: [],
    selectedAttendeeTags: [],
    preferredDealSizes: [],
    selectedPreferredDealSizes: [],
    investmentTypes: [],
    selectedInvestmentTypes: [],
    lookingFor: [],
    selectedLookingFor: [],
    industry: [],
    selectedIndustry: [],
    platform: [],
    selectedPlatform: [],
    countries: [],
    selectedCountries: [],
    companySize: [],
    selectedCompanySize: [],
    greenFlag: [
        {id: true, name: 'Yes'},
        {id: false, name: 'No'}
    ],
    selectedGreenFlag: [
        {id: true, name: 'Yes'},
        {id: false, name: 'No'}
    ],
    redFlag: [
        {id: true, name: 'Yes'},
        {id: false, name: 'No'}
    ],
    selectedRedFlag: [
        {id: true, name: 'Yes'},
        {id: false, name: 'No'}
    ]
};

export default function meetToMatchConference(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                status: statuses.INIT_MEET_TO_MATCH_CONFERENCE_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                status: statuses.INIT_MEET_TO_MATCH_CONFERENCE_SUCCEEDED,
                list: action.response.result,
                total: action.response.total
            }
        case actions.REQUEST_SEARCH_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                status: statuses.SEARCH_MEET_TO_MATCH_CONFERENCE_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                status: statuses.SEARCH_MEET_TO_MATCH_CONFERENCE_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.SUCCEED_MEET_TO_MATCH_CONFERENCE_FILTER_DATA:
            const attendeeTagsList = convertToArrayOfObjectsKeys(action.response.attendeeTags);
            const preferredDealSizesList = convertToArrayOfObjectsKeys(action.response.preferredDealSizes);
            const lookingForList = convertToArrayOfObjectsKeys(action.response.lookingFor);
            const industryList = convertToArrayOfObjectsKeys(action.response.industries);
            const platformList = convertToArrayOfObjectsKeys(action.response.platforms);
            const countriesList = convertToArrayOfObjectsKeys(action.response.countries);
            const companySizesList = convertToArrayOfObjectsKeys(action.response.companySizes);
            const investmentTypesList = convertToArrayOfObjectsKeys(action.response.lookingForInvestmentTypes);

            return {
                ...state,
                attendeeTags: attendeeTagsList,
                selectedAttendeeTags: attendeeTagsList,
                preferredDealSizes: preferredDealSizesList,
                selectedPreferredDealSizes: preferredDealSizesList,
                lookingFor: lookingForList,
                selectedLookingFor: lookingForList,
                industry: industryList,
                selectedIndustry: industryList,
                platform: platformList,
                selectedPlatform: platformList,
                countries: countriesList,
                selectedCountries: countriesList,
                companySize: companySizesList,
                selectedCompanySize: companySizesList,
                investmentTypes: investmentTypesList,
                selectedInvestmentTypes: investmentTypesList,
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_ATTENDEE_TAG:
            return {
                ...state,
                selectedAttendeeTags: action.attendeeTags
            }
        case actions.SET_IS_FILTER_SELECTED_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_PREFERRED_DEAD_SIZES:
            return {
                ...state,
                selectedPreferredDealSizes: action.preferredDealSizes
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_LOOKING_FOR:
            return {
                ...state,
                selectedLookingFor: action.lookingFor
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_INDUSTRY:
            return {
                ...state,
                selectedIndustry: action.industry
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_PLATFORM:
            return {
                ...state,
                selectedPlatform: action.platform
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_COUNTRIES:
            return {
                ...state,
                selectedCountries: action.countries
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_COMPANY_SIZE:
            return {
                ...state,
                selectedCompanySize: action.companySize
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_GREEN_FLAG:
            return {
                ...state,
                selectedGreenFlag: action.greenFlag
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_RED_FLAG:
            return {
                ...state,
                selectedRedFlag: action.redFlag
            }
        case actions.SET_MEET_TO_MATCH_CONFERENCE_INVESTMENT_TYPES:
            return {
                ...state,
                investmentTypes: action.investmentTypes
            }
        case actions.CLEAR_FILTERS_MEET_TO_MATCH:
            return {
                ...state,
                selectedCountries: state.countries,
                selectedIndustry: state.industry,
                selectedAttendeeTags: state.attendeeTags,
                selectedCompanySize: state.companySize,
                selectedGreenFlag: state.greenFlag,
                selectedRedFlag: state.redFlag,
                selectedLookingFor: state.lookingFor,
                selectedPlatform: state.platform,
                selectedInvestmentTypes: state.investmentTypes,
                selectedPreferredDealSizes: state.preferredDealSizes
            }
        case actions.SET_EVENT_ID_MEET_TO_MATCH_CONFERENCE:
            return {
                ...state,
                eventId: action.eventId
            }
        default:
            return state;
    }
}