import isEqual from "lodash/isEqual";
import {
    getCrowdinRecordsRequest,
    getLastUpdateCrowdinRequest,
    exportCrowdinRequest,
    getFilterDataCrowdinRequest
} from "../../services/grpcBufServices";
import {
    requestInitCrowdin, succeedInitCrowdin, requestSearchCrowdin,
    succeedSearchCrowdin, setSearch, setReleased, setProjectType,
    setTargetLanguagesCount, requestLastUpdateCrowdin, succeedLastUpdateCrowdin,
    succeedGetFilterData, clearFilters, setLastActivity
} from "./actions";
import {getReleasedValue} from "../../helpers/dateUtils";
import {requestExport, succeedExport} from "../App/actions";

export const initCrowdin = () =>
    async (dispatch, getState) => {
        const {app: {token}, crowdin: {limit, offset}} = getState();
        dispatch(requestInitCrowdin());
        const response = await getCrowdinRecordsRequest(token, limit, offset);
        dispatch(succeedInitCrowdin(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateCrowdin());
        const lastUpdate = await getLastUpdateCrowdinRequest(token);
        dispatch(succeedLastUpdateCrowdin(lastUpdate));
    };

export const searchCrowdin = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, crowdin: {limit, offset, search, releasedDateRange, lastActivityDateRange, defaultDateRange, targetLanguagesCount, selectedProjectType, projectTypes}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const released = getReleasedValue(releasedDateRange);
        const lastActivity = getReleasedValue(lastActivityDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const lastActivityDate = isEqual(lastActivity, defaultDate) ? null : getReleasedValue(lastActivityDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        dispatch(requestSearchCrowdin(updateLimit, updateOffset));
        const response = await getCrowdinRecordsRequest(token, updateLimit, updateOffset, releasedDate, search, targetLanguagesCount, projectTypeOptions, lastActivityDate);
        dispatch(succeedSearchCrowdin(response));
    };

export const refreshCrowdin = () =>
    async (dispatch, getState) => {
        const {app: {token}, crowdin: {limit, search, offset, releasedDateRange, defaultDateRange, lastActivityDateRange, targetLanguagesCount, selectedProjectType, projectTypes}} = getState();
        dispatch(requestInitCrowdin());
        const released = getReleasedValue(releasedDateRange);
        const lastActivity = getReleasedValue(lastActivityDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const lastActivityDate = isEqual(lastActivity, defaultDate) ? null : getReleasedValue(lastActivityDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const response = await getCrowdinRecordsRequest(token, limit, offset, releasedDate, search, targetLanguagesCount, projectTypeOptions, lastActivityDate);
        dispatch(succeedInitCrowdin(response));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
            dispatch(setSearch(search));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setLastActivityAsync = (lastActivity) =>
    async (dispatch, getState) => {
        dispatch(setLastActivity(lastActivity));
    };

export const setProjectTypeAsync = (projectType) =>
    async (dispatch, getState) => {
        dispatch(setProjectType(projectType));
    };

export const setTargetLanguagesCountAsync = (targetLanguagesCount) =>
    async (dispatch, getState) => {
        dispatch(setTargetLanguagesCount(targetLanguagesCount));
    };

export const exportCrowdin = () =>
    async (dispatch, getState) => {
        const {app: {token}, crowdin: {search, releasedDateRange, defaultDateRange, lastActivityDateRange, targetLanguagesCount, selectedProjectType, projectTypes}} = getState();
        dispatch(requestExport());
        const released = getReleasedValue(releasedDateRange);
        const lastActivity = getReleasedValue(lastActivityDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const lastActivityDate = isEqual(lastActivity, defaultDate) ? null : getReleasedValue(lastActivityDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const exportId = await exportCrowdinRequest(token, releasedDate, search, targetLanguagesCount, projectTypeOptions, lastActivityDate);
        dispatch(succeedExport(exportId.task.id));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getFilterDataCrowdinRequest(token);
        dispatch(succeedGetFilterData(response));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };