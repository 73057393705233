import React from "react";
import classnames from "classnames";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import forEach from "lodash/forEach";

const SelectedAppstoreFilters = ({filters, onEdit, onClose, isVisible}) => {

    const getRenderFiltersValues = () => {
        return map(filters, (item, i) => {
            const filterProps = item.props.children.props;
            const {type, title, selectedOptions, value, filterName, options, optionCount} = filterProps;
            const optionsCount = selectedOptions?.length;
            const languagesFilterTitle = optionsCount && optionsCount !== options.length ? `${title} ${optionsCount}` : title;
            const filterTitle = filterName ? languagesFilterTitle : title;
            let customFilterName = '';
            let filterValue = "";

            if (type === 'select') {
                const {selectedOptions, options, isOnlyChosen, filterName} = filterProps;

                if (isOnlyChosen) {
                    customFilterName = <span className="only-chosen-count-filter">(Only chosen {filterName})</span>;
                }
                else if (optionCount?.from || optionCount?.to) {
                    const fromText = optionCount.from && `From ${optionCount.from}`;
                    const toText = optionCount.to && `To ${optionCount.to}`;
                    const optionCountText = `${fromText} ${toText}`.trim();
                    customFilterName = <span className="only-chosen-count-filter">({optionCountText})</span>;
                }

                let selectedValue = '';
                if (typeof selectedOptions === 'number') {
                    forEach(options, option => {if (option.id === selectedOptions) selectedValue = option.name});
                }
                else if (typeof selectedOptions !== 'object') {
                    selectedValue = selectedOptions;
                }
                else if (selectedOptions.length === options.length) {
                    selectedValue = 'All';
                }
                else if (selectedOptions.length === 0 || !selectedOptions.length) {
                    selectedValue = 'None';
                }
                else {
                    selectedValue = map(selectedOptions, item => item.name);
                }
                const renderValue = typeof selectedValue === 'object'
                    ? join(selectedValue, ', ')
                    : selectedValue;
                filterValue = <span className="filter-value" title={renderValue}>{renderValue}</span>;

            }

            else if (type === 'dateRange') {
                const {startDateInput, endDateInput} = filterProps.initDates;
                const renderValue = `${startDateInput} - ${endDateInput}`;
                filterValue = <span className="filter-value" title={renderValue}>{renderValue}</span>
            }

            else if (type === 'downloadCount') {
                const {from, to} = filterProps.value;
                const renderValue = isEmpty(filterProps.value) ? 'All' : `${from} - ${to}`;
                filterValue = <span className="filter-value" title={renderValue}>{renderValue}</span>
            }

            else if (type === 'languagesCount') {
                filterValue = value;
            }

            return <div key={i} className="selected-filter__container">
                <span className="filter-name">{filterTitle}{customFilterName}:</span>
                <span className="filter-value" title={filterValue}>{filterValue}</span>
            </div>
        })
    }

    const renderValue = getRenderFiltersValues();
    const appstoreSelectedFiltersStyle = classnames("selected-filters", {"visible": isVisible});

    return (
        <div className={appstoreSelectedFiltersStyle}>
            {renderValue}
            <div className="selected-filters-icons">
                <div className="filters-edit-icon" onClick={onEdit}/>
                <div className="filters-close-icon" onClick={onClose}/>
            </div>
        </div>
    )
}

export default SelectedAppstoreFilters;