export const REQUEST_INIT_MEET_TO_MATCH_CONFERENCE = "REQUEST_INIT_MEET_TO_MATCH_CONFERENCE";
export const SUCCEED_INIT_MEET_TO_MATCH_CONFERENCE = "SUCCEED_INIT_MEET_TO_MATCH_CONFERENCE";
export const REQUEST_SEARCH_MEET_TO_MATCH_CONFERENCE = "REQUEST_SEARCH_MEET_TO_MATCH_CONFERENCE";
export const SUCCEED_SEARCH_MEET_TO_MATCH_CONFERENCE = "SUCCEED_SEARCH_MEET_TO_MATCH_CONFERENCE";
export const REQUEST_MEET_TO_MATCH_CONFERENCE_FILTER_DATA = "REQUEST_MEET_TO_MATCH_CONFERENCE_FILTER_DATA";
export const SUCCEED_MEET_TO_MATCH_CONFERENCE_FILTER_DATA = "SUCCEED_MEET_TO_MATCH_CONFERENCE_FILTER_DATA";

export const SET_MEET_TO_MATCH_CONFERENCE_SEARCH = "SET_MEET_TO_MATCH_CONFERENCE_SEARCH";
export const SET_MEET_TO_MATCH_CONFERENCE_ATTENDEE_TAG = "SET_MEET_TO_MATCH_CONFERENCE_ATTENDEE_TAG";
export const SET_IS_FILTER_SELECTED_MEET_TO_MATCH_CONFERENCE = "SET_IS_FILTER_SELECTED_MEET_TO_MATCH_CONFERENCE";
export const SET_MEET_TO_MATCH_CONFERENCE_PREFERRED_DEAD_SIZES = "SET_MEET_TO_MATCH_CONFERENCE_PREFERRED_DEAD_SIZES";
export const SET_MEET_TO_MATCH_CONFERENCE_INVESTMENT_TYPES = "SET_MEET_TO_MATCH_CONFERENCE_INVESTMENT_TYPES";
export const SET_MEET_TO_MATCH_CONFERENCE_LOOKING_FOR = "SET_MEET_TO_MATCH_CONFERENCE_LOOKING_FOR";
export const SET_MEET_TO_MATCH_CONFERENCE_INDUSTRY = "SET_MEET_TO_MATCH_CONFERENCE_INDUSTRY";
export const SET_MEET_TO_MATCH_CONFERENCE_PLATFORM = "SET_MEET_TO_MATCH_CONFERENCE_PLATFORM";
export const SET_MEET_TO_MATCH_CONFERENCE_COUNTRIES = "SET_MEET_TO_MATCH_CONFERENCE_COUNTRIES";
export const SET_MEET_TO_MATCH_CONFERENCE_COMPANY_SIZE = "SET_MEET_TO_MATCH_CONFERENCE_COMPANY_SIZE";
export const SET_MEET_TO_MATCH_CONFERENCE_GREEN_FLAG = "SET_MEET_TO_MATCH_CONFERENCE_GREEN_FLAG";
export const SET_MEET_TO_MATCH_CONFERENCE_RED_FLAG = "SET_MEET_TO_MATCH_CONFERENCE_RED_FLAG";
export const SET_EVENT_ID_MEET_TO_MATCH_CONFERENCE = "SET_EVENT_ID_MEET_TO_MATCH_CONFERENCE";
export const CLEAR_FILTERS_MEET_TO_MATCH = "CLEAR_FILTERS_MEET_TO_MATCH";