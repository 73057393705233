import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";

const initialState = {
    decideObject: {},
    status: null,
    counts: {},
    closeFilter: '',
    isProcessing: false,
    processingMessage: "",
    noMoreRecordsMessage: ""
};

export default function subscribeDecide(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_GET_DECIDE_RECORD:
            return {
                ...state,
                decideObject: {},
                status: statuses.GET_DECIDE_RECORD_REQUESTED
            }
        case actions.SUCCEED_GET_DECIDE_RECORD:
            if (action.response.case === 'record') {
                return {
                    ...state,
                    status: statuses.GET_DECIDE_RECORD_SUCCEEDED,
                    decideObject: action.response.value,
                    processingMessage: "",
                    noMoreRecordsMessage: ""
                }
            }
            else {
                return {
                    ...state,
                    status: statuses.GET_DECIDE_RECORD_SUCCEEDED,
                    noMoreRecordsMessage: action.response.value.processing,
                    processingMessage: ""
                }
            }
        case actions.REQUEST_REFRESH_DECIDE_RECORD:
            return {
                ...state,
                decideObject: {},
                status: statuses.REFRESH_DECIDE_RECORD_REQUESTED
            }
        case actions.SUCCEED_REFRESH_DECIDE_RECORD:
            if (action.response.case === 'record') {
                return {
                    ...state,
                    status: statuses.REFRESH_DECIDE_RECORD_SUCCEEDED,
                    decideObject: action.response.value,
                    processingMessage: "",
                    noMoreRecordsMessage: ""
                }
            }
            else {
                return {
                    ...state,
                    status: statuses.REFRESH_DECIDE_RECORD_SUCCEEDED,
                    noMoreRecordsMessage: action.response.value.processing,
                    processingMessage: ""
                }
            }
        case actions.SUCCEED_GET_SUBSCRIBE_COUNTS:
            return {
                ...state,
                counts: action.response
            }
        case actions.SUCCEED_GET_SUBSCRIBE_CURRENT_FILTER:
            return {
                ...state,
                closeFilter: action.response.leadSearchQuery
            }
        case actions.REQUEST_UPDATE_SUBSCRIBE_FILTER:
            return {
                ...state,
            }
        case actions.SUCCEED_UPDATE_SUBSCRIBE_FILTER:
            const closeFilter = action.response.success ? action.response.message : state.closeFilter;
            return {
                ...state,
                closeFilter: closeFilter,
            }
        case actions.UPDATE_PROCESSING_MESSAGE:
            return {
                ...state,
                processingMessage: action.message
            }
        default:
            return state;
    }
}