export const REQUEST_INIT_CROWDIN = 'REQUEST_INIT_CROWDIN';
export const SUCCEED_INIT_CROWDIN = 'SUCCEED_INIT_CROWDIN';
export const SET_CROWDIN_SEARCH = 'SET_CROWDIN_SEARCH';
export const SET_CROWDIN_RELEASED = 'SET_CROWDIN_RELEASED';
export const REQUEST_SEARCH_CROWDIN = 'REQUEST_SEARCH_CROWDIN';
export const SUCCEED_SEARCH_CROWDIN = 'SUCCEED_SEARCH_CROWDIN';
export const REQUEST_LAST_UPDATE_CROWDIN = 'REQUEST_LAST_UPDATE_CROWDIN';
export const SUCCEED_LAST_UPDATE_CROWDIN = 'SUCCEED_LAST_UPDATE_CROWDIN';
export const SET_CROWDIN_PROJECT_TYPE = 'SET_CROWDIN_PROJECT_TYPE';
export const SET_CROWDIN_TARGET_LANGUAGES_COUNT = 'SET_CROWDIN_TARGET_LANGUAGES_COUNT';
export const SUCCEED_GET_FILTER_DATA_CROWDIN = 'SUCCEED_GET_FILTER_DATA_CROWDIN';
export const CLEAR_FILTERS_CROWDIN = 'CLEAR_FILTERS_CROWDIN';
export const SET_IS_FILTER_SELECTED_CROWDIN = 'SET_IS_FILTER_SELECTED_CROWDIN';
export const SET_CROWDIN_LAST_ACTIVITY = 'SET_CROWDIN_LAST_ACTIVITY';