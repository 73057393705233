import * as types from "./constants";

export function setSortMeetToMatch(order, sortField) {
    return {
        type: types.SET_SORT_MEET_TO_MATCH,
        order, sortField
    };
}

export function requestInitMeetToMatch() {
    return {
        type: types.REQUEST_INIT_MEET_TO_MATCH
    };
}

export function succeedInitMeetToMatch(response) {
    return {
        type: types.SUCCEED_INIT_MEET_TO_MATCH,
        response
    };
}

export function requestSearchMeetToMatch(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_MEET_TO_MATCH,
        limit, offset
    };
}

export function succeedSearchMeetToMatch(response) {
    return {
        type: types.SUCCEED_SEARCH_MEET_TO_MATCH,
        response
    };
}

