import React, {Component} from 'react';
import classNames from 'classnames';
import {convertDateFormat} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import IFrame from "../IFrame";

class DecideLetter extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const base = document.querySelector('base');
        base && base.setAttribute('href', '');
    }

    openCloseIo = () => {
        const {leadId} = this.props;
        window.open(`https://app.close.com/lead/${leadId}`);
    }

    render() {
        const {from, to, subject, date, bodyHtml, totalLeft} = this.props;
        const receivedDate = date && convertDateFormat(date, CONST.DATE_FORMAT_WITH_TIME);
        const letterClassName = classNames("subscribe-decide__letter", {
            "hidden": totalLeft === 0
        });

        const renderTitle = totalLeft === 0 ? 'There are no messages' : 'Latest message';

        return (
            <React.Fragment>
                <div className="subscribe-decide__title-message">{renderTitle}</div>
                <div className={letterClassName}>
                    <div className="subscribe-decide__letter-title">
                        <div className="info__container">
                            <div className="info__content">
                                <div className="title">Received</div>
                                <div className="value">{receivedDate}</div>
                            </div>

                            <div className="info__content">
                                <div className="title">From</div>
                                <div className="value">{from}</div>
                            </div>

                            <div className="info__content">
                                <div className="title">To</div>
                                <div className="value">{to}</div>
                            </div>

                            <div className="info__content">
                                <div className="title">Subject</div>
                                <div className="value">{subject}</div>
                            </div>
                        </div>
                        <div className="close-io__container">
                            <div className="title">Open in</div>
                            <div className="close-io-button" onClick={this.openCloseIo}/>
                        </div>
                    </div>
                    <div className="subscribe-decide__letter-body">
                        {bodyHtml && <IFrame width='100%' srcDoc={`<html><body>${bodyHtml}</body></html>`} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DecideLetter;