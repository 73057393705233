import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {setCrunchbaseTypeField, convertToArrayOfObjects} from "../../helpers/utils";
import {crunchbaseProjectTypes} from "../../constants/CrunchbaseProjectTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";

const initialState = {
    status: null,
    limit: 10,
    total: 0,
    offset: 0,
    list: [],
    selectedProjectType: crunchbaseProjectTypes,
    projectTypes: crunchbaseProjectTypes,
    locationsList: [],
    selectedLocations: '',
    rank: {},
    lastUpdate: null,
    count: '',
    createdDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    createdStartDate: null,
    isFiltersSelected: false,
    maxRankOrg: '',
    isCountSocialsLoading: false
};

export default function crunchbase(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_CRUCHBASE:
            return {
                ...state,
                status: statuses.INIT_CRUCHBASE_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_CRUCHBASE:
            return {
                ...state,
                status: statuses.INIT_CRUCHBASE_SUCCEEDED,
                list: setCrunchbaseTypeField(action.response.result),
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_CRUCHBASE:
            return {
                ...state,
                status: statuses.SEARCH_CRUCHBASE_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_CRUCHBASE:
            return {
                ...state,
                status: statuses.SEARCH_CRUCHBASE_SUCCEEDED,
                list: setCrunchbaseTypeField([...state.list, ...action.response.result]),
                total: action.response.total
            }
        case actions.SET_CRUNCHBASE_PROJECT_TYPE:
            return {
                ...state,
                selectedProjectType: action.projectType,
            }
        case actions.GET_CRUNCHBASE_FILTER_DATA_REQUEST:
            return {
                ...state,
                status: statuses.GET_FILTER_DATA_CRUNCHBASE_REQUESTED,
            }
        case actions.GET_CRUNCHBASE_FILTER_DATA_SUCCEEDED:
            const locationList = convertToArrayOfObjects(action.filterData.locations);
            const createdStartDate = action.filterData.createdStartDate;
            return {
                ...state,
                status: statuses.GET_FILTER_DATA_CRUNCHBASE_SUCCEEDED,
                locationsList: locationList,
                createdStartDate: createdStartDate,
                createdDateRange: getDefaultReleased(createdStartDate),
                defaultDateRange: getDefaultReleased(createdStartDate),
                maxRankOrg: action.filterData.maxRankOrg
            }
        case actions.SET_SELECTED_CRUNCHBASE_LOCATIONS:
            return {
                ...state,
                selectedLocations: action.selectedLocations,
            }
        case actions.SET_CRUNCHBASE_RANK:
            return {
                ...state,
                rank: action.rank,
            }
        case actions.SET_CRUNCHBASE_CREATED:
            return {
                ...state,
                createdDateRange: action.created,
            }
        case actions.REQUEST_LAST_UPDATE_CRUNCHBASE:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_CRUNCHBASE:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_GET_COUNT_CRUNCHBASE:
            return {
                ...state,
                isCountSocialsLoading: true
            }
        case actions.SUCCEED_GET_COUNT_CRUNCHBASE:
            return {
                ...state,
                count: action.count,
                isCountSocialsLoading: false
            }
        case actions.SET_IS_FILTER_SELECTED_CRUNCHBASE:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_CRUNCHBASE:
            return {
                ...state,
                selectedProjectType: state.projectTypes,
                selectedLocations: '',
                createdDateRange: state.defaultDateRange,
                rank: {}
            }
        default:
            return state;
    }
}