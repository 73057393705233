import * as types from "./constants";

export function requestInitGooglePlay() {
    return {
        type: types.REQUEST_INIT_GOOGLE_PLAY
    };
}

export function succeedInitGooglePlay(response) {
    return {
        type: types.SUCCEED_INIT_GOOGLE_PLAY,
        response
    };
}

export function requestGetCountGooglePlay() {
    return {
        type: types.REQUEST_GET_COUNT_GOOGLE_PLAY
    };
}

export function succeedGetCountGooglePlay(count) {
    return {
        type: types.SUCCEED_GET_COUNT_GOOGLE_PLAY,
        count
    };
}

export function requestSearchGooglePlay(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_GOOGLE_PLAY,
        limit, offset
    };
}

export function succeedSearchGooglePlay(response) {
    return {
        type: types.SUCCEED_SEARCH_GOOGLE_PLAY,
        response
    };
}

export function setCategory(category) {
    return {
        type: types.SET_GOOGLE_PLAY_CATEGORY,
        category
    };
}

export function setCountry(selectedCountry) {
    return {
        type: types.SET_GOOGLE_PLAY_COUNTRY,
        selectedCountry
    };
}

export function setReleased(released) {
    return {
        type: types.SET_GOOGLE_PLAY_RELEASED,
        released
    };
}

export function setUpdated(updated) {
    return {
        type: types.SET_GOOGLE_PLAY_UPDATED,
        updated
    };
}

export function setHasVideo(hasVideo) {
    return {
        type: types.SET_GOOGLE_PLAY_HAS_VIDEO,
        hasVideo
    };
}

export function setPrice(selectedPrice) {
    return {
        type: types.SET_GOOGLE_PLAY_PRICE,
        selectedPrice
    };
}

export function setDownloads(downloads) {
    return {
        type: types.SET_GOOGLE_PLAY_DOWNLOADS,
        downloads
    };
}

export function setAppCount(appCount) {
    return {
        type: types.SET_GOOGLE_PLAY_APP_COUNT,
        appCount
    };
}

export function setSearch(search) {
    return {
        type: types.SET_GOOGLE_PLAY_SEARCH,
        search
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_GOOGLE_PLAY,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_GOOGLE_PLAY,
        lastUpdate
    };
}

export function succeedGetFilterData(response, isInsightsRequest) {
    return {
        type: types.SUCCEED_GET_FILTER_DATA,
        response, isInsightsRequest
    };
}

export function setCountriesCount(countriesCount) {
    return {
        type: types.SET_COUNTRIES_COUNT_PLAY,
        countriesCount
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_GOOGLE_PLAY,
    };
}

export function setIsOnlyChosenMarkets(isOnlyChosenMarkets) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_MARKETS_GOOGLE_PLAY,
        isOnlyChosenMarkets
    };
}

export function setScore(score) {
    return {
        type: types.SET_GOOGLE_PLAY_SCORE,
        score
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_GOOGLE_PLAY,
        isFiltersSelected
    };
}