import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {deleteItemFromArray} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: ''
};

export default function twitter(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_TWITTER_AUDIENCES:
            return {
                ...state,
                status: statuses.INIT_TWITTER_AUDIENCES_REQUESTED,
            }
        case actions.SUCCEED_INIT_TWITTER_AUDIENCES:
            return {
                ...state,
                status: statuses.INIT_TWITTER_AUDIENCES_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_TWITTER_AUDIENCES:
            return {
                ...state,
                status: statuses.SEARCH_TWITTER_AUDIENCES_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_TWITTER_AUDIENCES:
            return {
                ...state,
                status: statuses.SEARCH_TWITTER_AUDIENCES_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.REQUEST_CREATE_TWITTER_AUDIENCES:
            return {
                ...state,
            }
        case actions.SUCCEED_CREATE_TWITTER_AUDIENCES:
            return {
                ...state,
                list: [action.response.audience, ...state.list],
                limit: state.limit + 1,
                total: state.total + 1
            }
        case actions.SET_SEARCH_TWITTER_AUDIENCES:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case statuses.REMOVE_TWITTER_AUDIENCE_SUCCEED:
            return {
                ...state,
                list: deleteItemFromArray(state.list, action.id),
                total: state.total - 1,
                limit: state.limit - 1
            }
        default:
            return state;
    }
}