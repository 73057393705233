import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {updateItemInArray} from "../../helpers/utils";

const initialState = {
    limit: 10,
    offset: 0,
    total: 0,
    status: null,
    list: [],
    search: '',
    countSubscribe: {},
    decisionStatus: [
        {id: 1, name: "New"},
        {id: 2, name: "Processed"},
        {id: 3, name: "Snoozed"},
        {id: 4, name: "Skipped"},
    ],
    selectedDecisionStatus: 'All',

    sortField: 'imported',
    sortOrder: 'asc',
    isFiltersSelected: false,
};

export default function subscribeList(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.INIT_SUBSCRIBE_LIST_REQUESTED,
                countSubscribe: null,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.INIT_SUBSCRIBE_LIST_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
                countSubscribe: action.response.count,
            }
        case actions.REQUEST_SEARCH_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.SEARCH_SUBSCRIBE_LIST_SALES_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.SEARCH_SUBSCRIBE_LIST_SALES_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.REQUEST_REFRESH_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.REFRESH_SUBSCRIBE_LIST_REQUESTED,
                limit: 10,
                offset: 0,
                total: 0,
                list: [],
            }
        case actions.SUCCEED_REFRESH_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.REFRESH_SUBSCRIBE_LIST_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_UPDATE_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.SUBSCRIBE_LIST_UPDATE_REQUESTED,
                list: updateItemInArray(state.list, action.updatedItem),
            }
        case actions.SUCCEED_UPDATE_SUBSCRIBE_LIST:
            return {
                ...state,
                status: statuses.SUBSCRIBE_LIST_UPDATE_REQUESTED,
                list: updateItemInArray(state.list, action.response.contact),
                countSubscribe: action.response.count
            }
        case actions.SUCCEED_UPDATE_SUBSCRIBE_RECORD:
            return {
                ...state,
                status: statuses.SUBSCRIBE_LIST_UPDATE_REQUESTED,
                list: updateItemInArray(state.list, action.response.contact),
                countSubscribe: action.response.count
            }
        case actions.SET_SEARCH_SUBSCRIBE_LIST:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_SORT_SUBSCRIBE_LIST:
            return {
                ...state,
                sortField: action.field,
                sortOrder: action.order,
            }
        case actions.SET_DECISION_STATUS_SUBSCRIBE:
            return {
                ...state,
                selectedDecisionStatus: action.selectedDecisionStatus,
            }
        case actions.SET_IS_FILTER_SELECTED_SUBSCRIBE:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_SUBSCRIBE:
            return {
                ...state,
                isOnlyChosenLanguages: false,
                selectedDecisionStatus: 'All',
            }
        default:
            return state;
    }
}