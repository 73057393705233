import React from 'react';
import classnames from 'classnames';
import Button from "../Button";

const FiltersDropdown = ({filters, onApply, onClear, exitFilters, isOpen}) => {

    const handleClear = () => {
        onClear();
    }

    const handleApply = () => {
        onApply();
    }

    const appstoreFiltersStyle = classnames("filters-container", {"visible": isOpen});

    return (
        <div className={appstoreFiltersStyle}>
            <div className="exit-icon" onClick={exitFilters}/>
            {filters}
            <div className="select-items__buttons">
                <Button className="clear-filters" title="Clear filters" onClick={handleClear}/>
                <Button className="submit-filters" title="Apply filters" onClick={handleApply}/>
            </div>
        </div>

    )
}

export default FiltersDropdown;