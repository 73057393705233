export const REQUEST_INIT_NINTENDO = 'REQUEST_INIT_NINTENDO';
export const SUCCEED_INIT_NINTENDO = 'SUCCEED_INIT_NINTENDO';
export const REQUEST_SEARCH_NINTENDO = 'REQUEST_SEARCH_NINTENDO';
export const SUCCEED_SEARCH_NINTENDO = 'SUCCEED_SEARCH_NINTENDO';
export const REQUEST_LAST_UPDATE_NINTENDO = 'REQUEST_LAST_UPDATE_NINTENDO';
export const SUCCEED_LAST_UPDATE_NINTENDO = 'SUCCEED_LAST_UPDATE_NINTENDO';
export const SET_NINTENDO_SEARCH = 'SET_NINTENDO_SEARCH';
export const SET_NINTENDO_FILTER_DATA = 'SET_NINTENDO_FILTER_DATA';
export const SET_SELECTED_LANGUAGES_NINTENDO = 'SET_SELECTED_LANGUAGES_NINTENDO';
export const SET_RELEASED_NINTENDO = 'SET_RELEASED_NINTENDO';
export const SET_COUNT_LANGUAGES_NINTENDO = 'SET_COUNT_LANGUAGES_NINTENDO';
export const SET_TYPE_LANGUAGES_NINTENDO = 'SET_TYPE_LANGUAGES_NINTENDO';
export const SET_NINTENDO_SELECTED_GENRES = 'SET_NINTENDO_SELECTED_GENRES';
export const SET_NINTENDO_SELECTED_AVAILABILITIES = 'SET_NINTENDO_SELECTED_AVAILABILITIES';
export const SET_IS_ONLY_CHOSEN_LANGUAGES_NINTENDO = 'SET_IS_ONLY_CHOSEN_LANGUAGES_NINTENDO';
export const CLEAR_FILTERS_NINTENDO = 'CLEAR_FILTERS_NINTENDO';
export const SET_IS_FILTER_SELECTED_NINTENDO = 'SET_IS_FILTER_SELECTED_NINTENDO';