import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import moment from "moment";
import map from "lodash/map";
import {routes} from "../../constants/AppConstants";
import {
    getCountPlayRecords,
    getFilterData as getPlayFilterData,
    getLastUpdate as getPlayLastUpdate,
    getPlayInsights,
    setDownloadsAsync,
    setHasVideoAsync,
    setReleasedAsync,
    setUpdatedAsync,
} from "../GooglePlay/actionsAsync";
import {setIsFiltersSelected} from "../AppStore/actions";
import {setIsFiltersSelected as setIsFiltersSelectedGooglePlay} from "../GooglePlay/actions";
import {
    setScoreAsync,
    setUpdatedAsync as setUpdatedAppStore,
    setHasVideoAsync as setHasVideoAppStore,
    getAppStoreInsights,
    getLastUpdate as getAppStoreLastUpdate,
    getFilterDataAppStore as getAppStoreFilterData,
    getCount
} from "../AppStore/actionsAsync";
import CONST from "../../constants/Constants";

import "../../css/dashboard.css";

class Dashboard extends Component {

    state = {
        hoverIndex: -1,
    }

    setHoverKey = hoverIndex => this.setState({hoverIndex});

    onNavigate = route => {
        const {history} = this.props;
        history.push(route);
    };

    getDashboardItems = (isTools) => {
        const {hoverIndex} = this.state;

        return map(routes, (item, i) => {
            const {name, icon, hoverIcon, route, isDashboardTools} = item;
            const isHover = hoverIndex === i;
            const iconStyle = classNames(icon, {[hoverIcon]: isHover});
            const textStyle = classNames("service-name", {"name-hover": isHover});
            const isRenderItem = isTools ? isDashboardTools : !isDashboardTools;
            return isRenderItem && <div className="dashboard__item" onMouseEnter={() => this.setHoverKey(i)}
                      onMouseLeave={() => this.setHoverKey(-1)} onClick={() => this.onNavigate(route)} key={i}>
                <div className="dashboard__image-container">
                    <div className={iconStyle}/>
                </div>
                <p className={textStyle}>{name}</p>
            </div>
        })
    }

    redirectToPlay = (isDownloadFilter) => {
        const {history, setReleased, getPlayInsights, setIsFiltersSelectedGooglePlay, getPlayLastUpdate, getPlayFilterData, setUpdatedPlay, setDownloads, setHasVideoPlay, playStatus, getCountPlayRecords} = this.props;
        const endDate = moment();
        const endDateInput = endDate.format(CONST.DATE_STANDARD_FORMAT);
        if (isDownloadFilter) {
            const startDateYear = moment().year() - 1;
            const startDateInput = `01.01.${startDateYear}`;
            const startDate = moment(startDateInput);
            setUpdatedPlay({
                startDate,
                endDate,
                startDateInput,
                endDateInput
            });
            setHasVideoPlay([{id: 2, name: 'No'}]);
            setDownloads({from: 5000, to: ''});
        }
        else {
            const startDate = moment().subtract(30, 'd');
            const startDateInput = startDate.format(CONST.DATE_STANDARD_FORMAT);
            setReleased({
                startDate,
                endDate,
                startDateInput,
                endDateInput
            });
        }
        if (playStatus === null) {
            getPlayLastUpdate();
            getPlayFilterData(true);
        }
        setIsFiltersSelectedGooglePlay(true);
        getPlayInsights();
        getCountPlayRecords();

        history.push("/play");
    }

    redirectToAppStore = () => {
        const {history, setScore, setUpdatedAppStore, setHasVideoAppStore, getAppStoreInsights, getAppStoreLastUpdate, getAppStoreFilterData, appStoreStatus,
            setIsFiltersSelected, getCount} = this.props;
        setScore({from: 4, to: ''});
        const endDate = moment();
        const endDateInput = endDate.format(CONST.DATE_STANDARD_FORMAT);
        const startDateYear = moment().subtract(1, 'y');
        const startDateInput = startDateYear.format(CONST.DATE_STANDARD_FORMAT);
        const startDate = moment(startDateInput);
        setUpdatedAppStore({
            startDate,
            endDate,
            startDateInput,
            endDateInput
        });
        setHasVideoAppStore([{id: 2, name: 'No'}]);

        if (appStoreStatus === null) {
            getAppStoreLastUpdate();
            getAppStoreFilterData(true);
        }

        setIsFiltersSelected(true);
        getAppStoreInsights();
        getCount();
        history.push("/appstore");
    }

    render() {
        const dashboardDatabasesItems = this.getDashboardItems(false);
        const dashboardToolsItems = this.getDashboardItems(true);

        return (
            <div className="dashboard__container">
                <div className="dashboard__kraken-logo"/>
                <div className="insights-container">
                    <h2>Insights</h2>
                    <p className="insights-links" onClick={() => this.redirectToPlay(false)}>⚡️Check out the top 10 applications for the past month (Google Play)</p>
                    <p className="insights-links" onClick={() => this.redirectToPlay(true)}>⚡️Check the top applications that do not have videos (Google Play)</p>
                    <p className="insights-links" onClick={this.redirectToAppStore}>⚡️Check the top applications that do not have videos (App Store)</p>
                </div>
                <div className="databases-container">
                    <h2>Databases</h2>
                    <div className="dashboard__items">
                        {dashboardDatabasesItems}
                    </div>
                </div>
                <div className="tools-container">
                    <h2>Tools</h2>
                    <div className="dashboard__items">
                        {dashboardToolsItems}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        playStatus: state.googlePlay.status,
        appStoreStatus: state.appStore.status,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        getPlayInsights: () => dispatch(getPlayInsights()),
        getPlayLastUpdate: () => dispatch(getPlayLastUpdate()),
        getPlayFilterData: (isInsightsRequest) => dispatch(getPlayFilterData(isInsightsRequest)),
        setUpdatedPlay: (updated) => dispatch(setUpdatedAsync(updated)),
        setDownloads: (downloads) => dispatch(setDownloadsAsync(downloads)),
        setHasVideoPlay: hasVideo => dispatch(setHasVideoAsync(hasVideo)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        setIsFiltersSelectedGooglePlay: isFiltersSelected => dispatch(setIsFiltersSelectedGooglePlay(isFiltersSelected)),

        getAppStoreInsights: () => dispatch(getAppStoreInsights()),
        setScore: (score) => dispatch(setScoreAsync(score)),
        setUpdatedAppStore: (updated) => dispatch(setUpdatedAppStore(updated)),
        setHasVideoAppStore: hasVideo => dispatch(setHasVideoAppStore(hasVideo)),
        getAppStoreLastUpdate: () => dispatch(getAppStoreLastUpdate()),
        getAppStoreFilterData: (isInsightsRequest) => dispatch(getAppStoreFilterData(isInsightsRequest)),
        getCountPlayRecords: () => dispatch(getCountPlayRecords()),
        getCount: () => dispatch(getCount()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);