import * as types from "./constants";

export function requestInitSteamPublishers() {
    return {
        type: types.REQUEST_INIT_STEAM_PUBLISHERS
    };
}

export function succeedInitSteamPublishers(response) {
    return {
        type: types.SUCCEED_INIT_STEAM_PUBLISHERS,
        response
    };
}

export function setSteamPublishersFilterData(response) {
    return {
        type: types.SET_STEAM_PUBLISHERS_FILTER_DATA,
        response
    };
}

export function requestSearchSteamPublishers(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_STEAM_PUBLISHERS,
        limit, offset
    };
}

export function succeedSearchSteamPublishers(response) {
    return {
        type: types.SUCCEED_SEARCH_STEAM_PUBLISHERS,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_SEARCH_LANGUAGES_STEAM_PUBLISHERS,
        search
    };
}

export function setReleased(released) {
    return {
        type: types.SET_STEAM_PUBLISHERS_RELEASED,
        released
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_STEAM_PUBLISHERS,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_STEAM_PUBLISHERS,
        lastUpdate
    };
}

export function setProductCount(productCount) {
    return {
        type: types.SET_PRODUCT_COUNT_STEAM_PUBLISHERS,
        productCount
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_STEAM_PUBLISHERS,
        isFiltersSelected
    };
}

export function clearFiltersSteamPublishers() {
    return {
        type: types.CLEAR_FILTERS_STEAM_PUBLISHERS,
    };
}