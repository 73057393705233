import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import Table from "../../components/Table";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";
import {
    exportSteamGames,
    initSteamGames,
    refreshSteamGames,
    searchSteamGames,
    setLanguageAsync,
    setSearchAsync,
    setGenresAsync,
    getLastUpdate,
    setReleasedAsync,
    getFilterData,
    setOptionsCountAsync,
    setPriceAsync,
    setEarlyAccessAsync,
    setReviewsAsync,
    clearFilters,
    getSteamCount
} from "./actionsAsync";
import {setIsOnlyChosenLanguages, setIsFiltersSelected} from "./actions";
import Link from "../../components/Link";
import {DATE_RANGE, DOWNLOAD_COUNT, SELECT} from "../../constants/FilterTypes";
import {
    INIT_STEAM_GAMES_REQUESTED, SEARCH_STEAM_GAMES_REQUESTED
} from "../../store/StatusTypes";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";
import ExportPopup from "../../components/Popup/components/ExportPopup";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'Steam Games';

const PAID = 'Paid';
const FREE = 'Free';
const NO = 'No';
const YES = 'Yes';

class SteamGames extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initSteamGames, getLastUpdate, status, lastUpdate, getSteamCount, getFilterData} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initSteamGames();
            getSteamCount();
            getFilterData();
        }
        lastUpdate === null && getLastUpdate();
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getLanguageValue = (selectedOptions, options) => {
        const {languagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = languagesCount.from ? `From ${languagesCount.from}` : '';
        const languagesCountTo = languagesCount.to ? `To ${languagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    getGenresValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    handleClearFilters = () => {
        const {clearFilters, initSteamGames, getSteamCount} = this.props;
        clearFilters().then(() => {
            initSteamGames();
            getSteamCount();
        });
    }

    handleRefresh = () => {
        const {refreshSteamGames, getSteamCount} = this.props;
        refreshSteamGames();
        getSteamCount();
    }

    onClearReleased = () => {
        const {setReleased, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => {
            this.handleRefresh();
        });
    }

    onExportSteam = () => {
        const {exportSteamGames, showPopup, fieldsList, getSteamCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getSteamCount}/>
        }

        const handleApply = (fields) => {
            exportSteamGames(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    getFiltersOptions = () => {
        const {setLanguage, selectedLanguage, languages, genres, selectedGenres, isOnlyChosenLanguages, setIsOnlyChosenLanguages, setGenres, releasedDateRange,
            defaultDateRange, setReleased, setOptionsCount, price, languagesCount, selectedPrice, setPrice, earlyAccess, setEarlyAccess, selectedEarlyAccess,
            reviews, setReviews, reviewsMax} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Languages',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getLanguageValue,
                selectedOptions: selectedLanguage,
                onChange: setLanguage,
                setOptionsCount: setOptionsCount,
                filterName: "languages",
                submitButtonName: 'Apply',
                isOnlyChosen: isOnlyChosenLanguages,
                setIsOnlyChosen: setIsOnlyChosenLanguages,
                optionCount: languagesCount,
            },
            {
                type: SELECT,
                title: 'Genres',
                popoverClassName: 'steam-genre-popover',
                options: genres,
                renderValue: this.getGenresValue,
                selectedOptions: selectedGenres,
                onChange: setGenres
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getGenresValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
            {
                type: SELECT,
                title: 'Early access',
                options: earlyAccess,
                renderValue: this.getGenresValue,
                selectedOptions: selectedEarlyAccess,
                onChange: setEarlyAccess,
                columnsCount: 1,
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Reviews',
                value: reviews,
                onChange: setReviews,
                submitButtonName: 'Submit',
                className: 'download-count-filter',
                placeholder: {from: 0, to: reviewsMax}
            }
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_STEAM_GAMES_REQUESTED
            || status === SEARCH_STEAM_GAMES_REQUESTED;
    }

    onShowMore = (count) => {
        const {searchSteamGames} = this.props;
        searchSteamGames(count);
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderLink = (value) => <Link path={value} value={value}/>;

    renderList = (row, column, value) => join(value, ', ');

    renderGenresList = (row, column, value) => {
        if (value && value.length) {
            const nameValues = map(value, option => option.name);
            return join(nameValues, ', ');
        }
    }

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderPriceCell = (row, column, value) => value ? FREE : PAID;

    renderEarlyAccess = (row, column, value) => value ? YES : NO;

    render() {
        const {lastUpdate, title, list, total, limit, offset, search, setSearch, isFiltersSelected, setIsFiltersSelected, emails, isCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const emailsCount = isCountLoading ? <Loading/> : emails;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Name',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'profile', name: 'Profile', width: 200,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'free', name: 'Price', width: 110, renderValue: this.renderPriceCell},
            {key: 'earlyAccess', name: 'Early access', width: 110, renderValue: this.renderEarlyAccess},
            {key: 'releaseDate', name: 'Release Date', width: 120,
                renderValue: this.renderReleasedValue
            },
            {key: 'reviews', name: 'Reviews', width: 100,
                renderValue: renderBigNumbers
            },
            {key: 'website', name: 'Website', width: 250,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'devTeam', name: 'Developer', width: 150,
            },
            {key: 'publisher', name: 'Publisher', width: 120},
            {key: 'languages', name: 'Languages', width: 180,
                renderValue: this.renderList
            },
            {key: 'steamGenres', name: 'Genres', width: 180,
                renderValue: this.renderGenresList
            },
            {key: 'type', name: 'Type', width: 100},
            {key: 'email', name: 'Email', width: 220},
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                          <div>Emails: {emailsCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportSteam}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={this.onShowMore}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.steamGames.list,
        status: state.steamGames.status,
        total: state.steamGames.total,
        limit: state.steamGames.limit,
        offset: state.steamGames.offset,
        languages: state.steamGames.languages,
        selectedLanguage: state.steamGames.selectedLanguage,
        search: state.steamGames.search,
        genres: state.steamGames.genres,
        selectedGenres: state.steamGames.selectedGenres,
        lastUpdate: state.steamGames.lastUpdate,
        releasedDateRange: state.steamGames.releasedDateRange,
        defaultDateRange: state.steamGames.defaultDateRange,
        startDate: state.steamGames.startDate,
        isOnlyChosenLanguages: state.steamGames.isOnlyChosenLanguages,
        price: state.steamGames.price,
        selectedPrice: state.steamGames.selectedPrice,
        earlyAccess: state.steamGames.earlyAccess,
        selectedEarlyAccess: state.steamGames.selectedEarlyAccess,
        languagesCount: state.steamGames.languagesCount,
        reviews: state.steamGames.reviews,
        isFiltersSelected: state.steamGames.isFiltersSelected,
        reviewsMax: state.steamGames.reviewsMax,
        isCountLoading: state.steamGames.isCountLoading,
        fieldsList: state.steamGames.fieldsList,
        emails: state.steamGames.emails,

        languageOptions: state.app.languageOptions,
        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        setLanguage: languages => dispatch(setLanguageAsync(languages)),
        setGenres: genres => dispatch(setGenresAsync(genres)),
        setSearch: search => dispatch(setSearchAsync(search)),
        initSteamGames: () => dispatch(initSteamGames()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterData: () => dispatch(getFilterData()),
        searchSteamGames: (count) => dispatch(searchSteamGames(count)),
        refreshSteamGames: () => dispatch(refreshSteamGames()),
        exportSteamGames: (fields) => dispatch(exportSteamGames(fields)),
        setReleased: released => dispatch(setReleasedAsync(released)),
        setOptionsCount: count => dispatch(setOptionsCountAsync(count)),
        setIsOnlyChosenLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenLanguages(isOnlyChosenLanguages)),
        setPrice: selectedPrice => dispatch(setPriceAsync(selectedPrice)),
        setEarlyAccess: selectedPrice => dispatch(setEarlyAccessAsync(selectedPrice)),
        setReviews: (reviews) => dispatch(setReviewsAsync(reviews)),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFilters()),
        getSteamCount: (fields) => dispatch(getSteamCount(fields)),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SteamGames)