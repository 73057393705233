import * as types from "./constants";

export function requestInitCrunchbase() {
    return {
        type: types.REQUEST_INIT_CRUCHBASE
    };
}

export function succeedInitCrunchbase(response) {
    return {
        type: types.SUCCEED_INIT_CRUCHBASE,
        response
    };
}

export function requestSearchCrunchbase(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_CRUCHBASE,
        limit, offset
    };
}

export function succeedSearchCrunchbase(response) {
    return {
        type: types.SUCCEED_SEARCH_CRUCHBASE,
        response
    };
}

export function setProjectType(projectType) {
    return {
        type: types.SET_CRUNCHBASE_PROJECT_TYPE,
        projectType
    };
}

export function requestGetCrunchbaseFilterData() {
    return {
        type: types.GET_CRUNCHBASE_FILTER_DATA_REQUEST,
    };
}

export function succeedGetCrunchbaseFilterData(filterData) {
    return {
        type: types.GET_CRUNCHBASE_FILTER_DATA_SUCCEEDED,
        filterData
    };
}

export function setCrunchbaseLocations(selectedLocations) {
    return {
        type: types.SET_SELECTED_CRUNCHBASE_LOCATIONS,
        selectedLocations
    };
}

export function setCrunchbaseRank(rank) {
    return {
        type: types.SET_CRUNCHBASE_RANK,
        rank
    };
}

export function setCreated(created) {
    return {
        type: types.SET_CRUNCHBASE_CREATED,
        created
    };
}

export function requestLastUpdateCrunchbase() {
    return {
        type: types.REQUEST_LAST_UPDATE_CRUNCHBASE,
    };
}

export function succeedLastUpdateCrunchbase(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_CRUNCHBASE,
        lastUpdate
    };
}

export function requestGetCountCrunchbase() {
    return {
        type: types.REQUEST_GET_COUNT_CRUNCHBASE
    };
}

export function succeedGetCountCrunchbase(count) {
    return {
        type: types.SUCCEED_GET_COUNT_CRUNCHBASE,
        count
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_CRUNCHBASE,
        isFiltersSelected
    };
}

export function clearFiltersCrunchbase() {
    return {
        type: types.CLEAR_FILTERS_CRUNCHBASE,
    };
}