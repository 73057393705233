import React, {Component} from 'react';
import PropTypes from "prop-types";
import map from 'lodash/map';
import TableRow from "./TableRow";

class TableBody extends Component {

    static propTypes = {
        columns: PropTypes.array,
        data: PropTypes.array,
    };

    static defaultProps = {
        columns: [],
        data: [],
    }

    renderRows = () => {
        const {columns, data, addedWidth, onRowClick} = this.props;

        return map(data, (row, i) => {
            return <TableRow
                key={i}
                rowIndex={i}
                row={row}
                addedWidth={addedWidth}
                columns={columns}
                onRowClick={onRowClick}
            />
        })
    };

    render() {
        const rows = this.renderRows();

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }
}

export default (TableBody);
