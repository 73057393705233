import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    productCount: {},
    isFiltersSelected: false,
    publishersProductsCountMax: '',

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    startDate: null,
};

export default function steamPublishers(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_STEAM_PUBLISHERS:
            return {
                ...state,
                status: statuses.INIT_STEAM_PUBLISHERS_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_STEAM_PUBLISHERS:
            return {
                ...state,
                status: statuses.INIT_STEAM_PUBLISHERS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_LAST_UPDATE_STEAM_PUBLISHERS:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_STEAM_PUBLISHERS:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_STEAM_PUBLISHERS:
            return {
                ...state,
                status: statuses.SEARCH_STEAM_PUBLISHERS_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_STEAM_PUBLISHERS:
            return {
                ...state,
                status: statuses.SEARCH_STEAM_PUBLISHERS_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.SET_STEAM_PUBLISHERS_FILTER_DATA:
            const startDate = action.response.publishersCreatedStartDate;
            return {
                ...state,
                releasedDateRange: getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                startDate: startDate,
                publishersProductsCountMax: action.response.publishersProductsCountMax
            }
        case actions.SET_SEARCH_LANGUAGES_STEAM_PUBLISHERS:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_STEAM_PUBLISHERS_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_PRODUCT_COUNT_STEAM_PUBLISHERS:
            return {
                ...state,
                productCount: action.productCount,
            }
        case actions.SET_IS_FILTER_SELECTED_STEAM_PUBLISHERS:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_STEAM_PUBLISHERS:
            return {
                ...state,
                releasedDateRange: getDefaultReleased(state.startDate),
                defaultDateRange: getDefaultReleased(state.startDate),
                productCount: {},
            }
        default:
            return state;
    }
}