export const REQUEST_INIT_EPIC = 'REQUEST_INIT_EPIC';
export const SUCCEED_INIT_EPIC = 'SUCCEED_INIT_EPIC';
export const REQUEST_SEARCH_EPIC = 'REQUEST_SEARCH_EPIC';
export const SUCCEED_SEARCH_EPIC = 'SUCCEED_SEARCH_EPIC';
export const REQUEST_LAST_UPDATE_EPIC = 'REQUEST_LAST_UPDATE_EPIC';
export const SUCCEED_LAST_UPDATE_EPIC = 'SUCCEED_LAST_UPDATE_EPIC';
export const SET_EPIC_SEARCH = 'SET_EPIC_SEARCH';
export const SET_SELECTED_AUDIO_LANGUAGES_EPIC = 'SET_SELECTED_AUDIO_LANGUAGES_EPIC';
export const SET_SELECTED_TEXT_LANGUAGES_EPIC = 'SET_SELECTED_TEXT_LANGUAGES_EPIC';
export const SET_AUDIO_LANGUAGES_COUNT_EPIC = 'SET_AUDIO_LANGUAGES_COUNT_EPIC';
export const SET_TEXT_LANGUAGES_COUNT_EPIC = 'SET_TEXT_LANGUAGES_COUNT_EPIC';
export const SET_SELECTED_TAGS_EPIC = 'SET_SELECTED_TAGS_EPIC';
export const SET_RELEASED_EPIC = 'SET_RELEASED_EPIC';
export const GET_EPIC_FILTER_DATA = 'GET_EPIC_FILTER_DATA';
export const REQUEST_GET_COUNT_EPIC = 'REQUEST_GET_COUNT_EPIC';
export const SUCCEED_GET_COUNT_EPIC = 'SUCCEED_GET_COUNT_EPIC';
export const SET_IS_ONLY_CHOSEN_AUDIO_LANGUAGES_EPIC = 'SET_IS_ONLY_CHOSEN_AUDIO_LANGUAGES_EPIC';
export const SET_IS_ONLY_CHOSEN_TEXT_LANGUAGES_EPIC = 'SET_IS_ONLY_CHOSEN_TEXT_LANGUAGES_EPIC';
export const CLEAR_FILTERS_EPIC = 'CLEAR_FILTERS_EPIC';
export const SET_IS_FILTER_SELECTED_EPIC = 'SET_IS_FILTER_SELECTED_EPIC';
export const SET_PRICE_EPIC = 'SET_PRICE_EPIC';