import React from "react";
import classnames from "classnames";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

import "./css/account-popup.css";

const AccountPopup = ({isOpen, email, userName, onLogout, closePopup, accountContainerRef}) => {
    useOnClickOutside(accountContainerRef, () => isOpen && closePopup());
    const accountPopupStyle = classnames("account-popup", {"open": isOpen});

    return <div className={accountPopupStyle}>
        <div className="user-info">
            {userName && <div className="user-name">
                {userName}
            </div>}
            <div className="email">
                {email}
            </div>
        </div>
        <div className="logout-button" onClick={onLogout}>
            <span>Log Out</span>
            <div className="logout-icon"/>
        </div>
    </div>
}

export default AccountPopup;