import React from 'react';
import classNames from 'classnames';

import Button from "../Button";

import './css/hover-button.css';

const HoverButton = ({value, buttonTitle, className, onClick}) => {

    const hoverButtonClassNames = classNames("hover-button__container", className)

    return <div className={hoverButtonClassNames} >
        <Button className="hover-button-button" title={buttonTitle} onClick={onClick}/>
        <div className="hover-button-value">{value}</div>
    </div>;
}

export default (HoverButton);
