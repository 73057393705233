import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import {
    exportNintendo,
    initNintendo,
    refreshNintendo,
    searchNintendo,
    setCountLanguageAsync,
    setLanguageAsync,
    setReleasedAsync,
    setSearchAsync,
    getLastUpdate,
    getFilterData,
    setGenresAsync,
    setAvailabilitiesAsync,
    clearFiltersAsync
} from "./actionsAsync";
import {setIsOnlyChosenLanguages, setIsFiltersSelected} from "./actions";
import {DATE_RANGE, SELECT} from "../../constants/FilterTypes";
import {
    INIT_NINTENDO_REQUESTED,
    SEARCH_NINTENDO_REQUESTED
} from "../../store/StatusTypes";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import Link from "../../components/Link";
import {getLastUpdateTitle} from "../../helpers/utils";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import LanguagesFilter from "../../components/Select/LanguagesFilter";

const CONTAINER_NAME = 'Nintendo';
const FREE = 'Free';
const PAID = 'Paid';

class Nintendo extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initNintendo, getLastUpdate, getFilterData, status} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initNintendo();
            getFilterData();
            getLastUpdate();
        }
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    onExportNintendo =() => {
        const {exportNintendo} = this.props;
        exportNintendo();
    }

    handleClearFilters = () => {
        const {clearFilters, initNintendo} = this.props;
        clearFilters().then(() => initNintendo());
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getLanguageValue = (selectedOptions, options) => {
        const {languagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = languagesCount.from ? `From ${languagesCount.from}` : '';
        const languagesCountTo = languagesCount.to ? `To ${languagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    onClearReleased = () => {
        const {setReleased, refreshNintendo, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => refreshNintendo());
    }

    getFiltersValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {languages, selectedLanguage, setLanguage, setLanguageCount, releasedDateRange, defaultDateRange, setReleased, setIsOnlyChosenLanguages,
        genres, selectedGenres, setGenres, selectedAvailabilities, setAvailabilities, availabilities, isOnlyChosenLanguages, languagesCount} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Language',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getLanguageValue,
                selectedOptions: selectedLanguage,
                onChange: setLanguage,
                submitButtonName: 'Apply',
                setOptionsCount: setLanguageCount,
                filterName: "languages",
                optionCount: languagesCount,
                isOnlyChosen: isOnlyChosenLanguages,
                setIsOnlyChosen: setIsOnlyChosenLanguages,
            },
            {
                type: SELECT,
                title: 'Genres',
                popoverClassName: 'steam-genre-popover',
                options: genres,
                renderValue: this.getFiltersValue,
                selectedOptions: selectedGenres,
                onChange: setGenres,
                submitButtonName: 'Apply',
            },
            {
                type: SELECT,
                title: 'Availabilities',
                options: availabilities,
                renderValue: this.getFiltersValue,
                selectedOptions: selectedAvailabilities,
                onChange: setAvailabilities,
                columnsCount: 1,
                submitButtonName: 'Apply',
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_NINTENDO_REQUESTED
            || status === SEARCH_NINTENDO_REQUESTED;
    }

    onShowMore = () => {
        const {searchNintendo} = this.props;
        searchNintendo();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderLink = (value) => <Link path={value} value={value}/>;

    renderLanguagesList = (row, column, value) => join(value, ', ');

    renderGenreList = (row, column, value) => {
        const nameValues = map(value, option => option.name);
        return join(nameValues, ', ');
    };

    renderPriceCell = (row, column, value) => value ? FREE : PAID;

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    render() {
        const {lastUpdate, title, list, total, limit, offset, refreshNintendo, searchNintendo, search, setSearch, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Game Name'},
            {key: 'developers', name: 'Developer', width: 150},
            {key: 'publishers', name: 'Publisher', width: 150},
            {key: 'url', name: 'URL', width: 200,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'releaseDate', name: 'Release Date', width: 120,
                renderValue: this.renderReleasedValue
            },
            {key: 'genres', name: 'Genre', width: 150,
                renderValue: this.renderGenreList
            },
            {key: 'languages', name: 'Languages Supported', width: 250,
                renderValue: this.renderLanguagesList
            },
            {key: 'availability', name: 'Availability', width: 250,
                renderValue: this.renderLanguagesList
            },
            {key: 'free', name: 'Price', width: 110, renderValue: this.renderPriceCell},
            {key: 'website', name: 'Website', width: 200, cellValueWrapperFn: this.renderLink},
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onApplyFilters={refreshNintendo}
                                 onExport={this.onExportNintendo}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchNintendo}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.nintendo.list,
        status: state.nintendo.status,
        total: state.nintendo.total,
        limit: state.nintendo.limit,
        offset: state.nintendo.offset,
        search: state.nintendo.search,
        languages: state.nintendo.languages,
        selectedLanguage: state.nintendo.selectedLanguage,
        releasedDateRange: state.nintendo.releasedDateRange,
        defaultDateRange: state.nintendo.defaultDateRange,
        lastUpdate: state.nintendo.lastUpdate,
        genres: state.nintendo.genres,
        selectedGenres: state.nintendo.selectedGenres,
        availabilities: state.nintendo.availabilities,
        selectedAvailabilities: state.nintendo.selectedAvailabilities,
        startDate: state.nintendo.startDate,
        isOnlyChosenLanguages: state.nintendo.isOnlyChosenLanguages,
        isFiltersSelected: state.nintendo.isFiltersSelected,
        languagesCount: state.nintendo.languagesCount,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initNintendo: () => dispatch(initNintendo()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterData: () => dispatch(getFilterData()),
        searchNintendo: (count) => dispatch(searchNintendo(count)),
        refreshNintendo: () => dispatch(refreshNintendo()),
        exportNintendo: () => dispatch(exportNintendo()),
        setSearch: search => dispatch(setSearchAsync(search)),
        setLanguage: languages => dispatch(setLanguageAsync(languages)),
        setReleased: released => dispatch(setReleasedAsync(released)),
        setLanguageCount: languages => dispatch(setCountLanguageAsync(languages)),
        setGenres: genres => dispatch(setGenresAsync(genres)),
        setAvailabilities: availabilities => dispatch(setAvailabilitiesAsync(availabilities)),
        setIsOnlyChosenLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenLanguages(isOnlyChosenLanguages)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Nintendo)