import {
    getSubscribeDecidedRequest, updateSubscribeContactRequest, getSubscribeCountsRequest,
    getSubscribeCurrentFilterRequest, updateSubscribeFilterRequest
} from "../../services/grpcBufServices";
import {
    requestGetDecidedRecord, requestRefreshDecidedRecord,
    requestUpdateSubscribeRecord,
    succeedGetDecidedRecord, succeedRefreshDecidedRecord,
    successUpdateSubscribeRecord, successGetSubscribeCounts,
    requestGetSubscribeCurrentFilter, succeedGetSubscribeCurrentFilter,
    requestUpdateSubscribeFilter, succeedUpdateSubscribeFilter,
    updateProcessingMessage
} from "./actions";
import {getUpdateSubscribeListField} from "../../helpers/utils";

export const getDecidedRecord = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetDecidedRecord());
        const response = await getSubscribeDecidedRequest(token, dispatch, updateProcessingMessage);
        dispatch(succeedGetDecidedRecord(response));
    };

export const refreshDecidedRecord = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestRefreshDecidedRecord());
        const response = await getSubscribeDecidedRequest(token, dispatch, updateProcessingMessage);
        dispatch(succeedRefreshDecidedRecord(response));
    };

export const updateDecideRecord = (id, key, type, value) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestUpdateSubscribeRecord());
        const field = getUpdateSubscribeListField(key);
        const response = await updateSubscribeContactRequest(token, id, field, type, value);
        dispatch(successUpdateSubscribeRecord(response));
    };

export const getSubscribeCounts = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getSubscribeCountsRequest(token);
        dispatch(successGetSubscribeCounts(response));
    };

export const getSubscribeCurrentFilter = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetSubscribeCurrentFilter());
        const response = await getSubscribeCurrentFilterRequest(token);
        dispatch(succeedGetSubscribeCurrentFilter(response));
    };

export const updateSubscribeFilter = (closeFilter) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestUpdateSubscribeFilter());
        const response = await updateSubscribeFilterRequest(token, closeFilter);
        dispatch(succeedUpdateSubscribeFilter(response));
        return response;
    };

