import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckboxItem from "./CheckboxItem";
import findIndex from "lodash/findIndex";
import map from "lodash/map";

import './css/select.css';
import Loading from "../Loading";

class Options extends Component {

    static propTypes = {
        selectedOptions: PropTypes.array,
        title: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        columnsCount: PropTypes.number,
        onClick: PropTypes.func,
    }

    static defaultProps = {
        columnsCount: 4
    }

    isChecked = (id) => {
        const {selectedOptions} = this.props;
        return findIndex(selectedOptions, option => option.id === id) > -1;
    }

    onChange = (option) => (e, checked, id) => {
        const {onChange} = this.props;
        onChange && onChange(e, checked, id, option);
    }

    getRenderOptions = () => {
        const {options} = this.props;

        return map(options, option => {
            const {id, name} = option;
            const checked = this.isChecked(id);
            return (
                <CheckboxItem id={id} key={id} name={name} checked={checked} onChange={this.onChange(option)}/>
            )
        })
    }

    allCheckboxChange = (e, checked) => {
        const {setSelectedOptions, options} = this.props;
        let changeOptions = [];
        if (checked) {
            changeOptions = options;
        }

        setSelectedOptions(changeOptions);
    }

    calculateContainerHeight = () => {
        const {options, columnsCount} = this.props;
        const elementHeight = 26;
        const height = Math.ceil(options.length / columnsCount ) * elementHeight;
        return height || elementHeight;
    };

    render() {
        const {options: optionsProps, selectedOptions, className, allTitle} = this.props;
        const options = this.getRenderOptions();
        const allCheckboxChecked = optionsProps.length === selectedOptions.length;
        const containerClassName = classNames("options__container", className);
        const allCheckboxName = allTitle ? allTitle : 'All';
        const containerHeight = this.calculateContainerHeight();

        return optionsProps.length
            ? (
                <React.Fragment>
                    <CheckboxItem id='all' name={allCheckboxName} className='all-checkbox' checked={allCheckboxChecked} onChange={this.allCheckboxChange}/>
                    <div className={containerClassName} style={{height: containerHeight + 'px'}}>
                        {options}
                    </div>
                </React.Fragment>)
            : (
                <div className="options-loading">
                    <Loading/>
                </div>);
    }
}

export default (Options);