import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {convertToArrayOfObjects} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    countriesList: [],
    selectedCountry: 'All',
    lastUpdate: null,
    isFiltersSelected: false,
    isCountLoading: false,
    count: ''
};

export default function euStartups(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_EU_STARTUPS:
            return {
                ...state,
                status: statuses.INIT_EU_STARTUPS_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_EU_STARTUPS:
            return {
                ...state,
                status: statuses.INIT_EU_STARTUPS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_EU_STARTUPS:
            return {
                ...state,
                status: statuses.SEARCH_EU_STARTUPS_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_EU_STARTUPS:
            return {
                ...state,
                status: statuses.SEARCH_EU_STARTUPS_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.REQUEST_LAST_UPDATE_EU_STARTUPS:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_EU_STARTUPS:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.SET_EU_STARTUPS_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.GET_EU_STARTUPS_FILTER_DATA:
            return {
                ...state,
                countriesList: [...state.countriesList, ...convertToArrayOfObjects(action.response.countries)]
            }
        case actions.SET_EU_STARTUPS_COUNTRY:
            return {
                ...state,
                selectedCountry: action.selectedCountry,
            }
        case actions.REQUEST_GET_COUNT_EU_STARTUPS:
            return {
                ...state,
                isCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_EU_STARTUPS:
            return {
                ...state,
                count: action.count,
                isCountLoading: false
            }
        case actions.SET_IS_FILTER_SELECTED_EU_STARTUPS:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_EU_STARTUPS:
            return {
                ...state,
                selectedCountry: 'All',
            }
        default:
            return state;
    }
}