import isEqual from "lodash/isEqual";
import {
    requestInitAppStore, requestRefreshAppStore,
    requestSearchAppStore, setLanguageCount,
    setReleased, setSelectedGenres, setSelectedLanguage,
    succeedInitAppStore, succeedRefreshAppStore,
    succeedSearchAppStore, requestLastUpdateappStoreLastUpdate,
    succeedLastUpdateappStoreLastUpdate, setReviews,
    setScore, requestGetFilterData, succeedGetFilterData,
    clearFiltersAppStore, setCountry, setUpdated,
    succeedGetCountAppStore, setHasVideo, requestGetCountAppStore,
    setCountryCount, succeedGetCountDevelopersAndEmailsAppStore,
    setPrice, setAppCount, requestGetCountDevelopersAndEmailsAppStore
} from "./actions";
import {setSearch} from "./actions";
import {
    exportAppStoreRecordsRequest,
    getAppStoreRecordsRequest,
    getLastUpdateAppStoreRequest,
    getFilterDataAppStoreRequest,
    getCountAppStore,
    getAppStoreInsightsRequest,
    getCountDevelopersAndEmailsAppStore
} from "../../services/grpcBufServices";
import {getExportFieldValues, getRequestOptions} from "../../helpers/utils";
import {getReleasedValue} from "../../helpers/dateUtils";
import {requestExport, succeedExport} from "../App/actions";

export const initAppStore = () =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {limit, offset, search, selectedGenres, genres: genresOptions, releasedDateRange, selectedLanguages, languagesCount, countryOptions, languages, reviews, score, selectedCountry, updatedDateRange, countriesCount, isOnlyChosenMarkets, isOnlyChosenLanguages}} = getState();
        dispatch(requestInitAppStore());
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const response = await getAppStoreRecordsRequest(limit, offset, search, genreOptions, released, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updated, null, marketsCountValue);
        dispatch(succeedInitAppStore(response));
    };

export const refreshAppStore = () =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {limit, offset, search, selectedGenres, selectedPrice, appCount, genres: genresOptions, defaultDateRange, countryOptions, releasedDateRange, selectedLanguages, languagesCount, languages, reviews, score, selectedHasVideo, selectedCountry, updatedDateRange, countriesCount, isOnlyChosenMarkets, isOnlyChosenLanguages}} = getState();
        dispatch(requestRefreshAppStore());
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const response = await getAppStoreRecordsRequest(limit, offset, search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, priceOption, appCount);
        dispatch(succeedRefreshAppStore(response));
    };

export const searchAppStore = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {limit, offset, search, selectedPrice, selectedHasVideo, appCount, defaultDateRange, selectedGenres, genres: genresOptions, countryOptions, releasedDateRange, selectedLanguages, languagesCount, languages, reviews, score, selectedCountry, updatedDateRange, countriesCount, isOnlyChosenLanguages, isOnlyChosenMarkets}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchAppStore(updateLimit, updateOffset));
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const response = await getAppStoreRecordsRequest(updateLimit, updateOffset, search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, priceOption, appCount);
        dispatch(succeedSearchAppStore(response));
    };

export const exportAppStoreRecords = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {limit, offset, search, selectedPrice, selectedGenres, defaultDateRange, genres: genresOptions, countryOptions, releasedDateRange, languagesCount, selectedLanguages, languages, reviews, score, selectedHasVideo, appCount, selectedCountry, updatedDateRange, countriesCount, isOnlyChosenLanguages, isOnlyChosenMarkets}} = getState();
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const fieldValues = getExportFieldValues(fields);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        dispatch(requestExport());
        const exportId = await exportAppStoreRecordsRequest(limit, offset, search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, fieldValues, priceOption, appCount);
        dispatch(succeedExport(exportId.task.id));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();

        dispatch(requestLastUpdateappStoreLastUpdate());
        const lastUpdate = await getLastUpdateAppStoreRequest(token);
        dispatch(succeedLastUpdateappStoreLastUpdate(lastUpdate));
    };

export const getFilterDataAppStore = (isInsightsRequest) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetFilterData());
        const response = await getFilterDataAppStoreRequest(token);
        dispatch(succeedGetFilterData(response, isInsightsRequest));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearch(search))
    };

export const setLanguageAsync = languages =>
    async (dispatch, getState) => {
        const {appStore: {languagesCount}} = getState();
        if (languagesCount.from && languagesCount.to && +languagesCount.from > +languagesCount.to) {
            const correctLanguagesCount = {from: languagesCount.to, to: languagesCount.from};
            dispatch(setLanguageCount(correctLanguagesCount))
        }
        dispatch(setSelectedLanguage(languages));
    };

export const setGenresAsync = genres =>
    async (dispatch, getState) => {
        dispatch(setSelectedGenres(genres));
    };

export const setLanguageCountAsync = languagesCount =>
    async (dispatch, getState) => {
        dispatch(setLanguageCount(languagesCount));
    };

export const setCountryCountAsync = countriesCount =>
    async (dispatch, getState) => {
        dispatch(setCountryCount(countriesCount));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setUpdatedAsync = (updated) =>
    async (dispatch, getState) => {
        dispatch(setUpdated(updated));
    };

export const setReviewsAsync = (reviews) =>
    async (dispatch, getState) => {
        dispatch(setReviews(reviews));
    };

export const setScoreAsync = (score) =>
    async (dispatch, getState) => {
        dispatch(setScore(score));
    };

export const clearFilters = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersAppStore());
    };

export const setCountryAsync = (country) =>
    async (dispatch, getState) => {
        const {appStore: {countriesCount}} = getState();
        if (countriesCount.from && countriesCount.to && +countriesCount.from > +countriesCount.to) {
            const correctCountriesCount = {from: countriesCount.to, to: countriesCount.from};
            dispatch(setCountryCount(correctCountriesCount))
        }
        dispatch(setCountry(country));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {search, defaultDateRange, selectedPrice, selectedGenres, genres: genresOptions, releasedDateRange, appCount, countryOptions, selectedLanguages, languagesCount, languages, reviews, score, selectedCountry, updatedDateRange, selectedHasVideo, countriesCount, isOnlyChosenLanguages, isOnlyChosenMarkets}} = getState();
        dispatch(requestGetCountAppStore());
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const count = await getCountAppStore(search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, null, priceOption, appCount);
        dispatch(succeedGetCountAppStore(count));
    };

export const getExportCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {search, defaultDateRange, selectedPrice, selectedGenres, appCount, genres: genresOptions, releasedDateRange, countryOptions, selectedLanguages, languagesCount, languages, reviews, score, selectedCountry, updatedDateRange, selectedHasVideo, countriesCount, isOnlyChosenLanguages, isOnlyChosenMarkets}} = getState();
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const fieldValues = getExportFieldValues(fields);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const count = await getCountAppStore(search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, fieldValues, priceOption, appCount);
        return count.records;
    };

export const getCountDevelopersAndEmails = () =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {search, defaultDateRange, selectedPrice, selectedGenres, genres: genresOptions, releasedDateRange, appCount, countryOptions, selectedLanguages, languagesCount, languages, reviews, score, selectedCountry, updatedDateRange, selectedHasVideo, countriesCount, isOnlyChosenLanguages, isOnlyChosenMarkets}} = getState();
        dispatch(requestGetCountDevelopersAndEmailsAppStore());
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const countriesOptions = getRequestOptions(selectedCountry, countryOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const count = await getCountDevelopersAndEmailsAppStore(search, genreOptions, releasedDate, languageOptions, languagesCountValue, reviews, score, countriesOptions, token, updatedDate, hasVideoOption, marketsCountValue, null, priceOption, appCount);
        dispatch(succeedGetCountDevelopersAndEmailsAppStore(count));
    };

export const setHasVideoAsync = hasVideo =>
    async (dispatch, getState) => {
        dispatch(setHasVideo(hasVideo));
    };

export const getAppStoreInsights = () =>
    async (dispatch, getState) => {
        const {app: {token}, appStore: {limit, offset, updatedDateRange, selectedHasVideo, score}} = getState();
        const updated = getReleasedValue(updatedDateRange);
        const hasVideoOption = selectedHasVideo[0].name;
        dispatch(requestInitAppStore());
        const response = await getAppStoreInsightsRequest(token, limit, offset, updated, hasVideoOption, score);
        dispatch(succeedInitAppStore(response));
    };

export const setPriceAsync = (price) =>
    async (dispatch, getState) => {
        dispatch(setPrice(price));
    };

export const setAppCountAsync = (score) =>
    async (dispatch, getState) => {
        dispatch(setAppCount(score));
    };
