export const SIGN_OUT = 'SIGN_OUT';
export const SUCCEED_GET_USER_INFO = 'SUCCEED_GET_USER_INFO';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const SUCCEED_AUTH = 'SUCCEED_AUTH';
export const FAIL_AUTH = 'FAIL_AUTH';

export const INIT_APP = 'INIT_APP';
export const BLOCK_UI = 'BLOCK_UI';
export const UNBLOCK_UI = 'UNBLOCK_UI';
export const WAIT_UI = 'WAIT_UI';
export const UNWAIT_UI = 'UNWAIT_UI';

export const SET_TITLE = 'SET_TITLE';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const SET_INIT_CONTAINER = 'SET_INIT_CONTAINER';

export const REQUEST_GET_HEALTH = 'REQUEST_GET_HEALTH';
export const SUCCEED_GET_HEALTH = 'SUCCEED_GET_HEALTH';
export const REQUEST_GET_ALL_HEALTH = 'REQUEST_GET_ALL_HEALTH';
export const SUCCEED_GET_ALL_HEALTH = 'SUCCEED_GET_ALL_HEALTH';

export const SHOW_POPUP = 'SHOW_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const REQUEST_EXPORT = 'REQUEST_EXPORT';
export const SUCCEED_EXPORT = 'SUCCEED_EXPORT';

export const SET_MAGNET_SALES_HEALTH_WARNING = 'SET_MAGNET_SALES_HEALTH_WARNING'
export const SET_MAGNET_VENDORS_HEALTH_WARNING = 'SET_MAGNET_VENDORS_HEALTH_WARNING'
export const SUCCEED_IS_MAGNET_FAILED = 'SUCCEED_IS_MAGNET_FAILED'