import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import {convertCategoriesList, googlePlayFieldsList} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    isLoadingPlayRecords: false,
    isLoadingCount: false,
    downloadsMax: '',
    isFiltersSelected: false,
    score: {},
    appCount: {},

    categories: [],
    selectedCategories: [],
    selectedCountry: [],
    countryOptions: [],
    countriesCount: {from: '', to: ''},
    fieldsList: googlePlayFieldsList,
    downloads: {},
    hasVideo: [
        {id: 1, name: "Yes"},
        {id: 2, name: "No"},
    ],
    selectedHasVideo: [
        {id: 1, name: "Yes"},
        {id: 2, name: "No"},
    ],
    price: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    selectedPrice: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    isOnlyChosenMarkets: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    updatedDateRange: getDefaultReleased(),
    startDate: null,
};

export default function googlePlay(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_GOOGLE_PLAY:
            return {
                ...state,
                status: statuses.INIT_GOOGLE_PLAY_REQUESTED,
                isLoadingPlayRecords: true,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_GOOGLE_PLAY:
            const total = action.response.total !== 0 ? action.response.total : state.total;
            return {
                ...state,
                status: statuses.INIT_GOOGLE_PLAY_SUCCEEDED,
                list: action.response.result,
                total: total,
                isLoadingPlayRecords: false
            }
        case actions.REQUEST_LAST_UPDATE_GOOGLE_PLAY:
            return {
                ...state,
                lastUpdate: {},
            }
        case actions.SUCCEED_LAST_UPDATE_GOOGLE_PLAY:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_GET_COUNT_GOOGLE_PLAY:
            return {
                ...state,
                status: statuses.GET_COUNT_PLAY_REQUESTED,
                isLoadingCount: true
            }
        case actions.SUCCEED_GET_COUNT_GOOGLE_PLAY:
            return {
                ...state,
                status: statuses.GET_COUNT_GOOGLE_PLAY_SUCCEEDED,
                total: action.count.count,
                isLoadingCount: false
            }
        case actions.REQUEST_SEARCH_GOOGLE_PLAY:
            return {
                ...state,
                status: statuses.SEARCH_GOOGLE_PLAY_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_GOOGLE_PLAY:
            return {
                ...state,
                status: statuses.SEARCH_GOOGLE_PLAY_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }

        case actions.SET_GOOGLE_PLAY_CATEGORY:
            return {
                ...state,
                selectedCategories: action.category,
            }

        case actions.SET_GOOGLE_PLAY_COUNTRY:
            return {
                ...state,
                selectedCountry: action.selectedCountry,
            }

        case actions.SET_GOOGLE_PLAY_DOWNLOADS:
            return {
                ...state,
                downloads: action.downloads,
            }
        case actions.SET_GOOGLE_PLAY_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_GOOGLE_PLAY_UPDATED:
            return {
                ...state,
                updatedDateRange: action.updated
            }
        case actions.SET_GOOGLE_PLAY_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_GOOGLE_PLAY_PRICE:
            return {
                ...state,
                selectedPrice: action.selectedPrice,
            }
        case actions.SET_GOOGLE_PLAY_APP_COUNT:
            return {
                ...state,
                appCount: action.appCount,
            }
        case actions.SUCCEED_GET_FILTER_DATA:
            const categoriesList = convertCategoriesList(action.response.categories);
            const startDate = action.response.releasedStartDate;
            return {
                ...state,
                categories: categoriesList,
                selectedCategories: categoriesList,
                countryOptions: action.response.countries,
                selectedCountry: action.response.countries,
                releasedDateRange: action.isInsightsRequest ? state.releasedDateRange : getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                updatedDateRange: action.isInsightsRequest ? state.updatedDateRange : getDefaultReleased(startDate),
                startDate: startDate,
                downloadsMax: action.response.downloadsMax
            }
        case actions.SET_COUNTRIES_COUNT_PLAY:
            return {
                ...state,
                countriesCount: action.countriesCount,
            }
        case actions.SET_GOOGLE_PLAY_HAS_VIDEO:
            return {
                ...state,
                selectedHasVideo: action.hasVideo,
            }
        case actions.CLEAR_FILTERS_GOOGLE_PLAY:
            return {
                ...state,
                search: '',
                selectedCountry: state.countryOptions,
                countriesCount: {from: '', to: ''},
                downloads: {},
                selectedHasVideo: [
                    {id: 1, name: "Yes"},
                    {id: 2, name: "No"},
                ],
                releasedDateRange: getDefaultReleased(state.startDate),
                defaultDateRange: getDefaultReleased(state.startDate),
                updatedDateRange: getDefaultReleased(state.startDate),
                selectedPrice: [
                    {id: 1, name: 'Free'},
                    {id: 2, name: 'Paid'}
                ],
                selectedCategories: state.categories,
                appCount: {},
            }
        case actions.SET_IS_ONLY_CHOSEN_MARKETS_GOOGLE_PLAY:
            return {
                ...state,
                isOnlyChosenMarkets: action.isOnlyChosenMarkets,
            }
        case actions.SET_IS_FILTER_SELECTED_GOOGLE_PLAY:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.SET_GOOGLE_PLAY_SCORE:
            return {
                ...state,
                score: action.score,
            }
        default:
            return state;
    }
}