import React, {Component} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import map from "lodash/map";
import join from "lodash/join";

import {setInitContainer, setTitle} from "../App/actions";
import {
    initMeetToMatchConference,
    searchMeetToMatchConference,
    getFilterData,
    setSearchAsync,
    refreshMeetToMatchConference,
    setAttendeeTagsAsync,
    setPreferredDealSizesAsync,
    setInvestmentTypesAsync,
    setLookingForAsync,
    setIndustryAsync,
    setPlatformAsync,
    setCountriesAsync,
    setCompanySizeAsync,
    setGreenFlagAsync,
    setRedFlagAsync,
    clearFilters,
    exportMeetToMatch
} from "./actionsAsync";
import {setIsFiltersSelected, setEventId} from "./actions";

import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";

import {INIT_MEET_TO_MATCH_CONFERENCE_REQUESTED, SEARCH_MEET_TO_MATCH_CONFERENCE_REQUESTED} from "../../store/StatusTypes";
import {SELECT} from "../../constants/FilterTypes";

const CONTAINER_NAME = 'MeetToMatch';

class MeetToMatchConference extends Component {

    componentDidMount() {
        const {setTitle, setInitContainer, initContainers, setEventId, getFilterData, initMeetToMatchConference, match: {params: {id}}} = this.props;
        setTitle(CONTAINER_NAME);

        if (id) {
            setEventId(id)
            initMeetToMatchConference();
            getFilterData();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getAttendeeTagValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {attendeeTags, setAttendeeTags, selectedAttendeeTags, preferredDealSizes, setPreferredDealSizes, selectedPreferredDealSizes,
            investmentTypes, setInvestmentTypes, selectedInvestmentTypes, lookingFor, setLookingFor, selectedLookingFor, selectedIndustry,
            industry, setIndustry, selectedPlatform, platform, setPlatform, countries, setCountries, selectedCountries, companySize, setCompanySize,
            selectedCompanySize, greenFlag, setGreenFlag, selectedGreenFlag, redFlag, setRedFlag, selectedRedFlag} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Looking for',
                popoverClassName: 'big-filter-popover',
                options: lookingFor,
                onChange: setLookingFor,
                selectedOptions: selectedLookingFor,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Country',
                popoverClassName: 'big-filter-popover',
                options: countries,
                onChange: setCountries,
                selectedOptions: selectedCountries,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Industry',
                popoverClassName: 'big-filter-popover',
                options: industry,
                onChange: setIndustry,
                selectedOptions: selectedIndustry,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Attendee tag',
                options: attendeeTags,
                onChange: setAttendeeTags,
                selectedOptions: selectedAttendeeTags,
                renderValue: this.getAttendeeTagValue,
                columnsCount: 1,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Preferred deal size',
                options: preferredDealSizes,
                onChange: setPreferredDealSizes,
                selectedOptions: selectedPreferredDealSizes,
                renderValue: this.getAttendeeTagValue,
                columnsCount: 1,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Investment type',
                options: investmentTypes,
                onChange: setInvestmentTypes,
                selectedOptions: selectedInvestmentTypes,
                renderValue: this.getAttendeeTagValue,
                columnsCount: 1,
                submitButtonName: 'Submit',
            },
            {
                type: SELECT,
                title: 'Platform',
                options: platform,
                onChange: setPlatform,
                selectedOptions: selectedPlatform,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
                columnsCount: 1
            },
            {
                type: SELECT,
                title: 'Size',
                options: companySize,
                onChange: setCompanySize,
                selectedOptions: selectedCompanySize,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
                columnsCount: 1
            },
            {
                type: SELECT,
                title: 'Green flag',
                options: greenFlag,
                onChange: setGreenFlag,
                selectedOptions: selectedGreenFlag,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
                columnsCount: 1
            },
            {
                type: SELECT,
                title: 'Red flag',
                options: redFlag,
                onChange: setRedFlag,
                selectedOptions: selectedRedFlag,
                renderValue: this.getAttendeeTagValue,
                submitButtonName: 'Submit',
                columnsCount: 1
            },
        ];

        return filtersOptions
    };

    handleClearFilters = () => {
        const {clearFilters, initMeetToMatchConference} = this.props;
        clearFilters().then(() => {
            initMeetToMatchConference();
        });
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_MEET_TO_MATCH_CONFERENCE_REQUESTED
            || status === SEARCH_MEET_TO_MATCH_CONFERENCE_REQUESTED;
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;
    renderLink = (value) => <Link path={value} value={value}/>;
    renderList = (row, column, value) => join(value, ', ');
    renderBool = (row, column, value) => {
        return value ? 'Yes' : 'No';
    }

    render() {
        const {offset, limit, total, setIsFiltersSelected, isFiltersSelected, searchMeetToMatchConference,
            list, refreshMeetToMatchConference, exportMeetToMatch} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'index', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'id', name: 'id', width: 60},
            {key: 'name', name: 'Name', width: 120},
            {key: 'attendeeTag', name: 'Attendee tag', width: 100},
            {key: 'linkedin', name: 'Linkedin', width: 120, cellValueWrapperFn: this.renderLink},
            {key: 'twitter', name: 'Twitter', width: 120, cellValueWrapperFn: this.renderLink},
            {key: 'jobTitle', name: 'Job title', width: 100},
            {key: 'preferredDealSize', name: 'Preferred deal size', width: 120, renderValue: this.renderList},
            {key: 'presentation', name: 'Online presentation', width: 120, cellValueWrapperFn: this.renderLink},
            {key: 'lookingForInvestmentType', name: 'Investment type', width: 120, renderValue: this.renderList},
            {key: 'lookingFor', name: 'Looking for', width: 120, renderValue: this.renderList},
            {key: 'industry', name: 'Industry', width: 120, renderValue: this.renderList},
            {key: 'platform', name: 'Platform', width: 120, renderValue: this.renderList},
            {key: 'event', name: 'Event', width: 100},
            {key: 'company.name', name: 'Company', width: 120},
            {key: 'company.country', name: 'Country', width: 100},
            {key: 'company.size', name: 'Size', width: 100},
            {key: 'company.profile', name: 'Profile', width: 120},
            {key: 'company.greenFlag', name: 'Green flag', width: 60, renderValue: this.renderBool},
            {key: 'company.redFlag', name: 'Red flag', width: 60, renderValue: this.renderBool},
            {key: 'company.website', name: 'Website', width: 120, cellValueWrapperFn: this.renderLink},
        ];

        return (
            <div className="app-root__table-container">
                <AppstoreFilters filtersOptions={filtersOptions}
                                 onExport={exportMeetToMatch}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                                 onApplyFilters={refreshMeetToMatchConference}
                                 onClearFilters={this.handleClearFilters}
                />
                <div className="app-root__table">
                    <Table
                        className="appStore-table"
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchMeetToMatchConference}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.meetToMatchConference.list,
        status: state.meetToMatchConference.status,
        total: state.meetToMatchConference.total,
        limit: state.meetToMatchConference.limit,
        offset: state.meetToMatchConference.offset,
        search: state.meetToMatchConference.search,
        attendeeTags: state.meetToMatchConference.attendeeTags,
        selectedAttendeeTags: state.meetToMatchConference.selectedAttendeeTags,
        isFiltersSelected: state.meetToMatchConference.isFiltersSelected,
        preferredDealSizes: state.meetToMatchConference.preferredDealSizes,
        selectedPreferredDealSizes: state.meetToMatchConference.selectedPreferredDealSizes,
        investmentTypes: state.meetToMatchConference.investmentTypes,
        selectedInvestmentTypes: state.meetToMatchConference.selectedInvestmentTypes,
        lookingFor: state.meetToMatchConference.lookingFor,
        selectedLookingFor: state.meetToMatchConference.selectedLookingFor,
        industry: state.meetToMatchConference.industry,
        selectedIndustry: state.meetToMatchConference.selectedIndustry,
        platform: state.meetToMatchConference.platform,
        selectedPlatform: state.meetToMatchConference.selectedPlatform,
        countries: state.meetToMatchConference.countries,
        selectedCountries: state.meetToMatchConference.selectedCountries,
        companySize: state.meetToMatchConference.companySize,
        selectedCompanySize: state.meetToMatchConference.selectedCompanySize,
        greenFlag: state.meetToMatchConference.greenFlag,
        selectedGreenFlag: state.meetToMatchConference.selectedGreenFlag,
        redFlag: state.meetToMatchConference.redFlag,
        selectedRedFlag: state.meetToMatchConference.selectedRedFlag,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initMeetToMatchConference: () => dispatch(initMeetToMatchConference()),
        refreshMeetToMatchConference: () => dispatch(refreshMeetToMatchConference()),
        searchMeetToMatchConference: (count) => dispatch(searchMeetToMatchConference(count)),
        getFilterData: () => dispatch(getFilterData()),
        setSearch: (search) => dispatch(setSearchAsync(search)),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFilters()),
        exportMeetToMatch: () => dispatch(exportMeetToMatch()),

        //filters
        setAttendeeTags: (attendeeTag) => dispatch(setAttendeeTagsAsync(attendeeTag)),
        setPreferredDealSizes: (preferredDealSizes) => dispatch(setPreferredDealSizesAsync(preferredDealSizes)),
        setInvestmentTypes: (investmentTypes) => dispatch(setInvestmentTypesAsync(investmentTypes)),
        setLookingFor: (lookingFor) => dispatch(setLookingForAsync(lookingFor)),
        setIndustry: (industry) => dispatch(setIndustryAsync(industry)),
        setPlatform: (platform) => dispatch(setPlatformAsync(platform)),
        setCountries: (countries) => dispatch(setCountriesAsync(countries)),
        setCompanySize: (companySize) => dispatch(setCompanySizeAsync(companySize)),
        setGreenFlag: (greenFlag) => dispatch(setGreenFlagAsync(greenFlag)),
        setRedFlag: (redFlag) => dispatch(setRedFlagAsync(redFlag)),
        setEventId: (eventId) => dispatch(setEventId(eventId)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MeetToMatchConference);