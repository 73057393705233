import React, {Component} from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

class PrivateRoute extends Component {

    static propTypes = {
    };

    render() {
        const {path, component: Component, token} = this.props;

        return (
            <Route
                path={path}
                render={props =>
                    token
                        ? (<Component {...props} />)
                        : (<Redirect to={{pathname: "/login", state: {from: props.location}}}/>)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.app.token
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRoute);