import React, {Component} from 'react';
import classNames from 'classnames';
import {isTextLineBreak, isTextOverflow} from "../../helpers/utils";

class TableCell extends Component {

    static propTypes = {
    };

    componentDidMount() {
        this.setTitleAttribute();
    }

    setTitleAttribute = () => {
        const {cellContend} = this;
        const {value, title, isHiddenTitle, isRowSelection} = this.props;

        setTimeout(() => {
            if (!isHiddenTitle) {
                if (title && value) {
                    cellContend.setAttribute('title', title);
                } else if (!isRowSelection && (isTextOverflow(cellContend) || isTextLineBreak(value))) {
                    typeof value !== "object" && cellContend.setAttribute('title', value);
                }
            }

        }, 0);
    };

    render() {
        const {width, cellKey, value, onCellValueWrapperFn, cellValueWrapperFn, isFixedColumn, cellClassName, fixedColumnLeft} = this.props;
        const tableCellContentStyle = width ? {width} : {};

        const tableCellClassName = classNames("kraken-table-td", cellClassName, {
            "fixed-cell": isFixedColumn
        });

        const tableCellStyle = isFixedColumn ? {left: fixedColumnLeft} : {};

        const renderValue = cellValueWrapperFn
            ? onCellValueWrapperFn(value, cellKey, cellValueWrapperFn)
            : value;

        return (
            <td className={tableCellClassName} style={tableCellStyle}>
                <div className="kraken-table-td-flex-container">
                    <div className="kraken-table-td-content" style={tableCellContentStyle} ref={cellContentElement => this.cellContend = cellContentElement}>
                        {renderValue}
                    </div>
                </div>
            </td>
        );
    }
}

export default (TableCell);
