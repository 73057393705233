import * as actions from "./constants";
import {convertToArrayOfObjects, hrefFieldsList} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    isLoading: true,
    isCountLoading: false,

    languages: [],
    selectedLanguage: [],
    languagesCount: {from: '', to: ''},
    count: '',
    isOnlyChosenLanguages: false,
    isFiltersSelected: false,
    fieldsList: hrefFieldsList,

    sources: [],
    selectedSource: [],
    emails: 0,
    twitters: 0,
};

export default function href(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_HREF:
            return {
                ...state,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_HREF:
            return {
                ...state,
                list: action.response && action.response.result
            }
        case actions.REQUEST_LAST_UPDATE_HREF:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_HREF:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_HREF:
            return {
                ...state,
                isLoading: true,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_HREF:
            return {
                ...state,
                isLoading: false,
                list: [...state.list, ...action.response.result]
            }
        case actions.SET_SEARCH_LANGUAGES_HREF:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_HREF_FILTER_DATA:
            const sourcesList = convertToArrayOfObjects(action.response.sources);
            return {
                ...state,
                sources: sourcesList,
                selectedSource: sourcesList,
                languages: action.response.languages,
                selectedLanguage: action.response.languages,
            }
        case actions.SET_SELECTED_LANGUAGES_HREF:
            return {
                ...state,
                selectedLanguage: action.selectedLanguage,
            }
        case actions.SET_SELECTED_SOURCE_HREF:
            return {
                ...state,
                selectedSource: action.selectedSource,
            }
        case actions.SET_LANGUAGE_COUNT_HREF:
            return {
                ...state,
                languagesCount: action.languagesCount,
            }
        case actions.REQUEST_GET_COUNT_HREF:
            return {
                ...state,
                isLoading: true,
            }
        case actions.SUCCEED_GET_COUNT_HREF:
            return {
                ...state,
                isLoading: false,
                count: action.from !== 'popup'? action.count : state.count,
                total: action.from !== 'popup'? action.count.records : state.total,
            }

        case actions.REQUEST_GET_COUNT_EMAILS_AND_TWITTERS_HREF:
            return {
                ...state,
                isLoading: true,
                isCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_EMAILS_AND_TWITTERS_HREF:
            return {
                ...state,
                isLoading: false,
                emails: action.emails,
                twitters: action.twitters,
                isCountLoading: false
            }
        case actions.SET_IS_ONLY_CHOSEN_LANGUAGES_HREF:
            return {
                ...state,
                isOnlyChosenLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_FILTER_SELECTED_HREF:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_HREF:
            return {
                ...state,
                selectedLanguage: [],
                languagesCount: {from: '', to: ''},
                selectedSource: state.sources,
                isOnlyChosenLanguages: false,
                limit: 10,
                offset: 0
            }
        default:
            return state;
    }
}