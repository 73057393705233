import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {updateItemInArray} from "../../helpers/utils";

const initialState = {
    limit: 10,
    offset: 0,
    status: null,
    search: '',
    list: [],
    total: 0,
    magnetContactsAccount: 2,
    selectedStatus: 'All',
    sortOrder: 'asc',
    isFiltersSelected: false,
    statusList: [
        {id: 1, name: 'New'},
        {id: 2, name: 'Approved'},
        {id: 3, name: 'Rejected'},
        {id: 4, name: 'Skipped'},
        {id: 5, name: 'Failed'},
        {id: 6, name: 'Not found'}
    ],
};

export default function magnetVendorsContacts(state = initialState, action) {
    switch (action.type) {

        case actions.REQUEST_INIT_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_CONTACTS_REQUESTED,
                list: [],
                total: 0
            }
        case actions.SUCCEED_INIT_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_CONTACTS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_CONTACTS_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_CONTACTS_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.REQUEST_REFRESH_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_CONTACTS_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_REFRESH_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_CONTACTS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_UPDATE_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.UPDATE_MAGNET_CONTACTS_REQUESTED,
                list: updateItemInArray(state.list, action.updatedItem),
            }
        case actions.SUCCEED_UPDATE_MAGNET_CONTACTS:
            return {
                ...state,
                status: statuses.UPDATE_MAGNET_CONTACTS_SUCCEEDED,
            }

        case actions.SET_MAGNET_CONTACTS_SEARCH:
            return {
                ...state,
                search: action.search
            }
        case actions.SET_SELECTED_MAGNET_CONTACTS_STATUS:
            return {
                ...state,
                selectedStatus: action.selectedStatus,
            }
        case actions.SET_SORT_MAGNET_CONTACTS:
            return {
                ...state,
                sortOrder: action.order,
            }
        case actions.SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_VENDORS:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_MAGNET_CONTACTS_VENDORS:
            return {
                ...state,
                selectedStatus: 'All',
            }
        default:
            return state;
    }
}