import isEqual from "lodash/isEqual";
import {getNintendoRecordsRequest, getLastUpdateNintendoRequest, getNintendoFilterDataRequest, exportNintendoRecordsRequest} from "../../services/grpcBufServices";
import {
    requestInitNintendo,
    requestSearchNintendo,
    setNintendoCountLanguage,
    setNintendoFilterData,
    setNintendoSelectedLanguage,
    setReleased,
    setSearch,
    succeedInitNintendo,
    succeedSearchNintendo,
    requestLastUpdate,
    succeedLastUpdate,
    setSelectedGenres,
    setSelectedAvailabilities,
    clearFilters
} from "./actions";
import {getReleasedValue} from "../../helpers/dateUtils";
import {getIdsList, getRequestOptions} from "../../helpers/utils";
import {requestExport, succeedExport} from "../App/actions";

export const initNintendo = () =>
    async (dispatch, getState) => {
        const {app: {token}, nintendo: {limit, offset}} = getState();
        dispatch(requestInitNintendo());

        const response = await getNintendoRecordsRequest(token, limit, offset);
        dispatch(succeedInitNintendo(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getLastUpdateNintendoRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getNintendoFilterDataRequest(token);
        dispatch(setNintendoFilterData(response));
    };

export const refreshNintendo = () =>
    async (dispatch, getState) => {
        const {app: {token}, nintendo: {limit, offset, releasedDateRange, defaultDateRange, selectedLanguage, languages, languagesCount, search,
            selectedGenres, genres: genresOptions, selectedAvailabilities, availabilities: availabilitiesOptions, isOnlyChosenLanguages}} = getState();
        dispatch(requestInitNintendo());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const languagesIds = getIdsList(languagesOptions);
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const availabilityOptions = getRequestOptions(selectedAvailabilities, availabilitiesOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getNintendoRecordsRequest(token, limit, offset, languagesIds, languagesCountValue, releasedDate, search, genreOptions, availabilityOptions);
        dispatch(succeedInitNintendo(response));
    };

export const searchNintendo = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, nintendo: {limit, offset, defaultDateRange, releasedDateRange, selectedLanguage, languages, languagesCount, search,
            selectedGenres, genres: genresOptions, selectedAvailabilities, availabilities: availabilitiesOptions, isOnlyChosenLanguages}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchNintendo(updateLimit, updateOffset));
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const languagesIds = getIdsList(languagesOptions);
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const availabilityOptions = getRequestOptions(selectedAvailabilities, availabilitiesOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getNintendoRecordsRequest(token, updateLimit, updateOffset, languagesIds, languagesCountValue, releasedDate, search, genreOptions, availabilityOptions);
        dispatch(succeedSearchNintendo(response));
    };

export const exportNintendo = () =>
    async (dispatch, getState) => {
        const {app: {token}, nintendo: {limit, offset, defaultDateRange, releasedDateRange, selectedLanguage, languages, languagesCount, search, selectedGenres, genres: genresOptions, isOnlyChosenLanguages}} = getState();
        dispatch(requestExport());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const languagesIds = getIdsList(languagesOptions);
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const exportId = await exportNintendoRecordsRequest(token, limit, offset, languagesIds, languagesCountValue, releasedDate, search, genreOptions);
        dispatch(succeedExport(exportId.result.value.id));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearch(search));
    };

export const setLanguageAsync = (selectedLanguage) =>
    async (dispatch, getState) => {
        dispatch(setNintendoSelectedLanguage(selectedLanguage));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setCountLanguageAsync = (languagesCount) =>
    async (dispatch, getState) => {
        dispatch(setNintendoCountLanguage(languagesCount));
    };

export const setGenresAsync = genres =>
    async (dispatch, getState) => {
        dispatch(setSelectedGenres(genres));
    };

export const setAvailabilitiesAsync = availabilities =>
    async (dispatch, getState) => {
        dispatch(setSelectedAvailabilities(availabilities));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };