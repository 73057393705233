import React, {Component} from 'react';
import {connect} from "react-redux";
import {setInitContainer, setTitle} from "../App/actions";
import {
    createMagnetAccount, deleteMagnetAccount,
    initMagnetAccounts,
    refreshMagnetAccounts,
    searchMagnetAccounts,
    setSearchMagnetAccounts,
    setSortAsync, updateMagnetAccount
} from "./actionsAsync";
import Filters from "../../components/Filters";
import Table from "../../components/Table";
import {
    INIT_MAGNET_ACCOUNTS_REQUESTED, SEARCH_MAGNET_ACCOUNTS_REQUESTED,
    } from "../../store/StatusTypes";
import {convertTimestampFormat} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import SortingTitle from "../../components/Table/SortingTitle";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import {ADD_ELEMENT} from "../../constants/FilterTypes";

import '../../css/magnet-accouts.css';

class MagnetAccountsVendors extends Component {

    componentDidMount() {
        const {initMagnetAccounts, account, status} = this.props;
        status === null && initMagnetAccounts(account);
    }

    createMagnetAccountRequest = (value) => {
        const {createMagnetAccount} = this.props;
        createMagnetAccount(value);
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_MAGNET_ACCOUNTS_REQUESTED
            || status === SEARCH_MAGNET_ACCOUNTS_REQUESTED;
    }

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderDirectionValue = (row, column, value) => {
        const items = {
            0: 'unspecified',
            1: 'inbound',
            2: 'outbound',
            3: 'both',
        }
        return items[value];
    }

    getFiltersOptions = () => {
        const filtersOptions = [
            {
                type: ADD_ELEMENT,
                customWrapperClassName: '',
                placeholder: 'email@alconost.com',
                buttonTitle: 'Add Account',
                onClick: this.createMagnetAccountRequest
            },
        ];

        return filtersOptions;
    };

    handleSetSort = (field, order) => {
        const {setSort, refreshMagnetAccounts} = this.props;
        setSort(order, field).then(() => {
            refreshMagnetAccounts();
        })
    }

    subscribeListSortingTitleRender = (key, name) => {
        const {sortOrder} = this.props;

        return <SortingTitle
            columnId={key}
            sortOrder={sortOrder}
            sortField="id"
            value={name}
            onClick={this.handleSetSort}/>
    }

    subscribeListSortingCheckpointRender = (key, name) => {
        const {sortOrder} = this.props;

        return <SortingTitle
            columnId={key}
            sortOrder={sortOrder}
            sortField="checkpoint"
            value={name}
            onClick={this.handleSetSort}/>
    }

    renderMagnetCheckbox = (value, cellKey, row) => {
        const {updateMagnetAccount} = this.props;
        const onChange = (e, checked, id) => {
            updateMagnetAccount(row, cellKey, checked);
        };
        return <Checkbox checked={value} onChange={onChange}/>
    }
    renderRemoveButton = (value, cellKey, row) => {
        const {deleteMagnetAccount} = this.props;
        const onClick = () => {
            deleteMagnetAccount(row.id);
        };
        return <Button title='Remove' onClick={onClick}/>
    }

    render() {
        const {search, list, limit, offset, total, refreshMagnetAccounts, searchMagnetAccounts, setSearch} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'id', name: 'Accounts', fixedColumn: {}, width: 250,
                headerClassName: "header-sort-column",
                headerValueWrapperFn: this.subscribeListSortingTitleRender,
            },
            {key: 'checkpoint', name: 'Checkpoint', fixedColumn: {}, width: 150,
                renderValue: this.renderReleasedValue,
                headerClassName: "header-sort-column",
                headerValueWrapperFn: this.subscribeListSortingCheckpointRender,
            },
            {key: 'isInbound', name: 'Inbound', fixedColumn: {}, width: 100,
                cellClassName: "magnet-checkbox-cell",
                cellValueWrapperFn: this.renderMagnetCheckbox},
            {key: 'isOutbound', name: 'Outbound', fixedColumn: {}, width: 100,
                cellClassName: "magnet-checkbox-cell",
                cellValueWrapperFn: this.renderMagnetCheckbox},
            {key: 'empty', name: '', width: 10},
            {key: 'button', name: '', fixedColumn: {}, width: 131,
                cellClassName: "magnet-blacklist-button",
                cellValueWrapperFn: this.renderRemoveButton
            },
        ];

        return (
            <React.Fragment>
                <Filters containerClassName="magnet-account-filter"
                         filtersOptions={filtersOptions}
                         search={search}
                         setSearch={setSearch}
                         onRefresh={refreshMagnetAccounts}/>
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchMagnetAccounts}
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        search: state.magnetVendorsAccounts.search,
        status: state.magnetVendorsAccounts.status,
        list: state.magnetVendorsAccounts.list,
        total: state.magnetVendorsAccounts.total,
        limit: state.magnetVendorsAccounts.limit,
        offset: state.magnetVendorsAccounts.offset,

        sortOrder: state.magnetVendorsAccounts.sortOrder,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initMagnetAccounts: (account) => dispatch(initMagnetAccounts(account)),
        refreshMagnetAccounts: () => dispatch(refreshMagnetAccounts()),
        searchMagnetAccounts: (count) => dispatch(searchMagnetAccounts(count)),
        setSearch: (search) => dispatch(setSearchMagnetAccounts(search)),
        setSort: (order, sortField) => dispatch(setSortAsync(order, sortField)),

        createMagnetAccount: (value) => dispatch(createMagnetAccount(value)),
        deleteMagnetAccount: (value) => dispatch(deleteMagnetAccount(value)),
        updateMagnetAccount: (item, cellKey, checked) => dispatch(updateMagnetAccount(item, cellKey, checked)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MagnetAccountsVendors)