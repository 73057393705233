import {getContactsPagedRequest, updateMagnetContactRequest} from "../../services/grpcBufServices";
import {
    requestInitMagnetContacts, requestRefreshMagnetContacts, requestSearchMagnetContacts, requestUpdateMagnetContacts,
    setMagnetContactsSearch, succeedInitMagnetContacts, setSortMagnetContacts, clearFilters,
    succeedRefreshMagnetContacts, succeedSearchMagnetContacts, succeedUpdateMagnetContacts, setMagnetSelectedStatus
} from "./actions";
import {getSortOrder} from "../../helpers/utils";

export const initMagnetContacts = (account) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesContacts: {limit, offset, search, sortOrder}} = getState();
        dispatch(requestInitMagnetContacts(account));
        const order = getSortOrder(sortOrder);
        const response = await getContactsPagedRequest(token, limit, offset, search, account, "", order);
        dispatch(succeedInitMagnetContacts(response));
    };

export const refreshMagnetContacts = () =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesContacts: {limit, offset, search, magnetContactsAccount, selectedStatus, sortOrder}} = getState();
        dispatch(requestRefreshMagnetContacts());
        const order = getSortOrder(sortOrder);
        const response = await getContactsPagedRequest(token, limit, offset, search, magnetContactsAccount, selectedStatus, order);
        dispatch(succeedRefreshMagnetContacts(response));
    };

export const searchMagnetContacts = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesContacts: {limit, offset, search, magnetContactsAccount, selectedStatus, sortOrder}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchMagnetContacts(updateLimit, updateOffset));
        const order = getSortOrder(sortOrder);
        const response = await getContactsPagedRequest(token, updateLimit, updateOffset, search, magnetContactsAccount, selectedStatus, order);
        dispatch(succeedSearchMagnetContacts(response));
    };

export const updateMagnetContacts = (item) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesContacts: {magnetContactsAccount}} = getState();
        dispatch(requestUpdateMagnetContacts(item));
        await updateMagnetContactRequest(token, magnetContactsAccount, item);
        dispatch(succeedUpdateMagnetContacts());
    };

export const setSearchMagnetContacts = (search) =>
    async (dispatch, getState) => {
        dispatch(setMagnetContactsSearch(search));
    };

export const setStatusAsync = status =>
    async (dispatch, getState) => {
        dispatch(setMagnetSelectedStatus(status));
    };

export const setSortAsync = (order) =>
    async (dispatch, getState) => {
        dispatch(setSortMagnetContacts(order));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };