import {getListSubscribeRequest, updateSubscribeContactRequest} from "../../services/grpcBufServices";
import {
    requestInitSubscribeList, requestRefreshSubscribeList, requestSearchSubscribeList,
    requestUpdateSubscribeList, setSearchSubscribeList, setSortSubscribeList,
    succeedInitSubscribeList, succeedRefreshSubscribeList, succeedSearchSubscribeList,
    successUpdateSubscribeList, setDecisionStatus, clearFilters
} from "./actions";
import {getListSubscribeSortField, getSortOrder, getUpdateSubscribeListField} from "../../helpers/utils";

export const getListSubscribe = () =>
    async (dispatch, getState) => {
        const {app: {token}, subscribeList: {limit, offset, search, sortField, sortOrder}} = getState();
        dispatch(requestInitSubscribeList());
        const field = getListSubscribeSortField(sortField);
        const order = getSortOrder(sortOrder);
        const response = await getListSubscribeRequest(token, limit, offset, search, field, order);
        dispatch(succeedInitSubscribeList(response));
    };

export const searchSubscribeList = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, subscribeList: {limit, offset, search, sortField, sortOrder, selectedDecisionStatus}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchSubscribeList(updateLimit, updateOffset));
        const field = getListSubscribeSortField(sortField);
        const order = getSortOrder(sortOrder);
        const decisionStatus = selectedDecisionStatus === 'All' ? null : selectedDecisionStatus;
        const response = await getListSubscribeRequest(token, updateLimit, updateOffset, search, field, order, decisionStatus);
        dispatch(succeedSearchSubscribeList(response));
    };

export const refreshListSubscribe = () =>
    async (dispatch, getState) => {
        const {app: {token}, subscribeList: {limit, offset, search, sortField, sortOrder, selectedDecisionStatus}} = getState();
        dispatch(requestRefreshSubscribeList());
        const field = getListSubscribeSortField(sortField);
        const order = getSortOrder(sortOrder);
        const decisionStatus = selectedDecisionStatus === 'All' ? null : selectedDecisionStatus;
        const response = await getListSubscribeRequest(token, limit, offset, search, field, order, decisionStatus);
        dispatch(succeedRefreshSubscribeList(response));
    };

export const updateSubscribeList = (updateItem, key, type, value) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestUpdateSubscribeList(updateItem));
        const field = getUpdateSubscribeListField(key);
        const response = await updateSubscribeContactRequest(token, updateItem.id, field, type, value);
        dispatch(successUpdateSubscribeList(response));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearchSubscribeList(search));
    };

export const setSortAsync = (field, order) =>
    async (dispatch, getState) => {
        dispatch(setSortSubscribeList(field, order));
    };

export const setDecisionStatusAsync = (selectedDecisionStatus) =>
    async (dispatch, getState) => {
        dispatch(setDecisionStatus(selectedDecisionStatus));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };