import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookieByName = (name) => {
    return cookies.get(name);
}

export const setCookie = (name, value) => {
    return cookies.set(name, value);
}

export const removeCookieByName = (name, options) => {
    return cookies.remove(name, options);
}