import React, {Component} from 'react';
import {connect} from "react-redux";
import join from "lodash/join";
import map from "lodash/map";
import find from "lodash/find";
import {setInitContainer, setTitle} from "../App/actions";
import {
    initCrunchbase, refreshCrunchbase, searchCrunchbase, setProjectTypeAsync,
    getFilterData, setLocationsAsync, setRankAsync, exportSocials, exportOrganizations,
    setCreatedAsync, getLastUpdate, getCount, clearFiltersAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";
import {
    INIT_CRUCHBASE_REQUESTED,
    SEARCH_CRUCHBASE_REQUESTED,
    GET_FILTER_DATA_CRUNCHBASE_REQUESTED,
    GET_FILTER_DATA_CRUNCHBASE_SUCCEEDED
} from "../../store/StatusTypes";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";
import CountrySelect from "../../components/Select/CountrySelect";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import {getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";
import CONST from "../../constants/Constants";
import {DATE_RANGE, DOWNLOAD_COUNT, SELECT} from "../../constants/FilterTypes";
import {crunchbaseProjectTypes} from "../../constants/CrunchbaseProjectTypes";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'Crunchbase';

class Crunchbase extends Component {

    state = {
        isLoadingFilterData: true
    }

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initCrunchbase, getFilterData, getLastUpdate, status, getCount} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initCrunchbase();
            getFilterData();
            getLastUpdate();
            getCount();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {status} = this.props;
        const {isLoadingFilterData} = this.state;
        if (status === GET_FILTER_DATA_CRUNCHBASE_SUCCEEDED && isLoadingFilterData === true) {
            this.setState({
                isLoadingFilterData: false
            })
        }
    }

    onExportOrganizations = () => {
        const {exportOrganizations} = this.props;
        exportOrganizations();
    }

    onExportSocials = (social) => {
        const {exportSocials} = this.props;
        exportSocials(social);
    }

    handleClearFilters = () => {
        const {clearFilters, initCrunchbase, getCount} = this.props;
        clearFilters().then(() => {
            initCrunchbase();
            getCount();
        });
    }

    getProjectTypeValue = (selectedOptions, options) => {
        if (selectedOptions.length === 0) {
            return 'Unspecified'
        }
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getLocationsValue = (selectedOptions, options) => {
        if (selectedOptions === '') {
            return 'All';
        } else {
            const item = find(options, option => option.id === selectedOptions);
            return item && item.name;
        }
    }

    getLocationsSelect = props => {
        return <CountrySelect {...props} />
    }

    onClearCreated = () => {
        const {setCreated, createdStartDate} = this.props;
        const defaultCreated = getDefaultReleased(createdStartDate);
        setCreated(defaultCreated).then(() => this.handleRefresh());
    }

    getFiltersOptions = () => {
        const {selectedProjectType, setProjectType, locationsList, selectedLocations, setLocations, setRank, rank, createdDateRange,
               defaultDateRange, setCreated, maxRankOrg} = this.props;
        const {isLoadingFilterData} = this.state;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Type organization',
                options: crunchbaseProjectTypes,
                onChange: setProjectType,
                selectedOptions: selectedProjectType,
                renderValue: this.getProjectTypeValue,
                columnsCount: 1,
                defaultOption: {value: []},
                submitButtonName: 'Apply',
            },
            {
                type: SELECT,
                title: 'Country',
                options: locationsList,
                renderValue: this.getLocationsValue,
                customOptionsComponent: this.getLocationsSelect,
                selectedOptions: selectedLocations,
                onChange: setLocations,
                defaultOption: {value: ''},
                isLoading: isLoadingFilterData,
                submitButtonName: 'Apply',
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Organization rank',
                value: rank,
                onChange: setRank,
                placeholder: {from: 0, to: maxRankOrg}
            },
            {
                type: DATE_RANGE,
                title: 'Created',
                customWrapperClassName: 'date-input',
                initDates: createdDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearCreated,
                onChange: setCreated
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_CRUCHBASE_REQUESTED
            || status === SEARCH_CRUCHBASE_REQUESTED
            || status === GET_FILTER_DATA_CRUNCHBASE_REQUESTED;
    }

    onShowMore = () => {
        const {searchCrunchbase} = this.props;
        searchCrunchbase();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderLink = (value) => <Link path={value} value={value}/>;

    renderTimeValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderList = (row, column, value) => join(value, ', ');

    handleRefresh = () => {
        const {refreshCrunchbase, getCount} = this.props;
        refreshCrunchbase();
        getCount();
    }

    render() {
        const {list, total, limit, offset, searchCrunchbase, lastUpdate, title, count, setIsFiltersSelected, isFiltersSelected, isCountSocialsLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const facebookCount = isCountSocialsLoading ? <Loading/> : count.facebooks;
        const twitterCount = isCountSocialsLoading ? <Loading/> : count.twitters;
        const linkedinCount = isCountSocialsLoading ? <Loading/> : count.linkedins;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Organization name', width: 200,
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'location', name: 'Country', width: 150},
            {key: 'permalink', name: 'Crunchbase link', width: 220,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'createdAt', name: 'Created at date', width: 120,
                renderValue: this.renderTimeValue
            },
            {key: 'updatedAt', name: 'Updated on Crunchbase', width: 120,
                renderValue: this.renderTimeValue},
            {key: 'organizationType', name: 'Type of organization', width: 150,
                renderValue: this.renderList},
            {key: 'rankOrg', name: 'Rank organization', width: 110,
                renderValue: renderBigNumbers
            },
            {key: 'rankPrincipal', name: 'Rank principal', width: 110,
                renderValue: renderBigNumbers
            },
            {key: 'stockExchangeSymbol', name: 'Stock exchange symbol', width: 140},
            {key: 'facebook', name: 'Facebook', width: 220,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'linkedin', name: 'Linkedin', width: 220,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'twitter', name: 'Twitter', width: 220,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'websiteUrl', name: 'Website', width: 220,
                cellValueWrapperFn: this.renderLink
            },
        ];

        const dropdownExportArray = [
            {
                onClick: this.onExportOrganizations,
                title: "All data",
                icon: "export-icon__all-data"
            },
            {
                onClick: () => this.onExportSocials(3),
                title: "Twitter",
                icon: "export-icon__twitter"
            },
            {
                onClick: () => this.onExportSocials(2),
                title: "Linkedin",
                icon: "export-icon__linkedin"
            },
            {
                onClick: () => this.onExportSocials(1),
                title: "Facebook",
                icon: "export-icon__facebook"
            },
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Facebooks: {facebookCount}</div>
                            <div>Linkedins: {linkedinCount}</div>
                            <div>Twitters: {twitterCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 dropdownExport={dropdownExportArray}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchCrunchbase}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.crunchbase.list,
        status: state.crunchbase.status,
        total: state.crunchbase.total,
        limit: state.crunchbase.limit,
        offset: state.crunchbase.offset,
        selectedProjectType: state.crunchbase.selectedProjectType,
        locationsList: state.crunchbase.locationsList,
        selectedLocations: state.crunchbase.selectedLocations,
        rank: state.crunchbase.rank,
        lastUpdate: state.crunchbase.lastUpdate,
        createdDateRange: state.crunchbase.createdDateRange,
        defaultDateRange: state.crunchbase.defaultDateRange,
        count: state.crunchbase.count,
        createdStartDate: state.crunchbase.createdStartDate,
        isFiltersSelected: state.crunchbase.isFiltersSelected,
        maxRankOrg: state.crunchbase.maxRankOrg,
        isCountSocialsLoading: state.crunchbase.isCountSocialsLoading,

        initContainers: state.app.initContainers,
        title: state.app.title
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initCrunchbase: () => dispatch(initCrunchbase()),
        searchCrunchbase: (count) => dispatch(searchCrunchbase(count)),
        refreshCrunchbase: () => dispatch(refreshCrunchbase()),
        exportOrganizations: () => dispatch(exportOrganizations()),
        exportSocials: (social) => dispatch(exportSocials(social)),
        setProjectType: (projectType) => dispatch(setProjectTypeAsync(projectType)),
        getFilterData: () => dispatch(getFilterData()),
        setLocations: selectedLocations => dispatch(setLocationsAsync(selectedLocations)),
        setRank: (rank) => dispatch(setRankAsync(rank)),
        setCreated: (created) => dispatch(setCreatedAsync(created)),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getCount: () => dispatch(getCount()),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Crunchbase)