import {combineReducers} from 'redux';
import app from "../containers/App/reducer";
import appStore from "../containers/AppStore/reducer";
import close from "../containers/Close/reducer";
import googlePlay from "../containers/GooglePlay/reducer";
import crunchbase from "../containers/Crunchbase/reducer";
import steamGames from "../containers/SteamGames/reducer";
import steamPublishers from "../containers/SteamPublishers/reducer";
import twitter from "../containers/Twitter/reducer";
import twitterAudience from "../containers/TwitterAudience/reducer";
import notification from "./enhancers/notification";
import subscribeDecide from "../containers/SubscribeDecide/reducer";
import subscribeList from "../containers/SubscribeList/reducer";
import epic from "../containers/Epic/reducer";
import nintendo from "../containers/Nintendo/reducer";
import href from "../containers/Href/reducer";
import magnet from "../containers/Magnet/reducer";
import magnetVendorsAccounts from "../containers/MagnetAccountsVendors/reducer";
import magnetSalesAccounts from "../containers/MagnetAccountsSales/reducer";
import magnetVendorsDecide from "../containers/MagnetDecideVendors/reducer";
import magnetSalesDecide from "../containers/MagnetDecideSales/reducer";
import magnetSalesBlacklist from "../containers/MagnetBlackListSales/reducer";
import magnetVendorsBlacklist from "../containers/MagnetBlackListVendors/reducer";
import magnetSalesContacts from "../containers/MagnetContactsSales/reducer";
import magnetVendorsContacts from "../containers/MagnetContactsVendors/reducer";
import crowdin from "../containers/Crowdin/reducer";
import snovio from "../containers/Snovio/reducer";
import itchGames from "../containers/ItchGames/reducer";
import itchAuthors from "../containers/ItchAuthors/reducer";
import euStartups from "../containers/EuStartups/reducer";
import gog from "../containers/Gog/reducer";
import meetToMatch from "../containers/MeetToMatch/reducer";
import meetToMatchConference from "../containers/MeetToMatchConference/reducer";

export const appReducer = combineReducers({
    app,
    notification,
    appStore,
    close,
    googlePlay,
    crunchbase,
    steamGames,
    steamPublishers,
    twitter,
    twitterAudience,
    subscribeDecide,
    subscribeList,
    epic,
    nintendo,
    href,
    magnet,
    magnetVendorsAccounts,
    magnetSalesAccounts,
    magnetVendorsDecide,
    magnetSalesDecide,
    magnetSalesBlacklist,
    magnetVendorsBlacklist,
    magnetSalesContacts,
    magnetVendorsContacts,
    crowdin,
    snovio,
    itchGames,
    itchAuthors,
    euStartups,
    gog,
    meetToMatch,
    meetToMatchConference
});

