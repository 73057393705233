import React, {useState, useRef} from "react";
import { usePopper } from "react-popper";
import classNames from 'classnames';
import {convertTimestampFormat, getDateTimeAgo} from "../../helpers/dateUtils";

import './css/cell-time-popover.css';
import CONST from "../../constants/Constants";

function CellTimeAgo({row, column, value}) {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef();
    const valueRef = useRef();
    const arrowRef = useRef();

    const { styles, attributes } = usePopper(
        valueRef.current,
        popoverRef.current,
        {
            placement: "bottom-start",
            modifiers: [
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [0, 8]
                    }
                }
            ]
        }
    );

    const openPopover = () => {
        setIsOpen(true);
    };

    const closePopover = () => {
        setIsOpen(false);
    };

    const timeAgo = getDateTimeAgo(value);
    const timeDescription = convertTimestampFormat(value, CONST.DATE_FORMAT_WITH_TIME);
    const popoverClassName = classNames("cell-time-ago__popover", {
        "cell-time-ago__popover_display": isOpen
    });
    return (
        <div>
            <div ref={valueRef} onMouseEnter={openPopover} onMouseLeave={closePopover}>{timeAgo}</div>
            <div ref={popoverRef} className={popoverClassName} style={styles.popper} {...attributes.popper}>
                <div ref={arrowRef} className="cell-time-ago__popover-arrow" style={styles.arrow}/>
                <div>
                    {timeDescription}
                </div>
            </div>
        </div>
    )
}

export default CellTimeAgo;