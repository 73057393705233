import * as types from "./constants";

export function requestInitEuStartups() {
    return {
        type: types.REQUEST_INIT_EU_STARTUPS
    };
}

export function succeedInitEuStartups(response) {
    return {
        type: types.SUCCEED_INIT_EU_STARTUPS,
        response
    };
}

export function requestSearchEuStartups(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_EU_STARTUPS,
        limit, offset
    };
}

export function succeedSearchEuStartups(response) {
    return {
        type: types.SUCCEED_SEARCH_EU_STARTUPS,
        response
    };
}

export function requestLastUpdateEuStartups() {
    return {
        type: types.REQUEST_LAST_UPDATE_EU_STARTUPS,
    };
}

export function succeedLastUpdateEuStartups(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_EU_STARTUPS,
        lastUpdate
    };
}

export function succeedGetEuStartupsFilterData(response) {
    return {
        type: types.GET_EU_STARTUPS_FILTER_DATA,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_EU_STARTUPS_SEARCH,
        search
    };
}

export function setCountry(selectedCountry) {
    return {
        type: types.SET_EU_STARTUPS_COUNTRY,
        selectedCountry
    };
}

export function requestGetCountEuStartups() {
    return {
        type: types.REQUEST_GET_COUNT_EU_STARTUPS,
    };
}

export function succeedGetCountEuStartups(count) {
    return {
        type: types.SUCCEED_GET_COUNT_EU_STARTUPS,
        count
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_EU_STARTUPS,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_EU_STARTUPS,
        isFiltersSelected
    };
}