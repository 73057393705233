import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from "../Popover";
import Button from "../Button";
import Input from "../Input";

import '../DownloadCount/css/download-count.css';
import './css/languages-count.css';

const COUNT = 'count';

class LanguagesCount extends Component {

    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.any,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            focusedInput: '',
            value: '',
        }

        this.input = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value} = this.props;
        if (value !== prevProps.value) {
            this.setState({
                value
            })
        }
    }

    onToggleMenu = () => {
        this.setState((state, props) => {
            return {
                isOpen: !state.isOpen,
                value: props.value
            }
        });
    }

    getValue = () => {
        const {value} = this.props;
        if (value) {
            return value
        } else {
            return 'All';
        }
    }

    isCountValid = count => {
        const ONLY_NUMBERS = /^[0-9]+$/;
        return ONLY_NUMBERS.test(count) || count === '';
    };

    onChange = (e) => {
        const value = e.target.value;
        const isValid = this.isCountValid(value);
        isValid && this.setState({
            value
        })
    };

    onFocusesInputChange = (focusedInput) => {
        this.setState({
            focusedInput
        });
    };

    onClickCountInput = () => {
        this.onFocusesInputChange(COUNT);
    };

    onCancel = () => {
        const {onChange} = this.props;
        this.setState({
            value: '',
        }, () => {
            onChange('');
            this.onToggleMenu();
        })
    }

    onSubmit = () => {
        const {value} = this.state;
        const {onChange} = this.props;
        onChange(value);
        this.onToggleMenu();
    }

    render() {
        const {isOpen, focusedInput, value} = this.state;
        const {title, popoverClassName} = this.props;

        const closedPopupValue = this.getValue();

        const inputClassNames = classNames("select__input", {
            "active": isOpen
        });

        const popoverClassNames = classNames("select-items__popover", popoverClassName);
        const countInputWrapperClassNames = classNames('languages-count__input-field', {
            'download-count__input-focused' : focusedInput === COUNT,
        });

        const inputRef = this.input && this.input.current;

        return (
            <div className="select__container">
                <div className="select__title">{title}</div>
                <div ref={this.input} className={inputClassNames} onClick={this.onToggleMenu}>
                    <div className="select__input-value" title={value}>
                        {closedPopupValue}
                    </div>
                    <div className="select__arrow down-arrow-icon"/>
                </div>

                <Popover anchorEl={inputRef} className={popoverClassNames} open={isOpen} withArrow={true} onClosePopover={this.onToggleMenu}>
                    <div className="download-count__input-container">
                        <div className="download-count__input">
                            <div className="title">Languages</div>
                            <Input className={countInputWrapperClassNames}
                                   value={value}
                                   onMouseDown={this.onClickCountInput}
                                   onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="select-items__buttons">
                        <Button className="cancel-button-download" title="Clear" onClick={this.onCancel}/>
                        <Button className="submit-button-download" title="Apply" onClick={this.onSubmit}/>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default (LanguagesCount);
