import {applyMiddleware, createStore} from 'redux';
import {appReducer} from './reducers';
import thunk from "redux-thunk";
import blockUiMiddleware from "./enhancers/blockUiMiddleware";
import showDownloadPopupMiddleware from "./enhancers/showDownloadPopupMiddleware";

const store = createStore(
    appReducer,
    applyMiddleware(thunk, blockUiMiddleware, showDownloadPopupMiddleware)
);

export default store;