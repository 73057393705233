import React, {useEffect, useRef} from "react";

import useResizeTextArea from "../../hooks/useResizeTextArea";

function ResizableTextArea({name, baseHeight, minTextRows, value, onChange}) {
    const textarea = useRef(null);
    const {calculateRowsCount} = useResizeTextArea();

    useEffect(() => {
        const scrollHeight = textarea.current.scrollHeight;
        scrollHeight > baseHeight && calculateRowsCount(textarea, baseHeight, minTextRows);
    }, [baseHeight, calculateRowsCount, minTextRows]);

    const handleInput = () => {
        calculateRowsCount(textarea, baseHeight, minTextRows);
    };

    return (
        <textarea ref={textarea} name={name} onInput={handleInput} rows={minTextRows} defaultValue={value} onChange={onChange}/>
    )
}

export default ResizableTextArea
