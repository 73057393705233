import React from 'react';

import '../css/subscribe-success.css';

function SuccessSetFilterPopup({closePopup}) {

    return (
        <div className="subscribe-success-component__container">
            <div className="popup-component__title">Hoooray!<span className="subscribe-success-component__icon"/></div>
            <div className="subscribe-success-component__description">In short time you will see new emails to work on</div>
            <div className="subscribe-success-button" onClick={closePopup}>
                <div className="subscribe-close-button__title">
                    Close
                </div>
            </div>
        </div>
    )
}

export default SuccessSetFilterPopup;