import {
    getSnovioEmailsRequest, getLastUpdateSnovioRequest, getSnovioFilterDataRequest, exportSnovioEmailsRequest, getSnovioCountRequest
} from "../../services/grpcBufServices";
import {
    requestInitSnovio, succeedInitSnovio,
    requestSearchSnovio, succeedSearchSnovio,
    requestLastUpdate, succeedLastUpdate,
    requestGetFilterData, succeedGetFilterData,
    setSource, clearFilters, succeedGetCountSnovio,
    requestGetCountSnovio
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";
import {getExportFieldValues} from "../../helpers/utils";

export const initSnovio = () =>
    async (dispatch, getState) => {
        const {app: {token}, snovio: {limit, offset}} = getState();
        dispatch(requestInitSnovio());
        const response = await getSnovioEmailsRequest(token, limit, offset);
        dispatch(succeedInitSnovio(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getLastUpdateSnovioRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const searchSnovio = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, snovio: {limit, offset, selectedSource}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchSnovio(updateLimit, updateOffset));
        const response = await getSnovioEmailsRequest(token, updateLimit, updateOffset, selectedSource);
        dispatch(succeedSearchSnovio(response));
    };

export const refreshSnovio = () =>
    async (dispatch, getState) => {
        const {app: {token}, snovio: {limit, offset, selectedSource}} = getState();
        dispatch(requestInitSnovio());
        const response = await getSnovioEmailsRequest(token, limit, offset, selectedSource);
        dispatch(succeedInitSnovio(response));
    };

export const getCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, snovio: {selectedSource}} = getState();
        dispatch(requestGetCountSnovio());
        const fieldValues = getExportFieldValues(fields);
        const response = await getSnovioCountRequest(token, selectedSource, fieldValues);
        dispatch(succeedGetCountSnovio(response));
        return response.count;
    };

export const exportSnovioEmails = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, snovio: {limit, offset, selectedSource}} = getState();
        dispatch(requestExport());
        const fieldValues = getExportFieldValues(fields);
        const exportId = await exportSnovioEmailsRequest(token, limit, offset, selectedSource, fieldValues);
        dispatch(succeedExport(exportId.task.id));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetFilterData());
        const response = await getSnovioFilterDataRequest(token);
        dispatch(succeedGetFilterData(response));
    };

export const setSourceAsync = selectedSource =>
    async (dispatch, getState) => {
        dispatch(setSource(selectedSource));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };

