import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {convertDateFormat} from "../../helpers/dateUtils";
import {escapeHTML, getHighlightSubstring} from "../../helpers/utils";
import CONST from "../../constants/Constants";
import IFrame from "../IFrame";
import Link from "../Link/index";

class MagnetDecideLetter extends Component {
    static propTypes = {
        headers: PropTypes.object,
    };

    static defaultProps = {
        headers: {}
    };

    render() {
        const {id, userId, headers, email, bodyHtml, totalLeft, isDisplayLetterLink, isFailed} = this.props;
        const {from, to, cc, replyTo, subject, date} = headers;
        const receivedDate = date && convertDateFormat(date, CONST.DATE_FORMAT_WITH_TIME);
        const notFountTime = convertDateFormat(date, CONST.DATE_TIME);
        const notFoundDate = convertDateFormat(date, CONST.DATE_STANDARD_FORMAT);
        const letterClassName = classNames("magnet-decide__letter", {
            "hidden": totalLeft === 0
        });

        const emptyLetterClassName = classNames("magnet-decide__empty-letter", {
            "magnet-decide__empty-letter_hidden": totalLeft !== 0
        });

        const letterLink = `https://mail.google.com/mail?authuser=${userId}#all/${id}`;

        const emailWithoutHtmlTags = email && escapeHTML(email);
        const toWithoutHtmlTags = to && escapeHTML(to);
        const fromWithoutHtmlTags = from && escapeHTML(from);
        const ccWithoutHtmlTags = cc && escapeHTML(cc);
        const replyToWithoutHtmlTags = replyTo && escapeHTML(replyTo);

        const updateTo = toWithoutHtmlTags && getHighlightSubstring(toWithoutHtmlTags, emailWithoutHtmlTags);
        const updateFrom = fromWithoutHtmlTags && getHighlightSubstring(fromWithoutHtmlTags, emailWithoutHtmlTags);
        const updateCc = ccWithoutHtmlTags && getHighlightSubstring(ccWithoutHtmlTags, emailWithoutHtmlTags);
        const updateReplyTo = replyToWithoutHtmlTags && getHighlightSubstring(replyToWithoutHtmlTags, emailWithoutHtmlTags);
        const titleClassNames = classNames("magnet-decide__letter-title", {
            "magnet-decide__vendors-title": isDisplayLetterLink
        });

        return (
            <React.Fragment>
                <div className={emptyLetterClassName}>
                    <div className="magnet-decide__empty-letter-icon"/>
                </div>
                <div className={letterClassName}>
                    {isFailed
                        ? <div className="not-found-message">
                            <div className="text">
                                Oops! Email of the contact <span className="highlight-text">{email}</span> isn’t found – 404.<br/>
                                The last interaction with the email was at <span className="highlight-text">{notFountTime} on {notFoundDate}</span><br/>
                                You can try to find this email by logging in to <span className="highlight-text">{userId}</span> and <Link path={letterLink} value={"following the link."}/><br/>
                                <br/>
                                If you can’t find it – skip it!
                            </div>
                          </div>
                        : <React.Fragment>
                        <div className={titleClassNames}>
                            <div className="info__container magnet-decide__letter-title-main">
                                <div className="info__content">
                                    <div className="title">Received</div>
                                    <div className="value">{receivedDate}</div>
                                </div>

                                <div className="info__content">
                                    <div className="title">From</div>
                                    <div className="value" dangerouslySetInnerHTML={{__html: updateFrom}}/>
                                </div>

                                <div className="info__content">
                                    <div className="title">To</div>
                                    <div className="value" dangerouslySetInnerHTML={{__html: updateTo}}/>
                                </div>

                                <div className="info__content">
                                    <div className="title">CC</div>
                                    <div className="value" dangerouslySetInnerHTML={{__html: updateCc}}/>
                                </div>
                            </div>
                            <div className="info__container">
                                <div className="info__content">
                                    <div className="title">Reply to</div>
                                    <div className="value" dangerouslySetInnerHTML={{__html: updateReplyTo}}/>
                                </div>
                                <div className="info__content">
                                    <div className="title">Subject</div>
                                    <div className="value">{subject}</div>
                                </div>
                            </div>
                            {isDisplayLetterLink && <a className="info__letter-link" target="_blank" rel="noreferrer" href={letterLink}>
                                <span className="title">View original</span>
                                <div className="icon"/>
                            </a>}
                        </div>
                        <div className="magnet-decide__letter-body">
                            {bodyHtml && <IFrame width='100%' srcDoc={`<html><body style="margin: 0; overflow-y: hidden;">${bodyHtml}</body></html>`}/>}
                        </div>
                    </React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

export default MagnetDecideLetter;