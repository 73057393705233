import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import classNames from "classnames";

import './css/radio-button.css';

class RadioButton extends PureComponent {

    static propTypes = {
        value: PropTypes.any,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func
    };

    static defaultProps = {
        className: '',
        tabIndex: 0,
        checked: false,
    };

    handleChange = (e) => {
        const {onChange, id} = this.props;
        onChange && onChange(e, id);
    };

    handleClick = (e) => {
        const {onClick, id} = this.props;
        onClick && onClick(e, id);
    }

    render() {
        const {checked} = this.props;

        const radioClassName = classNames("radio", {
            "active": checked
        });

        return (
            <div className={radioClassName}>
                <input type="radio" name="radio" className="radio__input" onChange={this.handleChange} onClick={this.handleClick}/>
                <label className="radio__label" />
            </div>
        );
    }
}

export default RadioButton;
