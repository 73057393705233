import React, {Component} from 'react';
import {connect} from "react-redux";
import {setInitContainer, setTitle} from "../App/actions";
import {
    addDomainBlacklistRecord, deleteDomainBlacklistRecord,
    initMagnetBlacklist,
    refreshMagnetBlacklist,
    searchMagnetBlacklist,
    setSearchMagnetBlacklist
} from "./actionsAsync";
import Filters from "../../components/Filters";
import Table from "../../components/Table";
import {
    INIT_MAGNET_BLACKLIST_REQUESTED,
    SEARCH_MAGNET_BLACKLIST_REQUESTED,
} from "../../store/StatusTypes";
import {ADD_ELEMENT} from "../../constants/FilterTypes";
import Button from "../../components/Button";

import '../../css/magnet-blacklist.css';

class MagnetBlacklistVendors extends Component {

    componentDidMount() {
        const {initMagnetBlacklist, account, status} = this.props;
        status === null && initMagnetBlacklist(account);
    }

    addDomainBlacklistRecordRequest = (value) => {
        const {addDomainBlacklistRecord} = this.props;
        addDomainBlacklistRecord(value);
    }

    getFiltersOptions = () => {
        const filtersOptions = [
            {
                type: ADD_ELEMENT,
                customWrapperClassName: '',
                placeholder: 'spam.example.com',
                buttonTitle: 'Add to Blacklist',
                onClick: this.addDomainBlacklistRecordRequest
            },
        ];

        return filtersOptions;
    };

    renderRemoveButton = (value, cellKey, row) => {
        const {deleteDomainBlacklistRecord} = this.props;
        const onClick = () => {
            deleteDomainBlacklistRecord(row.id, row.record);
        }
        return <Button title='Remove' onClick={onClick}/>
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_MAGNET_BLACKLIST_REQUESTED
            || status === SEARCH_MAGNET_BLACKLIST_REQUESTED;
    }

    render() {
        const {search, list, limit, offset, total, refreshMagnetBlacklist, searchMagnetBlacklist, setSearch} = this.props;
        const countItems = offset + limit;

        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'record', name: '', width: 300},
            {key: '', name: '', fixedColumn: {}, width: 131,
                cellClassName: "magnet-blacklist-button",
                cellValueWrapperFn: this.renderRemoveButton
            },
        ];

        return (
            <React.Fragment>
                <Filters containerClassName="magnet-blacklist-filter"
                         filtersOptions={filtersOptions}
                         search={search}
                         setSearch={setSearch}
                         onRefresh={refreshMagnetBlacklist}/>
                <div className="app-root__table">
                    <Table
                        isHiddenHead={true}
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchMagnetBlacklist}
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        search: state.magnetVendorsBlacklist.search,
        status: state.magnetVendorsBlacklist.status,
        list: state.magnetVendorsBlacklist.list,
        total: state.magnetVendorsBlacklist.total,
        limit: state.magnetVendorsBlacklist.limit,
        offset: state.magnetVendorsBlacklist.offset,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initMagnetBlacklist: (account) => dispatch(initMagnetBlacklist(account)),
        refreshMagnetBlacklist: () => dispatch(refreshMagnetBlacklist()),
        searchMagnetBlacklist: (count) => dispatch(searchMagnetBlacklist(count)),
        setSearch: (search) => dispatch(setSearchMagnetBlacklist(search)),

        addDomainBlacklistRecord: (value) => dispatch(addDomainBlacklistRecord(value)),
        deleteDomainBlacklistRecord: (id, value) => dispatch(deleteDomainBlacklistRecord(id, value)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MagnetBlacklistVendors)