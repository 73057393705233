import React from "react";
import isNumber from "lodash/isNumber";

import '../css/download-csv.css';

const DownloadCsvPopup = ({closePopup, exportId, ...props}) => {
    const exportIdMessage = isNumber(exportId) ? ` Your export ID is #${exportId}.` : '';

    return (
        <div className="download-csv__container">
            <div className="download-csv__title">Your data is being processed</div>
            <div className="download-csv__description">
                The creation of an archive with data may require more than a few minutes. Once the processing is complete, a download link will be sent to your email address.
                {exportIdMessage}
            </div>
            <div className="download-csv__button" onClick={closePopup}>
                <div className="download-csv__button-title">Got it</div>
            </div>
        </div>
    )
}

export default DownloadCsvPopup;