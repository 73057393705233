import React, {useState, useRef} from "react";
import classNames from "classnames";
import Popover from "../../Popover";
import ResizableTextArea from "../../ResizableTextArea/index";
import Loading from "../../Loading";

import '../css/subscribe-close-filter.css';

function SubscribeCloseFilterPopup({closePopup, value, onApply, updateSubscribeFilter}) {
    const FRONT_VALIDATION_MESSAGE = "Ooops – we’re unable to fetch data. Check the filter again.";
    const BACK_VALIDATION_MESSAGE = "Oops – we’re unable to fetch data. Please, use another filter.";
    const textareaContainer = useRef();
    const [textareaValue, setTextareaValue] = useState(value);
    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(FRONT_VALIDATION_MESSAGE);
    const [isLoading, setIsLoading] = useState(false);
    const MIN_TEXT_ROWS = 1;

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        setTextareaValue(value);
    }

    const filterValidation = () => {
        const cyrillicPattern = /[а-яА-ЯЁё]/;
        const isHasBrackets = textareaValue.indexOf('(') !== -1 && textareaValue.indexOf(')') !== -1;
        const isHasCyrillic = cyrillicPattern.test(textareaValue);
        const isLineBreak = textareaValue.indexOf('\n') !== -1;
        return isHasBrackets && !isHasCyrillic && !isLineBreak;
    }

    const handleApply = () => {
        const isValid = filterValidation(textareaValue);
        if (isValid) {
            setIsLoading(true);
            setIsOpen(false);
            updateSubscribeFilter(textareaValue).then((response) => {
                setIsLoading(false);
                if (response?.leadSearchQuery) {
                    closePopup();
                    onApply();
                }
                else {
                    setErrorMessage(BACK_VALIDATION_MESSAGE);
                    setIsOpen(true);
                }
            });
        }
        else {
            setErrorMessage(FRONT_VALIDATION_MESSAGE);
            setIsOpen(true);
        }
    }

    const textareaContainerStyle = classNames("subscribe-filter-textarea__container", {"invalid": isOpen});

    return (
        <div className="subscribe-component__container">
            <div className="popup-component__title">Add new filter</div>
            <div className={textareaContainerStyle} ref={textareaContainer}>
                <ResizableTextArea name="subscribe-filter-textarea" baseHeight={24} minTextRows={MIN_TEXT_ROWS} value={value} onChange={handleTextareaChange}/>
            </div>
            <div className="subscribe-buttons">
                <div className="popup-component__button" onClick={handleApply}>
                    <div className="popup-component__button-title">Add new filter</div>
                </div>
                <div className="subscribe-close-button" onClick={closePopup}>
                    <div className="subscribe-close-button__title">
                        Cancel
                    </div>
                </div>
                {isLoading && <Loading/>}
            </div>
            <Popover anchorEl={textareaContainer.current} className="crunchbase-filter__popover" position="right" open={isOpen}>
                <div className="crunchbase-filter__error-message">
                    {errorMessage}
                </div>
            </Popover>
        </div>
    )
}

export default SubscribeCloseFilterPopup;