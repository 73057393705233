import React, {Component} from 'react';
import {connect} from "react-redux";
import clone from 'lodash/clone';
import find from 'lodash/find';
import {setInitContainer, setTitle} from "../App/actions";
import {
    initMagnetContacts,
    refreshMagnetContacts,
    searchMagnetContacts,
    setSearchMagnetContacts,
    updateMagnetContacts,
    setStatusAsync,
    setSortAsync,
    clearFiltersAsync
} from "./actionsAsync";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import RadioButtonSelect from "../../components/Select/RadioButtonSelect";
import {
    INIT_MAGNET_CONTACTS_REQUESTED,
    SEARCH_MAGNET_CONTACTS_REQUESTED,
} from "../../store/StatusTypes";
import {setIsFiltersSelected} from "./actions";

import {convertTimestampFormat} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import {SELECT} from "../../constants/FilterTypes";
import HoverButton from "../../components/HoverButton";
import SortingTitle from "../../components/Table/SortingTitle";

import '../../css/magnet-contacts.css';

class MagnetContactsSales extends Component {

    componentDidMount() {
        const {initMagnetContacts, account, status} = this.props;
        status === null && initMagnetContacts(account);
    }

    getStatusValue = (selectedOptions, options) => {
        const item = find(options, option => option.id === selectedOptions);
        return item && item.name;
    }

    getStatusSelect = props => {
        return <RadioButtonSelect {...props} />
    }

    getFiltersOptions = () => {
        const {setStatus, selectedStatus, statusList} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Status',
                options: statusList,
                onChange: setStatus,
                selectedOptions: selectedStatus,
                customOptionsComponent: this.getStatusSelect,
                renderValue: this.getStatusValue,
                defaultOption: {value: 'All'}
            },
        ];

        return filtersOptions;
    };

    handleClearFilters = () => {
        const {clearFilters, initMagnetContacts, account} = this.props;
        clearFilters().then(() => initMagnetContacts(account));
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_MAGNET_CONTACTS_REQUESTED
            || status === SEARCH_MAGNET_CONTACTS_REQUESTED;
    }

    handleSetSort = (field, order) => {
        const {setSort, refreshMagnetContacts} = this.props;
        setSort(order).then(() => {
            refreshMagnetContacts();
        })
    }

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    releasedTitleRender = (key, name) => {
        const {sortOrder} = this.props;

        return <SortingTitle
            columnId={key}
            sortField="decisionTime"
            sortOrder={sortOrder}
            value={name}
            onClick={this.handleSetSort}/>
    }

    renderStatusValue = (row, column, value) => {
        const contactStatus = {
            1: 'new',
            2: 'approved',
            3: 'rejected',
            4: 'skipped',
            5: 'failed',
            6: 'not found'
        };
        return contactStatus[value];
    };

    renderDecisionButton = (value, cellKey, row) => {
        const {updateMagnetContacts} = this.props;
        const onClick = () => {
            const updateRow = clone(row);
            updateRow.status = 1; //обновляем статус new
            updateMagnetContacts(updateRow);
        }

        return row.status === 1 //если new, то Redecide не можем
            ? <div className="hover-button-value">{value}</div>
            : <HoverButton key={row.id} buttonTitle='Redecide' value={value} onClick={onClick}/>
    };

    render() {
        const {search, list, limit, offset, total, refreshMagnetContacts, searchMagnetContacts, setSearch, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'id', name: 'Email', width: 300},
            {key: 'messageId', name: 'Message ID', width: 200},
            {key: 'sourceEmail', name: 'Account', width: 200},
            {key: 'status', name: 'Status', width: 100,
                cellClassName: "magnet-contact-button-cell",
                renderValue: this.renderStatusValue,
                cellValueWrapperFn: this.renderDecisionButton
            },
            {key: 'decisionBy', name: 'Decision by', width: 200},
            {key: 'decisionTime', name: 'Decision time', width: 140,
                headerValueWrapperFn: this.releasedTitleRender,
                renderValue: this.renderReleasedValue
            },
        ];

        return (
            <React.Fragment>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onApplyFilters={refreshMagnetContacts}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchMagnetContacts}
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        search: state.magnetSalesContacts.search,
        status: state.magnetSalesContacts.status,
        list: state.magnetSalesContacts.list,
        total: state.magnetSalesContacts.total,
        limit: state.magnetSalesContacts.limit,
        offset: state.magnetSalesContacts.offset,
        selectedStatus: state.magnetSalesContacts.selectedStatus,
        statusList: state.magnetSalesContacts.statusList,
        isFiltersSelected: state.magnetSalesContacts.isFiltersSelected,

        sortOrder: state.magnetSalesContacts.sortOrder,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initMagnetContacts: (account) => dispatch(initMagnetContacts(account)),
        updateMagnetContacts: (contact) => dispatch(updateMagnetContacts(contact)),
        refreshMagnetContacts: () => dispatch(refreshMagnetContacts()),
        searchMagnetContacts: (count) => dispatch(searchMagnetContacts(count)),
        setSearch: (search) => dispatch(setSearchMagnetContacts(search)),
        setStatus: status => dispatch(setStatusAsync(status)),
        setSort: (order) => dispatch(setSortAsync(order)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MagnetContactsSales)