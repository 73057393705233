import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import isEmpty from "lodash/isEmpty";

const initialState = {
    status: null,
    magnetAccount: 2,
    message: {headers: {}},
    totalLeft: ''
};

export default function magnetVendorsDecide(state = initialState, action) {
    switch (action.type) {

        case actions.REQUEST_GET_MAGNET_MESSAGE:
            return {
                ...state,
                status: statuses.GET_MAGNET_MESSAGE_REQUESTED
            }
        case actions.SUCCESS_GET_MAGNET_MESSAGE:
            const magnetMessage = !isEmpty(action.response) && action.response.result.case === 'message' ? action.response.result.value : state.message;
            const totalLeftValue = isEmpty(action.response) ? 0 : action.response.totalLeft;

            return {
                ...state,
                status: statuses.GET_MAGNET_MESSAGE_SUCCEEDED,
                message: magnetMessage,
                totalLeft: totalLeftValue
            }
        default:
            return state;
    }
}