import * as types from "./constants";

export function requestInitNintendo() {
    return {
        type: types.REQUEST_INIT_NINTENDO
    };
}

export function succeedInitNintendo(response) {
    return {
        type: types.SUCCEED_INIT_NINTENDO,
        response
    };
}

export function requestSearchNintendo(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_NINTENDO,
        limit, offset
    };
}

export function succeedSearchNintendo(response) {
    return {
        type: types.SUCCEED_SEARCH_NINTENDO,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_NINTENDO_SEARCH,
        search
    };
}

export function setNintendoFilterData(response) {
    return {
        type: types.SET_NINTENDO_FILTER_DATA,
        response
    };
}

export function setNintendoSelectedLanguage(selectedLanguage) {
    return {
        type: types.SET_SELECTED_LANGUAGES_NINTENDO,
        selectedLanguage
    };
}

export function setReleased(released) {
    return {
        type: types.SET_RELEASED_NINTENDO,
        released
    };
}

export function setNintendoCountLanguage(languagesCount) {
    return {
        type: types.SET_COUNT_LANGUAGES_NINTENDO,
        languagesCount
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_NINTENDO,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_NINTENDO,
        lastUpdate
    };
}

export function setSelectedGenres(selectedGenres) {
    return {
        type: types.SET_NINTENDO_SELECTED_GENRES,
        selectedGenres
    };
}

export function setSelectedAvailabilities(selectedAvailabilities) {
    return {
        type: types.SET_NINTENDO_SELECTED_AVAILABILITIES,
        selectedAvailabilities
    };
}

export function setIsOnlyChosenLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_LANGUAGES_NINTENDO,
        isOnlyChosenLanguages
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_NINTENDO,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_NINTENDO,
        isFiltersSelected
    };
}