import React, {PureComponent} from 'react';
import {connect} from "react-redux";

import './css/block-ui.css';

class BlockUi extends PureComponent {

    render() {
        const {blockUi} = this.props;

        return (
            <React.Fragment>
                {blockUi && (
                    <div className="block">
                        <div className="block_overlay"/>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

BlockUi.propTypes = {};

function mapStateToProps(state) {
    return {
        blockUi: state.app.blockUi,
    }
}

export default connect(
    mapStateToProps,
)(BlockUi)