export const REQUEST_INIT_MAGNET_CONTACTS = 'REQUEST_INIT_MAGNET_CONTACTS';
export const SUCCEED_INIT_MAGNET_CONTACTS = 'SUCCEED_INIT_MAGNET_CONTACTS';
export const REQUEST_SEARCH_MAGNET_CONTACTS = 'REQUEST_SEARCH_MAGNET_CONTACTS';
export const SUCCEED_SEARCH_MAGNET_CONTACTS = 'SUCCEED_SEARCH_MAGNET_CONTACTS';
export const REQUEST_REFRESH_MAGNET_CONTACTS = 'REQUEST_REFRESH_MAGNET_CONTACTS';
export const SUCCEED_REFRESH_MAGNET_CONTACTS = 'SUCCEED_REFRESH_MAGNET_CONTACTS';
export const SET_MAGNET_CONTACTS_SEARCH = 'SET_MAGNET_CONTACTS_SEARCH';
export const REQUEST_UPDATE_MAGNET_CONTACTS = 'REQUEST_UPDATE_MAGNET_CONTACTS';
export const SUCCEED_UPDATE_MAGNET_CONTACTS = 'SUCCEED_UPDATE_MAGNET_CONTACTS';
export const SET_STATUS_MAGNET_CONTACTS = 'SET_STATUS_MAGNET_CONTACTS';
export const SET_SELECTED_MAGNET_CONTACTS_STATUS = 'SET_SELECTED_MAGNET_CONTACTS_STATUS';
export const SET_SORT_MAGNET_CONTACTS = 'SET_SORT_MAGNET_CONTACTS';
export const CLEAR_FILTERS_MAGNET_CONTACTS_VENDORS = 'CLEAR_FILTERS_MAGNET_CONTACTS_VENDORS';
export const SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_VENDORS = 'SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_VENDORS';