import React, {Component} from 'react';
import GoogleLogin from "react-google-login";
import {ALCONOST_URL} from "../../services/grpcServices";

class Login extends Component {

    render() {
        return (
            <GoogleLogin
                className='google-login-button'
                clientId='770875976056-qm72cts2365jjjsb3q99jcu4fnuro359.apps.googleusercontent.com'
                render={renderProps => ( <div />)}
                autoLoad={true}
                uxMode={'redirect'}
                prompt={'select_account'}
                redirectUri={`${ALCONOST_URL}/auth`}
                cookiePolicy={'single_host_origin'}
            />
        );
    }
}

export default (Login)