import React, {useState, useEffect} from "react";
import classNames from "classnames";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import map from "lodash/map";
import capitalize from "lodash/capitalize";

import SearchInput from "../SearchInput";
import Loading from "../Loading";
import Input from "../Input";
import Checkbox from "../Checkbox";
import CheckboxItem from "./CheckboxItem";

import './css/select.css';

const FROM_COUNT = 'from';
const TO_COUNT = 'to';

const LanguagesFilter = ({options, onChange, selectedOptions, setSelectedOptions, setOptionsCount, filterName, optionCount, isOnlyChosen, setIsOnlyChosen}) => {
    const [focusedInput, setFocusedInput] = useState('');
    const [languagesCount, setLanguagesCount] = useState(optionCount);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setLanguagesCount(optionCount);
    }, [optionCount])

    const fromCountInputWrapperClassNames = classNames('number-languages__input', {
        'number-languages__input-focused': focusedInput === FROM_COUNT,
    });

    const toCountInputWrapperClassNames = classNames('number-languages__input', {
        'number-languages__input-focused': focusedInput === TO_COUNT,
    });

    const onFocusesInputChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    };

    const numberLanguagesInputsStyle = classNames("number-languages__inputs", {'inputs-disabled': isOnlyChosen});

    const isCountValid = (count) => {
        const validateRegex = /^[0-9]+$/;
        return (validateRegex.test(count) || count === '') && count <= options.length;
    };

    const onChangeNumberLanguages = (e, name, isTextLanguages) => {
        const value = e.target.value;
        const isValid = isCountValid(value);

        if (isValid) {
            const setValue = name === FROM_COUNT
                ? {from: value, to: languagesCount.to}
                : {from: languagesCount.from, to: value}
            setLanguagesCount(setValue);
            setOptionsCount(setValue);
        }
    };

    const handleChange = (option) => (e, checked, id) => {
        onChange && onChange(e, checked, id, option);
    }

    const getRenderItems = () => {
        options.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        const filterOption = filter(options, option => {
            const {name} = option;
            return includes(toLower(name), toLower(searchValue));
        })

        return map(filterOption, option => {
            const {id, name} = option;
            const checked = findIndex(selectedOptions, option => option.id === id) > -1;

            return (
                <CheckboxItem key={id} id={id} name={name} checked={checked} onChange={handleChange(option)}/>
            )
        });
    }

    const calculateHeight = () => {
        const countItemsInColumn = Math.ceil(options.length / 4);
        const ITEM_HEIGHT = 26;
        return {height: countItemsInColumn * ITEM_HEIGHT};
    }

    const allCheckboxChange = (e, checked) => {
        let changeOptions = [];
        if (checked) {
            changeOptions = options;
        }

        setSelectedOptions(changeOptions);
    }

    const toggleCountLanguagesChange = (e, checked) => {
        setIsOnlyChosen(checked);
    }

    const renderOptions = getRenderItems();
    const filterOptionStyle = calculateHeight();
    const allItemName = `All ${filterName}`;
    const numberLanguagesText = `Number of ${capitalize(filterName)}`;

    return (
        <>
            <div className="languages-filter__header">
                <div className="number-languages">
                    <p>{numberLanguagesText}</p>
                    <div className={numberLanguagesInputsStyle}>
                        <div className="number-languages__input-container">
                            <div className="title">From</div>
                            <Input className={fromCountInputWrapperClassNames}
                                   onMouseDown={() => onFocusesInputChange(FROM_COUNT)}
                                   onChange={(e) => onChangeNumberLanguages(e, FROM_COUNT)}
                                   placeholder={1}
                                   value={optionCount?.from || languagesCount?.from}
                                   disabled={isOnlyChosen}/>
                        </div>
                        <div className="number-languages__input-container">
                            <div className="title">To</div>
                            <Input className={toCountInputWrapperClassNames}
                                   onMouseDown={() => onFocusesInputChange(TO_COUNT)}
                                   onChange={(e) => onChangeNumberLanguages(e, TO_COUNT)}
                                   placeholder={options.length}
                                   value={optionCount?.to || languagesCount?.to}
                                   disabled={isOnlyChosen}/>
                        </div>
                    </div>
                    <SearchInput value={searchValue} onChange={setSearchValue} className="appstore-filter" placeholder="Search"/>
                </div>
                <div className="languages-only">
                    <span>Only chosen {filterName}</span>
                    <Checkbox onChange={toggleCountLanguagesChange} checked={isOnlyChosen}/>
                </div>
            </div>
            <div className="languages-filter">
                {options.length ?
                    <>
                        <div className="select-items__item">
                            <CheckboxItem className='all-checkbox' checked={selectedOptions.length === options.length}
                                          name={allItemName} onChange={allCheckboxChange}/>
                        </div>
                        <div className="languages-filter__options-container">
                            <div className="languages-filter__options" style={filterOptionStyle}>
                                {renderOptions}
                            </div>
                        </div>
                    </> : <Loading/>}

            </div>
        </>
    )
}

export default LanguagesFilter;