import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import './css/scrollbar.css'
import classNames from "classnames";

class Scrollbar extends Component {
    static defaultProps = {
    };

    static propTypes = {
        onScrollHandler: PropTypes.func,
    };

    onScrollFrameHandler = values => {
        const {onScrollHandler} = this.props;
        if (onScrollHandler) {
            onScrollHandler(values);
        }
    };

    render() {
        const {className, height} = this.props;
        const scrollbarsClassNames = classNames('scrollbars', className);
        const scrollHeight = height || '100%';

        return (
            <Scrollbars ref={ref => this.scrollbars = ref}
                        className={scrollbarsClassNames}
                        style={{ width: '100%', height: scrollHeight }}
                        autoHide={true}
                        autoHideTimeout={200}
                        autoHideDuration={200}
                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                        onScrollFrame={this.onScrollFrameHandler}>
                {this.props.children}
            </Scrollbars>
        );
    }
}

export default Scrollbar;
