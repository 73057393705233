import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import split from "lodash/split";
import {setInitContainer, setTitle} from "../App/actions";
import {
    exportEpic,
    initEpic,
    refreshEpic,
    searchEpic,
    setAudioLanguagesAsync,
    setTextLanguagesAsync,
    setAudioLanguageCountAsync,
    setTextLanguageCountAsync,
    setReleasedAsync,
    setSearchAsync,
    setTagsAsync,
    getLastUpdate,
    getEpicFilterData,
    getCount,
    clearFiltersAsync,
    setPriceAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";
import {setIsOnlyChosenAudioLanguages, setIsOnlyChosenTextLanguages} from "./actions";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";

import CONST from "../../constants/Constants";
import {DATE_RANGE,  SELECT} from "../../constants/FilterTypes";
import {
    INIT_EPIC_REQUESTED,
    SEARCH_EPIC_REQUESTED
} from "../../store/StatusTypes";

import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getLastUpdateTitle} from "../../helpers/utils";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'Epic';

class Epic extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initEpic, getLastUpdate, getFilterData, status, getCount} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initEpic();
            getFilterData();
            getLastUpdate();
            getCount();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    onExportEpic =() => {
        const {exportEpic} = this.props;
        exportEpic();
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getAudioLanguageValue = (selectedOptions, options) => {
        const {audioLanguagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = audioLanguagesCount.from ? `From ${audioLanguagesCount.from}` : '';
        const languagesCountTo = audioLanguagesCount.to ? `To ${audioLanguagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    getTextLanguageValue = (selectedOptions, options) => {
        const {textLanguagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = textLanguagesCount.from ? `From ${textLanguagesCount.from}` : '';
        const languagesCountTo = textLanguagesCount.to ? `To ${textLanguagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    onClearReleased = () => {
        const {setReleased, refreshEpic, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => refreshEpic());
    }

    getCategoryValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {languages, setAudioLanguages, setTextLanguages, releasedDateRange, defaultDateRange, setReleased, audioLanguagesCount, setIsOnlyChosenAudioLanguages,
            epicTags, selectedEpicTags, setTags, selectedAudioLanguages, selectedTextLanguages, textLanguagesCount, setAudioLanguageCount, price, selectedPrice,
            setTextLanguageCount, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages, setIsOnlyChosenTextLanguages, setPrice} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Audio Languages',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getAudioLanguageValue,
                selectedOptions: selectedAudioLanguages,
                onChange: setAudioLanguages,
                setOptionsCount: setAudioLanguageCount,
                filterName: "languages",
                optionCount: audioLanguagesCount,
                popoverClassName: "popover-languages-filter",
                isOnlyChosen: isOnlyChosenAudioLanguages,
                setIsOnlyChosen: setIsOnlyChosenAudioLanguages,
            },
            {
                type: SELECT,
                title: 'Text Languages',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getTextLanguageValue,
                selectedOptions: selectedTextLanguages,
                onChange: setTextLanguages,
                setOptionsCount: setTextLanguageCount,
                filterName: "languages",
                optionCount: textLanguagesCount,
                popoverClassName: "popover-languages-filter",
                isOnlyChosen: isOnlyChosenTextLanguages,
                setIsOnlyChosen: setIsOnlyChosenTextLanguages,
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: SELECT,
                title: 'Tags',
                popoverClassName: 'epic-category-popover',
                options: epicTags,
                renderValue: this.getCategoryValue,
                selectedOptions: selectedEpicTags,
                onChange: setTags,
                submitButtonName: 'Apply',
            },
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getCategoryValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_EPIC_REQUESTED
            || status === SEARCH_EPIC_REQUESTED;
    }

    onShowMore = () => {
        const {searchEpic} = this.props;
        searchEpic();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderLinks = value => {
        const splitUrl = split(value, ', ');
        return map(splitUrl, (url, index) => {
            if (splitUrl.length === index + 1) {
                return <Link path={url} value={value} key={index}/>;
            } else {
                return <React.Fragment key={index}><Link path={url} value={value}/>, </React.Fragment>;
            }
        });
    }

    handleClearFilters = () => {
        const {clearFilters, initEpic, getCount} = this.props;
        clearFilters().then(() => {
            initEpic();
            getCount();
        });
    }

    renderLink = (value) => <Link path={value} value={value}/>;

    renderPriceCell = (row, column, value) => value ? 'Paid' : 'Free';

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderTagsList = (row, column, value) => {
        const nameValues = map(value, option => option.name);
        return join(nameValues, ', ');
    };

    renderTextLanguagesList = (row, column, value) => join(value, ', ');

    handleRefresh = () => {
        const {refreshEpic, getCount} = this.props;
        refreshEpic();
        getCount();
    }

    render() {
        const {lastUpdate, title, list, total, limit, offset, searchEpic, search, setSearch, count, setIsFiltersSelected, isFiltersSelected, isTwittersCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const twittersCount = isTwittersCountLoading ? <Loading/> : count.twitters;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Name', width: 150},
            {key: 'price', name: 'Price', width: 110, renderValue: this.renderPriceCell},
            {key: 'developer', name: 'Developer', width: 150},
            {key: 'publisher', name: 'Publisher', width: 150},
            {key: 'releaseDate', name: 'Released', width: 120,
                renderValue: this.renderReleasedValue
            },
            {key: 'tags', name: 'Tags', width: 150,
                renderValue: this.renderTagsList
            },
            {key: 'audioLanguages', name: 'Audio Languages', width: 250,
                renderValue: this.renderTextLanguagesList
            },
            {key: 'textLanguages', name: 'Text Languages', width: 250,
                renderValue: this.renderTextLanguagesList
            },
            {key: 'website', name: 'Website', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'socialLinks', name: 'Social links', width: 120,
                cellValueWrapperFn: this.renderLinks
            },
            {key: 'storeLink', name: 'Url', width: 120,
                cellValueWrapperFn: this.renderLinks
            },
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Twitters: {twittersCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportEpic}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchEpic}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.epic.list,
        status: state.epic.status,
        total: state.epic.total,
        limit: state.epic.limit,
        offset: state.epic.offset,
        search: state.epic.search,

        languages: state.epic.languages,
        selectedAudioLanguages: state.epic.selectedAudioLanguages,
        selectedTextLanguages: state.epic.selectedTextLanguages,

        releasedDateRange: state.epic.releasedDateRange,
        defaultDateRange: state.epic.defaultDateRange,
        epicTags: state.epic.epicTags,
        selectedEpicTags: state.epic.selectedEpicTags,
        lastUpdate: state.epic.lastUpdate,
        count: state.epic.count,
        startDate: state.epic.startDate,
        isOnlyChosenAudioLanguages: state.epic.isOnlyChosenAudioLanguages,
        isOnlyChosenTextLanguages: state.epic.isOnlyChosenTextLanguages,
        isFiltersSelected: state.epic.isFiltersSelected,
        audioLanguagesCount: state.epic.audioLanguagesCount,
        textLanguagesCount: state.epic.textLanguagesCount,
        price: state.epic.price,
        selectedPrice: state.epic.selectedPrice,
        isTwittersCountLoading: state.epic.isTwittersCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initEpic: () => dispatch(initEpic()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterData: () => dispatch(getEpicFilterData()),
        searchEpic: (count) => dispatch(searchEpic(count)),
        refreshEpic: () => dispatch(refreshEpic()),
        exportEpic: () => dispatch(exportEpic()),
        setSearch: search => dispatch(setSearchAsync(search)),
        setAudioLanguages: languages => dispatch(setAudioLanguagesAsync(languages)),
        setTextLanguages: languages => dispatch(setTextLanguagesAsync(languages)),
        setAudioLanguageCount: number => dispatch(setAudioLanguageCountAsync(number)),
        setTextLanguageCount: number => dispatch(setTextLanguageCountAsync(number)),
        setTags: tags => dispatch(setTagsAsync(tags)),
        setReleased: released => dispatch(setReleasedAsync(released)),
        getCount: () => dispatch(getCount()),
        setIsOnlyChosenAudioLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenAudioLanguages(isOnlyChosenLanguages)),
        setIsOnlyChosenTextLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenTextLanguages(isOnlyChosenLanguages)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
        setPrice: price => dispatch(setPriceAsync(price)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Epic)