import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import {steamFieldsList} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    startDate: null,
    isOnlyChosenLanguages: false,
    isFiltersSelected: false,
    isCountLoading: false,
    reviewsMax: '',
    price: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    selectedPrice: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    earlyAccess: [
        {id: 1, name: 'Yes'},
        {id: 2, name: 'No'}
    ],
    selectedEarlyAccess: [
        {id: 1, name: 'Yes'},
        {id: 2, name: 'No'}
    ],
    fieldsList: steamFieldsList,

    genres: [],
    selectedGenres: [],
    reviews: {},

    languages: [],
    selectedLanguage: [],
    languagesCount: {from: '', to: ''},
};

export default function steamGames(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_STEAM_GAMES:
            return {
                ...state,
                status: statuses.INIT_STEAM_GAMES_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_STEAM_GAMES:
            return {
                ...state,
                status: statuses.INIT_STEAM_GAMES_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_LAST_UPDATE_STEAM:
            return {
                ...state,
                lastUpdate: {},
            }
        case actions.SUCCEED_LAST_UPDATE_STEAM:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_STEAM_GAMES:
            return {
                ...state,
                status: statuses.SEARCH_STEAM_GAMES_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_STEAM_GAMES:
            return {
                ...state,
                status: statuses.SEARCH_STEAM_GAMES_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.SET_STEAM_GAMES_FILTER_DATA:
            const startDate = action.response.releasedStartDate;
            return {
                ...state,
                genres: action.response.genres,
                selectedGenres: action.response.genres,
                languages: action.response.languages,
                selectedLanguage: action.response.languages,
                releasedDateRange: getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                startDate: startDate,
                reviewsMax: action.response.reviewsMax,
            }
        case actions.SET_SELECTED_LANGUAGES_STEAM_GAMES:
            return {
                ...state,
                selectedLanguage: action.selectedLanguage,
            }
        case actions.SET_SELECTED_GENRES_STEAM_GAMES:
            return {
                ...state,
                selectedGenres: action.selectedGenres,
            }
        case actions.SET_SEARCH_LANGUAGES_STEAM_GAMES:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_LANGUAGES_COUNT_STEAM_GAMES:
            return {
                ...state,
                languagesCount: action.languagesCount,
            }
        case actions.SET_STEAM_GAMES_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released
            }
        case actions.SET_IS_ONLY_CHOSEN_LANGUAGES_STEAM_GAMES:
            return {
                ...state,
                isOnlyChosenLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_PRICE_PLAY:
            return {
                ...state,
                selectedPrice: action.selectedPrice,
            }
        case actions.SET_EARLY_ACCESS_STEAM_GAMES:
            return {
                ...state,
                selectedEarlyAccess: action.earlyAccess,
            }
        case actions.SET_REVIEWS_STEAM_GAMES:
            return {
                ...state,
                reviews: action.reviews,
            }
        case actions.SET_IS_FILTER_SELECTED_STEAM_GAMES:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_STEAM_GAMES:
            return {
                ...state,
                reviews: {},
                selectedGenres: state.genres,
                selectedLanguage: state.languages,
                releasedDateRange: getDefaultReleased(state.startDate),
                defaultDateRange: getDefaultReleased(state.startDate),
                languagesCount: {from: '', to: ''},
                selectedPrice: state.price,
                selectedEarlyAccess: state.earlyAccess,
                isOnlyChosenLanguages: false,
            }
        case actions.REQUEST_GET_STEAM_GAMES_COUNT:
            return {
                ...state,
                isCountLoading: true
            }
        case actions.SUCCEED_GET_STEAM_GAMES_COUNT:
            return {
                ...state,
                total: action.response.records,
                emails: action.response.emails,
                isCountLoading: false
            }
        default:
            return state;
    }
}