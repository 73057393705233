import * as types from "./constants";

export function requestInitMagnetBlacklist(account) {
    return {
        type: types.REQUEST_INIT_MAGNET_BLACKLIST,
        account
    };
}

export function succeedInitMagnetBlacklist(response) {
    return {
        type: types.SUCCEED_INIT_MAGNET_BLACKLIST,
        response
    };
}

export function requestSearchMagnetBlacklist(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_MAGNET_BLACKLIST,
        limit, offset
    };
}

export function succeedSearchMagnetBlacklist(response) {
    return {
        type: types.SUCCEED_SEARCH_MAGNET_BLACKLIST,
        response
    };
}


export function requestRefreshMagnetBlacklist() {
    return {
        type: types.REQUEST_REFRESH_MAGNET_BLACKLIST
    };
}

export function succeedRefreshMagnetBlacklist(response) {
    return {
        type: types.SUCCEED_REFRESH_MAGNET_BLACKLIST,
        response
    };
}

export function requestDeleteMagnetBlacklist(id) {
    return {
        type: types.REQUEST_DELETE_MAGNET_BLACKLIST,
        blockUi: true,
        id,
    };
}

export function succeedDeleteMagnetBlacklist() {
    return {
        type: types.SUCCEED_DELETE_MAGNET_BLACKLIST,
        blockUi: false,
    };
}

export function requestAddMagnetBlacklist() {
    return {
        type: types.REQUEST_ADD_MAGNET_BLACKLIST,
        blockUi: true,
    };
}

export function succeedAddMagnetBlacklist(response) {
    return {
        type: types.SUCCEED_ADD_MAGNET_BLACKLIST,
        blockUi: false,
        response
    };
}


export function setMagnetBlacklistSearch(search) {
    return {
        type: types.SET_MAGNET_BLACKLIST_SEARCH,
        search
    }
}