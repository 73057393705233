import React, {useState} from "react";
import classNames from 'classnames';
import Input from "../Input";
import Button from "../Button";

import {NAME_REGEXP} from "../../constants/AppConstants";

import './css/add-input.css';

function AddInput({isValidate, validateText, placeholder, startAdornment, inputLabel, buttonTitle, onClick}) {
    const [value, setValue] = useState('');
    const [isValid, setValid] = useState(true);

    const onChange = (e) => {
        const newValue = e.target.value;
        if (isValidate && newValue !== '') {
            const isValid = NAME_REGEXP.test(newValue);
            setValid(isValid);
        }
        setValue(newValue);
    };

    const onClickButton = () => {
        value && onAddValue();
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onAddValue();
        }
    };

    const onAddValue = () => {
        if (isValid) {
            setValue('');
            onClick(value);
        }
    };

    const addInputClassName = classNames("add-input", {
        "add-input_invalid": !isValid,
    });

    const buttonClassName = classNames("add-input__button", {
        "add-input__button_disable": !value,
    });

    return (
        <div className={addInputClassName}>
            {inputLabel && <label htmlFor="add-input" className="add-input__input-label">{inputLabel}</label>}
            <Input id="add-input"
                   className='add-input__input'
                   startAdornment={startAdornment}
                   placeholder={placeholder}
                   value={value}
                   isDisplayPopper={!isValid}
                   popperText={validateText}
                   onChange={onChange}
                   onKeyDown={onKeyDown}/>
            <Button className={buttonClassName} title={buttonTitle} onClick={onClickButton}/>
        </div>
    )
}

export default (AddInput);