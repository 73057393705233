import {
    requestInitHref,
    requestSearchHref,
    setHrefLanguageCount,
    setHrefSelectedLanguage,
    setHrefSelectedSource,
    setSearch,
    succeedInitHref,
    succeedSearchHref,
    requestLastUpdate,
    succeedLastUpdate,
    setFilterData,
    succeedGetCountHref,
    clearFilters,
    requestGetCountHref, requestGetCountEmailsAndTwittersHref, succeedGetCountEmailsAndTwittersHref
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";
import {
    getHrefRecordsRequest,
    getHrefLastUpdateRequest,
    getHrefFilterDataRequest,
    exportHrefRecordsRequest,
    getCountHref, getCountEmailsAndTwittersHref
} from "../../services/grpcBufServices";
import {
    convertArrayOfObjectsToFlatArray, getExportFieldValues,
    getRequestOptions
} from "../../helpers/utils";

export const initHref = () =>
    async (dispatch, getState) => {
        const {app: {token}, href: {limit, offset, selectedSource, sources, selectedLanguage, languagesCount, search, isOnlyChosenLanguages}} = getState();
        dispatch(requestInitHref());
        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getHrefRecordsRequest(token, limit, offset, requestSelectedSource, selectedLanguage, languagesCountValue, search);
        dispatch(succeedInitHref(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getHrefLastUpdateRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const getHrefFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getHrefFilterDataRequest(token);
        dispatch(setFilterData(response));
    };

export const refreshHref = () =>
    async (dispatch, getState) => {
        const {app: {token}, href: {limit, offset, selectedSource, languages, sources, selectedLanguage, languagesCount, isOnlyChosenLanguages, search}} = getState();
        dispatch(requestInitHref());

        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getHrefRecordsRequest(token, limit, offset, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search);

        dispatch(succeedInitHref(response));
    };

export const searchHref = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, href: {limit, offset, selectedSource, sources, languages, selectedLanguage, languagesCount, isOnlyChosenLanguages, search}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchHref(updateLimit, updateOffset));

        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getHrefRecordsRequest(token, updateLimit, updateOffset, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search);

        dispatch(succeedSearchHref(response));
    };

export const exportHref = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, href: {limit, offset, selectedSource, languages, sources, selectedLanguage, languagesCount, isOnlyChosenLanguages, search}} = getState();
        dispatch(requestExport())
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const fieldValues = getExportFieldValues(fields);

        const exportId = await exportHrefRecordsRequest(token, limit, offset, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search, fieldValues);
        dispatch(succeedExport(exportId.task.id));
    };

export const setSearchAsync = search =>
    async (dispatch, getState) => {
        dispatch(setSearch(search));
    };

export const setLanguageAsync = languages =>
    async (dispatch, getState) => {
        dispatch(setHrefSelectedLanguage(languages));
    };

export const setSourceAsync = sources =>
    async (dispatch, getState) => {
        dispatch(setHrefSelectedSource(sources));
    };


export const setLanguageCountAsync = languagesCount =>
    async (dispatch, getState) => {
        dispatch(setHrefLanguageCount(languagesCount));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        const {app: {token}, href: {selectedSource, languages, sources, selectedLanguage, isOnlyChosenLanguages, languagesCount, search}} = getState();
        dispatch(requestGetCountHref());
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const count = await getCountHref(token, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search);
        dispatch(succeedGetCountHref(count));
    };

export const getExportCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, href: {selectedSource, languages, sources, selectedLanguage, isOnlyChosenLanguages, languagesCount, search}} = getState();
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const fieldValues = getExportFieldValues(fields);

        const count = await getCountHref(token, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search, fieldValues);
        return count.records;
    };

export const getCountEmailsAndTwitters = () =>
    async (dispatch, getState) => {
        const {app: {token}, href: {selectedSource, languages, sources, selectedLanguage, isOnlyChosenLanguages, languagesCount, search}} = getState();
        dispatch(requestGetCountEmailsAndTwittersHref());
        const languagesOptions = getRequestOptions(selectedLanguage, languages);
        const requestSelectedLanguages = convertArrayOfObjectsToFlatArray(languagesOptions, 'name');

        const selectedSourceOptions = getRequestOptions(selectedSource, sources);
        const requestSelectedSource = convertArrayOfObjectsToFlatArray(selectedSourceOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const countEmailsAndTwitters = await getCountEmailsAndTwittersHref(token, requestSelectedSource, requestSelectedLanguages, languagesCountValue, search);
        const {emails, twitters} = countEmailsAndTwitters
        dispatch(succeedGetCountEmailsAndTwittersHref(emails, twitters));
        return countEmailsAndTwitters;
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };