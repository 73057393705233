import React, {Component} from 'react';
import {connect} from "react-redux";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import Table from "../../components/Table";
import Link from "../../components/Link";
import {
    exportSteamPublishers,
    initSteamPublishers,
    refreshSteamPublishers,
    searchSteamPublishers, setReleasedAsync,
    setSearchAsync,
    getLastUpdate,
    getFilterData,
    setProductCountAsync,
    clearFilters
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";
import {
    INIT_STEAM_PUBLISHERS_REQUESTED, SEARCH_STEAM_PUBLISHERS_REQUESTED
} from "../../store/StatusTypes";
import {DATE_RANGE, DOWNLOAD_COUNT} from "../../constants/FilterTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";
import AppstoreFilters from "../../components/Filters/PageFilters";

const CONTAINER_NAME = 'Steam publishers';

class SteamPublishers extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initSteamPublishers, getLastUpdate, status, getFilterData} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initSteamPublishers();
            getLastUpdate();
            getFilterData();
        }
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getFiltersOptions = () => {
        const {releasedDateRange, defaultDateRange, setReleased, productCount, setProductCount, publishersProductsCountMax} = this.props;

        const filtersOptions = [
            {
                type: DATE_RANGE,
                title: 'Parsed',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: DOWNLOAD_COUNT,
                title: '№ of products',
                value: productCount,
                onChange: setProductCount,
                submitButtonName: 'Submit',
                className: 'download-count-filter',
                placeholder: {from: 0, to: publishersProductsCountMax}
            }
        ];

        return filtersOptions
    };

    onClearReleased = () => {
        const {setReleased, refreshSteamPublishers, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(refreshSteamPublishers);
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_STEAM_PUBLISHERS_REQUESTED
            || status === SEARCH_STEAM_PUBLISHERS_REQUESTED;
    }

    handleClearFilters = () => {
        const {clearFilters, initSteamPublishers} = this.props;
        clearFilters().then(() => initSteamPublishers());
    }

    onExportSteam = () => {
        const {exportSteamPublishers} = this.props;
        exportSteamPublishers();
    }

    renderList = (row, column, value) => join(value, ', ');

    renderLink = (value) => <Link path={value} value={value}/>;

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    render() {
        const {lastUpdate, title, list, total, limit, offset, refreshSteamPublishers, searchSteamPublishers, search, setSearch, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0}, renderValue: this.renderIndexCell},
            {key: 'name', name: 'Company name', width: 150,
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'productCount', name: '№ of products', width: 100,
                renderValue: renderBigNumbers
            },
            {key: 'website', name: 'Website', width: 200, cellValueWrapperFn: this.renderLink},
            {key: 'facebook', name: 'Facebook', width: 200, cellValueWrapperFn: this.renderLink},
            {key: 'twitch', name: 'Twitch', width: 200, cellValueWrapperFn: this.renderLink},
            {key: 'twitter', name: 'Twitter', width: 200, cellValueWrapperFn: this.renderLink},
            {key: 'youtube', name: 'Youtube', width: 200, cellValueWrapperFn: this.renderLink},
            {key: 'steamUrl', name: 'Steam URL', width: 200, cellValueWrapperFn: this.renderLink},
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportSteam}
                                 onApplyFilters={refreshSteamPublishers}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchSteamPublishers}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.steamPublishers.list,
        status: state.steamPublishers.status,
        total: state.steamPublishers.total,
        limit: state.steamPublishers.limit,
        offset: state.steamPublishers.offset,
        search: state.steamPublishers.search,
        releasedDateRange: state.steamPublishers.releasedDateRange,
        defaultDateRange: state.steamPublishers.defaultDateRange,
        lastUpdate: state.steamPublishers.lastUpdate,
        startDate: state.steamPublishers.startDate,
        productCount: state.steamPublishers.productCount,
        isFiltersSelected: state.steamPublishers.isFiltersSelected,
        publishersProductsCountMax: state.steamPublishers.publishersProductsCountMax,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        setSearch: search => dispatch(setSearchAsync(search)),
        initSteamPublishers: () => dispatch(initSteamPublishers()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        searchSteamPublishers: (count) => dispatch(searchSteamPublishers(count)),
        refreshSteamPublishers: () => dispatch(refreshSteamPublishers()),
        exportSteamPublishers: () => dispatch(exportSteamPublishers()),
        getFilterData: () => dispatch(getFilterData()),
        setProductCount: (productCount) => dispatch(setProductCountAsync(productCount)),
        setReleased: released => dispatch(setReleasedAsync(released)),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFilters()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SteamPublishers)