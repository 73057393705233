import React, {Component} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {setInitContainer, setTitle} from "../App/actions";
import {
    createTwitterAudience,
    initTwitterAudiences,
    refreshTwitterAudiences,
    searchTwitterAudiences, sendAudienceToTwitter,
    setSearchAsync
} from "./actionsAsync";

import Filters from "../../components/Filters";
import Table from "../../components/Table";
import CellTimeAgo from "../../components/CellTimeAgo";
import HoverButton from "../../components/HoverButton";

import {
    INIT_TWITTER_AUDIENCES_REQUESTED,
    SEARCH_TWITTER_AUDIENCES_REQUESTED
} from "../../store/StatusTypes";
import {
    getDateTimeAgo, getLocalizedTime
} from "../../helpers/dateUtils";
import {renderBigNumbers} from "../../helpers/utils";
import {ADD_ELEMENT} from "../../constants/FilterTypes";
import {handleServiceError} from "../../helpers/catchErrorUtils";

import '../../css/twitter.css';

const CONTAINER_NAME = 'Twitter';

class Twitter extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initTwitterAudiences, status} = this.props;

        setTitle(CONTAINER_NAME);
        status === null && initTwitterAudiences();
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleCreateAudience = (value) => {
        /*const {createTwitterAudience} = this.props;
        if (value) {
            createTwitterAudience(value).then((response) => {
                this.onRowClick(null, response.audience);
            });
        }*/
        // временно убрали добавление аудитории
        handleServiceError(0, "Apologies! The Twitter parser is currently experiencing technical difficulties, so you are unable to add audience at the moment.");
    }

    getFiltersOptions = () => {
        const filtersOptions = [
            {
                type: ADD_ELEMENT,
                isValidate: true,
                validateText: `It's allowed to use spaces, digits (0-9), letters (A-Z, a-z), and a few special characters ( "-" , "." , "_")`,
                customWrapperClassName: '',
                placeholder: 'Audience name',
                buttonTitle: 'Create an audience',
                onClick: this.handleCreateAudience
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_TWITTER_AUDIENCES_REQUESTED
            || status === SEARCH_TWITTER_AUDIENCES_REQUESTED;
    }

    renderUpdateValue = (row, column, value) => {
        return <CellTimeAgo row={row} column={column} value={value}/>
    };

    renderSentTimeValue = (row, column, value) => {
        const {sendAudienceToTwitter} = this.props;
        const agoTime = getDateTimeAgo(value);
        const buttonTitle = <div className="twitter-button">Send to<div className="twitter-button__icon"/></div>;
        const onClick = () => {
            sendAudienceToTwitter(row.id);
        };
        return <HoverButton key={row.id} buttonTitle={buttonTitle} value={agoTime} onClick={onClick}/>
    }

    renderStatusValue = (row, column, value) => {
        if (value < 100) {
            const {estimatedTime} = row;
            const renderEstimatedTime = getLocalizedTime(estimatedTime);
            return `${value}% (${renderEstimatedTime})`;
        } else if (value === 100) {
            return <div className="twitter-table__completed-status-cell"/>
        }
    };

    onRowClick = (e, item) => {
        const {history} = this.props;
        const {name} = item;
        history.push(`/twitter/${name}`);
    };

    render() {
        const {list, total, limit, offset, search, setSearch, refreshTwitterAudiences, searchTwitterAudiences} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'name', name: 'Audience',
                width: 230
            },
            {key: 'donorsCount', name: 'Donors',
                width: 100
            },
            {key: 'followersCount', name: 'Followers',
                width: 140,
                renderValue: renderBigNumbers
            },
            {key: 'status', name: 'Status',
                width: 120,
                renderValue: this.renderStatusValue
            },
            {key: 'updated', name: 'Updated',
                width: 150,
                isHiddenTitle: true,
                renderValue: this.renderUpdateValue
            },
            {key: 'sentTime', name: 'Sent to Twitter',
                width: 200,
                isHiddenTitle: true,
                cellClassName: "twitter-button-cell",
                renderValue: this.renderSentTimeValue
            },
        ];

        return (
            <div className="app-root__table-container twitter-container">
                <Filters filtersOptions={filtersOptions}
                         search={search}
                         setSearch={setSearch}
                         onRefresh={refreshTwitterAudiences}/>
                <div className="app-root__table">
                    <Table
                        className="twitter-table"
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchTwitterAudiences}
                        onRowClick={this.onRowClick}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.twitter.list,
        status: state.twitter.status,
        total: state.twitter.total,
        limit: state.twitter.limit,
        offset: state.twitter.offset,
        search: state.twitter.search,

        initContainers: state.app.initContainers,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initTwitterAudiences: () => dispatch(initTwitterAudiences()),
        refreshTwitterAudiences: () => dispatch(refreshTwitterAudiences()),
        searchTwitterAudiences: (count) => dispatch(searchTwitterAudiences(count)),
        createTwitterAudience: (name) => dispatch(createTwitterAudience(name)),
        sendAudienceToTwitter: (id) => dispatch(sendAudienceToTwitter(id)),

        setSearch: search => dispatch(setSearchAsync(search))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Twitter);