export const REQUEST_INIT_CLOSE = 'REQUEST_INIT_CLOSE';
export const SUCCEED_INIT_CLOSE = 'SUCCEED_INIT_CLOSE';
export const REQUEST_SEARCH_CLOSE = 'REQUEST_SEARCH_CLOSE';
export const SUCCEED_SEARCH_CLOSE = 'SUCCEED_SEARCH_CLOSE';
export const REQUEST_LAST_UPDATE_CLOSE = 'REQUEST_LAST_UPDATE_CLOSE';
export const SUCCEED_LAST_UPDATE_CLOSE = 'SUCCEED_LAST_UPDATE_CLOSE';
export const SET_CLOSE_SEARCH = 'SET_CLOSE_SEARCH';
export const SUCCEED_CLOSE_FILTER_DATA = 'SUCCEED_CLOSE_FILTER_DATA';
export const SET_CLOSE_CATEGORY = 'SET_CLOSE_CATEGORY';
export const SUCCEED_GET_COUNT_CLOSE = 'SUCCEED_GET_COUNT_CLOSE';
export const SET_IS_FILTER_SELECTED_CLOSE = 'SET_IS_FILTER_SELECTED_CLOSE';
export const CLEAR_FILTERS_CLOSE = 'CLEAR_FILTERS_CLOSE';
export const REQUEST_GET_COUNT_CLOSE = 'REQUEST_GET_COUNT_CLOSE';