import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";
import {
    exportHref,
    initHref,
    refreshHref,
    searchHref, setLanguageAsync, setLanguageCountAsync,
    setSearchAsync, setSourceAsync, getLastUpdate,
    getHrefFilterData, getCount, getExportCount, clearFiltersAsync, getCountEmailsAndTwitters
} from "./actionsAsync";
import {setIsOnlyChosenLanguages, setIsFiltersSelected} from "./actions";

import {SELECT} from "../../constants/FilterTypes";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getLastUpdateTitle} from "../../helpers/utils";
import ExportPopup from "../../components/Popup/components/ExportPopup";
import {convertTimestampFormat} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'Html';

class Href extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initHref, getLastUpdate, status, getHrefFilterData, getCount, getCountEmailsAndTwitters} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initHref();
            getLastUpdate();
            getHrefFilterData();
            getCount();
            getCountEmailsAndTwitters();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleClearFilters = () => {
        const {clearFilters, initHref, getCount, getCountEmailsAndTwitters} = this.props;
        clearFilters().then(() => {
            initHref();
            getCount();
            getCountEmailsAndTwitters();
        });
    }

    onExportHref = () => {
        const {exportHref, showPopup, fieldsList, getExportCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getExportCount}/>
        }

        const handleApply = (fields) => {
            exportHref(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getLanguageValue = (selectedOptions, options) => {
        const {languagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = languagesCount.from ? `From ${languagesCount.from}` : '';
        const languagesCountTo = languagesCount.to ? `To ${languagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    getSourcesValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All sources';
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {languages, selectedLanguage, setLanguage, setSource, sources, selectedSource, setLanguageCount, isOnlyChosenLanguages, setIsOnlyChosenLanguages, languagesCount} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Source',
                allTitle: 'All Sources',
                options: sources,
                columnsCount: 1,
                renderValue: this.getSourcesValue,
                selectedOptions: selectedSource,
                onChange: setSource,
            },
            {
                type: SELECT,
                title: 'Language',
                options: languages,
                popoverClassName: 'steam-genre-popover',
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getLanguageValue,
                selectedOptions: selectedLanguage,
                onChange: setLanguage,
                optionCount: languagesCount,
                filterName: "languages",
                setOptionsCount: setLanguageCount,
                isOnlyChosen: isOnlyChosenLanguages,
                setIsOnlyChosen: setIsOnlyChosenLanguages,
            },
        ];

        return filtersOptions;
    };

    onShowMore = () => {
        const {searchHref} = this.props;
        searchHref();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderLink = (value) => {
        const displayValue = value.replace(/^https:\/\//g, '').replace(/^http:\/\//g, '').replace(/^www./g, '');
        return <Link path={value} value={displayValue}/>;
    }

    renderUpdateValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    handleRefresh = () => {
        const {refreshHref, getCount, getCountEmailsAndTwitters} = this.props;
        refreshHref();
        getCount();
        getCountEmailsAndTwitters();
    }

    render() {
        const {lastUpdate, isLoading, title, list, total, limit, offset, searchHref, search, setSearch, emails, twitters, isFiltersSelected,
            setIsFiltersSelected, isCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const currentPage = 'href';
        const emailsCount = isCountLoading ? <Loading/> : emails;
        const twittersCount = isCountLoading ? <Loading/> : twitters;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'website', name: 'Website', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'source', name: 'Source', width: 120},
            {key: 'languageQuantity', name: 'Language Qty', width: 100},
            {key: 'languages', name: 'Languages Supported', width: 150},
            {key: 'emails', name: 'Email', width: 200},
            {key: 'twitter', name: 'Twitter', width: 200},
            {key: 'contacts', name: 'Contacts', width: 200},
            {key: 'phone', name: 'Phone', width: 200},
            {key: 'sourceEmail', name: 'Source email', width: 200},
            {key: 'updated', name: 'Updated', width: 120,
                renderValue: this.renderUpdateValue
            },
            {key: 'developers', name: 'Developers', width: 100},
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Emails: {emailsCount}</div>
                            <div>Twitters: {twittersCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportHref}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                                 currentPage={currentPage}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchHref}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.href.list,
        status: state.href.status,
        total: state.href.total,
        limit: state.href.limit,
        offset: state.href.offset,
        search: state.href.search,

        languages: state.href.languages,
        selectedLanguage: state.href.selectedLanguage,

        sources: state.href.sources,
        selectedSource: state.href.selectedSource,
        lastUpdate: state.href.lastUpdate,
        count: state.href.count,
        emails: state.href.emails,
        twitters: state.href.twitters,
        isOnlyChosenLanguages: state.href.isOnlyChosenLanguages,
        isFiltersSelected: state.href.isFiltersSelected,
        languagesCount: state.href.languagesCount,
        fieldsList: state.href.fieldsList,
        isLoading: state.href.isLoading,
        isCountLoading: state.href.isCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        setSearch: search => dispatch(setSearchAsync(search)),
        initHref: () => dispatch(initHref()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        searchHref: (count) => dispatch(searchHref(count)),
        refreshHref: () => dispatch(refreshHref()),
        exportHref: (fields) => dispatch(exportHref(fields)),
        setLanguage: languages => dispatch(setLanguageAsync(languages)),
        setLanguageCount: count => dispatch(setLanguageCountAsync(count)),
        setIsOnlyChosenLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenLanguages(isOnlyChosenLanguages)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        setSource: sources => dispatch(setSourceAsync(sources)),
        getHrefFilterData: () => dispatch(getHrefFilterData()),
        getCount: (fields, from) => dispatch(getCount(fields, from)),
        getExportCount: (fields) => dispatch(getExportCount(fields)),
        getCountEmailsAndTwitters: () => dispatch(getCountEmailsAndTwitters()),
        clearFilters: () => dispatch(clearFiltersAsync()),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Href)