import {onBlockUi, onUnblockUi} from "../../containers/App/actions";

const BLOCK_PROPERTY = 'blockUi';

const blockUiMiddleware = ({dispatch}) => next => action => {
    if (BLOCK_PROPERTY in action) {
        const {blockUi} = action;

        blockUi
            ? dispatch(onBlockUi())
            : dispatch(onUnblockUi());
    }
    next(action);
};

export default blockUiMiddleware;
