import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import {
    initGog, searchGog, getLastUpdate, refreshGog, exportGog, setPriceAsync, setReleasedAsync, setRatingAsync,
    getFilterData, setGenresAsync, clearFiltersAsync, getExportCount
} from "./actionsAsync";
import AppstoreFilters from "../../components/Filters/PageFilters";
import {setIsFiltersSelected} from "./actions";
import {setInitContainer, setTitle} from "../App/actions";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import Table from "../../components/Table";
import {INIT_GOG_REQUESTED, SEARCH_GOG_REQUESTED} from "../../store/StatusTypes";
import {getLastUpdateTitle} from "../../helpers/utils";
import join from "lodash/join";
import Link from "../../components/Link";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import {DATE_RANGE, DOWNLOAD_COUNT, SELECT} from "../../constants/FilterTypes";
import ExportPopup from "../../components/Popup/components/ExportPopup";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";

const CONTAINER_NAME = 'Gog';

class Gog extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initGog, status, getLastUpdate, getFilterData} = this.props;
        setTitle(CONTAINER_NAME);
        if (status === null) {
            initGog();
            getFilterData();
            getLastUpdate();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleClearFilters = () => {
        const {clearFilters, initGog} = this.props;
        clearFilters().then(() => initGog());
    }

    getGenresValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    onExportGog = () => {
        const {exportGog, showPopup, fieldsList, getExportCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getExportCount} isGog={true}/>
        }

        const handleApply = (fields) => {
            exportGog(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    onClearReleased = () => {
        const {setReleased, refreshGog} = this.props;
        const defaultReleased = getDefaultReleased();
        setReleased(defaultReleased).then(refreshGog);
    }

    getFiltersOptions = () => {
        const {price, selectedPrice, setPrice, releasedDateRange, defaultDateRange, setReleased, rating, setRating,
               genres, selectedGenres, setGenres} = this.props;
        const filtersOptions = [
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getGenresValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Rating',
                value: rating,
                onChange: setRating,
                rateValidate: true,
                placeholder: {from: 0, to: 5}
            },
            {
                type: SELECT,
                title: 'Genres',
                popoverClassName: 'steam-genre-popover',
                options: genres,
                renderValue: this.getGenresValue,
                selectedOptions: selectedGenres,
                onChange: setGenres,
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_GOG_REQUESTED
            || status === SEARCH_GOG_REQUESTED;
    }

    priceValue = (row, column, value) => {
        return value ? "Paid" : "Free";
    }

    renderList = (row, column, value) => join(value, ', ');

    renderLink = (value) => <Link path={value} value={value}/>;

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    render() {
        const {list, total, limit, offset, lastUpdate, title, searchGog, refreshGog, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'title', name: 'Name',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'isPaid', name: 'Price', width: 70,
                renderValue: this.priceValue
            },
            {key: 'rating', name: 'Rating', width: 70},
            {key: 'textLanguages', name: 'Text languages', width: 120, renderValue: this.renderList},
            {key: 'audioLanguages', name: 'Audio languages', width: 120, renderValue: this.renderList},
            {key: 'genres', name: 'Genres', width: 120, renderValue: this.renderList},
            {key: 'platforms', name: 'Platforms', width: 120, renderValue: this.renderList},
            {key: 'developer.name', name: 'Developer', width: 120},
            {key: 'developer.emails', name: 'Developer emails', width: 120, renderValue: this.renderList},
            {key: 'developer.website', name: 'Developer website', width: 120, renderValue: this.renderList},
            {key: 'publisher.name', name: 'Publisher', width: 120},
            {key: 'publisher.emails', name: 'Publisher emails', width: 120, renderValue: this.renderList},
            {key: 'publisher.website', name: 'Publisher website', width: 120},
            {key: 'link', name: 'Link', width: 80, cellValueWrapperFn: this.renderLink},
            {key: 'releaseDate', name: 'Release', width: 100, renderValue: this.renderReleasedValue},
        ]

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 onExport={this.onExportGog}
                                 onApplyFilters={refreshGog}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchGog}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        status: state.gog.status,
        lastUpdate: state.gog.lastUpdate,
        list: state.gog.list,
        total: state.gog.total,
        limit: state.gog.limit,
        offset: state.gog.offset,
        releasedDateRange: state.gog.releasedDateRange,
        defaultDateRange: state.gog.defaultDateRange,
        selectedPrice: state.gog.selectedPrice,
        price: state.gog.price,
        rating: state.gog.rating,
        genres: state.gog.genres,
        selectedGenres: state.gog.selectedGenres,
        isFiltersSelected: state.gog.isFiltersSelected,
        fieldsList: state.gog.fieldsList,

        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initGog: () => dispatch(initGog()),
        searchGog: (count) => dispatch(searchGog(count)),
        refreshGog: () => dispatch(refreshGog()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        exportGog: (fields) => dispatch(exportGog(fields)),
        setPrice: price => dispatch(setPriceAsync(price)),
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        setRating: (rating) => dispatch(setRatingAsync(rating)),
        getFilterData: () => dispatch(getFilterData()),
        setGenres: genres => dispatch(setGenresAsync(genres)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
        getExportCount: (fields) => dispatch(getExportCount(fields)),

        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gog)