export const REQUEST_INIT_MAGNET_SALES_BLACKLIST = 'REQUEST_INIT_MAGNET_SALES_BLACKLIST';
export const SUCCEED_INIT_MAGNET_SALES_BLACKLIST = 'SUCCEED_INIT_MAGNET_SALES_BLACKLIST';
export const REQUEST_SEARCH_MAGNET_SALES_BLACKLIST = 'REQUEST_SEARCH_MAGNET_SALES_BLACKLIST';
export const SUCCEED_SEARCH_MAGNET_SALES_BLACKLIST = 'SUCCEED_SEARCH_MAGNET_SALES_BLACKLIST';
export const REQUEST_REFRESH_MAGNET_SALES_BLACKLIST = 'REQUEST_REFRESH_MAGNET_SALES_BLACKLIST';
export const SUCCEED_REFRESH_MAGNET_SALES_BLACKLIST = 'SUCCEED_REFRESH_MAGNET_SALES_BLACKLIST';
export const SET_MAGNET_SALES_BLACKLIST_SEARCH = 'SET_MAGNET_SALES_BLACKLIST_SEARCH';

export const REQUEST_DELETE_MAGNET_SALES_BLACKLIST = 'REQUEST_DELETE_MAGNET_SALES_BLACKLIST';
export const SUCCEED_DELETE_MAGNET_SALES_BLACKLIST = 'SUCCEED_DELETE_MAGNET_SALES_BLACKLIST';
export const REQUEST_ADD_MAGNET_SALES_BLACKLIST = 'REQUEST_ADD_MAGNET_SALES_BLACKLIST';
export const SUCCEED_ADD_MAGNET_SALES_BLACKLIST = 'SUCCEED_ADD_MAGNET_SALES_BLACKLIST';