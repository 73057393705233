import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import filter from 'lodash/filter';
import RadioButton from "../RadioButton";
import SearchInput from "../SearchInput";
import Loading from "../Loading";

import './css/select.css';

class CountrySelect extends Component {

    static propTypes = {
        selectedOptions: PropTypes.any,
        title: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        optionsContainerStyle: PropTypes.string,
        isLoading: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.state = {
            country: '',
            searchValue: ''
        }
    }

    onChangeSearch = (value) => {
        this.setState({
            searchValue: value
        })
    }

    setCountry = country => {
        this.setState({country: country})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedOptions, isOpen} = this.props;
        const {selectedOptions: prevSelectedOptions, isOpen: prevOpen} = prevProps;

        if (selectedOptions !== prevSelectedOptions) {
            this.setCountry(selectedOptions)
        }
        if (isOpen !== prevOpen) {
            this.setState({searchValue: ''})
        }
    }

    onChange = (option) => (e, checked, id) => {
        const {onChange} = this.props;
        onChange && onChange(e, checked, id, option);
    }

    onChangeCountry = (e, id) => {
        const {setSelectedOptions} = this.props;
        this.setCountry(id);
        setSelectedOptions(id);
    }

    getRenderOptions = () => {
        const {country} = this.state;
        const options = this.getFilterOptions();
        const optionBlocks = map(options, item => {
            const {id, name} = item;
            const checked = id === country;
            return (
                <div key={id} className="country-options__item">
                    <RadioButton id={id} checked={checked} onChange={this.onChangeCountry}/>
                    <div className="country-options__item-title" onClick={(e) => this.onChangeCountry(e, id)} title={name}>{name}</div>
                </div>
            )
        });

        return optionBlocks.length ? optionBlocks : <div className="country-options__empty">(nothing found)</div>;
    }

    getFilterOptions = () => {
        const {searchValue} = this.state;
        const {options} = this.props;

        return filter(options, option => {
            const {name} = option;
            return includes(toLower(name), toLower(searchValue));
        })
    }

    calculateContainerHeight = () => {
        const options = this.getFilterOptions();
        const elementHeight = 32;
        const maxCountItemInColumn = 40;
        let height = elementHeight * maxCountItemInColumn;
        if (options.length < maxCountItemInColumn) {
            height = options.length * elementHeight;
        }
        return height || elementHeight;
    }

    calculateContainerWidth = () => {
        const {options} = this.props;
        const elementWidth = 250;
        const maxCountItemInColumn = 40;
        return options.length ? Math.ceil(options.length / maxCountItemInColumn) * elementWidth : '100%';
    }

    render() {
        const {searchValue} = this.state;
        const {optionsContainerStyle, isLoading} = this.props;
        const options = this.getRenderOptions();
        const containerHeight = this.calculateContainerHeight();
        const containerWidth = this.calculateContainerWidth();
        const countryOptionsContainerStyle = classNames("country-options__container", optionsContainerStyle);

        return (
            <React.Fragment>
                <div className="country-options__search">
                    <SearchInput placeholder="Search" value={searchValue} onChange={this.onChangeSearch}/>
                </div>
                <div className="country-options__title">All Countries</div>
                <div className={countryOptionsContainerStyle} style={{height: containerHeight, width: containerWidth}}>
                    {isLoading
                        ? <div className="loading-spinner-center"><Loading/></div>
                        : options}
                </div>
            </React.Fragment>
        );
    }
}

export default (CountrySelect);