export const REQUEST_INIT_GOOGLE_PLAY = 'REQUEST_INIT_GOOGLE_PLAY';
export const SUCCEED_INIT_GOOGLE_PLAY = 'SUCCEED_INIT_GOOGLE_PLAY';
export const REQUEST_SEARCH_GOOGLE_PLAY = 'REQUEST_SEARCH_GOOGLE_PLAY';
export const SUCCEED_SEARCH_GOOGLE_PLAY = 'SUCCEED_SEARCH_GOOGLE_PLAY';
export const REQUEST_GET_COUNT_GOOGLE_PLAY = 'REQUEST_GET_COUNT_GOOGLE_PLAY';
export const SUCCEED_GET_COUNT_GOOGLE_PLAY = 'SUCCEED_GET_COUNT_GOOGLE_PLAY';
export const REQUEST_LAST_UPDATE_GOOGLE_PLAY = 'REQUEST_LAST_UPDATE_GOOGLE_PLAY';
export const SUCCEED_LAST_UPDATE_GOOGLE_PLAY = 'SUCCEED_LAST_UPDATE_GOOGLE_PLAY';
export const SUCCEED_GET_FILTER_DATA = 'SUCCEED_GET_FILTER_DATA';

export const SET_GOOGLE_PLAY_CATEGORY = 'SET_GOOGLE_PLAY_CATEGORY';
export const SET_GOOGLE_PLAY_COUNTRY = 'SET_GOOGLE_PLAY_COUNTRY';
export const SET_GOOGLE_PLAY_RELEASED = 'SET_GOOGLE_PLAY_RELEASED';
export const SET_GOOGLE_PLAY_PRICE = 'SET_GOOGLE_PLAY_PRICE';
export const SET_GOOGLE_PLAY_DOWNLOADS = 'SET_GOOGLE_PLAY_DOWNLOADS';
export const SET_GOOGLE_PLAY_SEARCH = 'SET_GOOGLE_PLAY_SEARCH';
export const SET_COUNTRIES_COUNT_PLAY = 'SET_COUNTRIES_COUNT_PLAY';
export const SET_COUNTRIES_COMPARE_OPERATOR_PLAY = 'SET_COUNTRIES_COMPARE_OPERATOR_PLAY';
export const SET_GOOGLE_PLAY_UPDATED = 'SET_GOOGLE_PLAY_UPDATED';
export const SET_GOOGLE_PLAY_HAS_VIDEO = 'SET_GOOGLE_PLAY_HAS_VIDEO';
export const CLEAR_FILTERS_GOOGLE_PLAY = 'CLEAR_FILTERS_GOOGLE_PLAY';
export const SET_IS_ONLY_CHOSEN_MARKETS_GOOGLE_PLAY = 'SET_IS_ONLY_CHOSEN_MARKETS_GOOGLE_PLAY';
export const SET_GOOGLE_PLAY_SCORE = 'SET_GOOGLE_PLAY_SCORE';
export const SET_IS_FILTER_SELECTED_GOOGLE_PLAY = 'SET_IS_FILTER_SELECTED_GOOGLE_PLAY';
export const SET_GOOGLE_PLAY_APP_COUNT = 'SET_GOOGLE_PLAY_APP_COUNT';