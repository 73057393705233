import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    sortField: 'conference',

    sortOrder: 'asc',
};

export default function meetToMatch(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SORT_MEET_TO_MATCH:
            return {
                ...state,
                sortOrder: action.order,
                sortField: action.sortField,
                limit: state.limit + state.offset,
                offset: 0,
            }
        case actions.REQUEST_INIT_MEET_TO_MATCH:
            return {
                ...state,
                status: statuses.INIT_MEET_TO_MATCH_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_MEET_TO_MATCH:
            return {
                ...state,
                status: statuses.INIT_MEET_TO_MATCH_SUCCEEDED,
                list: action.response.events,
                total: action.response.total
            }
        case actions.REQUEST_SEARCH_MEET_TO_MATCH:
            return {
                ...state,
                status: statuses.SEARCH_MEET_TO_MATCH_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_MEET_TO_MATCH:
            return {
                ...state,
                status: statuses.SEARCH_MEET_TO_MATCH_SUCCEEDED,
                list: [...state.list, ...action.response.events],
                total: action.response.total
            }
        default:
            return state;
    }
}