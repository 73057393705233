import isEqual from "lodash/isEqual";
import {
    getGogGamesRequest, getLastUpdateGogRequest, exportGogRequest, getGogFilterDataRequest, getGogExportCount
} from "../../services/grpcBufServices";
import {
    requestInitGog, succeedInitGog, requestLastUpdateGog, succeedLastUpdateGog, requestSearchGog, succeedSearchGog, setPrice,
    setReleased, setGogRating, getGogFilterData, setSelectedGenres, clearFilters
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";
import {getReleasedValue} from "../../helpers/dateUtils";
import {getExportFieldValues, getRequestOptions} from "../../helpers/utils";

export const initGog = () =>
    async (dispatch, getState) => {
        const {app: {token}, gog: {limit, offset}} = getState();
        dispatch(requestInitGog());
        const response = await getGogGamesRequest(token, limit, offset);
        dispatch(succeedInitGog(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateGog());
        const lastUpdate = await getLastUpdateGogRequest(token);
        dispatch(succeedLastUpdateGog(lastUpdate));
    };

export const searchGog = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, gog: {limit, offset, selectedPrice, defaultDateRange, releasedDateRange, rating, selectedGenres, genres}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const genreOptions = getRequestOptions(selectedGenres, genres);
        dispatch(requestSearchGog(updateLimit, updateOffset));
        const response = await getGogGamesRequest(token, updateLimit, updateOffset, selectedPrice, releasedDate, rating, genreOptions);
        dispatch(succeedSearchGog(response));
    };

export const refreshGog = () =>
    async (dispatch, getState) => {
        const {app: {token}, gog: {limit, offset, selectedPrice, defaultDateRange, releasedDateRange, rating, selectedGenres, genres}} = getState();
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const genreOptions = getRequestOptions(selectedGenres, genres);
        dispatch(requestInitGog());
        const response = await getGogGamesRequest(token, limit, offset, selectedPrice, releasedDate, rating, genreOptions);
        dispatch(succeedInitGog(response));
    };

export const exportGog = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, gog: {selectedPrice, releasedDateRange, defaultDateRange, offset, limit, rating, selectedGenres, genres}} = getState();
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const genreOptions = getRequestOptions(selectedGenres, genres);
        const fieldValues = getExportFieldValues(fields);
        dispatch(requestExport());
        const exportId = await exportGogRequest(token, selectedPrice, releasedDate, rating, genreOptions, limit, offset, fieldValues);
        dispatch(succeedExport(exportId.task.id));
    };

export const getExportCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, gog: {selectedPrice, releasedDateRange, defaultDateRange, offset, limit, rating, selectedGenres, genres}} = getState();
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const genreOptions = getRequestOptions(selectedGenres, genres);
        const fieldValues = getExportFieldValues(fields);
        const count = await getGogExportCount(token, selectedPrice, releasedDate, rating, genreOptions, limit, offset, fieldValues);
        return count.countGames;
    };

export const setPriceAsync = price =>
    async (dispatch, getState) => {
        dispatch(setPrice(price));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setRatingAsync = rating =>
    async (dispatch, getState) => {
        dispatch(setGogRating(rating));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getGogFilterDataRequest(token);
        dispatch(getGogFilterData(response));
    };

export const setGenresAsync = genres =>
    async (dispatch, getState) => {
        dispatch(setSelectedGenres(genres));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };

