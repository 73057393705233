import React, {Component} from 'react';
import classNames from "classnames";
import moment from "moment";
import trim from "lodash/trim";
import replace from "lodash/replace";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DayPickerRangeController} from "react-dates";

import Popover from "../Popover";

import './css/date-range.css';
import './css/calendar.css';
import {isDateInRange, parseMomentToDateString, setStartWeekMonday} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import Input from "../Input";
import Button from "../Button";

const START_DATE = 'startDate';
const END_DATE = 'endDate';

class DateRange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            focusedInput: START_DATE,
            startDate: null,
            endDate: null,
            startDateInput: '',
            endDateInput: '',
            startDateInputValid: true,
            endDateInputValid: true,
            isOpen: false
        }

        this.input = React.createRef();
        setStartWeekMonday();
    }

    componentDidMount() {
        this.setInitDates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen} = this.state;
        const {initDates} = this.props;
        if (isOpen && isOpen !== prevState.isOpen) {
            this.onFocusesInputChange(START_DATE);
        }
        if (prevProps.initDates && initDates) {
            if (prevProps.initDates.startDateInput !== initDates.startDateInput
                || prevProps.initDates.endDateInput !== initDates.endDateInput) {
                this.setInitDates();
            }
        }
    }

    setInitDates = () => {
        const {initDates} = this.props;

        const {startDate, endDate} = initDates || {};
        this.onDatesChange({startDate, endDate});
    };

    onApply = () => {
        const {startDate, endDate, startDateInput, endDateInput} = this.state;
        const {onChange} = this.props;
        onChange({startDate, endDate, startDateInput, endDateInput});
        this.onToggleMenu();
    };

    onClear = () => {
        const {onClear} = this.props;
        onClear();
        this.onToggleMenu();
    };

    onStartDateInput = (event) => {
        const startDateInput = event.target.value;
        const replacedStartDate = this.getReplacedDateInput(startDateInput);
        const isDateValid = this.isDateValid(replacedStartDate);
        const startDateInputValid = isDateValid || replacedStartDate === '';

        const updateStartDate = isDateValid ? moment(replacedStartDate, CONST.DATE_STANDARD_FORMAT) : null;

        this.setState({
            startDate: updateStartDate,
            startDateInput: replacedStartDate,
            startDateInputValid
        });
    };


    onEndDateInput = (event) => {
        const endDateInput = event.target.value;
        const replacedEndDate = this.getReplacedDateInput(endDateInput);
        const isDateValid = this.isDateValid(replacedEndDate);
        const endDateInputValid = isDateValid || replacedEndDate === '';

        const updateEndDate = isDateValid ? moment(replacedEndDate, CONST.DATE_STANDARD_FORMAT) : null;

        this.setState({
            endDate: updateEndDate,
            endDateInput: replacedEndDate,
            endDateInputValid
        });
    };

    onDatesChange = (dates) => {
        const {startDate, endDate} = dates;
        const startDateInput = this.inputToMoment(startDate);
        const endDateInput = this.inputToMoment(endDate);

        this.setState({
            startDate,
            endDate,
            startDateInput,
            endDateInput,
            startDateInputValid: this.isDateValid(startDateInput) || startDateInput === '',
            endDateInputValid: this.isDateValid(endDateInput) || endDateInput === '',
        });
    };

    onClickStartDateInput = () => {
        this.onFocusesInputChange(START_DATE);
    };

    onClickEndDateInput = () => {
        this.onFocusesInputChange(END_DATE);
    };

    onFocusedChange = () => {
        const {focusedInput: focusedInputState} = this.state;
        const updateFocusedInput = focusedInputState === START_DATE ? END_DATE : focusedInputState;
        this.onFocusesInputChange(updateFocusedInput);
    };

    onFocusesInputChange = (focusedInput) => {
        this.setState({
            focusedInput
        });
    };

    renderDayContents = (date, props) => {
        return (
            <div className="date-range__day">{date.date()}</div>
        );
    };

    getReplacedDateInput = date => {
        const REPLACE_DATE_REGEXP = /([^\d\s+*:;,.()/\\\-_]+)/gi;
        return trim(replace(date, REPLACE_DATE_REGEXP, ''));
    };

    isDateValid = date => {
        const DATE_FORMAT_REGEXP = /^\d{2}[/]\d{2}[/]\d{4}$/;
        return DATE_FORMAT_REGEXP.test(date)/* || date === ''*/;
    };

    inputToMoment = date => parseMomentToDateString(date, CONST.DATE_STANDARD_FORMAT);

    onToggleMenu = () => {
        this.setState((state, props) => {
            return {
                isOpen: !state.isOpen,
                startDateInput: props.initDates.startDateInput,
                endDateInput: props.initDates.endDateInput,
                startDate: props.initDates.startDate,
                endDate: props.initDates.endDate,
            }
        });
    }

    getOutsideDays = (day) => {
        const {defaultDateRange} = this.props;
        const dateInRange = isDateInRange(day, defaultDateRange);
        return !dateInRange
    }

    render() {
        const {isOpen, focusedInput, startDate, endDate, startDateInput, endDateInput, startDateInputValid, endDateInputValid} = this.state;
        const {title, submitButtonName} = this.props;

        const startDateInputWrapperClassNames = classNames('date-range__input-wrapper', {
            'date-range__input-error' : !startDateInputValid
        });

        const endDateInputWrapperClassNames = classNames('date-range__input-wrapper', {
            'date-range__input-error' : !endDateInputValid
        });

        const submitButton = submitButtonName || 'Apply';
        const CLEAR_BUTTON = 'Cancel';

        const isSaveButtonDisable = false;

        const inputClassNames = classNames("select__input", {
            "active": isOpen
        });

        const value = startDateInput && `${startDateInput} - ${endDateInput}`;

        const anchorEl = this.input && this.input.current;

        return (
            <div className="select__container">
                <div className="select__title">{title}</div>
                <div ref={this.input} className={inputClassNames} onClick={this.onToggleMenu}>
                    <div className="select__input-value" title={value}>
                        {value}
                    </div>
                    <div className="select__arrow down-arrow-icon"/>
                </div>

                {isOpen && <Popover
                    anchorEl={anchorEl}
                    open={true}
                    withArrow={true}
                    className="date-range-popover"
                    onClosePopover={this.onToggleMenu}>
                    <div className="date-range">
                        <div>
                            <DayPickerRangeController
                                focusedInput={focusedInput}
                                initialVisibleMonth={() => endDate}
                                hideKeyboardShortcutsPanel={true}
                                //enableOutsideDays={false}
                                isOutsideRange={this.getOutsideDays}
                                transitionDuration={0}
                                minimumNights={0}
                                startDate={startDate}
                                endDate={endDate}
                                renderDayContents={this.renderDayContents}
                                onDatesChange={this.onDatesChange}
                                onFocusChange={this.onFocusedChange}/>
                        </div>
                        <div className="date-range__inputs">
                            <div className="date-range__inputs-header">
                                <p>Date range</p>
                                <div className="date-range__inputs-header__clear-icon" onClick={this.onClear}/>
                            </div>
                            <div className="inputs-container">
                                <Input className={startDateInputWrapperClassNames}
                                       placeholder="Start date"
                                       value={startDateInput}
                                       onMouseDown={this.onClickStartDateInput}
                                       onChange={this.onStartDateInput}
                                       mask="dd/mm/yyyy"/>

                                <div className="date-range__inputs-separator"/>

                                <Input className={endDateInputWrapperClassNames}
                                       placeholder="End date"
                                       value={endDateInput}
                                       onMouseDown={this.onClickEndDateInput}
                                       onChange={this.onEndDateInput}
                                       mask="dd/mm/yyyy"/>
                            </div>
                        </div>
                        <div className="date-range__buttons">
                            <Button
                                className="date-range__button date-range__button-close"
                                title={CLEAR_BUTTON}
                                onClick={this.onClear}/>
                            <Button
                                ref={ref => this.saveButtonRef = ref}
                                className="date-range__button date-range__button-apply"
                                title={submitButton}
                                disabled={isSaveButtonDisable}
                                onClick={this.onApply}/>
                        </div>
                    </div>

                </Popover>}
            </div>
        );
    }
}

DateRange.propTypes = {};

export default DateRange;
