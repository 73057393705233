import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import classNames from "classnames";

import './css/chekbox.css';

class Checkbox extends PureComponent {

    static propTypes = {
        value: PropTypes.any,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        className: '',
        tabIndex: 0,
        checked: false,
        disabled: false,
    };

    handleChange = (e) => {
        const {onChange, id} = this.props;
        const checked = e && e.target.checked;
        onChange && onChange(e, checked, id);
    };

    render() {
        const {className, label, style, isWaitLoading, value, checked, disabled} = this.props;

        const checkmarkClassNames = classNames({
            'checkmark-disable': disabled,
            'display-none': isWaitLoading,
        });

        const labelClassNames = classNames({
            'display-none': isWaitLoading,
        });

        return (
            <React.Fragment>
                <label className={`container ${className}`} style={style}>
                    <input type="checkbox"
                           value={value}
                           checked={checked}
                           disabled={disabled}
                           onChange={this.handleChange}/>
                    <span className={`checkmark ${checkmarkClassNames}`} />
                </label>
                {label && <span className={`kraken-checkbox-label ${labelClassNames}`}>{label}</span>}
            </React.Fragment>
        );
    }
}

export default Checkbox;
