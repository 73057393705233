import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import {gogFieldsList, convertToArrayOfObjectsKeys} from "../../helpers/utils";

const initialState = {
    status: null,
    lastUpdate: null,
    limit: 10,
    total: 0,
    list: [],
    offset: 0,
    rating: {},
    genres: [],
    selectedGenres: [],
    fieldsList: gogFieldsList,
    price: [
        {id: 1, name: "Free"},
        {id: 2, name: "Paid"},
    ],
    selectedPrice: [
        {id: 1, name: "Free"},
        {id: 2, name: "Paid"},
    ],
    isFiltersSelected: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
};

export default function gog(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_GOG:
            return {
                ...state,
                status: statuses.INIT_GOG_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_GOG:
            return {
                ...state,
                status: statuses.INIT_GOG_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_LAST_UPDATE_GOG:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_GOG:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_GOG:
            return {
                ...state,
                status: statuses.SEARCH_GOG_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_GOG:
            return {
                ...state,
                status: statuses.SEARCH_GOG_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.SET_PRICE_GOG:
            return {
                ...state,
                selectedPrice: action.price,
            }
        case actions.SET_GOG_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_GOG_RATING:
            return {
                ...state,
                rating: action.rating,
            }
        case actions.GET_GOG_FILTER_DATA:
            const genresList = convertToArrayOfObjectsKeys(action.response.genres);
            return {
                ...state,
                genres: genresList,
                selectedGenres: genresList
            }
        case actions.SET_SELECTED_GENRES_GOG:
            return {
                ...state,
                selectedGenres: action.selectedGenres,
            }
        case actions.SET_IS_FILTER_SELECTED_GOG:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_GOG:
            return {
                ...state,
                selectedPrice: state.price,
                releasedDateRange: state.defaultDateRange,
                rating: {},
                selectedGenres: state.genres
            }
        default:
            return state;
    }
}