export const REQUEST_INIT_MAGNET_SALES_ACCOUNTS = 'REQUEST_INIT_MAGNET_SALES_ACCOUNTS';
export const SUCCEED_INIT_MAGNET_SALES_ACCOUNTS = 'SUCCEED_INIT_MAGNET_SALES_ACCOUNTS';

export const REQUEST_REFRESH_MAGNET_SALES_ACCOUNTS = 'REQUEST_REFRESH_MAGNET_SALES_ACCOUNTS';
export const SUCCEED_REFRESH_MAGNET_SALES_ACCOUNTS = 'SUCCEED_REFRESH_MAGNET_SALES_ACCOUNTS';

export const REQUEST_SEARCH_MAGNET_SALES_ACCOUNTS = 'REQUEST_SEARCH_MAGNET_SALES_ACCOUNTS';
export const SUCCEED_SEARCH_MAGNET_SALES_ACCOUNTS = 'SUCCEED_SEARCH_MAGNET_SALES_ACCOUNTS';

export const SET_MAGNET_SALES_SEARCH = 'SET_MAGNET_SALES_SEARCH';
export const SET_SORT_MAGNET_SALES_ACCOUNTS = 'SET_SORT_MAGNET_SALES_ACCOUNTS';

export const REQUEST_CREATE_MAGNET_SALES_ACCOUNT = 'REQUEST_CREATE_MAGNET_SALES_ACCOUNT';
export const SUCCEED_CREATE_MAGNET_SALES_ACCOUNT = 'SUCCEED_CREATE_MAGNET_SALES_ACCOUNT';
export const REQUEST_DELETE_MAGNET_SALES_ACCOUNT = 'REQUEST_DELETE_MAGNET_SALES_ACCOUNT';
export const SUCCEED_DELETE_MAGNET_SALES_ACCOUNT = 'SUCCEED_DELETE_MAGNET_SALES_ACCOUNT';
export const REQUEST_UPDATE_MAGNET_SALES_ACCOUNT = 'REQUEST_UPDATE_MAGNET_SALES_ACCOUNT';
export const SUCCEED_UPDATE_MAGNET_SALES_ACCOUNT = 'SUCCEED_UPDATE_MAGNET_SALES_ACCOUNT';