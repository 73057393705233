import React from "react";

import '../css/magnet-close.css';

const MagnetClosePopup = ({closePopup, message}) => {

    const openCloseIo = () => {
        window.open('https://app.close.com/', '_blank');
    }

    return (
        <div className="popup-component__container">
            <div className="popup-component__title">This contact is not added to close.com</div>
            <div className="popup-component__description">
                {message}
                Please, add the contact to close.com to move forward
            </div>
            <div className="magnet-close-buttons">
                <div className="popup-component__button" onClick={closePopup}>
                    <div className="popup-component__button-title">Got it</div>
                </div>
                <div className="magnet-close-button" onClick={openCloseIo}>
                    <div className="magnet-close-button__title">
                        Open close.com
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MagnetClosePopup;