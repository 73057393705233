import React, {PureComponent } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';

import './css/link.css';

class Link extends PureComponent  {

    static propTypes = {
        path: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
    };

    render() {
        const {value, path, className} = this.props;

        const linkClassNames = classNames('kraken-link', className);

        return (
            <a className={linkClassNames} href={path} target='_blank' rel="noreferrer">
                {value}
            </a>
        );
    }
}

export default (Link);
