import {getTwitterAudiencesRequest, createTwitterAudienceRequest, sendAudienceToTwitterRequest} from "../../services/grpcBufServices";
import {
    requestCreateTwitterAudiences,
    requestInitTwitterAudiences,
    requestSearchTwitterAudiences,
    requestSendAudienceToTwitter,
    setSearchTwitterAudiences,
    succeedCreateTwitterAudiences,
    succeedInitTwitterAudiences,
    succeedSearchTwitterAudiences,
    succeedSendAudienceToTwitter
} from "./actions";

export const initTwitterAudiences = () =>
    async (dispatch, getState) => {
        const {app: {token}, twitter: {limit, offset, search}} = getState();
        dispatch(requestInitTwitterAudiences());
        const response = await getTwitterAudiencesRequest(token, limit, offset, search);
        dispatch(succeedInitTwitterAudiences(response));
    };

export const refreshTwitterAudiences = () =>
    async (dispatch, getState) => {
        const {app: {token}, twitter: {limit, offset, search}} = getState();
        dispatch(requestInitTwitterAudiences());
        const response = await getTwitterAudiencesRequest(token, limit, offset, search);
        dispatch(succeedInitTwitterAudiences(response));
    };

export const searchTwitterAudiences = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, twitter: {limit, offset, search}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchTwitterAudiences(updateLimit, updateOffset));
        const response = await getTwitterAudiencesRequest(token, updateLimit, updateOffset, search);
        dispatch(succeedSearchTwitterAudiences(response));
    };

export const createTwitterAudience = (name) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestCreateTwitterAudiences());
        const response = await createTwitterAudienceRequest(token, name);
        dispatch(succeedCreateTwitterAudiences(response));
        return response;
    };

export const sendAudienceToTwitter = (id) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestSendAudienceToTwitter());
        await sendAudienceToTwitterRequest(token, id);
        dispatch(succeedSendAudienceToTwitter());
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearchTwitterAudiences(search));
    };