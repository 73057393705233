export const REQUEST_INIT_HREF = 'REQUEST_INIT_HREF';
export const SUCCEED_INIT_HREF = 'SUCCEED_INIT_HREF';
export const REQUEST_SEARCH_HREF = 'REQUEST_SEARCH_HREF';
export const SUCCEED_SEARCH_HREF = 'SUCCEED_SEARCH_HREF';
export const REQUEST_LAST_UPDATE_HREF = 'REQUEST_LAST_UPDATE_HREF';
export const SUCCEED_LAST_UPDATE_HREF = 'SUCCEED_LAST_UPDATE_HREF';
export const SET_SELECTED_GENRES_HREF = 'SET_SELECTED_GENRES_HREF';
export const SET_SELECTED_LANGUAGES_HREF = 'SET_SELECTED_LANGUAGES_HREF';
export const SET_SELECTED_SOURCE_HREF = 'SET_SELECTED_SOURCE_HREF';
export const SET_SEARCH_LANGUAGES_HREF = 'SET_SEARCH_LANGUAGES_HREF';
export const SET_LANGUAGE_COUNT_HREF = 'SET_LANGUAGE_COUNT_HREF';
export const SET_HREF_FILTER_DATA = 'SET_HREF_FILTER_DATA';
export const REQUEST_GET_COUNT_HREF = 'REQUEST_GET_COUNT_HREF';
export const SUCCEED_GET_COUNT_HREF = 'SUCCEED_GET_COUNT_HREF';
export const SET_IS_ONLY_CHOSEN_LANGUAGES_HREF = 'SET_IS_ONLY_CHOSEN_LANGUAGES_HREF';
export const CLEAR_FILTERS_HREF = 'CLEAR_FILTERS_HREF';
export const SET_IS_FILTER_SELECTED_HREF = 'SET_IS_FILTER_SELECTED_HREF';
export const REQUEST_GET_COUNT_EMAILS_AND_TWITTERS_HREF = 'REQUEST_GET_COUNT_EMAILS_AND_TWITTERS_HREF';
export const SUCCEED_GET_COUNT_EMAILS_AND_TWITTERS_HREF = 'SUCCEED_GET_COUNT_EMAILS_AND_TWITTERS_HREF';