import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from "classnames";

import Scrollbar from "../Scrollbar";

import {closePopupRequest} from "../../containers/App/actionsAsync";

import './css/popup.css';
import './css/common.css';

export class Popup extends Component {
    static propTypes = {

    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {popup} = this.props;
        const {open} = popup;
        if (open) {
            window.addEventListener('keydown', this.handleKeyDown);
        } else {
            window.removeEventListener('keydown', this.handleKeyDown)
        }
    }

    handleKeyDown = (e) => {
        const {closePopup} = this.props;
        const isEsc = e.keyCode === 27;
        if (isEsc) {
            closePopup();
        }
    }

    onCancel = () => {
        const {closePopup} = this.props;
        closePopup && closePopup();
    }

    render() {
        const {popup, closePopup} = this.props;
        const {component, open, onApply} = popup;

        const popupClassName = classNames("popup__wrapper", {
            "open": open
        });

        const renderComponent = component && component(onApply, closePopup);

        return (
            <div className={popupClassName}>
                <Scrollbar>
                    <div className="popup__container">
                        <div className="popup-value__container">
                            <div className="popup-value__cancel-button">
                                <div className="cancel" onClick={this.onCancel} />
                            </div>

                            {renderComponent}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        popup: state.app.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Popup)
