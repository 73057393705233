export const NAME_REGEXP = /^[a-zA-Z0-9\s_.-]+$/;

export const routes = [
    {name: 'App Store', route: '/appstore', permissionKey: "/appstore", icon: 'app-store-icon', healthName: "AppStore"},
    {name: 'Close', route: '/close', permissionKey: "/close", icon: 'close-io-icon', healthName: "Close"},
    {name: 'Crowdin', route: '/crowdin', permissionKey: "/crowdin", icon: 'crowdin-icon', healthName: "Crowdin"},
    {name: 'Crunchbase', route: '/crunchbase', permissionKey: "/crunchbase", icon: 'crunchbase-icon', healthName: "Crunchbase"},
    {name: 'EU Startups', route: '/eustartups', permissionKey: "/eustartups", icon: 'eustartups-icon', healthName: "EU Startups"},
    {name: 'Epic Games', route: '/epic', permissionKey: "/epic", icon: 'epic-icon', healthName: "Epic"},
    {name: 'Google Play' ,route: '/play', permissionKey: "/play", icon: 'google-play-icon', healthName: "GooglePlay"},
    {name: 'Html', route: '/html', permissionKey: "/html", icon: 'href-icon', healthName: "Href"},
    {name: 'Itch.io' ,route: '/itchio/games', permissionKey: "/itchio", icon: 'itch-icon', isHasChildren: true, healthName: ["ItchIo"],
        routes: [
            {name: 'Games', route: '/itchio/games', permissionKey: "/itchio/games", healthName: "ItchIo"},
            {name: 'Authors', route: '/itchio/authors', permissionKey: "/itchio/authors", healthName: "ItchIo"}
        ]
    },
    {name: 'MeetToMatch' ,route: '/meet-to-match', permissionKey: "/meet-to-match", icon: 'meet-to-match-icon', healthName: "Meet to Match"},
    {name: 'Nintendo', route: '/nintendo', permissionKey: "/nintendo", icon: 'nintendo-icon', healthName: "Nintendo"},
    {name: 'Snov.io', route: '/snovio', permissionKey: "/snovio", icon: 'snovio-icon', healthName: "Snovio"},
    {name: 'Steam', route: '/steam/games', permissionKey: "/steam", icon: 'steam-icon', isHasChildren: true, healthName: ["Steam", "Steam Publishers"],
        routes: [
            {name: 'Games', route: '/steam/games', permissionKey: "/steam/games", healthName: "Steam"},
            {name: 'Publishers', route: '/steam/publishers', permissionKey: "/steam/publishers", healthName: "Steam Publishers"},
        ]
    },
    {name: 'Twitter', route: '/twitter', permissionKey: "/twitter", icon: 'twitter-icon', healthName: "Twitter"},
    {name: 'GOG.COM', route: '/gog', permissionKey: "/gog", icon: 'gog-icon', healthName: "gog.com", isLastDatabases: true},
    {name: 'Magnet', route: '/magnet/sales', permissionKey: "/magnet", icon: 'magnet-icon', isHasChildren: true, healthName: ["Magnet for Sales", "Magnet for Vendors"], isDashboardTools: true,
        routes: [
            {name: 'Sales', route: '/magnet/sales', permissionKey: "/magnet/sales", healthName: "Magnet for Sales"},
            {name: 'Vendors', route: '/magnet/vendors', permissionKey: "/magnet/vendors", healthName: "Magnet for Vendors"},
        ]
    },
    {name: 'Subscribe', route: '/subscribe/decide', permissionKey: "/subscribe", icon: 'subscribe-icon', isHasChildren: true, healthName: ["Subscribe"], isDashboardTools: true,
        routes: [
            {name: 'Decide', route: '/subscribe/decide', permissionKey: "/subscribe/decide", healthName: "Subscribe"},
            {name: 'List', route: '/subscribe/list', permissionKey: "/subscribe/list", healthName: "Subscribe"},
        ]
    },
]