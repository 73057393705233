import React from "react";
import {convertTimestampFormat, getDefaultReleased, getUpdateReleased} from "./dateUtils";
import moment from "moment";
import remove from "lodash/remove";
import map from "lodash/map";
import forEach from "lodash/forEach";
import capitalize from "lodash/capitalize";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import CONST from "../constants/Constants";
import Loading from "../components/Loading";

export const deleteItemFromArray = (array, id) => {
    return remove(array, item => item.id !== id);
}

export const setPageTitle = title => {
    document.title = title;
};

export const isTextOverflow = cellContend => cellContend.offsetHeight < cellContend.scrollHeight || cellContend.offsetWidth < cellContend.scrollWidth;

export const isTextLineBreak = value => /\r|\n/.exec(value);

export const removeTextInBrackets = (str) => {
    return str.split(/\[.*?]/).join('').trim();
}

export const getHealthStatus = (statusCount) => {
    const healthStatuses = {
        0: "In progress",
        1: "Success",
        2: "Failed"
    }

    return healthStatuses[statusCount];
}

export const getPriceType = price => {
    const priceTypes = {
        'all': 0,
        'free': 1,
        'paid': 2,
        'newFree': 1,
        'newPaid': 2,
    };

    return priceTypes[price] || 0;
};

export const getDecisionValue = decision => {
    const decisionTypes = {
        0: 'Unknown',
        1: 'New',
        2: 'Processed',
        3: 'Snooze',
        4: 'Skipped'
    };

    return decisionTypes[decision] || 0;
}

export const getUpdateSubscribeListField = key => {
    const subscribeListField = {
        'unknown': 0,
        'isDoNotDisturb': 3,
        'snooze': 4,
        'skip': 5,
        'redecide': 6,
        'isEn': 7,
        'isRu': 8,
    };

    return subscribeListField[key] || 0;
}

export const getUpdateReleasedByPrice = (price, released) => {
    if (price === 'newFree' || price === 'newPaid') {
        return getUpdateReleased();
    } else {
        return getDefaultReleased();
    }
};

export const getRequestOptions = (selectedOptions, allOptions) => {
    return selectedOptions.length === allOptions.length ? [] : selectedOptions;
}

export const getIdsList = options => map(options, item => item.id);

export const getCountryValue = value => !!value ? [value] : [];

export const getEpicLanguageType = (type, languages) => type;

export const updateItemInArray = (target, item) => {
    return map(target, targetItem => {
        return targetItem.id === item.id ? item : targetItem;
    });
};

export const getListSubscribeSortField = field => {
    const subscribeListSortField = {
        'imported': 0,
        'decisionTime': 1,
    };

    return subscribeListSortField[field] || 0;
};

export const getTwitterAudiencesSortField = field => {
    const twitterAudiencesSortField = {
        'id': 0,
        'name': 1,
        'updated': 2,
        'sentTime': 3,
    };

    return twitterAudiencesSortField[field] || 0;
};

export const getSortOrder = order => {
    const sortOrder = {
        'asc': 0,
        'desc': 1,
    };

    return sortOrder[order] || 0;
};

export const renderBigNumbers = (row, column, value) => {
    const stringValue = value.toString().split("").reverse();
    const countArray = [];
    forEach(stringValue, (number, i) => {
        if (i % 3 === 0 && i !== 0) {
            countArray.push(' ');
        }
        countArray.push(number);
    })
    return countArray.reverse().join('');
}

export const convertToArrayOfObjects = (array) => {
    return map(array, item => {
        return {id: item, name: item};
    });
}

export const convertObjectToArrayOfKeys = (object) => map(object, tag => tag.id);

export const convertToArrayOfObjectsKeys = (array) => {
    return map(array, item => {
        const lowerName = toLower(item);
        const name = capitalize(lowerName).replace("_", " ");
        return {id: item, name: name};
    });
}

export const getLastUpdateTitle = (lastUpdate, title) => {
    let lastUpdateTitle = <div>Last update – <Loading/></div>;
    if (lastUpdate && lastUpdate.timestamp) {
        const getUpdateString = (title, count) => {
            const serviceTypes = {
                'Google Play': `(+${count} new apps)`,
                'Steam Games': `(+${count} new games)`,
                'Steam publishers': `(+${count} new publishers)`,
                'Html': `(+${count} new records)`,
                'Epic': `(+${count} new games)`,
                'Nintendo': `(+${count} new games)`,
                'App Store': `(+${count} new games)`,
                'Close': ' ',
                'Crowdin': `(+${count} new records)`,
                'Crunchbase': ' ',
                'Snovio': ' ',
                'Itch.io games': `(+${count} new games)`,
                'Itch.io authors': `(+${count} new games)`,
                'EU Startups': `(+${count} new startups)`,
                'Gog': `(+${count} new games)`,
            };

            return serviceTypes[title] || null;
        };
        const lastUpdateTime = convertTimestampFormat(lastUpdate.timestamp, CONST.DATE_FORMAT_WITH_TIME);
        const updateCount = getUpdateString(title, lastUpdate.newRecords);
        lastUpdateTitle = `Last update – ${lastUpdateTime} ${updateCount}`
    }

    return lastUpdateTitle;
}

export const convertArrayOfObjectsToFlatArray = (array, key = 'id') => map(array, item => item[key]);

// экранируем специальные символы
const escapeRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const getHighlightSubstring = (str, substr) => {
    const escapedSubstr = escapeRegExp(substr);
    return str.replace(RegExp(escapedSubstr, 'i'), `<span class="highlight-text">${substr}</span>`);
}

export const escapeHTML = (html) => html.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/\*/g,'&#8727;');

const crunchbaseTypes = {
    isCompany: 'Company',
    isInvestor: 'Investor',
    isSchool: 'School'
};

export const setCrunchbaseTypeField = (list) => {
    map(list, item => {
        let types = [];
        map(Object.keys(crunchbaseTypes), type => item[type] && types.push(crunchbaseTypes[type]));
        item.organizationType = types;
    })
    return list;
}

export const convertCategoriesList = (categories) => {
    return map(categories, category => {
        const categoryName = capitalize(category.toLowerCase().replaceAll("_", " "));
        return {id: category, name: categoryName}
    })
}

export const getGooglePlayCategoriesOption = (categoriesList) => {
    const categoryOption = [
        {id: 'common', checkboxName: 'All not gaming apps', name: 'Not gaming apps', optionsClassName: 'common-category', options: []},
        {id: 'games', checkboxName: 'All games', name: 'Games', options: []}
    ];

    map(categoriesList, category => {
        category.id.split('_')[0] === "GAME"
            ? categoryOption[1].options.push(category)
            : categoryOption[0].options.push(category);
    })

    return categoryOption;
}

export const googlePlayFieldsList = [
    {id: "email", name: "Emails", uniqId: 1},
    {id: "email", name: "Emails (unique only)", uniqId: 1, isUniq: true},
    {id: "developer", name: "Developers", uniqId: 2},
    {id: "developer", name: "Developers (unique only)", uniqId: 2, isUniq: true},
    {id: "developer_website", name: "Websites", uniqId: 3},
    {id: "developer_website", name: "Websites (unique only)", uniqId: 3, isUniq: true},
    {id: "title", name: "Name"},
    {id: "free", name: "Prices"},
    {id: "rating", name: "Downloads"},
    {id: "category", name: "Categories"},
    {id: "score", name: "Scores"},
    {id: "released", name: "Release dates"},
    {id: "url", name: "Google Play links"},
    {id: "stores", name: "Markets"},
    {id: "updated", name: "Updated"},
    {id: "has_video", name: "Video"},
    {id: "app_count", name: "App count"},
    {id: "id", name: "Id"},
];

export const steamFieldsList = [
    {id: "email", name: "Emails", uniqId: 1},
    {id: "email", name: "Emails (unique only)", uniqId: 1, isUniq: true},
    {id: "dev_team", name: "Developers", uniqId: 2},
    {id: "dev_team", name: "Developers (unique only)", uniqId: 2, isUniq: true},
    {id: "publisher", name: "Publishers", uniqId: 3},
    {id: "publisher", name: "Publishers (unique only)", uniqId: 3, isUniq: true},
    {id: "id", name: "Id"},
    {id: "name", name: "Name"},
    {id: "profile", name: "Profile"},
    {id: "free", name: "Prices"},
    {id: "early_access", name: "Early access"},
    {id: "release_date", name: "Release dates"},
    {id: "reviews", name: "Reviews"},
    {id: "website", name: "Website"},
    {id: "type", name: "Type"},
    {id: "languages", name: "Languages"},
    {id: "genres", name: "Genres"},
];

export const appStoreFieldsList = [
    {id: "developer", name: "Developers", uniqId: 1},
    {id: "developer", name: "Developers (unique only)", uniqId: 1, isUniq: true},
    {id: "email", name: "Emails", uniqId: 2},
    {id: "email", name: "Emails (unique only)", uniqId: 2, isUniq: true},
    {id: "title", name: "Name"},
    {id: "url", name: "Url"},
    {id: "primary_genre", name: "Primary genre"},
    {id: "released", name: "Release dates"},
    {id: "updated", name: "Updated"},
    {id: "free", name: "Prices"},
    {id: "developer_url", name: "Developer url"},
    {id: "developer_website", name: "Websites"},
    {id: "score", name: "Scores"},
    {id: "reviews", name: "Reviews"},
    {id: "platform", name: "Platform"},
    {id: "has_video", name: "Video"},
    {id: "genres", name: "Genres"},
    {id: "languages", name: "Languages"},
    {id: "countries", name: "Countries"},
    {id: "app_count", name: "App count"},
    {id: "id", name: "Id"},
];

export const gogFieldsList = [
    {id: "email", name: "Email (unique only)", uniqId: 1, isUniq: true},
    {id: "developer_name", name: "Developer", uniqId: 2},
    {id: "developer_name", name: "Developer (unique only)", uniqId: 2, isUniq: true},
    {id: "publisher_name", name: "Publisher", uniqId: 3},
    {id: "publisher_name", name: "Publisher (unique only)", uniqId: 3, isUniq: true},
    {id: "id", name: "Id"},
    {id: "title", name: "Title"},
    {id: "link", name: "Link"},
    {id: "genres", name: "Genres"},
    {id: "platforms", name: "Platforms"},
    {id: "is_paid", name: "Price"},
    {id: "rating", name: "Rating"},
    {id: "release_date", name: "Released"},
    {id: "audio_languages", name: "Audio languages"},
    {id: "text_languages", name: "Text languages"},
    {id: "developer_website", name: "Developer website"},
    {id: "developer_emails", name: "Developer emails"},
    {id: "publisher_website", name: "Publisher website"},
    {id: "publisher_emails", name: "Publisher emails"},
];

export const hrefFieldsList = [
    {id: "developers", name: "Developers", uniqId: 1},
    {id: "developers", name: "Developers (unique only)", uniqId: 1, isUniq: true},
    {id: "email", name: "Emails", uniqId: 2},
    {id: "email", name: "Emails (unique only)", uniqId: 2, isUniq: true},
    {id: "publishers", name: "Publishers", uniqId: 3},
    {id: "publishers", name: "Publishers (unique only)", uniqId: 3, isUniq: true},
    {id: "source_email", name: "Source email", uniqId: 4},
    {id: "source_email", name: "Source email (unique only)", uniqId: 4, isUniq: true},
    {id: "phone", name: "Phone", uniqId: 5},
    {id: "phone", name: "Phone (unique only)", uniqId: 5, isUniq: true},
    {id: "twitter", name: "Twitter", uniqId: 6},
    {id: "twitter", name: "Twitter (unique only)", uniqId: 6, isUniq: true},
    {id: "website", name: "Website"},
    {id: "source", name: "Source"},
    {id: "language_quantity", name: "Language quantity"},
    {id: "languages", name: "Languages"},
    {id: "contacts", name: "Contacts"},
    {id: "updated", name: "Updated"}
];

export const snovioFieldsList = [
    {id: "email", name: "Email", uniqId: 1},
    {id: "email", name: "Email (unique only)", uniqId: 1, isUniq: true},
    {id: "id", name: "Id"},
    {id: "name", name: "Name"},
    {id: "position", name: "Position"},
    {id: "company_name", name: "Company name"},
    {id: "source", name: "Source"},
    {id: "url", name: "Url"}
];

export const getExportFieldValues = (fields) => {
    const fieldsList = {
        fieldsList: [],
        uniqueFieldsList: []
    }

    map(fields, field => {
        !includes(fieldsList.fieldsList, field.id) && fieldsList.fieldsList.push(field.id);
        field.isUniq && fieldsList.uniqueFieldsList.push(field.id);
    });

    return fieldsList;
}

export const healthServicesName = {
    "/appstore": "AppStore",
    "/close": "Close",
    "/crowdin": "Crowdin",
    "/crunchbase": "Crunchbase",
    "/epic": "Epic",
    "/play": "GooglePlay",
    "/html": "Href",
    "/itchio/games": "ItchIo",
    "/itchio/authors": "ItchIo",
    "/magnet/sales": "Magnet for Sales",
    "/magnet/vendors": "Magnet for Vendors",
    "/nintendo": "Nintendo",
    "/snovio": "Snovio",
    "/steam/games": "Steam",
    "/steam/publishers": "Steam Publishers",
    "/subscribe/decide": "Subscribe",
    "/subscribe/list": "Subscribe",
    "/twitter": "Twitter",
    "/eustartups": "EU Startups",
    "/gog": "gog.com",
    "/meet-to-match": "Meet to Match",
}

export const checkMagnetCheckpoint = (checkpoint) => {
    const date = convertTimestampFormat(checkpoint, CONST.DATE_STANDARD_FORMAT);
    const checkDate = moment().subtract(3,'d').format(CONST.DATE_STANDARD_FORMAT);
    return moment(checkDate).isAfter(date);
}
