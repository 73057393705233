import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from "../Checkbox";

import './css/select.css';

class CheckboxItem extends Component {

    static propTypes = {
        id: PropTypes.any,
        name: PropTypes.string,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        onChange: PropTypes.func,
    }

    render() {
        const {id, name, checked, className, disabled, onChange} = this.props;

        const itemClassName = classNames("select-items__item", className);
        const itemTitleClassName = classNames("select-items__item-name", {
            "disabled": disabled
        });

        return (
            <div key={id} className={itemClassName}>
                <Checkbox disabled={disabled} id={id} checked={checked} onChange={onChange}/>
                <div className={itemTitleClassName} title={name}>{name}</div>
            </div>
        );
    }
}

export default (CheckboxItem);