import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import Button from '../Button';
import Select from '../Select';
import ItemSelect from "../ItemSelect";
import DateRange from "../DateRange";
import DownloadCount from "../DownloadCount";
import SearchInput from "../SearchInput";
import AddInput from "../AddInput";
import LanguagesCount from "../LanguagesCount";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import './css/filters.css';

const itemsSideBar = {
    button: Button,
    select: Select,
    itemSelect: ItemSelect,
    dateRange: DateRange,
    downloadCount: DownloadCount,
    addElement: AddInput,
    languagesCount: LanguagesCount
};

function Filters(props) {
    const {containerClassName, search, setSearch, onRefresh, onExport, dropdownExport, searchStyle, totalItems, twitterButton} = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const exportDropdownButton = useRef();
    const exportDropdownContainer = useRef();
    useOnClickOutside(exportDropdownButton, () => setIsDropdownOpen(false), exportDropdownContainer);

    const setItemSideBar = (type) => {
        return itemsSideBar[type]
    };

    const handleChange = (onChange) => options => {
        const {onRefresh} = props;
        if (onChange) {
            onChange(options).then(() => {
                onRefresh && onRefresh();
            })
        }
    }

    const renderFilters = () => {
        const {filtersOptions} = props;
        const filters = map(filtersOptions, (option, index) => {
            const {type, customWrapperClassName, onChange} = option;

            const ItemSideBar = setItemSideBar(type);

            const itemSideBarProps = {
                ...option,
                onChange: handleChange(onChange),
            };

            const itemWrapperClassNames = classNames('filter__item_wrapper', customWrapperClassName);

            return <div className={itemWrapperClassNames} key={index}><ItemSideBar {...itemSideBarProps}/></div>;
        });
        return filters;
    };

    const onToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const renderExportDropdown = () => {
        const {dropdownExport} = props;
        return map(dropdownExport, item => {
            const {onClick, title, icon} = item;
            return <div key={title} className="export-dropdown-item" onClick={onClick}>
                {icon && <div className={icon}/>}
                <div>{title}</div>
            </div>;
        });
    }

    const filters = renderFilters();

    const containerClassNames = classNames("filters__container", containerClassName);
    const dropdownItems = renderExportDropdown();
    const exportDropdownContainerStyle = classNames("filters__export-dropdown-container", {"open": isDropdownOpen});
    const searchContainerStyle = classNames("filter-search", searchStyle);
    const exportButtonStyle = classNames({"disabled": totalItems === 0});
    const exportDropdownButtonStyle = classNames("export-dropdown-button", exportButtonStyle);

    return (
        <div className={containerClassNames}>
            {onExport && <div className="filters__export">
                <Button title='Export' onClick={onExport} className={exportButtonStyle}/>
            </div>}
            {dropdownExport && <div className="filters__export">
                <div ref={exportDropdownButton}>
                    <Button className={exportDropdownButtonStyle} title='Export' onClick={onToggleDropdown}
                            icon={<div className='down-arrow-white-icon snooze-button-icon'/>}/>
                </div>
                <div ref={exportDropdownContainer} className={exportDropdownContainerStyle}>
                    {dropdownItems}
                </div>
                {twitterButton}
            </div>}
            <div className="filter-items">
                {filters}
                {setSearch && <SearchInput className={searchContainerStyle}
                                           placeholder="Search"
                                           value={search}
                                           isSearchOnEmpty={true}
                                           onSearch={setSearch}
                                           refreshPage={onRefresh}/>}
            </div>
        </div>
    );
}

Filters.propTypes = {
    search: PropTypes.string,
    setSearch: PropTypes.func,
    onExport: PropTypes.func,
    onRefresh: PropTypes.func,
    filtersOptions: PropTypes.array,
    containerClassName: PropTypes.string,
    dropdownExport: PropTypes.array
}

export default (Filters);
