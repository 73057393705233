import React from 'react';

const Iframe = ({srcDoc, id, width, ...props}) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState("0px");
    const onLoad = () => {
        const scrollHeight = ref.current && ref.current?.contentWindow.document.body.scrollHeight;
        if (scrollHeight) {
            const messageHeight = Math.max(
                ref.current?.contentWindow.document.body.scrollHeight, ref.current?.contentWindow.document.documentElement.scrollHeight,
                ref.current?.contentWindow.document.body.offsetHeight, ref.current?.contentWindow.document.documentElement.offsetHeight,
                ref.current?.contentWindow.document.body.clientHeight, ref.current?.contentWindow.document.documentElement.clientHeight
            );
            setHeight(messageHeight + "px");
        } else {
            setTimeout(() => {
                onLoad();
            }, 100);
        }
    };

    return (<iframe title={id} ref={ref} id={id} onLoad={onLoad} width={width} height={height} srcDoc={srcDoc}/>);
};

export default Iframe;