import React, {Component} from 'react';
import Loading from "../Loading";

const ADDED_LIMIT = 100;

class TableFoot extends Component {

    static propTypes = {
    };

    state = {
        maxWidth: 0
    }

    componentDidMount() {
        const {tableRef} = this.props;
        this.setState({
            maxWidth: tableRef && tableRef.current && tableRef.current.offsetWidth
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tableRef} = this.props;
        const {maxWidth} = this.state;
        const width = tableRef && tableRef.current && tableRef.current.offsetWidth;
        if (maxWidth !== width) {
            this.setState({
                maxWidth: width
            })
        }
    }

    onShowMore = (count) => (e) => {
        const {onShowMore} = this.props;
        onShowMore(count)
    }

    renderContent = () => {
        const {isLoading, countItems, totalItems} = this.props;
        const convertedTotalItems = +totalItems.replace(/ /g,'');
        if (isLoading) {
            return (
                <Loading />
            )
        } else if (convertedTotalItems === 0) {
            return (
                <div className="kraken-table-foot-content nothing">
                    (nothing found)
                </div>
            )
        } else if (convertedTotalItems === countItems || countItems > convertedTotalItems) {
            const title = `${totalItems} out of ${totalItems}`;
            return (
                <div className="kraken-table-foot-content nothing">
                    {title}
                </div>
            )
        } else if (ADDED_LIMIT > convertedTotalItems || ADDED_LIMIT + countItems > convertedTotalItems) {
            const title = `Show more ${convertedTotalItems - countItems} out of ${totalItems}`;
            return (
                <div className="kraken-table-foot-content" onClick={this.onShowMore(convertedTotalItems - countItems)}>
                    {title}
                </div>
            )
        } else {
            const title = `Show more 100 out of ${totalItems}`;
            return (
                <div className="kraken-table-foot-content" onClick={this.onShowMore(ADDED_LIMIT)}>
                    {title}
                </div>
            )
        }
    }

    render() {
        const {maxWidth} = this.state;
        const content = this.renderContent();

        return (
            <div className="kraken-table-foot" style={{maxWidth: maxWidth + 'px'}}>
                {content}
            </div>
        );
    }
}

export default (TableFoot);
