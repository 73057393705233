import * as types from "./constants";
import * as statuses from "./../../store/StatusTypes";

export function requestGetAudienceByName() {
    return {
        type: types.REQUEST_GET_AUDIENCE_BY_NAME,
        blockUi: true
    };
}

export function succeedGetAudienceByName(response, donors) {
    return {
        type: types.SUCCEED_GET_AUDIENCE_BY_NAME,
        blockUi: false,
        response, donors
    };
}

export function requestCreateTwitterDonor() {
    return {
        type: types.REQUEST_CREATE_TWITTER_DONOR,
        blockUi: true
    };
}

export function succeedCreateTwitterDonor(response) {
    return {
        type: types.SUCCEED_CREATE_TWITTER_DONOR,
        blockUi: false,
        response
    };
}

export function requestRemoveTwitterDonor(id) {
    return {
        type: types.REQUEST_REMOVE_TWITTER_DONOR,
        blockUi: true,
        id
    };
}

export function succeedRemoveTwitterDonor(response) {
    return {
        type: types.SUCCEED_REMOVE_TWITTER_DONOR,
        blockUi: false,
        response
    };
}

export function requestRenameTwitterAudience(id, name) {
    return {
        type: types.REQUEST_RENAME_TWITTER_AUDIENCE,
        blockUi: true,
        id, name
    };
}

export function succeedRenameTwitterAudience() {
    return {
        type: types.SUCCEED_RENAME_TWITTER_AUDIENCE,
        blockUi: false,
    };
}

export function requestSendAudienceToTwitter() {
    return {
        type: types.REQUEST_SEND_AUDIENCE_TO_TWITTER,
        blockUi: true,
    };
}

export function succeedSendAudienceToTwitter() {
    return {
        type: types.SUCCEED_SEND_AUDIENCE_TO_TWITTER,
        blockUi: false,
    };
}

export function requestSearchTwitterDonors(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_TWITTER_DONORS,
        blockUi: true,
        limit, offset
    };
}

export function succeedSearchTwitterDonors(donors) {
    return {
        type: types.SUCCEED_SEARCH_TWITTER_DONORS,
        blockUi: false,
        donors
    };
}

export function requestRemoveAudience() {
    return {
        type: statuses.REMOVE_TWITTER_AUDIENCE_REQUEST,
        blockUi: true
    };
}

export function succeedRemoveAudience(response, id) {
    return {
        type: statuses.REMOVE_TWITTER_AUDIENCE_SUCCEED,
        blockUi: false,
        response, id
    };
}

export function setSearchTwitterDonors(search) {
    return {
        type: types.SET_SEARCH_TWITTER_DONORS,
        search
    }
}