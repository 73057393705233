import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from "classnames";
import {setInitContainer, setTitle} from "../App/actions";

import {showPopupRequest, closePopupRequest, checkIsFailedStatus} from "../App/actionsAsync";

import {setActiveTab} from "./actions";

import MagnetDecideVendors from "../MagnetDecideVendors";
import MagnetDecideSales from "../MagnetDecideSales";
import MagnetBlackListVendors from "../MagnetBlackListVendors";
import MagnetBlackListSales from "../MagnetBlackListSales";
import MagnetAccountsVendors from "../MagnetAccountsVendors";
import MagnetAccountsSales from "../MagnetAccountsSales";
import MagnetContactsVendors from "../MagnetContactsVendors";
import MagnetContactsSales from "../MagnetContactsSales";
import NavigationTabs from "../../components/NavigationTabs";
import Legend from "../../components/Legend";
import LegendPortal from "../../components/Legend/LegendPortal";

import '../../css/magnet.css';

const CONTAINER_NAME = 'Magnet';

class Magnet extends Component {

    componentDidMount() {
        const {setTitle, checkIsFailedStatus} = this.props;
        checkIsFailedStatus();
        setTitle(CONTAINER_NAME);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {account, setTitle} = this.props;
        const {account: prevAccount} = prevProps;
        if (account !== prevAccount) {
            const title = this.getMagnetTitle(account);
            setTitle(title);
        }
    }

    getMagnetTitle = (account) => {
        const accounts = {
            1: 'Sales',
            2: 'Vendors'
        };

        return `${CONTAINER_NAME} for ${accounts[account]}`;
    }

    render() {
        const {account, isAdmin, activeTab, setActiveTab} = this.props;

        const navigate = [
            {id: 1, name: 'Dashboard'},
            {id: 2, name: 'Blacklist'},
            {id: 3, name: 'Accounts', isHidden: !isAdmin},
            {id: 4, name: 'Contacts'},
        ];

        const containers = {
            1: account === 1 ? <MagnetDecideSales account={account}/> : <MagnetDecideVendors account={account}/>,
            2: account === 1 ? <MagnetBlackListSales account={account}/> : <MagnetBlackListVendors account={account}/>,
            3: account === 1 ? <MagnetAccountsSales account={account}/> : <MagnetAccountsVendors account={account}/>,
            4: account === 1 ? <MagnetContactsSales account={account}/> : <MagnetContactsVendors account={account}/>,
        };

        const renderContainer = containers[activeTab];

        const value = (
            <div className="magnet-legend">
                <div className="magnet-legend__item"><b>Close</b><span> – click to check whether the contact in Close.com</span></div>
                <div className="magnet-legend__item"><b>Non-Close</b><span> – click it if the contact is not related to sales and should not be in Close.com</span></div>
                <div className="magnet-legend__item"><b>Skip</b><span> – click it if you can't decide on based email whether contact needs to be in Close.com or not</span></div>
            </div>
        );

        const legendClassName = classNames("magnet-legend-popover", {
            "magnet-legend_hidden": activeTab !== 1, //показываем только для Decide
        });

        return (
            <div className="app-root__table-container">
                <LegendPortal>
                    <Legend className={legendClassName} value={value}/>
                </LegendPortal>
                <NavigationTabs className="magnet-navigation-tabs" activeItem={activeTab} items={navigate} onChange={setActiveTab}/>
                {renderContainer}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,
        isAdmin: state.app.isAdmin,

        activeTab: state.magnet.activeTab,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),

        setActiveTab: item => dispatch(setActiveTab(item)),
        checkIsFailedStatus: () => dispatch(checkIsFailedStatus()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Magnet)