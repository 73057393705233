import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {convertToArrayOfObjects, convertToArrayOfObjectsKeys} from "../../helpers/utils";
import {getDefaultReleased} from "../../helpers/dateUtils";

const initialState = {
    status: null,
    lastUpdate: null,
    limit: 10,
    total: 0,
    list: [],
    offset: 0,
    count: '',

    genres: [],
    selectedGenres: [],
    languages: [],
    selectedLanguages: [],
    languagesCount: {from: '', to: ''},
    platform: [],
    selectedPlatform: [],
    price: [
        {id: 1, name: "Paid"},
        {id: 2, name: "Free"},
    ],
    selectedPrice: [
        {id: 1, name: "Paid"},
        {id: 2, name: "Free"},
    ],
    isOnlyChosenLanguages: false,
    isFiltersSelected: false,
    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    startDate: null,
    isCountLoading: false
};

export default function itchGames(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_ITCH_GAMES:
            return {
                ...state,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_ITCH_GAMES:
            return {
                ...state,
                list: action.response.result,
            }
        case actions.REQUEST_SEARCH_ITCH_GAMES:
            return {
                ...state,
                status: statuses.GET_COUNT_ITCH_GAMES_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_ITCH_GAMES:
            return {
                ...state,
                status: statuses.GET_COUNT_ITCH_GAMES_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.SUCCEED_GET_ITCH_GAMES_FILTER_DATA:
            const platformList = convertToArrayOfObjectsKeys(action.response.platforms);
            const genresList = convertToArrayOfObjectsKeys(action.response.genres);
            const languagesList = convertToArrayOfObjects(action.response.languages);
            const startDate = action.response.publishedStartDate;
            return {
                ...state,
                languages: languagesList,
                selectedLanguages: languagesList,
                genres: genresList,
                selectedGenres: genresList,
                platform: platformList,
                releasedDateRange: getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                startDate: startDate
            }
        case actions.SET_SELECTED_GENRES_ITCH_GAMES:
            return {
                ...state,
                selectedGenres: action.selectedGenres,
            }
        case actions.SET_SELECTED_LANGUAGES_ITCH_GAMES:
            return {
                ...state,
                selectedLanguages: action.selectedLanguage,
            }
        case actions.SET_SELECTED_PLATFORM_ITCH_GAMES:
            return {
                ...state,
                selectedPlatform: action.selectedPlatform,
            }
        case actions.SET_LANGUAGE_COUNT_ITCH_GAMES:
            return {
                ...state,
                languagesCount: action.languagesCount,
            }
        case actions.SET_PRICE_ITCH_GAMES:
            return {
                ...state,
                selectedPrice: action.price,
            }
        case actions.REQUEST_ITCH_GAMES_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: {},
            }
        case actions.SUCCEED_ITCH_GAMES_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.SET_ITCH_GAMES_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.REQUEST_GET_COUNT_ITCH_GAMES:
            return {
                ...state,
                status: statuses.GET_COUNT_ITCH_GAMES_REQUESTED,
                isCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_ITCH_GAMES:
            return {
                ...state,
                status: statuses.GET_COUNT_ITCH_GAMES_SUCCEEDED,
                count: action.count,
                total: action.count.games,
                isCountLoading: false
            }
        case actions.SET_IS_ONLY_CHOSEN_LANGUAGES_ITCH_GAMES:
            return {
                ...state,
                isOnlyChosenLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_FILTER_SELECTED_ITCH_GAMES:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_ITCH_GAMES:
            return {
                ...state,
                selectedGenres: state.genres,
                languagesCount: {from: '', to: ''},
                selectedLanguages: state.languages,
                selectedPrice: state.price,
                releasedDateRange: state.defaultDateRange,
                selectedPlatform: state.platform,
                isOnlyChosenLanguages: false,
            }
        default:
            return state;
    }
}