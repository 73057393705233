import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './css/popover.css';

class Popover extends Component {

    static propTypes = {
        position: PropTypes.oneOf(['right', 'left']),
        open: PropTypes.bool,
        className: PropTypes.string,
        withArrow: PropTypes.bool,
        onClosePopover: PropTypes.func,
    };

    static defaultProps = {
        withArrow: false
    }

    componentDidMount() {
        this.addedListenerClickOutside();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.addedListenerClickOutside();
    }

    addedListenerClickOutside = () => {
        const {open} = this.props;
        if (open) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const {popover} = this;
        const {onClosePopover, anchorEl} = this.props;
        if (popover && !popover.contains(e.target) && anchorEl && !anchorEl.contains(e.target)) {
            onClosePopover && onClosePopover(e);
        }
    }

    render() {
        const {children, open, withArrow, className, position} = this.props;

        const containerClassNames = classNames("popover__container", className, {
            "open": open,
        });

        const menuContainerClassNames = classNames("popover__menu-container", {
            "left": position === 'left',
            "right": position === 'right',
        });
        return (
            <div className={containerClassNames} ref={ref => this.popover = ref}>
                {!withArrow && <div className="popover__arrow-container">
                    <div className="popover__arrow"/>
                </div>}
                <div className={menuContainerClassNames}>
                    {children}
                </div>
            </div>
        );
    }
}

export default (Popover)
