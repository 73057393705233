import * as actions from "./constants";

const initialState = {
    activeTab: 1,
};

export default function magnet(state = initialState, action) {
    switch (action.type) {

        case actions.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.item
            }
        default:
            return state;
    }
}