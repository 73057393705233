export const REQUEST_INIT_STEAM_PUBLISHERS = 'REQUEST_INIT_STEAM_PUBLISHERS';
export const SUCCEED_INIT_STEAM_PUBLISHERS = 'SUCCEED_INIT_STEAM_PUBLISHERS';
export const REQUEST_SEARCH_STEAM_PUBLISHERS = 'REQUEST_SEARCH_STEAM_PUBLISHERS';
export const SUCCEED_SEARCH_STEAM_PUBLISHERS = 'SUCCEED_SEARCH_STEAM_PUBLISHERS';
export const REQUEST_LAST_UPDATE_STEAM_PUBLISHERS = 'REQUEST_LAST_UPDATE_STEAM_PUBLISHERS';
export const SUCCEED_LAST_UPDATE_STEAM_PUBLISHERS = 'SUCCEED_LAST_UPDATE_STEAM_PUBLISHERS';
export const SET_SEARCH_LANGUAGES_STEAM_PUBLISHERS = 'SET_SEARCH_LANGUAGES_STEAM_PUBLISHERS';
export const SET_STEAM_PUBLISHERS_RELEASED = 'SET_STEAM_PUBLISHERS_RELEASED';
export const SET_STEAM_PUBLISHERS_FILTER_DATA = 'SET_STEAM_PUBLISHERS_FILTER_DATA';
export const SET_PRODUCT_COUNT_STEAM_PUBLISHERS = 'SET_PRODUCT_COUNT_STEAM_PUBLISHERS';
export const SET_IS_FILTER_SELECTED_STEAM_PUBLISHERS = 'SET_IS_FILTER_SELECTED_STEAM_PUBLISHERS';
export const CLEAR_FILTERS_STEAM_PUBLISHERS = 'CLEAR_FILTERS_STEAM_PUBLISHERS';