import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {NAME_REGEXP} from "../../constants/AppConstants";

import './css/rename-input.css';
import {usePopper} from "react-popper";

function RenameInput({value: valueProps, className, onEndEdit}) {
    const [value, setValue] = useState(valueProps);
    const [isValid, setValid] = useState(true);
    const popoverRef = useRef();
    const inputRef = useRef();
    const inputValueRef = useRef();
    useOnClickOutside(inputRef, (e) => handleEndEdit(e));
    const { styles, attributes } = usePopper(
        inputRef.current,
        popoverRef.current,
        {
            placement: "bottom-start",
            modifiers: [
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [0, 16]
                    }
                }
            ]
        }
    );

    useEffect(() => {
        setFocusOnTextArea();
    });

    const setFocusOnTextArea = () => {
        inputValueRef.current.focus();
        const finishSelection = valueProps.length;
        inputValueRef.current.setSelectionRange(0, finishSelection);
    };

    const onValueChange = (e) => {
        const newValue = e.target.value;
        const isValid = NAME_REGEXP.test(newValue);
        setValid(isValid);
        setValue(e.target.value);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleEndEdit(e);
        }
    };

    const handleEndEdit = (e) => {
        if (isValid && value) {
            onEndEdit && onEndEdit(e, value);
        }
    };

    const inputClassName = classNames("rename-input", className, {
        "rename-input_invalid": !isValid
    });

    const popperClassName = classNames("rename-input__popper", {
        "rename-input__popper_invalid": !isValid
    });

    return (
        <div ref={inputRef} className={inputClassName}>
            <input ref={inputValueRef} className="rename-input__input" value={value} onKeyDown={onKeyDown} onChange={onValueChange}/>

            <div ref={popoverRef} className={popperClassName} style={styles.popper} {...attributes.popper}>
                <div className="rename-input__popper-arrow"/>
                <div className="rename-input__popper-body">
                    It's allowed to use spaces, digits (0-9), letters (A-Z, a-z), and a few special characters ( "-" , "." , "_")
                </div>
            </div>
        </div>
    );
}

export default RenameInput;