export const AUTH_REQUESTED = 'AUTH_REQUESTED';
export const AUTH_SUCCEEDED = 'AUTH_SUCCEEDED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_OUT';

export const GET_HEALTH_REQUESTED = 'GET_HEALTH_REQUESTED';
export const GET_HEALTH_SUCCEEDED = 'GET_HEALTH_SUCCEEDED';

export const INIT_GOOGLE_PLAY_MARKETING_REQUESTED = 'INIT_GOOGLE_PLAY_MARKETING_REQUESTED';
export const INIT_GOOGLE_PLAY_MARKETING_SUCCEEDED = 'INIT_GOOGLE_PLAY_MARKETING_SUCCEEDED';
export const GET_COUNT_GOOGLE_PLAY_MARKETING_REQUESTED = 'GET_COUNT_GOOGLE_PLAY_MARKETING_REQUESTED';
export const GET_COUNT_GOOGLE_PLAY_MARKETING_SUCCEEDED = 'GET_COUNT_GOOGLE_PLAY_MARKETING_SUCCEEDED';
export const SEARCH_GOOGLE_PLAY_MARKETING_REQUESTED = 'SEARCH_GOOGLE_PLAY_MARKETING_REQUESTED';
export const SEARCH_GOOGLE_PLAY_MARKETING_SUCCEEDED = 'SEARCH_GOOGLE_PLAY_MARKETING_SUCCEEDED';

export const INIT_STEAM_GAMES_REQUESTED = 'INIT_STEAM_GAMES_REQUESTED';
export const INIT_STEAM_GAMES_SUCCEEDED = 'INIT_STEAM_GAMES_SUCCEEDED';
export const SEARCH_STEAM_GAMES_REQUESTED = 'SEARCH_STEAM_GAMES_REQUESTED';
export const SEARCH_STEAM_GAMES_SUCCEEDED = 'SEARCH_STEAM_GAMES_SUCCEEDED';

export const INIT_STEAM_PUBLISHERS_REQUESTED = 'INIT_STEAM_PUBLISHERS_REQUESTED';
export const INIT_STEAM_PUBLISHERS_SUCCEEDED = 'INIT_STEAM_PUBLISHERS_SUCCEEDED';
export const SEARCH_STEAM_PUBLISHERS_REQUESTED = 'SEARCH_STEAM_PUBLISHERS_REQUESTED';
export const SEARCH_STEAM_PUBLISHERS_SUCCEEDED = 'SEARCH_STEAM_PUBLISHERS_SUCCEEDED';

export const INIT_CLOSE_REQUESTED = 'INIT_CLOSE_REQUESTED';
export const INIT_CLOSE_SUCCEEDED = 'INIT_CLOSE_SUCCEEDED';
export const SEARCH_CLOSE_REQUESTED = 'SEARCH_CLOSE_REQUESTED';
export const SEARCH_CLOSE_SUCCEEDED = 'SEARCH_CLOSE_SUCCEEDED';

export const INIT_GOG_REQUESTED = 'INIT_GOG_REQUESTED';
export const INIT_GOG_SUCCEEDED = 'INIT_GOG_SUCCEEDED';
export const SEARCH_GOG_REQUESTED = 'SEARCH_GOG_REQUESTED';
export const SEARCH_GOG_SUCCEEDED = 'SEARCH_GOG_SUCCEEDED';

export const INIT_APP_STORE_REQUESTED = 'INIT_APP_STORE_REQUESTED';
export const INIT_APP_STORE_SUCCEEDED = 'INIT_APP_STORE_SUCCEEDED';
export const SEARCH_APP_STORE_REQUESTED = 'SEARCH_APP_STORE_REQUESTED';
export const SEARCH_APP_STORE_SUCCEEDED = 'SEARCH_APP_STORE_SUCCEEDED';

export const INIT_CRUCHBASE_REQUESTED = 'INIT_CRUCHBASE_REQUESTED';
export const INIT_CRUCHBASE_SUCCEEDED = 'INIT_CRUCHBASE_SUCCEEDED';
export const SEARCH_CRUCHBASE_REQUESTED = 'SEARCH_CRUCHBASE_REQUESTED';
export const SEARCH_CRUCHBASE_SUCCEEDED = 'SEARCH_CRUCHBASE_SUCCEEDED';
export const GET_FILTER_DATA_CRUNCHBASE_REQUESTED = 'GET_FILTER_DATA_CRUNCHBASE_REQUESTED';
export const GET_FILTER_DATA_CRUNCHBASE_SUCCEEDED = 'GET_FILTER_DATA_CRUNCHBASE_SUCCEEDED';

export const INIT_EPIC_REQUESTED = 'INIT_EPIC_REQUESTED';
export const INIT_EPIC_SUCCEEDED = 'INIT_EPIC_SUCCEEDED';
export const SEARCH_EPIC_REQUESTED = 'SEARCH_EPIC_REQUESTED';
export const SEARCH_EPIC_SUCCEEDED = 'SEARCH_EPIC_SUCCEEDED';

export const INIT_NINTENDO_REQUESTED = 'INIT_NINTENDO_REQUESTED';
export const INIT_NINTENDO_SUCCEEDED = 'INIT_NINTENDO_SUCCEEDED';
export const SEARCH_NINTENDO_REQUESTED = 'SEARCH_NINTENDO_REQUESTED';
export const SEARCH_NINTENDO_SUCCEEDED = 'SEARCH_NINTENDO_SUCCEEDED';

export const INIT_GOOGLE_PLAY_REQUESTED = 'INIT_GOOGLE_PLAY_SALES_REQUESTED';
export const INIT_GOOGLE_PLAY_SUCCEEDED = 'INIT_GOOGLE_PLAY_SALES_SUCCEEDED';
export const GET_COUNT_PLAY_REQUESTED = 'GET_COUNT_PLAY_SALES_REQUESTED';
export const GET_COUNT_GOOGLE_PLAY_SUCCEEDED = 'GET_COUNT_GOOGLE_PLAY_SALES_SUCCEEDED';
export const SEARCH_GOOGLE_PLAY_REQUESTED = 'SEARCH_GOOGLE_PLAY_SALES_REQUESTED';
export const SEARCH_GOOGLE_PLAY_SUCCEEDED = 'SEARCH_GOOGLE_PLAY_SALES_SUCCEEDED';

export const INIT_SUBSCRIBE_LIST_REQUESTED = 'INIT_SUBSCRIBE_LIST_REQUESTED';
export const INIT_SUBSCRIBE_LIST_SUCCEEDED = 'INIT_SUBSCRIBE_LIST_SUCCEEDED';
export const SEARCH_SUBSCRIBE_LIST_SALES_REQUESTED = 'SEARCH_SUBSCRIBE_LIST_SALES_REQUESTED';
export const SEARCH_SUBSCRIBE_LIST_SALES_SUCCEEDED = 'SEARCH_SUBSCRIBE_LIST_SALES_SUCCEEDED';
export const REFRESH_SUBSCRIBE_LIST_REQUESTED = 'REFRESH_SUBSCRIBE_LIST_REQUESTED';
export const REFRESH_SUBSCRIBE_LIST_SUCCEEDED = 'REFRESH_SUBSCRIBE_LIST_SUCCEEDED';
export const SUBSCRIBE_LIST_UPDATE_REQUESTED = 'SUBSCRIBE_LIST_UPDATE_REQUESTED';

export const GET_DECIDE_RECORD_REQUESTED = 'INIT_SUBSCRIBE_LIST_REQUESTED';
export const GET_DECIDE_RECORD_SUCCEEDED = 'INIT_SUBSCRIBE_LIST_SUCCEEDED';
export const REFRESH_DECIDE_RECORD_REQUESTED = 'REFRESH_DECIDE_RECORD_REQUESTED';
export const REFRESH_DECIDE_RECORD_SUCCEEDED = 'REFRESH_DECIDE_RECORD_SUCCEEDED';

export const GET_MAGNET_MESSAGE_REQUESTED = 'GET_MAGNET_MESSAGE_REQUESTED';
export const GET_MAGNET_MESSAGE_SUCCEEDED = 'GET_MAGNET_MESSAGE_SUCCEEDED';

export const INIT_MAGNET_ACCOUNTS_REQUESTED = 'INIT_MAGNET_ACCOUNTS_REQUESTED';
export const SEARCH_MAGNET_ACCOUNTS_REQUESTED = 'SEARCH_MAGNET_ACCOUNTS_REQUESTED';
export const SEARCH_MAGNET_ACCOUNTS_SUCCEEDED = 'SEARCH_MAGNET_ACCOUNTS_SUCCEEDED';
export const INIT_MAGNET_ACCOUNTS_SUCCEEDED = 'INIT_MAGNET_ACCOUNTS_SUCCEEDED';

export const INIT_MAGNET_BLACKLIST_REQUESTED = 'INIT_MAGNET_BLACKLIST_REQUESTED';
export const SEARCH_MAGNET_BLACKLIST_REQUESTED = 'SEARCH_MAGNET_BLACKLIST_REQUESTED';
export const SEARCH_MAGNET_BLACKLIST_SUCCEEDED = 'SEARCH_MAGNET_BLACKLIST_SUCCEEDED';
export const INIT_MAGNET_BLACKLIST_SUCCEEDED = 'INIT_MAGNET_BLACKLIST_SUCCEEDED';

export const INIT_MAGNET_CONTACTS_REQUESTED = 'INIT_MAGNET_CONTACTS_REQUESTED';
export const SEARCH_MAGNET_CONTACTS_REQUESTED = 'SEARCH_MAGNET_CONTACTS_REQUESTED';
export const SEARCH_MAGNET_CONTACTS_SUCCEEDED = 'SEARCH_MAGNET_CONTACTS_SUCCEEDED';
export const INIT_MAGNET_CONTACTS_SUCCEEDED = 'INIT_MAGNET_CONTACTS_SUCCEEDED';
export const UPDATE_MAGNET_CONTACTS_REQUESTED = 'UPDATE_MAGNET_CONTACTS_REQUESTED';
export const UPDATE_MAGNET_CONTACTS_SUCCEEDED = 'UPDATE_MAGNET_CONTACTS_SUCCEEDED';

export const INIT_TWITTER_AUDIENCES_REQUESTED = 'INIT_TWITTER_AUDIENCES_REQUESTED';
export const INIT_TWITTER_AUDIENCES_SUCCEEDED = 'INIT_TWITTER_AUDIENCES_SUCCEEDED';
export const SEARCH_TWITTER_AUDIENCES_REQUESTED = 'SEARCH_TWITTER_AUDIENCES_REQUESTED';
export const SEARCH_TWITTER_AUDIENCES_SUCCEEDED = 'SEARCH_TWITTER_AUDIENCES_SUCCEEDED';

export const INIT_TWITTER_BY_NAME_REQUESTED = 'INIT_TWITTER_BY_NAME_REQUESTED';
export const INIT_TWITTER_BY_NAME_SUCCEEDED = 'INIT_TWITTER_BY_NAME_SUCCEEDED';
export const SEARCH_TWITTER_DONORS_REQUESTED = 'SEARCH_TWITTER_DONORS_REQUESTED';
export const SEARCH_TWITTER_DONORS_SUCCEEDED = 'SEARCH_TWITTER_DONORS_SUCCEEDED';

export const REMOVE_TWITTER_AUDIENCE_REQUEST = 'REMOVE_TWITTER_AUDIENCE_REQUEST';
export const REMOVE_TWITTER_AUDIENCE_SUCCEED = 'REMOVE_TWITTER_AUDIENCE_SUCCEED';

export const INIT_CROWDIN_REQUESTED = 'INIT_CROWDIN_REQUESTED';
export const INIT_CROWDIN_SUCCEEDED = 'INIT_CROWDIN_SUCCEEDED';
export const SEARCH_CROWDIN_REQUESTED = 'SEARCH_CROWDIN_REQUESTED';
export const SEARCH_CROWDIN_SUCCEEDED = 'SEARCH_CROWDIN_SUCCEEDED';

export const INIT_SNOVIO_REQUESTED = 'INIT_SNOVIO_REQUESTED';
export const INIT_SNOVIO_SUCCEEDED = 'INIT_SNOVIO_SUCCEEDED';
export const SEARCH_SNOVIO_REQUESTED = 'SEARCH_SNOVIO_REQUESTED';
export const SEARCH_SNOVIO_SUCCEEDED = 'SEARCH_SNOVIO_SUCCEEDED';

export const GET_COUNT_ITCH_GAMES_REQUESTED = 'GET_COUNT_ITCH_GAMES_REQUESTED';
export const GET_COUNT_ITCH_GAMES_SUCCEEDED = 'GET_COUNT_ITCH_GAMES_SUCCEEDED';

export const GET_COUNT_ITCH_AUTHORS_REQUESTED = 'GET_COUNT_ITCH_AUTHORS_REQUESTED';
export const GET_COUNT_ITCH_AUTHORS_SUCCEEDED = 'GET_COUNT_ITCH_AUTHORS_SUCCEEDED';

export const INIT_EU_STARTUPS_REQUESTED = 'INIT_EU_STARTUPS_REQUESTED';
export const INIT_EU_STARTUPS_SUCCEEDED = 'INIT_EU_STARTUPS_SUCCEEDED';
export const SEARCH_EU_STARTUPS_REQUESTED = 'SEARCH_EU_STARTUPS_REQUESTED';
export const SEARCH_EU_STARTUPS_SUCCEEDED = 'SEARCH_EU_STARTUPS_SUCCEEDED';

export const INIT_MEET_TO_MATCH_REQUESTED = 'INIT_MEET_TO_MATCH_REQUESTED';
export const INIT_MEET_TO_MATCH_SUCCEEDED = 'INIT_MEET_TO_MATCH_SUCCEEDED';
export const SEARCH_MEET_TO_MATCH_REQUESTED = 'SEARCH_MEET_TO_MATCH_REQUESTED';
export const SEARCH_MEET_TO_MATCH_SUCCEEDED = 'SEARCH_MEET_TO_MATCH_SUCCEEDED';

export const INIT_MEET_TO_MATCH_CONFERENCE_REQUESTED = 'INIT_MEET_TO_MATCH_CONFERENCE_REQUESTED';
export const INIT_MEET_TO_MATCH_CONFERENCE_SUCCEEDED = 'INIT_MEET_TO_MATCH_CONFERENCE_SUCCEEDED';
export const SEARCH_MEET_TO_MATCH_CONFERENCE_REQUESTED = 'SEARCH_MEET_TO_MATCH_CONFERENCE_REQUESTED';
export const SEARCH_MEET_TO_MATCH_CONFERENCE_SUCCEEDED = 'SEARCH_MEET_TO_MATCH_CONFERENCE_SUCCEEDED';