import * as types from "./constants";

export function requestInitHref() {
    return {
        type: types.REQUEST_INIT_HREF
    };
}

export function succeedInitHref(response) {
    return {
        type: types.SUCCEED_INIT_HREF,
        response
    };
}

export function requestSearchHref(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_HREF,
        limit, offset
    };
}

export function succeedSearchHref(response) {
    return {
        type: types.SUCCEED_SEARCH_HREF,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_SEARCH_LANGUAGES_HREF,
        search
    };
}

export function setHrefSelectedLanguage(selectedLanguage) {
    return {
        type: types.SET_SELECTED_LANGUAGES_HREF,
        selectedLanguage
    };
}

export function setHrefSelectedSource(selectedSource) {
    return {
        type: types.SET_SELECTED_SOURCE_HREF,
        selectedSource
    };
}

export function setHrefLanguageCount(languagesCount) {
    return {
        type: types.SET_LANGUAGE_COUNT_HREF,
        languagesCount
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_HREF,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_HREF,
        lastUpdate
    };
}

export function setFilterData(response) {
    return {
        type: types.SET_HREF_FILTER_DATA,
        response
    };
}

export function requestGetCountHref() {
    return {
        type: types.REQUEST_GET_COUNT_HREF,
    };
}

export function succeedGetCountHref(count, from) {
    return {
        type: types.SUCCEED_GET_COUNT_HREF,
        count,
        from
    };
}

export function requestGetCountEmailsAndTwittersHref() {
    return {
        type: types.REQUEST_GET_COUNT_EMAILS_AND_TWITTERS_HREF,
    };
}

export function succeedGetCountEmailsAndTwittersHref(emails,twitters) {
    return {
        type: types.SUCCEED_GET_COUNT_EMAILS_AND_TWITTERS_HREF,
        emails, twitters
    };
}

export function setIsOnlyChosenLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_LANGUAGES_HREF,
        isOnlyChosenLanguages
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_HREF,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_HREF,
        isFiltersSelected
    };
}
