import * as types from "./constants";

export function requestInitAppStore() {
    return {
        type: types.REQUEST_INIT_APP_STORE
    };
}

export function succeedInitAppStore(response) {
    return {
        type: types.SUCCEED_INIT_APP_STORE,
        response
    };
}

export function requestRefreshAppStore() {
    return {
        type: types.REQUEST_REFRESH_APP_STORE
    };
}

export function succeedRefreshAppStore(response) {
    return {
        type: types.SUCCEED_REFRESH_APP_STORE,
        response
    };
}

export function requestSearchAppStore(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_APP_STORE,
        limit, offset
    };
}

export function succeedSearchAppStore(response) {
    return {
        type: types.SUCCEED_SEARCH_APP_STORE,
        response
    };
}

export function requestLastUpdateappStoreLastUpdate() {
    return {
        type: types.REQUEST_APP_STORE_LAST_UPDATE,
    };
}

export function succeedLastUpdateappStoreLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_APP_STORE_LAST_UPDATE,
        lastUpdate
    };
}

export function setSearch(search) {
    return {
        type: types.SET_APP_STORE_SEARCH,
        search
    }
}

export function setSelectedLanguage(selectedLanguage) {
    return {
        type: types.SET_SELECTED_LANGUAGES_APP_STORE,
        selectedLanguage
    };
}

export function setSelectedGenres(selectedGenres) {
    return {
        type: types.SET_SELECTED_GENRES_APP_STORE,
        selectedGenres
    };
}

export function setLanguageCount(languagesCount) {
    return {
        type: types.SET_LANGUAGE_COUNT_APP_STORE,
        languagesCount
    };
}

export function setCountryCount(countriesCount) {
    return {
        type: types.SET_COUNTRY_COUNT_APP_STORE,
        countriesCount
    };
}

export function setReleased(released) {
    return {
        type: types.SET_APP_STORE_RELEASED,
        released
    };
}

export function setUpdated(updated) {
    return {
        type: types.SET_APP_STORE_UPDATED,
        updated
    };
}

export function setReviews(reviews) {
    return {
        type: types.SET_APP_STORE_REVIEWS,
        reviews
    };
}

export function setScore(score) {
    return {
        type: types.SET_APP_STORE_SCORE,
        score
    };
}

export function requestGetFilterData() {
    return {
        type: types.REQUEST_APP_STORE_FILTER_DATA,
    };
}

export function succeedGetFilterData(response, isInsightsRequest) {
    return {
        type: types.SUCCEED_APP_STORE_FILTER_DATA,
        response, isInsightsRequest
    };
}

export function clearFiltersAppStore() {
    return {
        type: types.CLEAR_FILTERS_APP_STORE,
    };
}

export function setCountry(selectedCountry) {
    return {
        type: types.SET_APP_STORE_COUNTRY,
        selectedCountry
    };
}

export function requestGetCountAppStore() {
    return {
        type: types.REQUEST_GET_COUNT_APP_STORE,
    };
}

export function succeedGetCountAppStore(count) {
    return {
        type: types.SUCCEED_GET_COUNT_APP_STORE,
        count
    };
}

export function requestGetCountDevelopersAndEmailsAppStore() {
    return {
        type: types.REQUEST_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE
    };
}

export function succeedGetCountDevelopersAndEmailsAppStore(count) {
    return {
        type: types.SUCCEED_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE,
        count
    };
}

export function setHasVideo(hasVideo) {
    return {
        type: types.SET_APP_STORE_HAS_VIDEO,
        hasVideo
    };
}

export function setIsOnlyChosenMarkets(isOnlyChosenMarkets) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_MARKETS_APP_STORE,
        isOnlyChosenMarkets
    };
}

export function setIsOnlyChosenLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_LANGUAGES_APP_STORE,
        isOnlyChosenLanguages
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_APP_STORE,
        isFiltersSelected
    };
}

export function setAppCount(appCount) {
    return {
        type: types.SET_APP_STORE_APP_COUNT,
        appCount
    };
}
export function setPrice(selectedPrice) {
    return {
        type: types.SET_APP_STORE_PRICE,
        selectedPrice
    };
}