import React, {Component} from 'react';
import classNames from 'classnames';

const DESC = 'desc';
const ASC = 'asc';

class SortingTitle extends Component {

    static propTypes = {
    };

    getOrder = () => {
        const {columnId, sortField, sortOrder} = this.props;
        return sortField === columnId && sortOrder === DESC ? ASC : DESC;
    };

    onChangeSort = (e) => {
        const {columnId, onClick} = this.props;
        e.preventDefault();
        e.stopPropagation();

        const order = this.getOrder();
        onClick && onClick(columnId, order);
    }

    render() {
        const {columnId, value, sortOrder, sortField} = this.props;
        const isSort = columnId === sortField;
        const isCanSort = columnId !== sortField;

        const sortColumnClassName = classNames("sort-column", {
            'sort-field': isSort,
        });

        const sortArrowClassName = classNames("sort-column-icon", {
            'can-sort-icon': isCanSort,
            'asc-icon': isSort && sortOrder === 'asc',
            'desc-icon': isSort &&  sortOrder === 'desc',
        });

        return (
            <div className={sortColumnClassName} onClick={this.onChangeSort}>
                {value}
                <div className={sortArrowClassName}/>
            </div>
        );
    }
}

export default (SortingTitle);