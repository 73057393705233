import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';

import Button from "../Button";

import {showNotificationComplete} from "../../store/actions/notification";

import './css/snackbar.css';

export const authNotification = () => {
    const handleClick = () => {
        window.location = '/login';
    }

    return <div className='auth-error__container'>
        <div className='auth-error__title'>An email must belong to the domain alconost.com</div>
        <Button className='auth-error__button' title='Try again' onClick={handleClick}/>
    </div>
}

class Snackbar extends Component {

    static propTypes = {
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {notification: {display}} = this.props;

        if (display) {
            this.onHideNotification();
        }
    }

    onHideNotification = () => {
        const {notification: {timer}, showNotificationComplete} = this.props;
        if (timer) {
            setTimeout(() => {
                showNotificationComplete();
            }, timer);
        }
    }

    handleCloseNotification = () => {
        const {showNotificationComplete} = this.props;
        showNotificationComplete();
    }

    render() {
        const {notification} = this.props;
        const {display, typeNotification, customNotification, msg} = notification;

        const containerClassNames = classNames("snackbar__container position content", typeNotification, {
            "show": display
        });

        const renderNotification = customNotification ? customNotification() : msg;

        return (
            <div className={containerClassNames}>
                <div className="title-error">{renderNotification}</div>
                <div className="close-button" onClick={this.handleCloseNotification}>
                    <div className="close-button-icon"/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationComplete: () => dispatch(showNotificationComplete())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Snackbar)
