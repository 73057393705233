import React, {useEffect, useRef, useState} from "react";
import classNames from 'classnames';
import { usePopper } from "react-popper";
import map from 'lodash/map';

import useOnClickOutside from "../../hooks/useOnClickOutside";

import RenameInput from "../Input/RenameInput";

import './css/header-with-settings.css';

function HeaderWithSettings({classNamePopper, name, onDelete, onEdit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(name);
    const settingsContainer = useRef();
    const popoverRef = useRef();
    const valueRef = useRef();
    useOnClickOutside(settingsContainer, () => setIsOpen(false));

    const { styles, attributes, update } = usePopper(
        valueRef.current,
        popoverRef.current,
        {
            placement: "bottom-start",
            modifiers: [
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [0, 8]
                    }
                }
            ]
        }
    );

    useEffect(() => {
        update && update();
    }, [value, update])

    const onSettingsClick = () => {
        setIsOpen(!isOpen);
    };

    const onSettingClick = (onClick) => {
        setIsOpen(false);
        onClick();
    };

    const onSettingsEdit = () => {
        setIsOpen(false);
        setIsEdit(true);
    };

    const onEditName = (e, nemValue) => {
        setIsEdit(false);
        setValue(nemValue);
        if (nemValue !== value) {
            onEdit && onEdit(e, nemValue);
        }
    };

    const getMenuItems = () => {
        // временно убрали удаление аудитории
        const settingItems = [
            {name: <div className="twitter-audience__settings-item"><div className="rename"/> Rename</div>, onClick: onSettingsEdit},
            /*{name: <div className="twitter-audience__settings-item"><div className="delete"/> Delete</div>, onClick: onDelete},*/
        ];

        return map(settingItems, (settingItem, index) => {
            const {name, onClick} = settingItem;
            return (
                <div key={index} className="header-with-settings__settings-item" onClick={() => onSettingClick(onClick)}>
                    {name}
                </div>
            )
        });
    };

    const renderItems = getMenuItems();

    const settingsButtonClassName = classNames("header-with-settings__settings-button", {
        "header-with-settings__settings-button_active": isOpen
    });

    const popoverClassName = classNames("header-with-settings__settings", classNamePopper, {
        "header-with-settings__settings_display": isOpen
    });

    return (
        <React.Fragment>
            <div className="header-with-settings">
                {isEdit
                    ? <RenameInput value={value} onEndEdit={onEditName}/>
                    : <div className="header-with-settings__value">{value}</div>
                }
                <div ref={settingsContainer} className="header-with-settings__settings-container">
                    <div ref={valueRef} className={settingsButtonClassName} onClick={onSettingsClick}/>
                    <div ref={popoverRef} className={popoverClassName} style={styles.popper} {...attributes.popper}>
                        {renderItems}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HeaderWithSettings;