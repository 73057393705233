import React, {Component} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import Popover from "../Popover";

import './css/item-select.css';

class ItemSelect extends Component {

    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: '',
            selectedOptions: [],
        }

        this.itemSelectButton = React.createRef();
    }

    onToggleMenu = () => {
        this.setState((state, props) => {
            return {
                isOpen: !state.isOpen
            }
        });
    }

    getRenderButton = () => {
        const {isOpen} = this.state;
        const {renderButton, value} = this.props;
        const props = {
            onClick: this.onToggleMenu,
            value,
            isOpen
        };
        return renderButton(props);
    }

    onItemClick = (id) => {
        const {onChangeWithoutRefresh} = this.props;
        this.onToggleMenu();
        onChangeWithoutRefresh && onChangeWithoutRefresh(id);
    }

    getRenderOptions = () => {
        const {options} = this.props;
        return map(options, option => {
            const {id, name} = option;
            return <div key={id} className="item-select__item" onClick={() => this.onItemClick(id)}>
                {name}
            </div>
        })
    }

    render() {
        const {isOpen} = this.state;
        const renderButton = this.getRenderButton();
        const renderOptions = this.getRenderOptions();
        const anchorEl = this.itemSelectButton && this.itemSelectButton.current;

        return (
            <div className="item-select__container">
                <div className="item-select__button-container" ref={this.itemSelectButton}>
                    {renderButton}
                </div>
                <Popover anchorEl={anchorEl} className="item-select__popover" position='right' open={isOpen} withArrow={true} onClosePopover={this.onToggleMenu}>
                    {renderOptions}
                </Popover>
            </div>
        );
    }
}

export default (ItemSelect);