export const languageOptions = [
    {id: 'aa', en: 'Afar', ru: 'Афарский', ja: 'アファル語' },
    {id: 'ach', en: 'Acholi', ru: 'Ачоли', ja: 'アチョリ語' },
    {id: 'ae', en: 'Avestan', ru: 'Авестийский', ja: 'アヴェスター語' },
    {id: 'af', en: 'Afrikaans', ru: 'Африкаанс', ja: 'アフリカーンス語' },
    {id: 'ak', en: 'Akan', ru: 'Акан', ja: 'アカン語' },
    {id: 'am', en: 'Amharic', ru: 'Амхарский', ja: 'アムハラ語' },
    {id: 'an', en: 'Aragonese', ru: 'Арагонский', ja: 'アラゴン語' },
    {id: 'ar-AR', en: 'Arabic', ru: 'Арабский', ja: 'アラビア語' },
    {id: 'ar-BH', en: 'Arabic (Bahrain)', ru: 'Арабский (Бахрейн)', ja: 'アラビア語 (バーレーン)' },
    {id: 'ar-EG', en: 'Arabic (Egypt)', ru: 'Арабский (Египет)', ja: 'アラビア語 (エジプト)' },
    {id: 'ar-MA', en: 'Arabic (Morocco)', ru: 'Арабский (Марокко)', ja: 'アラビア語 (モロッコ)' },
    {id: 'ar-SA', en: 'Arabic (Saudi Arabia)', ru: 'Арабский (Саудовская Аравия)', ja: 'アラビア語 (サウジアラビア)' },
    {id: 'ar-YE', en: 'Arabic (Yemen)', ru: 'Арабский (Йемен)', ja: 'アラビア語 (イエメン)' },
    {id: 'arn', en: 'Mapudungun', ru: 'Мапуче', ja: 'マプチェ語' },
    {id: 'as', en: 'Assamese', ru: 'Ассамский', ja: 'アッサム語' },
    {id: 'ast', en: 'Asturian', ru: 'Астурийский', ja: 'アストゥリアス語' },
    {id: 'av', en: 'Avaric', ru: 'Аварский', ja: 'アヴァル語' },
    {id: 'ay', en: 'Aymara', ru: 'Аймара', ja: 'アイマラ語' },
    {id: 'az', en: 'Azerbaijani', ru: 'Азербайджанский', ja: 'アゼルバイジャン語' },
    {id: 'ba', en: 'Bashkir', ru: 'Башкирский', ja: 'バシキール語' },
    {id: 'bal', en: 'Balochi', ru: 'Белуджский', ja: 'バローチー語' },
    {id: 'ban', en: 'Balinese', ru: 'Балийский', ja: 'バリ語' },
    {id: 'be', en: 'Belarusian', ru: 'Белорусский', ja: 'ベラルーシ語' },
    {id: 'ber', en: 'Berber', ru: 'Берберские', ja: 'ベルベル語' },
    {id: 'bfo', en: 'Birifor', ru: 'Бирифор', ja: 'ビリフォー語' },
    {id: 'bg', en: 'Bulgarian', ru: 'Болгарский', ja: 'ブルガリア語' },
    {id: 'bh', en: 'Bihari', ru: 'Бихарский', ja: 'ビハール語' },
    {id: 'bi', en: 'Bislama', ru: 'Бислама', ja: 'ビスラマ語' },
    {id: 'bm', en: 'Bambara', ru: 'Бамана', ja: 'バンバラ語' },
    {id: 'bn-BN', en: 'Bengali', ru: 'Бенгальский', ja: 'ベンガル語' },
    {id: 'bn-IN', en: 'Bengali (India)', ru: 'Бенгальский (Индия)', ja: 'ベンガル語 (インド)' },
    {id: 'bo-BT', en: 'Tibetan', ru: 'Тибетский', ja: 'チベット語' },
    {id: 'br-FR', en: 'Breton', ru: 'Бретонский', ja: 'ブルトン語' },
    {id: 'bs', en: 'Bosnian', ru: 'Боснийский', ja: 'ボスニア語' },
    {id: 'ca', en: 'Catalan', ru: 'Каталонский', ja: 'カタロニア語' },
    {id: 'ce', en: 'Chechen', ru: 'Чеченский', ja: 'チェチェン語' },
    {id: 'ceb', en: 'Cebuano', ru: 'Себуанский', ja: 'セブアノ語' },
    {id: 'ch', en: 'Chamorro', ru: 'Чаморро', ja: 'チャモロ語' },
    {id: 'chr', en: 'Cherokee', ru: 'Чероки', ja: 'チェロキー語' },
    {id: 'ckb', en: 'Sorani (Kurdish)', ru: 'Сорани (Курдский)', ja: 'ソラニー (クルド語)' },
    {id: 'co', en: 'Corsican', ru: 'Корсиканский', ja: 'コルシカ語' },
    {id: 'cr', en: 'Cree', ru: 'Кри', ja: 'クリー語' },
    {id: 'crs', en: 'Seychellois Creole', ru: 'Сейшельский (Креольский)', ja: 'セーシェル・クレオール語' },
    {id: 'cs', en: 'Czech', ru: 'Чешский', ja: 'チェコ語' },
    {id: 'csb', en: 'Kashubian', ru: 'Кашубский', ja: 'カシューブ語' },
    {id: 'cv', en: 'Chuvash', ru: 'Чувашский', ja: 'チュヴァシ語' },
    {id: 'cy', en: 'Welsh', ru: 'Валлийский', ja: 'ウェールズ語' },
    {id: 'da', en: 'Danish', ru: 'Датский', ja: 'デンマーク語' },
    {id: 'de-DE', en: 'German', ru: 'Немецкий', ja: 'ドイツ語' },
    {id: 'de-AT', en: 'German (Austria)', ru: 'Немецкий (Австрия)', ja: 'ドイツ語 (オーストリア)' },
    {id: 'de-BE', en: 'German (Belgium)', ru: 'Немецкий (Бельгия)', ja: 'ドイツ語 (ベルギー)' },
    {id: 'de-CH', en: 'German (Switzerland)', ru: 'Немецкий (Швейцария)', ja: 'ドイツ語 (スイス)' },
    {id: 'de-LI', en: 'German (Liechtenstein)', ru: 'Немецкий (Лихтенштейн)', ja: 'ドイツ語 (リヒテンシュタイン)' },
    {id: 'de-LU', en: 'German (Luxembourg)', ru: 'Немецкий (Люксембург)', ja: 'ドイツ語 (ルクセンブルク)' },
    {id: 'dsb-DE', en: 'Lower Sorbian', ru: 'Нижнелужицкий', ja: '低地ソルブ語' },
    {id: 'dv', en: 'Dhivehi', ru: 'Мальдивский', ja: 'ディベヒ語' },
    {id: 'dz', en: 'Dzongkha', ru: 'Дзонг-кэ', ja: 'ゾンカ語' },
    {id: 'ee', en: 'Ewe', ru: 'Эве', ja: 'エウェ語' },
    {id: 'el-EL', en: 'Greek', ru: 'Греческий', ja: 'ギリシャ語' },
    {id: 'el-CY', en: 'Greek (Cyprus)', ru: 'Греческий (Кипр)', ja: 'ギリシャ語 (キプロス)' },
    {id: 'en-EN', en: 'English', ru: 'Английский', ja: '英語' },
    {id: 'en-AR', en: 'English (Arabia)', ru: 'Английский (Аравия)', ja: '英語 (アラビア)' },
    {id: 'en-AU', en: 'English (Australia)', ru: 'Английский (Австралия)', ja: '英語 (オーストラリア)' },
    {id: 'en-BZ', en: 'English (Belize)', ru: 'Английский (Белиз)', ja: '英語 (ベリーズ)' },
    {id: 'en-CA', en: 'English (Canada)', ru: 'Английский (Канада)', ja: '英語 (カナダ)' },
    {id: 'en-CB', en: 'English (Caribbean)', ru: 'Английский (Карибский)', ja: '英語 (カリブ海)' },
    {id: 'en-CN', en: 'English (China)', ru: 'Английский (Китай)', ja: '英語 (中国)' },
    {id: 'en-DK', en: 'English (Denmark)', ru: 'Английский (Дания)', ja: '英語 (デンマーク)' },
    {id: 'en-GB', en: 'English (United Kingdom)', ru: 'Английский (Великобритания)', ja: '英語 (イギリス)' },
    {id: 'en-HK', en: 'English (Hong Kong)', ru: 'Английский (Гонконг)', ja: '英語 (香港)' },
    {id: 'en-ID', en: 'English (Indonesia)', ru: 'Английский (Индонезия)', ja: '英語 (インドネシア)' },
    {id: 'en-IE', en: 'English (Ireland)', ru: 'Английский (Ирландия)', ja: '英語 (アイルランド)' },
    {id: 'en-IN', en: 'English (India)', ru: 'Английский (Индия)', ja: '英語 (インド)' },
    {id: 'en-JA', en: 'English (Japan)', ru: 'Английский (Япония)', ja: '英語 (日本)' },
    {id: 'en-JM', en: 'English (Jamaica)', ru: 'Английский (Ямайка)', ja: '英語 (ジャマイカ)' },
    {id: 'en-MY', en: 'English (Malaysia)', ru: 'Английский (Малайзия)', ja: '英語 (マレーシア)' },
    {id: 'en-NO', en: 'English (Norway)', ru: 'Английский (Норвегия)', ja: '英語 (ノルウェー)' },
    {id: 'en-NZ', en: 'English (New Zealand)', ru: 'Английский (Новая Зеландия)', ja: '英語 (ニュージーランド)' },
    {id: 'en-PH', en: 'English (Philippines)', ru: 'Английский (Филиппины)', ja: '英語 (フィリピン)' },
    {id: 'en-PR', en: 'English (Puerto Rico)', ru: 'Английский (Пуэрто-Рико)', ja: '英語 (プエルトリコ)' },
    {id: 'en-SE', en: 'English (Sweden)', ru: 'Английский (Швеция)', ja: '英語 (スウェーデン)' },
    {id: 'en-SG', en: 'English (Singapore)', ru: 'Английский (Сингапур)', ja: '英語 (シンガポール)' },
    {id: 'en-US', en: 'English (United States)', ru: 'Английский (Соединенные Штаты)', ja: '英語 (アメリカ合衆国)' },
    {id: 'en-ZA', en: 'English (South Africa)', ru: 'Английский (Южная Африка)', ja: '英語 (南アフリカ)' },
    {id: 'en-ZW', en: 'English (Zimbabwe)', ru: 'Английский (Зимбабве)', ja: '英語 (ジンバブエ)' },
    {id: 'eo', en: 'Esperanto', ru: 'Эсперанто', ja: 'エスペラント語' },
    {id: 'es-AR', en: 'Spanish (Argentina)', ru: 'Испанский (Аргентина)', ja: 'スペイン語 (アルゼンチン)' },
    {id: 'es-BO', en: 'Spanish (Bolivia)', ru: 'Испанский (Боливия)', ja: 'スペイン語 (ボリビア)' },
    {id: 'es-CL', en: 'Spanish (Chile)', ru: 'Испанский (Чили)', ja: 'スペイン語 (チリ)' },
    {id: 'es-CO', en: 'Spanish (Colombia)', ru: 'Испанский (Колумбия)', ja: 'スペイン語 (コロンビア)' },
    {id: 'es-CR', en: 'Spanish (Costa Rica)', ru: 'Испанский (Коста-Рика)', ja: 'スペイン語 (コスタリカ)' },
    {id: 'es-DO', en: 'Spanish (Dominican Republic)', ru: 'Испанский (Доминиканская Республика)', ja: 'スペイン語 (ドミニカ共和国)' },
    {id: 'es-EC', en: 'Spanish (Ecuador)', ru: 'Испанский (Эквадор)', ja: 'スペイン語 (エクアドル)' },
    {id: 'es-ES', en: 'Spanish', ru: 'Испанский', ja: 'スペイン語' },
    {id: 'es-GT', en: 'Spanish (Guatemala)', ru: 'Испанский (Гватемала)', ja: 'スペイン語 (グアテマラ)' },
    {id: 'es-HN', en: 'Spanish (Honduras)', ru: 'Испанский (Гондурас)', ja: 'スペイン語 (ホンジュラス)' },
    {id: 'es-MX', en: 'Spanish (Mexico)', ru: 'Испанский (Мексика)', ja: 'スペイン語 (メキシコ)' },
    {id: 'es-NI', en: 'Spanish (Nicaragua)', ru: 'Испанский (Никарагуа)', ja: 'スペイン語 (ニカラグア)' },
    {id: 'es-PA', en: 'Spanish (Panama)', ru: 'Испанский (Панама)', ja: 'スペイン語 (パナマ)' },
    {id: 'es-PE', en: 'Spanish (Peru)', ru: 'Испанский (Перу)', ja: 'スペイン語 (ペルー)' },
    {id: 'es-PR', en: 'Spanish (Puerto Rico)', ru: 'Испанский (Пуэрто-Рико)', ja: 'スペイン語 (プエルトリコ)' },
    {id: 'es-PY', en: 'Spanish (Paraguay)', ru: 'Испанский (Парагвай)', ja: 'スペイン語 (パラグアイ)' },
    {id: 'es-SV', en: 'Spanish (El Salvador)', ru: 'Испанский (Сальвадор)', ja: 'スペイン語 (エルサルバドル)' },
    {id: 'es-US', en: 'Spanish (United States)', ru: 'Испанский (Соединенные Штаты)', ja: 'スペイン語 (アメリカ合衆国)' },
    {id: 'es-UY', en: 'Spanish (Uruguay)', ru: 'Испанский (Уругвай)', ja: 'スペイン語 (ウルグアイ)' },
    {id: 'es-VE', en: 'Spanish (Venezuela)', ru: 'Испанский (Венесуэла)', ja: 'スペイン語 (ベネズエラ)' },
    {id: 'et', en: 'Estonian', ru: 'Эстонский', ja: 'エストニア語' },
    {id: 'eu', en: 'Basque', ru: 'Баскский', ja: 'バスク語' },
    {id: 'fa-FA', en: 'Persian (Farsi)', ru: 'Персидский (Фарси)', ja: 'ペルシア語 (ファールシー)' },
    {id: 'fa-AF', en: 'Dari', ru: 'Дари', ja: 'ダリー語' },
    {id: 'ff', en: 'Fula', ru: 'Фула', ja: 'フラ語' },
    {id: 'fi', en: 'Finnish', ru: 'Финский', ja: 'フィンランド語' },
    {id: 'fil', en: 'Filipino', ru: 'Филиппинский', ja: 'フィリピン語' },
    {id: 'fj', en: 'Fijian', ru: 'Фиджийский', ja: 'フィジー語' },
    {id: 'fo', en: 'Faroese', ru: 'Фарерский', ja: 'フェロー語' },
    {id: 'fr-FR', en: 'French', ru: 'Французский', ja: 'フランス語' },
    {id: 'fr-BE', en: 'French (Belgium)', ru: 'Французский (Бельгия)', ja: 'フランス語 (ベルギー)' },
    {id: 'fr-CA', en: 'French (Canada)', ru: 'Французский (Канада)', ja: 'フランス語 (カナダ)' },
    {id: 'fr-CH', en: 'French (Switzerland)', ru: 'Французский (Швейцария)', ja: 'フランス語 (スイス)' },
    {id: 'fr-LU', en: 'French (Luxembourg)', ru: 'Французский (Люксембург)', ja: 'フランス語 (ルクセンブルク)' },
    {id: 'fr-QC', en: 'French (Quebec)', ru: 'Французский (Квебек)', ja: 'フランス語 (ケベック)' },
    {id: 'fra-DE', en: 'Franconian', ru: 'Франконский', ja: '古フランク語' },
    {id: 'frp', en: 'Arpitan', ru: 'Франкопровансальский (Арпитанский)', ja: 'アルピタン語' },
    {id: 'fur-IT', en: 'Friulian', ru: 'Фриульский', ja: 'フリウリ語' },
    {id: 'fy-NL', en: 'Frisian', ru: 'Западнофризский', ja: 'フリジア語' },
    {id: 'ga-IE', en: 'Irish', ru: 'Ирландский', ja: 'アイルランド語' },
    {id: 'gaa', en: 'Ga', ru: 'Га', ja: 'ガ語' },
    {id: 'gd', en: 'Scottish Gaelic', ru: 'Шотландский (Кельтский)', ja: 'スコットランド・ゲール語' },
    {id: 'gl', en: 'Galician', ru: 'Галисийский', ja: 'ガリシア語' },
    {id: 'gn', en: 'Guarani', ru: 'Гуарани', ja: 'グアラニー語' },
    {id: 'got', en: 'Gothic', ru: 'Готский', ja: 'ゴート語' },
    {id: 'gu-IN', en: 'Gujarati', ru: 'Гуджарати', ja: 'グジャラート語' },
    {id: 'gv', en: 'Manx', ru: 'Мэнский', ja: 'マン島語' },
    {id: 'ha', en: 'Hausa', ru: 'Хауса', ja: 'ハウサ語' },
    {id: 'haw', en: 'Hawaiian', ru: 'Гавайский', ja: 'ハワイ語' },
    {id: 'he', en: 'Hebrew', ru: 'Иврит', ja: 'ヘブライ語' },
    {id: 'hi', en: 'Hindi', ru: 'Хинди', ja: 'ヒンディー語' },
    {id: 'hil', en: 'Hiligaynon', ru: 'Хилигайнон', ja: 'ヒリガイノン語' },
    {id: 'hmn', en: 'Hmong', ru: 'Хмонг', ja: 'ミャオ語' },
    {id: 'ho', en: 'HiriMotu', ru: 'Хири-моту', ja: 'ヒリモツ語' },
    {id: 'hr', en: 'Croatian', ru: 'Хорватский', ja: 'クロアチア語' },
    {id: 'hsb-DE', en: 'Upper Sorbian', ru: 'Верхнелужицкий', ja: '高地ソルブ語' },
    {id: 'ht', en: 'Haitian Creole', ru: 'Гаитянский креольский', ja: 'ハイチ語' },
    {id: 'hu', en: 'Hungarian', ru: 'Венгерский', ja: 'ハンガリー語' },
    {id: 'hy-AM', en: 'Armenian', ru: 'Армянский', ja: 'アルメニア語' },
    {id: 'hz', en: 'Herero', ru: 'Гереро', ja: 'ヘレロ語' },
    {id: 'id', en: 'Indonesian', ru: 'Индонезийский', ja: 'インドネシア語' },
    {id: 'ido', en: 'Ido', ru: 'Идо', ja: 'イド語' },
    {id: 'ig', en: 'Igbo', ru: 'Игбо', ja: 'イボ語' },
    {id: 'ii', en: 'SichuanYi', ru: 'Носу (Сычуаньский И)', ja: '四川彝語' },
    {id: 'ilo', en: 'Ilokano', ru: 'Илоканский', ja: 'イロカノ語' },
    {id: 'is', en: 'Icelandic', ru: 'Исландский', ja: 'アイスランド語' },
    {id: 'it', en: 'Italian', ru: 'Итальянский', ja: 'イタリア語' },
    {id: 'it-CH', en: 'Italian (Switzerland)', ru: 'Итальянский (Швейцария)', ja: 'イタリア語 (スイス)' },
    {id: 'iu', en: 'Inuktitut', ru: 'Инуктитут', ja: 'イヌクティトゥット語' },
    {id: 'ja', en: 'Japanese', ru: 'Японский', ja: '日本語' },
    {id: 'jbo', en: 'Lojban', ru: 'Ложбан', ja: 'ロジバン' },
    {id: 'jv', en: 'Javanese', ru: 'Яванский', ja: 'ジャワ語' },
    {id: 'ka', en: 'Georgian', ru: 'грузинский', ja: 'ジョージア語' },
    {id: 'kab', en: 'Kabyle', ru: 'Кабильский', ja: 'カビル語' },
    {id: 'kdh', en: 'Tem (Kotokoli)', ru: 'Тем (Котоколи)', ja: 'テム語 (コトコリ)' },
    {id: 'kg', en: 'Kongo', ru: 'Конго', ja: 'コンゴ語' },
    {id: 'kj', en: 'Kwanyama', ru: 'Кваньяма', ja: 'クワニャマ語' },
    {id: 'kk', en: 'Kazakh', ru: 'Казахский', ja: 'カザフ語' },
    {id: 'kl', en: 'Greenlandic', ru: 'Гренландский', ja: 'グリーンランド語' },
    {id: 'km', en: 'Khmer', ru: 'Кхмерский', ja: 'クメール語' },
    {id: 'kmr', en: 'Kurmanji (Kurdish)', ru: 'Курманджи (Курдский)', ja: 'クルマンジー (クルド語)' },
    {id: 'kn', en: 'Kannada', ru: 'Каннада', ja: 'カンナダ語' },
    {id: 'ko', en: 'Korean', ru: 'Корейский', ja: '韓国語' },
    {id: 'kok', en: 'Konkani', ru: 'Конкани', ja: 'コンカニ語' },
    {id: 'ks', en: 'Kashmiri', ru: 'Кашмирский', ja: 'カシミール語' },
    {id: 'ks-PK', en: 'Kashmiri (Pakistan)', ru: 'Кашмирский (Пакистан)', ja: 'カシミール語 (パキスタン)' },
    {id: 'ku', en: 'Kurdish', ru: 'Курдский', ja: 'クルド語' },
    {id: 'kv', en: 'Komi', ru: 'Коми', ja: 'コミ語' },
    {id: 'kw', en: 'Cornish', ru: 'Корнский', ja: 'コーンウォール語' },
    {id: 'ky', en: 'Kyrgyz', ru: 'Киргизский', ja: 'キルギス語' },
    {id: 'la-LA', en: 'Latin', ru: 'Латынь (Латинский)', ja: 'ラテン語' },
    {id: 'lb', en: 'Luxembourgish', ru: 'Люксембургский', ja: 'ルクセンブルク語' },
    {id: 'lg', en: 'Luganda', ru: 'Луганда', ja: 'ルガンダ語' },
    {id: 'li', en: 'Limburgish', ru: 'Лимбургский', ja: 'リンブルフ語' },
    {id: 'lij', en: 'Ligurian', ru: 'Лигурский', ja: 'リグリア語' },
    {id: 'ln', en: 'Lingala', ru: 'Лингала', ja: 'リンガラ語' },
    {id: 'lo', en: 'Lao', ru: 'Лаосский', ja: 'ラーオ語' },
    {id: 'lt', en: 'Lithuanian', ru: 'Литовский', ja: 'リトアニア語' },
    {id: 'luy', en: 'Luhya', ru: 'Лухья', ja: 'ルヒヤ語' },
    {id: 'lv', en: 'Latvian', ru: 'Латышский', ja: 'ラトビア語' },
    {id: 'mai', en: 'Maithili', ru: 'Майтхили', ja: 'マイティリー語' },
    {id: 'me', en: 'Montenegrin (Latin)', ru: 'Черногорский (латиница)', ja: 'モンテネグロ語 (ラテン文字)' },
    {id: 'mg', en: 'Malagasy', ru: 'Малагасийский', ja: 'マダガスカル語' },
    {id: 'mh', en: 'Marshallese', ru: 'Маршалльский', ja: 'マーシャル語' },
    {id: 'mi', en: 'Maori', ru: 'Маори', ja: 'マオリ語' },
    {id: 'mk', en: 'Macedonian', ru: 'Македонский', ja: 'マケドニア語' },
    {id: 'ml-IN', en: 'Malayalam', ru: 'Малаялам', ja: 'マラヤーラム語' },
    {id: 'mn', en: 'Mongolian', ru: 'Монгольский', ja: 'モンゴル語' },
    {id: 'moh', en: 'Mohawk', ru: 'Могаукский', ja: 'モホーク語' },
    {id: 'mos', en: 'Mossi', ru: 'Мооре', ja: 'モシ語' },
    {id: 'mr', en: 'Marathi', ru: 'Маратхи', ja: 'マラーティー語' },
    {id: 'ms-MS', en: 'Malay', ru: 'Малайский', ja: 'マレー語' },
    {id: 'ms-BN', en: 'Malay (Brunei)', ru: 'Малайский (Бруней)', ja: 'マレー語 (ブルネイ)' },
    {id: 'mt', en: 'Maltese', ru: 'Мальтийский', ja: 'マルタ語' },
    {id: 'my', en: 'Burmese', ru: 'Бирманский', ja: 'ビルマ語' },
    {id: 'na', en: 'Nauru', ru: 'Науруанский', ja: 'ナウル語' },
    {id: 'nb', en: 'Norwegian Bokmal', ru: 'Норвежский букмол', ja: 'ノルウェー語 (ブークモール)' },
    {id: 'nds', en: 'Low German', ru: 'Нижненемецкий', ja: '低地ドイツ語' },
    {id: 'ne-IN', en: 'Nepali (India)', ru: 'Непальский (Индия)', ja: 'ネパール語 (インド)' },
    {id: 'ne-NP', en: 'Nepali', ru: 'Непальский', ja: 'ネパール語' },
    {id: 'ng', en: 'Ndonga', ru: 'Ндонга', ja: 'ンドンガ語' },
    {id: 'nl-NL', en: 'Dutch', ru: 'Голландский', ja: 'オランダ語' },
    {id: 'nl-BE', en: 'Dutch (Belgium)', ru: 'Голландский (Бельгия)', ja: 'オランダ語 (ベルギー)' },
    {id: 'nl-SR', en: 'Dutch (Suriname)', ru: 'Голландский (Суринам)', ja: 'オランダ語 (スリナム)' },
    {id: 'nn-NO', en: 'Norwegian Nynorsk', ru: 'Новонорвежски', ja: 'ノルウェー語 (ニーノシュク)' },
    {id: 'no', en: 'Norwegian', ru: 'Норвежский', ja: 'ノルウェー語' },
    {id: 'nr', en: 'Southern Ndebele', ru: 'Южный Ндебеле', ja: '南ンデベレ語' },
    {id: 'nso', en: 'Northern Sotho', ru: 'Северный Сото', ja: '北ソト語' },
    {id: 'ny', en: 'Chewa', ru: 'Ньянджа (Чева)', ja: 'チェワ語' },
    {id: 'oc', en: 'Occitan', ru: 'Окситанский', ja: 'オック語' },
    {id: 'oj', en: 'Ojibwe', ru: 'Оджибве', ja: 'オジブウェー語' },
    {id: 'om', en: 'Oromo', ru: 'Оромо', ja: 'オロモ語' },
    {id: 'or', en: 'Odia', ru: 'Одиа', ja: 'オディア語' },
    {id: 'os', en: 'Ossetian', ru: 'Осетинский', ja: 'オセット語' },
    {id: 'pa-IN', en: 'Punjabi', ru: 'Панджаби', ja: 'パンジャブ語' },
    {id: 'pa-PK', en: 'Punjabi (Pakistan)', ru: 'Пенджаби (Пакистан)', ja: 'パンジャブ語 (パキスタン)' },
    {id: 'pam', en: 'Kapampangan', ru: 'Капампанганский', ja: 'パンパンガ語' },
    {id: 'pap', en: 'Papiamento', ru: 'Папьяменто', ja: 'パピアメント語' },
    {id: 'pcm', en: 'Nigerian Pidgin', ru: 'Нигерийско-креольский', ja: 'ナイジェリア・ピジン' },
    {id: 'pi', en: 'Pali', ru: 'Пали', ja: 'パーリ語' },
    {id: 'pl', en: 'Polish', ru: 'Польский', ja: 'ポーランド語' },
    {id: 'ps', en: 'Pashto', ru: 'Пушту', ja: 'パシュトー語' },
    {id: 'pt-BR', en: 'Portuguese (Brazilian)', ru: 'Португальский (Бразилия)', ja: 'ポルトガル語 (ブラジル)' },
    {id: 'pt-PT', en: 'Portuguese', ru: 'Португальский', ja: 'ポルトガル語' },
    {id: 'qu', en: 'Quechua', ru: 'Кечуа', ja: 'ケチュア語' },
    {id: 'quc', en: `K'iche`, ru: 'Киче', ja: 'キチェ語' },
    {id: 'qya-AA', en: 'Quenya', ru: 'Квенья', ja: 'クウェンヤ' },
    {id: 'rm-CH', en: 'Romansh', ru: 'Романшский', ja: 'ロマンシュ語' },
    {id: 'rn', en: 'Rundi', ru: 'Рунди', ja: 'ルンディ語' },
    {id: 'ro', en: 'Romanian', ru: 'Румынский', ja: 'ルーマニア語' },
    {id: 'ru-RU', en: 'Russian', ru: 'Русский', ja: 'ロシア語' },
    {id: 'ru-BY', en: 'Russian (Belarus)', ru: 'Русский (Беларусь)', ja: 'ロシア語 (ベラルーシ)' },
    {id: 'ru-MD', en: 'Russian (Moldova)', ru: 'Русский (Молдова)', ja: 'ロシア語 (モルドバ)' },
    {id: 'ru-UA', en: 'Russian (Ukraine)', ru: 'Русский (Украина)', ja: 'ロシア語 (ウクライナ)' },
    {id: 'rw', en: 'Kinyarwanda', ru: 'Руанда', ja: 'ルワンダ語' },
    {id: 'ry-UA', en: 'Rusyn', ru: 'Русинский', ja: 'ルシン語' },
    {id: 'sa', en: 'Sanskrit', ru: 'Санскрит', ja: 'サンスクリット語' },
    {id: 'sah', en: 'Sakha', ru: 'Якутский', ja: 'サハ語' },
    {id: 'sat', en: 'Santali', ru: 'Сантали', ja: 'サンタル語' },
    {id: 'sc', en: 'Sardinian', ru: 'Сардинский', ja: 'サルデーニャ語' },
    {id: 'sco', en: 'Scots', ru: 'Шотландский', ja: 'スコットランド語' },
    {id: 'sd', en: 'Sindhi', ru: 'Синдхи', ja: 'シンド語' },
    {id: 'se', en: 'Northern Sami', ru: 'Северносаамский', ja: '北部サーミ語' },
    {id: 'sg', en: 'Sango', ru: 'Санго', ja: 'サンゴ語' },
    {id: 'sh', en: 'Serbo-Croatian', ru: 'Сербохорватский', ja: 'セルビア・クロアチア語' },
    {id: 'si-LK', en: 'Sinhala', ru: 'Сингальский', ja: 'シンハラ語' },
    {id: 'sk', en: 'Slovak', ru: 'Словацкий', ja: 'スロバキア語' },
    {id: 'sl', en: 'Slovenian', ru: 'Словенский', ja: 'スロベニア語' },
    {id: 'sma', en: 'Southern Sami', ru: 'Южносаамский', ja: '南部サーミ語' },
    {id: 'sn', en: 'Shona', ru: 'Шона', ja: 'ショナ語' },
    {id: 'so', en: 'Somali', ru: 'Cомалийский', ja: 'ソマリ語' },
    {id: 'son', en: 'Songhay', ru: 'Сонгайский', ja: 'ソンガイ語' },
    {id: 'sq', en: 'Albanian', ru: 'Албанский', ja: 'アルバニア語' },
    {id: 'sr-SR', en: 'Serbian (Cyrillic)', ru: 'Сербский (кириллица)', ja: 'セルビア語 (キリル文字)' },
    {id: 'sr-CS', en: 'Serbian (Latin)', ru: 'Сербский (латиница)', ja: 'セルビア語 (ラテン文字)' },
    {id: 'sr-Cyrl-ME', en: 'Montenegrin (Cyrillic)', ru: 'Черногорский (кириллица)', ja: 'モンテネグロ語 (キリル文字)' },
    {id: 'ss', en: 'Swati', ru: 'Свати', ja: 'スワジ語' },
    {id: 'st', en: 'Southern Sotho', ru: 'Сесото (Южный Сото)', ja: 'ソト語' },
    {id: 'su', en: 'Sundanese', ru: 'Сунданский', ja: 'スンダ語' },
    {id: 'sv-FI', en: 'Swedish (Finland)', ru: 'Шведский (Финляндия)', ja: 'スウェーデン語 (フィンランド)' },
    {id: 'sv-SE', en: 'Swedish', ru: 'Шведский', ja: 'スウェーデン語' },
    {id: 'sw-SW', en: 'Swahili', ru: 'Суахили', ja: 'スワヒリ語' },
    {id: 'sw-KE', en: 'Swahili (Kenya)', ru: 'Суахили (Кения)', ja: 'スワヒリ語 (ケニア)' },
    {id: 'sw-TZ', en: 'Swahili (Tanzania)', ru: 'Суахили (Танзания)', ja: 'スワヒリ語 (タンザニア)' },
    {id: 'syc', en: 'Syriac', ru: 'Сирийский', ja: 'シリア語' },
    {id: 'ta', en: 'Tamil', ru: 'Тамильский', ja: 'タミル語' },
    {id: 'tay', en: 'Atayal', ru: 'Атаяльский', ja: 'タイヤル語' },
    {id: 'te', en: 'Telugu', ru: 'Телугу', ja: 'テルグ語' },
    {id: 'tg', en: 'Tajik', ru: 'Таджикский', ja: 'タジク語' },
    {id: 'th', en: 'Thai', ru: 'Тайский', ja: 'タイ語' },
    {id: 'ti', en: 'Tigrinya', ru: 'Тигринья', ja: 'ティグリニャ語' },
    {id: 'tk', en: 'Turkmen', ru: 'Туркменский', ja: 'トルクメン語' },
    {id: 'tl', en: 'Tagalog', ru: 'Тагальский', ja: 'タガログ語' },
    {id: 'tlh-AA', en: 'Klingon', ru: 'Клингонский', ja: 'クリンゴン語' },
    {id: 'tn', en: 'Tswana', ru: 'Тсвана', ja: 'ツワナ語' },
    {id: 'tr-TR', en: 'Turkish', ru: 'Турецкий', ja: 'トルコ語' },
    {id: 'tr-CY', en: 'Turkish (Cyprus)', ru: 'Турецкий (Кипр)', ja: 'トルコ語 (キプロス)' },
    {id: 'ts', en: 'Tsonga', ru: 'Тсонга', ja: 'ツォンガ語' },
    {id: 'tt-RU', en: 'Tatar', ru: 'Татарский', ja: 'タタール語' },
    {id: 'tw', en: 'Akan (Twi)', ru: 'Акан (Чви)', ja: 'アカン語 (トウィ語)' },
    {id: 'ty', en: 'Tahitian', ru: 'Таитянский', ja: 'タヒチ語' },
    {id: 'tzl', en: 'Talossan', ru: 'Талосский', ja: 'タロッサ語' },
    {id: 'ug', en: 'Uyghur', ru: 'Уйгурский', ja: 'ウイグル語' },
    {id: 'uk', en: 'Ukrainian', ru: 'украинский', ja: 'ウクライナ語' },
    {id: 'ur-IN', en: 'Urdu (India)', ru: 'Урду (Индия)', ja: 'ウルドゥー語 (インド)' },
    {id: 'ur-PK', en: 'Urdu (Pakistan)', ru: 'Урду (Пакистан)', ja: 'ウルドゥー語 (パキスタン)' },
    {id: 'uz', en: 'Uzbek', ru: 'Узбекский', ja: 'ウズベク語' },
    {id: 'val-ES', en: 'Valencian', ru: 'Валенсийский', ja: 'バレンシア語' },
    {id: 've', en: 'Venda', ru: 'Венда', ja: 'ヴェンダ語' },
    {id: 'vec', en: 'Venetian', ru: 'Венетский', ja: 'ヴェネト語' },
    {id: 'vi', en: 'Vietnamese', ru: 'Вьетнамский', ja: 'ベトナム語' },
    {id: 'vls-BE', en: 'Flemish', ru: 'Фламандский', ja: 'フラマン語' },
    {id: 'wa', en: 'Walloon', ru: 'Валлонский', ja: 'ワロン語' },
    {id: 'wo', en: 'Wolof', ru: 'Волоф', ja: 'ウォロフ語' },
    {id: 'xh', en: 'Xhosa', ru: 'Коса', ja: 'コサ語' },
    {id: 'yi', en: 'Yiddish', ru: 'Идиш', ja: 'イディッシュ語' },
    {id: 'yo', en: 'Yoruba', ru: 'Йоруба', ja: 'ヨルバ語' },
    {id: 'zea', en: 'Zeelandic', ru: 'Зеландский', ja: 'ゼーランド語' },
    {id: 'zh-CN', en: 'Chinese Simplified', ru: 'Китайский упрощенный', ja: '簡体字中国語' },
    {id: 'zh-HK', en: 'Chinese Traditional (Hong Kong)', ru: 'Китайский традиционный (Гонконг)', ja: '繁体字中国語 (香港)' },
    {id: 'zh-MO', en: 'Chinese Traditional (Macau)', ru: 'Китайский традиционный (Макао)', ja: '繁体字中国語 (マカオ)' },
    {id: 'zh-SG', en: 'Chinese Traditional (Singapore)', ru: 'Китайский традиционный (Сингапур)', ja: '繁体字中国語 (シンガポール)' },
    {id: 'zh-TW', en: 'Chinese Traditional', ru: 'Китайский традиционный', ja: '繁体字中国語' },
    {id: 'zu', en: 'Zulu', ru: 'Зулу', ja: 'ズールー語' }
];