import React, {Component} from 'react';
import classNames from 'classnames';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import Popover from "../Popover";
import Loading from "../Loading";
import {getHealthStatus} from "../../helpers/utils";
import {convertDateFormat} from "../../helpers/dateUtils";
import {GET_HEALTH_REQUESTED, GET_HEALTH_SUCCEEDED} from "../../store/StatusTypes";
import CONST from "../../constants/Constants";

import './css/health-menu.css';

class HealthMenu extends Component {

    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    onToggleMenu = () => {
        this.setState((state, props) => {
            return {
                isOpen: !state.isOpen
            }
        });
    }

    openMenu = () => {
        this.setState({
            isOpen: true
        })
    }

    closeMenu = () => {
        this.setState({
            isOpen: false
        })
    }

    getRenderHealth = () => {
        const {status} = this.props;

        if (status === GET_HEALTH_REQUESTED) {
            return <Loading/>
        } else if (status === GET_HEALTH_SUCCEEDED) {
            return this.getHealthItem();
        }
    }

    getHealthItem = () => {
        const {health} = this.props;
        let exportTime = '';
        let jobStartTime = '';

        let extraJobName = '';
        let extraJobTime = '';

        let subscribeJobName = 'Subscribe';
        let subscribeJobTime = '';

        let status = '';

        const getTime = (lastExecutionStatus, completionTime, startTime) => {
            return lastExecutionStatus === 1
                ? convertDateFormat(completionTime, CONST.DATE_FORMAT_WITH_TIME)
                : convertDateFormat(startTime, CONST.DATE_FORMAT_WITH_TIME);
        }

        forEach(health, item => {
            const {jobName, startTime, lastExecutionStatus, completionTime} = item;
            const isExportJob = includes(jobName, "export");
            const extraJobNames = {
                "update-developers": "Update developers",
                "parse-companies": "Parse companies",
                "check-subscribe-jobs": `Check subscribe jobs`
            };

            forEach(Object.keys(extraJobNames), name => {
                if (includes(jobName, name)) {
                    extraJobName = extraJobNames[name];
                    extraJobTime = getTime(lastExecutionStatus, completionTime, startTime);
                }
                if (includes(jobName, "subscribe-subscribe")) {
                    subscribeJobTime = getTime(lastExecutionStatus, completionTime, startTime);
                }
            });

            if (isExportJob) {
                exportTime = getTime(lastExecutionStatus, completionTime, startTime);
                status = getHealthStatus(lastExecutionStatus);
            }
            else {
                jobStartTime = new Date(startTime) <= new Date(jobStartTime)
                    ? convertDateFormat(jobStartTime, CONST.DATE_FORMAT_WITH_TIME)
                    : convertDateFormat(startTime, CONST.DATE_FORMAT_WITH_TIME);
            }
        });

        return <div className="health__item">
            {exportTime && <span>Last export: {status} {exportTime}</span>}
            <span>Job start time: {jobStartTime}</span>
            {extraJobName && <span>{extraJobName}: {extraJobTime}</span>}
            {subscribeJobTime && <span>{subscribeJobName}: {subscribeJobTime}</span>}
        </div>
    };

    checkHealthFailed = () => {
        const {health} = this.props;
        let styleList = [];
        forEach(health, item => {
            let style = "";
            const {lastExecutionStatus, lastFailedCount} = item;
            if (lastExecutionStatus === 2) {
                style = 'failed';
            } else if (lastExecutionStatus === 0 && lastFailedCount > 0) {
                style = 'warning';
            }
            styleList.push(style);
        })
        return includes(styleList, "failed")
            ? "failed"
            : includes(styleList, "warning")
                ? "warning"
                : "";
    };

    render() {
        const {isOpen} = this.state;
        const {health, popoverPosition} = this.props;

        const renderHealth = this.getRenderHealth();
        const heathStyle = this.checkHealthFailed();

        const healthIndicatorClassNames = classNames("health-indicator", heathStyle, {
            "waiting": isEmpty(health),
        });

        return (
            <div className="health-menu__container">
                <div className="health-menu__indicator-container">
                    <div className={healthIndicatorClassNames} onMouseEnter={this.openMenu} onMouseLeave={this.closeMenu}/>
                    <Popover position={popoverPosition || "right"} className="health-popover" open={isOpen} onClosePopover={this.onToggleMenu}>
                        <div className="health-value">
                            {renderHealth}
                        </div>
                    </Popover>
                </div>
            </div>
        );
    }
}

export default (HealthMenu)
