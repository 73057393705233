import React, {useRef} from 'react';
import classNames from 'classnames';
import {usePopper} from "react-popper";

import './css/input.css';

function Input({id, value, placeholder, mask, popperText, isDisplayPopper, startAdornment, className, onChange, onMouseDown, onKeyDown, disabled}) {
    const popoverRef = useRef();
    const inputRef = useRef();
    const { styles, attributes } = usePopper(
        inputRef.current,
        popoverRef.current,
        {
            placement: "bottom-start",
            modifiers: [
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [0, 16]
                    }
                }
            ]
        }
    );
    const inputClassName = classNames("input-root input-text", className);

    const popperClassName = classNames("kraken-input__popper", {
        "kraken-input__popper_display": isDisplayPopper
    });

    const maskText = mask && <><span className="input-value">{value}</span><span>{mask.substring(value.length)}</span></>;

    return (
        <div className={inputClassName}>
            {startAdornment && startAdornment}
            <input
                ref={inputRef}
                id={id}
                className="kraken-input"
                placeholder={placeholder}
                value={value}
                onMouseDown={onMouseDown}
                onKeyDown={onKeyDown}
                onChange={onChange}
                disabled={disabled}/>
            {mask && <div className="date-range__input-mask">{maskText}</div>}

            <div ref={popoverRef} className={popperClassName} style={styles.popper} {...attributes.popper}>
                <div className="kraken-input__popper-arrow"/>
                <div className="kraken-input__popper-body">
                    {popperText}
                </div>
            </div>
        </div>
    );
}

export default Input;
