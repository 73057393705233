import React, {Component} from 'react';
import {connect} from "react-redux";
import isEmpty from "lodash/isEmpty";
import classNames from 'classnames';
import {setInitContainer, setTitle} from "../App/actions";
import Filters from "../../components/Filters";
import {BUTTON_ELEMENT, ITEM_SELECT} from "../../constants/FilterTypes";
import Button from "../../components/Button";
import {getDecidedRecord, refreshDecidedRecord, getSubscribeCounts, updateDecideRecord, getSubscribeCurrentFilter} from "./actionsAsync";
import {addMonthToDate} from "../../helpers/dateUtils";
import {GET_DECIDE_RECORD_REQUESTED, REFRESH_DECIDE_RECORD_REQUESTED} from "../../store/StatusTypes";
import Loading from "../../components/Loading";
import DecideLetter from "../../components/DecideLetter";

import '../../css/subscribe-decide.css';

const CONTAINER_NAME = 'Subscribe';

class SubscribeDecide extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, getDecidedRecord, getSubscribeCounts, getSubscribeCurrentFilter, status} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            getDecidedRecord();
            getSubscribeCounts();
            getSubscribeCurrentFilter();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getSnoozeButton = (props) => {
        const {onClick} = props;
        return <Button
            title='Snooze'
            onClick={onClick}
            icon={<div className='down-arrow-white-icon snooze-button-icon'/>}/>
    }

    getFiltersOptions = () => {
        const {snoozeOptions} = this.props;

        const filtersOptions = [
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'subscribe-decide__button ignore',
                title: 'Skip',
                onClick: () => this.updateItem(null, 'skip', 'null')
            },
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'subscribe-decide__button video-en',
                title: 'EN',
                onClick: () => this.updateItem(true, 'isEn', 'bool')
            },
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'subscribe-decide__button video-ru',
                title: 'RU',
                onClick: () => this.updateItem(true, 'isRu', 'bool')
            },
            {
                type: ITEM_SELECT,
                customWrapperClassName: 'snooze-button',
                renderButton: this.getSnoozeButton,
                options: snoozeOptions,
                onChangeWithoutRefresh: this.onChangeSnooze
            },
        ];

        return filtersOptions
    };

    onChangeSnooze = (id) => {
        const value = addMonthToDate(id);
        this.updateItem(value, 'snooze', 'date');
    }

    updateItem = (value, key, type) => {
        const {decideObject, refreshDecidedRecord, getSubscribeCounts} = this.props;
        const {id} = decideObject;
        const {updateDecideRecord} = this.props;
        updateDecideRecord(id, key, type, value).then(() => {
            refreshDecidedRecord().then(() => getSubscribeCounts());
        });
    }

    getLoading = () => {
        const {decideObject, status} = this.props;
        return isEmpty(decideObject)
            || status === GET_DECIDE_RECORD_REQUESTED
            || status === REFRESH_DECIDE_RECORD_REQUESTED;
    }

    render() {
        const {decideObject, counts, processingMessage, noMoreRecordsMessage} = this.props;
        const {email, title, from, to, subject, name, company, date, bodyHtml, leadId} = decideObject;
        const {notProcessed, candidates, confirmed} = counts;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const renderName = title ? `${name}, ${title}` : name;

        const filterClassName = classNames("subscribe-decide__filter", {
            "hidden": notProcessed === 0,
        });

        return (
            <div className="app-root__table-container subscribe-decide__container">
                {isLoading
                    ? (<div className="subscribe-decide__loading">{processingMessage} <Loading/></div>)
                    : noMoreRecordsMessage
                        ? <div className="subscribe-decide__no-more-records">{noMoreRecordsMessage}</div>
                        : (<React.Fragment>
                            <div className="subscribe-decide__email-container">
                                <div className="email">{email}</div>
                                <div className="name">{renderName}</div>
                                <div className="company">{company}</div>
                            </div>
                            <div className="subscribe-decide__filters">
                                <div className="subscribe-decide__contacts-title">
                                    <span className="approved">Awaiting {candidates} / Approved {confirmed}</span>
                                    <span>{notProcessed} contacts to process</span>
                                </div>
                                <Filters containerClassName={filterClassName} filtersOptions={filtersOptions} />
                            </div>
                            <div className="app-root__table subscribe-decide__table">
                                <DecideLetter totalLeft={confirmed} leadId={leadId} from={from} to={to} subject={subject} date={date} bodyHtml={bodyHtml}/>
                            </div>
                    </React.Fragment>)
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,
        snoozeOptions: state.app.snoozeOptions,

        decideObject: state.subscribeDecide.decideObject,
        status: state.subscribeDecide.status,
        counts: state.subscribeDecide.counts,
        closeFilter: state.subscribeDecide.closeFilter,
        processingMessage: state.subscribeDecide.processingMessage,
        noMoreRecordsMessage: state.subscribeDecide.noMoreRecordsMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        getDecidedRecord: () => dispatch(getDecidedRecord()),
        refreshDecidedRecord: () => dispatch(refreshDecidedRecord()),
        updateDecideRecord: (id, field, type, value) => dispatch(updateDecideRecord(id, field, type, value)),
        getSubscribeCounts: () => dispatch(getSubscribeCounts()),
        getSubscribeCurrentFilter: () => dispatch(getSubscribeCurrentFilter()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeDecide)