import * as types from "./constants";

export function requestInitMagnetContacts(account) {
    return {
        type: types.REQUEST_INIT_MAGNET_SALES_CONTACTS,
        account
    };
}

export function succeedInitMagnetContacts(response) {
    return {
        type: types.SUCCEED_INIT_MAGNET_SALES_CONTACTS,
        response
    };
}

export function requestSearchMagnetContacts(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_MAGNET_SALES_CONTACTS,
        limit, offset
    };
}

export function succeedSearchMagnetContacts(response) {
    return {
        type: types.SUCCEED_SEARCH_MAGNET_SALES_CONTACTS,
        response
    };
}


export function requestRefreshMagnetContacts() {
    return {
        type: types.REQUEST_REFRESH_MAGNET_SALES_CONTACTS
    };
}

export function succeedRefreshMagnetContacts(response) {
    return {
        type: types.SUCCEED_REFRESH_MAGNET_SALES_CONTACTS,
        response
    };
}

export function setMagnetContactsSearch(search) {
    return {
        type: types.SET_MAGNET_SALES_CONTACTS_SEARCH,
        search
    }
}

export function requestUpdateMagnetContacts(updatedItem) {
    return {
        type: types.REQUEST_UPDATE_MAGNET_SALES_CONTACTS,
        updatedItem
    };
}

export function succeedUpdateMagnetContacts(response) {
    return {
        type: types.SUCCEED_UPDATE_MAGNET_SALES_CONTACTS,
        response
    }
}

export function setMagnetSelectedStatus(selectedStatus) {
    return {
        type: types.SET_SELECTED_MAGNET_SALES_CONTACTS_STATUS,
        selectedStatus
    };
}

export function setSortMagnetContacts(order) {
    return {
        type: types.SET_SORT_MAGNET_SALES_CONTACTS,
        order
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_MAGNET_CONTACTS_SALES,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_MAGNET_CONTACTS_SALES,
        isFiltersSelected
    };
}

