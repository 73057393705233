import * as types from "./constants";

export function requestInitGog() {
    return {
        type: types.REQUEST_INIT_GOG
    };
}

export function succeedInitGog(response) {
    return {
        type: types.SUCCEED_INIT_GOG,
        response
    };
}

export function requestLastUpdateGog() {
    return {
        type: types.REQUEST_LAST_UPDATE_GOG
    };
}

export function succeedLastUpdateGog(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_GOG,
        lastUpdate
    };
}

export function requestSearchGog(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_GOG,
        limit, offset
    };
}

export function succeedSearchGog(response) {
    return {
        type: types.SUCCEED_SEARCH_GOG,
        response
    };
}

export function setPrice(price) {
    return {
        type: types.SET_PRICE_GOG,
        price
    };
}

export function setReleased(released) {
    return {
        type: types.SET_GOG_RELEASED,
        released
    };
}

export function setGogRating(rating) {
    return {
        type: types.SET_GOG_RATING,
        rating
    };
}

export function getGogFilterData(response) {
    return {
        type: types.GET_GOG_FILTER_DATA,
        response
    };
}

export function setSelectedGenres(selectedGenres) {
    return {
        type: types.SET_SELECTED_GENRES_GOG,
        selectedGenres
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_GOG,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_GOG,
        isFiltersSelected
    };
}


