import React, {Component} from 'react';
import PropTypes from "prop-types";
import map from 'lodash/map';
import includes from 'lodash/includes';
import TableCell from "./TableCell";

class TableRow extends Component {

    static propTypes = {
        columns: PropTypes.array,
        onRowClick: PropTypes.func,
        row: PropTypes.object,
    };

    static defaultProps = {
        onRowClick: () => {}
    };

    handleCellValueWrapperFn = (value, cellKey, cellValueWrapperFn) => {
        const {row} = this.props;
        return cellValueWrapperFn(value, cellKey, row);
    }

    renderCells = () => {
        const {columns, row, rowIndex, addedWidth} = this.props;

        return map(columns, (column) => {
            const {key, width, fixedColumn, cellValueWrapperFn, cellClassName, renderValue, isHiddenTitle} = column;
            let value = row[key];
            // логика для составных ключей
            if (includes(key, '.')) {
                const keyArray = key.split('.');
                value = row[keyArray[0]][keyArray[1]];
            }
            const renderWidth = fixedColumn ? width : width + addedWidth;
            const displayValue = renderValue ? renderValue(row, column, value, rowIndex) : value;
            const isFixedColumn = !!fixedColumn;
            const fixedColumnLeft = isFixedColumn && fixedColumn.left;

            return <TableCell
                key={key}
                isHiddenTitle={isHiddenTitle}
                cellKey={key}
                isFixedColumn={isFixedColumn}
                fixedColumnLeft={fixedColumnLeft}
                cellClassName={cellClassName}
                width={renderWidth}
                value={displayValue}
                cellValueWrapperFn={cellValueWrapperFn}
                onCellValueWrapperFn={this.handleCellValueWrapperFn}
            />
        });
    };

    handleRowClick = (e) => {
        const {onRowClick, row} = this.props;
        onRowClick && onRowClick(e, row);
    };

    render() {
        const cells = this.renderCells();

        return (
            <tr className="kraken-table-row" onClick={this.handleRowClick}>
                {cells}
            </tr>
        );
    }
}

export default (TableRow);
