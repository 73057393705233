export default function useResizeTextArea() {

    const calculateRowsCount = (ref, baseHeight, minTextRows) => {
        if (ref?.current && ref.current.scrollHeight) {
            ref.current.rows = minTextRows;
            const singleLineHeight = parseInt(getComputedStyle(ref.current).lineHeight, 10);
            const rows = Math.ceil((ref.current.scrollHeight - baseHeight) / singleLineHeight);
            ref.current.rows = rows + minTextRows;
        }
    };

    return {
        calculateRowsCount
    }
}
