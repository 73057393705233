import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/button.css';

class Button extends Component {

    static propTypes = {
        icon: PropTypes.element,
        title: PropTypes.any,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }

    render() {
        const {title, icon, className, onClick} = this.props;
        const buttonClassName = classNames("button-root button-text", className);
        return (
            <button
                className={buttonClassName}
                onClick={onClick}>
                {icon}
                {title}
            </button>
        );
    }
}

export default (Button);
