import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import {crowdinProjectTypes} from "../../constants/CrowdinProjectTypes";

const initialState = {
    status: null,
    lastUpdate: null,
    limit: 10,
    total: 0,
    list: [],
    offset: 0,
    search: '',
    selectedProjectType: crowdinProjectTypes,
    projectTypes: crowdinProjectTypes,
    targetLanguagesCount: '',
    isFiltersSelected: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    lastActivityDateRange: getDefaultReleased(),
    firstCreatedDate: null
};

export default function crowdin(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_CROWDIN:
            return {
                ...state,
                status: statuses.INIT_CROWDIN_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_CROWDIN:
            return {
                ...state,
                status: statuses.INIT_CROWDIN_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.SET_CROWDIN_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.REQUEST_LAST_UPDATE_CROWDIN:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_CROWDIN:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_CROWDIN:
            return {
                ...state,
                status: statuses.SEARCH_CROWDIN_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_CROWDIN:
            return {
                ...state,
                status: statuses.SEARCH_CROWDIN_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.SET_CROWDIN_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_CROWDIN_LAST_ACTIVITY:
            return {
                ...state,
                lastActivityDateRange: action.lastActivity,
            }
        case actions.SET_CROWDIN_PROJECT_TYPE:
            return {
                ...state,
                selectedProjectType: action.projectType,
            }
        case actions.SET_CROWDIN_TARGET_LANGUAGES_COUNT:
            return {
                ...state,
                targetLanguagesCount: action.targetLanguagesCount,
            }
        case actions.SUCCEED_GET_FILTER_DATA_CROWDIN:
            const firstCreatedDate = action.response.firstCreatedDate;
            return {
                ...state,
                firstCreatedDate: firstCreatedDate,
                releasedDateRange: getDefaultReleased(firstCreatedDate),
                lastActivityDateRange: getDefaultReleased(firstCreatedDate),
                defaultDateRange: getDefaultReleased(firstCreatedDate),
            }
        case actions.SET_IS_FILTER_SELECTED_CROWDIN:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_CROWDIN:
            return {
                ...state,
                search: '',
                selectedProjectType: state.projectTypes,
                releasedDateRange: state.defaultDateRange,
                lastActivityDateRange: state.defaultDateRange,
                targetLanguagesCount: '',
            }
        default:
            return state;
    }
}