import React from "react";
import "./css/loader.css";

const Loader = () => {
    return <div className="kraken-loader__container">
        <div className="kraken-loader__gif"/>
        <div className="kraken-loader__app-name"/>
        <span>Please wait... Empowering access to your Data Ocean</span>
    </div>
}

export default Loader;