import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {deleteItemFromArray} from "../../helpers/utils";

const initialState = {
    limit: 10,
    offset: 0,
    status: null,
    search: '',
    list: [],
    total: 0,
    magnetBlacklistAccount: 1,
};

export default function magnetSalesBlacklist(state = initialState, action) {
    switch (action.type) {

        case actions.REQUEST_INIT_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.INIT_MAGNET_BLACKLIST_REQUESTED,
                list: [],
                total: 0
            }
        case actions.SUCCEED_INIT_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.INIT_MAGNET_BLACKLIST_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_BLACKLIST_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_BLACKLIST_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.REQUEST_REFRESH_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.INIT_MAGNET_BLACKLIST_REQUESTED,
            }
        case actions.SUCCEED_REFRESH_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                status: statuses.INIT_MAGNET_BLACKLIST_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_DELETE_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                list: deleteItemFromArray(state.list, action.id),
                total: state.total - 1,
                limit: state.limit - 1
            }
        case actions.SUCCEED_DELETE_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
            }
        case actions.REQUEST_ADD_MAGNET_SALES_BLACKLIST:
            return {
                ...state,

            }
        case actions.SUCCEED_ADD_MAGNET_SALES_BLACKLIST:
            return {
                ...state,
                list: [action.response.record, ...state.list],
                limit: state.limit + 1,
                total: state.total + 1
            }
        case actions.SET_MAGNET_SALES_BLACKLIST_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        default:
            return state;
    }
}