import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {deleteItemFromArray} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    audience: {},
};

export default function twitterAudience(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_GET_AUDIENCE_BY_NAME:
            return {
                ...state,
                status: statuses.INIT_TWITTER_BY_NAME_REQUESTED,
            }
        case actions.SUCCEED_GET_AUDIENCE_BY_NAME:
            return {
                ...state,
                status: statuses.INIT_TWITTER_BY_NAME_SUCCEEDED,
                audience: action.response,
                list: action.donors.result,
                total: action.donors.total,
            }
        case actions.REQUEST_SEARCH_TWITTER_DONORS:
            return {
                ...state,
                status: statuses.SEARCH_TWITTER_DONORS_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_TWITTER_DONORS:
            return {
                ...state,
                status: statuses.SEARCH_TWITTER_DONORS_SUCCEEDED,
                list: [...state.list, ...action.donors.result],
                total: action.donors.total
            }
        case actions.SET_SEARCH_TWITTER_DONORS:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.REQUEST_CREATE_TWITTER_DONOR:
            return {
                ...state,
            }
        case actions.SUCCEED_CREATE_TWITTER_DONOR:
            return {
                ...state,
                list: [action.response.donor, ...state.list],
                audience: action.response.audience,
                limit: state.limit + 1,
                total: state.total + 1
            }

        case actions.REQUEST_REMOVE_TWITTER_DONOR:
            return {
                ...state,
                list: deleteItemFromArray(state.list, action.id),
                total: state.total - 1,
                limit: state.limit - 1
            }
        case actions.SUCCEED_REMOVE_TWITTER_DONOR:
            return {
                ...state,
                audience: action.response.audience,
            }
        case actions.REQUEST_RENAME_TWITTER_AUDIENCE:
            return {
                ...state,
                audience: {...state.audience, name: action.name},
            }
        default:
            return state;
    }
}