export const REQUEST_GET_AUDIENCE_BY_NAME = 'REQUEST_GET_AUDIENCE_BY_NAME';
export const SUCCEED_GET_AUDIENCE_BY_NAME = 'SUCCEED_GET_AUDIENCE_BY_NAME';
export const REQUEST_SEARCH_TWITTER_DONORS = 'REQUEST_SEARCH_TWITTER_DONORS';
export const SUCCEED_SEARCH_TWITTER_DONORS = 'SUCCEED_SEARCH_TWITTER_DONORS';
export const REQUEST_CREATE_TWITTER_DONOR = 'REQUEST_CREATE_TWITTER_DONOR';
export const SUCCEED_CREATE_TWITTER_DONOR = 'SUCCEED_CREATE_TWITTER_DONOR';
export const REQUEST_REMOVE_TWITTER_DONOR = 'REQUEST_REMOVE_TWITTER_DONOR';
export const SUCCEED_REMOVE_TWITTER_DONOR = 'SUCCEED_REMOVE_TWITTER_DONOR';
export const REQUEST_SEND_AUDIENCE_TO_TWITTER = 'REQUEST_SEND_AUDIENCE_TO_TWITTER';
export const SUCCEED_SEND_AUDIENCE_TO_TWITTER = 'SUCCEED_SEND_AUDIENCE_TO_TWITTER';
export const REQUEST_RENAME_TWITTER_AUDIENCE = 'REQUEST_RENAME_TWITTER_AUDIENCE';
export const SUCCEED_RENAME_TWITTER_AUDIENCE = 'SUCCEED_RENAME_TWITTER_AUDIENCE';

export const SET_SEARCH_TWITTER_DONORS = 'SET_SEARCH_TWITTER_DONORS';