import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    isFiltersSelected: false,
    price: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    selectedPrice: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],

    languages: [],
    selectedAudioLanguages: [],
    selectedTextLanguages: [],
    audioLanguagesCount: {from: '', to: ''},
    textLanguagesCount: {from: '', to: ''},
    isOnlyChosenAudioLanguages: false,
    isOnlyChosenTextLanguages: false,

    epicTags: [],
    selectedEpicTags: [],
    count: '',
    isTwittersCountLoading: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    startDate: null,
};

export default function epic(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_EPIC:
            return {
                ...state,
                status: statuses.INIT_EPIC_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_EPIC:
            return {
                ...state,
                status: statuses.INIT_EPIC_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_EPIC:
            return {
                ...state,
                status: statuses.SEARCH_EPIC_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_EPIC:
            return {
                ...state,
                status: statuses.SEARCH_EPIC_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.REQUEST_LAST_UPDATE_EPIC:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_EPIC:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.SET_SELECTED_TAGS_EPIC:
            return {
                ...state,
                selectedEpicTags: action.selectedTags,
            }
        case actions.GET_EPIC_FILTER_DATA:
            const releasedStartDate = action.response.releasedStartDate;
            return {
                ...state,
                epicTags: action.response.tags,
                selectedEpicTags: action.response.tags,
                languages: action.response.languages,
                selectedAudioLanguages: action.response.languages,
                selectedTextLanguages: action.response.languages,
                startDate: releasedStartDate,
                releasedDateRange: getDefaultReleased(releasedStartDate),
                defaultDateRange: getDefaultReleased(releasedStartDate),
            }
        case actions.SET_SELECTED_AUDIO_LANGUAGES_EPIC:
            return {
                ...state,
                selectedAudioLanguages: action.selectedLanguages,
            }
        case actions.SET_SELECTED_TEXT_LANGUAGES_EPIC:
            return {
                ...state,
                selectedTextLanguages: action.selectedLanguages,
            }
        case actions.SET_AUDIO_LANGUAGES_COUNT_EPIC:
            return {
                ...state,
                audioLanguagesCount: action.languagesCount,
            }
        case actions.SET_TEXT_LANGUAGES_COUNT_EPIC:
            return {
                ...state,
                textLanguagesCount: action.languagesCount,
            }
        case actions.SET_RELEASED_EPIC:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_EPIC_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.REQUEST_GET_COUNT_EPIC:
            return {
                ...state,
                isTwittersCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_EPIC:
            return {
                ...state,
                count: action.count,
                isTwittersCountLoading: false
            }
        case actions.SET_IS_ONLY_CHOSEN_AUDIO_LANGUAGES_EPIC:
            return {
                ...state,
                isOnlyChosenAudioLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_ONLY_CHOSEN_TEXT_LANGUAGES_EPIC:
            return {
                ...state,
                isOnlyChosenTextLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_FILTER_SELECTED_EPIC:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_EPIC:
            return {
                ...state,
                search: '',
                releasedDateRange: state.defaultDateRange,
                selectedAudioLanguages: state.languages,
                selectedTextLanguages: state.languages,
                audioLanguagesCount: {from: '', to: ''},
                textLanguagesCount: {from: '', to: ''},
                selectedEpicTags: state.epicTags,
                isOnlyChosenLanguages: false,
                selectedPrice: [
                    {id: 1, name: 'Free'},
                    {id: 2, name: 'Paid'}
                ],
            }
        case actions.SET_PRICE_EPIC:
            return {
                ...state,
                selectedPrice: action.selectedPrice,
            }
        default:
            return state;
    }
}