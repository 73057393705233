import * as types from "./constants";

export function requestGetDecidedRecord() {
    return {
        type: types.REQUEST_GET_DECIDE_RECORD
    };
}

export function succeedGetDecidedRecord(response) {
    return {
        type: types.SUCCEED_GET_DECIDE_RECORD,
        response
    };
}

export function requestRefreshDecidedRecord() {
    return {
        type: types.REQUEST_REFRESH_DECIDE_RECORD
    };
}

export function succeedRefreshDecidedRecord(response) {
    return {
        type: types.SUCCEED_REFRESH_DECIDE_RECORD,
        response
    };
}

export function requestUpdateSubscribeRecord() {
    return {
        type: types.REQUEST_UPDATE_SUBSCRIBE_RECORD,
        blockUi: true,
    };
}

export function successUpdateSubscribeRecord(response) {
    return {
        type: types.SUCCEED_UPDATE_SUBSCRIBE_RECORD,
        blockUi: false,
        response
    };
}

export function successGetSubscribeCounts(response) {
    return {
        type: types.SUCCEED_GET_SUBSCRIBE_COUNTS,
        response
    };
}

export function requestGetSubscribeCurrentFilter() {
    return {
        type: types.REQUEST_GET_SUBSCRIBE_CURRENT_FILTER
    };
}

export function succeedGetSubscribeCurrentFilter(response) {
    return {
        type: types.SUCCEED_GET_SUBSCRIBE_CURRENT_FILTER,
        response
    };
}

export function requestUpdateSubscribeFilter() {
    return {
        type: types.REQUEST_UPDATE_SUBSCRIBE_FILTER
    };
}

export function succeedUpdateSubscribeFilter(response) {
    return {
        type: types.SUCCEED_UPDATE_SUBSCRIBE_FILTER,
        response
    };
}

export function updateProcessingMessage(message) {
    return {
        type: types.UPDATE_PROCESSING_MESSAGE,
        message
    };
}