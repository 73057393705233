export const REQUEST_INIT_GOG = 'REQUEST_INIT_GOG';
export const SUCCEED_INIT_GOG = 'SUCCEED_INIT_GOG';
export const REQUEST_LAST_UPDATE_GOG = 'REQUEST_LAST_UPDATE_GOG';
export const SUCCEED_LAST_UPDATE_GOG = 'SUCCEED_LAST_UPDATE_GOG';
export const REQUEST_SEARCH_GOG = 'REQUEST_SEARCH_GOG';
export const SUCCEED_SEARCH_GOG = 'SUCCEED_SEARCH_GOG';
export const SET_PRICE_GOG = 'SET_PRICE_GOG';
export const SET_GOG_RELEASED = 'SET_GOG_RELEASED';
export const SET_GOG_RATING = 'SET_GOG_RATING';
export const GET_GOG_FILTER_DATA = 'GET_GOG_FILTER_DATA';
export const SET_SELECTED_GENRES_GOG = 'SET_SELECTED_GENRES_GOG';
export const CLEAR_FILTERS_GOG = 'CLEAR_FILTERS_GOG';
export const SET_IS_FILTER_SELECTED_GOG = 'SET_IS_FILTER_SELECTED_GOG';