import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import map from 'lodash/map';

import TableColumn from "./TableColumn";

class TableHead extends Component {

    static propTypes = {
        columns: PropTypes.array,
    };

    static defaultProps = {
        columns: []
    };

    renderTableColumns = () => {
        const {columns, addedWidth} = this.props;

        return map(columns, (column, i) => {
            const {key} = column;
            return <TableColumn
                key={key}
                addedWidth={addedWidth}
                column={column}/>
        })
    };

    render() {
        const {isHiddenHead} = this.props;
        const tableColumns = this.renderTableColumns();

        const headClassName = classNames("kraken-table-head", {
            "kraken-table-head_hidden": isHiddenHead
        });

        return (
            <thead className={headClassName}>
                <tr className="kraken-table-row">
                    {tableColumns}
                </tr>
            </thead>
        );
    }
}

export default (TableHead);
