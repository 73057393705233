import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";
import {
    exportAppStoreRecords,
    initAppStore,
    refreshAppStore,
    searchAppStore, setGenresAsync,
    setLanguageAsync, setLanguageCountAsync,
    setReleasedAsync,
    setSearchAsync, getLastUpdate,
    setReviewsAsync, setScoreAsync,
    getFilterDataAppStore,
    clearFilters,
    setCountryAsync, setUpdatedAsync, getCount,
    setHasVideoAsync, setCountryCountAsync,
    getExportCount, getCountDevelopersAndEmails,
    setPriceAsync, setAppCountAsync
} from "./actionsAsync";
import {setIsOnlyChosenMarkets, setIsOnlyChosenLanguages, setIsFiltersSelected} from "./actions";

import ExportPopup from "../../components/Popup/components/ExportPopup";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import Loading from "../../components/Loading";

import CONST from "../../constants/Constants";
import {DATE_RANGE, DOWNLOAD_COUNT, SELECT} from "../../constants/FilterTypes";

import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import {getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";

const PAID = 'Paid';
const FREE = 'Free';
const CONTAINER_NAME = 'App Store';

class appStore extends Component {

    componentDidMount() {
        const {setTitle, initAppStore, setInitContainer, initContainers, getCount,
            getLastUpdate, status, lastUpdate, getFilterDataAppStore, getCountDevelopersAndEmails} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initAppStore();
            getFilterDataAppStore();
            getCount();
            getCountDevelopersAndEmails();
        }
        lastUpdate === null && getLastUpdate();

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getLanguageValue = (selectedOptions, options) => {
        const {languagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = languagesCount.from ? `From ${languagesCount.from}` : '';
        const languagesCountTo = languagesCount.to ? `To ${languagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    getMarketsValue = (selectedOptions, options) => {
        const {countriesCount} = this.props;
        let countriesValue = '';
        if (selectedOptions.length === 0) {
            countriesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            countriesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            countriesValue = join(nameValues, ', ');
        }
        const countriesCountFrom = countriesCount.from ? `From ${countriesCount.from}` : '';
        const countriesCountTo = countriesCount.to ? `To ${countriesCount.to}` : '';
        if (countriesCountFrom && countriesCountTo) {
            return `${countriesCountFrom} ${countriesCountTo}; ${countriesValue}`;
        }
        else if (countriesCountFrom && !countriesCountTo) {
            return `${countriesCountFrom}; ${countriesValue}`;
        }
        else if (!countriesCountFrom && countriesCountTo) {
            return `${countriesCountTo}; ${countriesValue}`;
        }
        else {
            return countriesValue;
        }
    }

    getGenresValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    onClearReleased = () => {
        const {setReleased, refreshAppStore, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => refreshAppStore());
    }

    onClearUpdated = () => {
        const {setUpdated, refreshAppStore} = this.props;
        const defaultReleased = getDefaultReleased();
        setUpdated(defaultReleased).then(() => refreshAppStore());
    }

    onExportAppStore = () => {
        const {exportAppStoreRecords, showPopup, fieldsList, getExportCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getExportCount}/>
        }

        const handleApply = (fields) => {
            exportAppStoreRecords(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    getFiltersOptions = () => {
        const {languages, selectedLanguages, setLanguage, genres, selectedGenres, setGenres, releasedDateRange, isOnlyChosenMarkets, isOnlyChosenLanguages,
            countryOptions, defaultDateRange, setReleased, reviews, setReviews, score, setScore, setCountry, setCountryCount, countriesCount,
            selectedCountry, updatedDateRange, setUpdated, setHasVideo, hasVideo, selectedHasVideo, setLanguageCount, languagesCount,
            setIsOnlyChosenLanguages, setIsOnlyChosenMarkets, maxReviews, maxAppCount, price, selectedPrice, setPrice, appCount, setAppCount} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Markets',
                options: countryOptions,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getMarketsValue,
                selectedOptions: selectedCountry,
                onChange: setCountry,
                setOptionsCount: setCountryCount,
                filterName: "markets",
                optionCount: countriesCount,
                popoverClassName: "popover-languages-filter",
                isOnlyChosen: isOnlyChosenMarkets,
                setIsOnlyChosen: setIsOnlyChosenMarkets,
            },
            {
                type: SELECT,
                title: 'Languages',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getLanguageValue,
                selectedOptions: selectedLanguages,
                onChange: setLanguage,
                setOptionsCount: setLanguageCount,
                filterName: "languages",
                optionCount: languagesCount,
                popoverClassName: "popover-languages-filter",
                isOnlyChosen: isOnlyChosenLanguages,
                setIsOnlyChosen: setIsOnlyChosenLanguages,
            },
            {
                type: SELECT,
                title: 'Genres',
                popoverClassName: 'steam-genre-popover',
                options: genres,
                renderValue: this.getGenresValue,
                selectedOptions: selectedGenres,
                onChange: setGenres,
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased,
                submitButtonName: 'Submit',
            },
            {
                type: DATE_RANGE,
                title: 'Updated',
                customWrapperClassName: 'date-input',
                initDates: updatedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearUpdated,
                onChange: setUpdated,
                submitButtonName: 'Submit',
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Reviews',
                value: reviews,
                onChange: setReviews,
                submitButtonName: 'Submit',
                placeholder: {from: 0, to: maxReviews}
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Score',
                value: score,
                onChange: setScore,
                rateValidate: true,
                submitButtonName: 'Submit',
                placeholder: {from: 0, to: 5}
            },
            {
                type: SELECT,
                title: 'Videos',
                options: hasVideo,
                renderValue: this.getGenresValue,
                selectedOptions: selectedHasVideo,
                onChange: setHasVideo,
                columnsCount: 1,
            },
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getGenresValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Count apps',
                value: appCount,
                onChange: setAppCount,
                submitButtonName: 'Submit',
                placeholder: {from: 0, to: maxAppCount}
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {isCountLoading} = this.props;
        return isCountLoading;
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderPriceCell = (row, column, value) => value ? FREE : PAID;

    renderLink = (value) => <Link path={value} value={value}/>;

    renderUpdateValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderReleasedValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderList = (row, column, value) => join(value, ', ');

    renderBool = (row, column, value) => {
        return value ? 'Yes' : 'No';
    }

    appCount = (row, column, value) => {
        return value || '-';
    }

    renderGenreList = (row, column, value) => {
        const nameValues = map(value, option => option.name);
        return join(nameValues, ', ');
    };

    handleClearFilters = () => {
        const {clearFilters, initAppStore, getCount, getCountDevelopersAndEmails} = this.props;
        clearFilters().then(() => {
            initAppStore();
            getCount();
            getCountDevelopersAndEmails();
        });
    }

    handleRefresh = () => {
        const {refreshAppStore, getCount, getCountDevelopersAndEmails} = this.props;
        refreshAppStore();
        getCount();
        getCountDevelopersAndEmails();
    }

    render() {
        const {list, total, limit, offset, searchAppStore, search, setSearch, lastUpdate, title, count,
            setIsFiltersSelected, isFiltersSelected, isRecordsCountLoading, isDevelopersAndEmailsCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const recordsCount = isRecordsCountLoading ? <Loading/> : total;
        const developersCount = isDevelopersAndEmailsCountLoading ? <Loading/> : count.developers;
        const emailsCount = isDevelopersAndEmailsCountLoading ? <Loading/> : count.emails;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'title', name: 'Name', width: 150, fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider shadow-fixed-cell',
                cellClassName: 'fixed-cell-divider shadow-fixed-cell',
            },
            {key: 'primaryGenre', name: 'Category', width: 100},
            {key: 'score', name: 'Score', width: 80},
            {key: 'reviews', name: 'Reviews', width: 80,
                renderValue: renderBigNumbers
            },
            {key: 'free', name: 'Price', width: 80,
                renderValue: this.renderPriceCell
            },
            {key: 'developer', name: 'Developer', width: 120},
            {key: 'developerUrl', name: 'Developer Url', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'developerWebsite', name: 'Developer Website', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'languages', name: 'Languages', width: 120,
                renderValue: this.renderList
            },
            {key: 'genres', name: 'Genre', width: 120,
                renderValue: this.renderGenreList
            },
            {key: 'released', name: 'Released', width: 120,
                renderValue: this.renderReleasedValue
            },
            {key: 'updated', name: 'Updated', width: 120,
                renderValue: this.renderUpdateValue
            },
            {key: 'countries', name: 'Markets', width: 100,
                renderValue: this.renderList
            },
            {key: 'developerEmailsList', name: 'Email', width: 120,
                renderValue: this.renderList
            },
            {key: 'hasVideo', name: 'Videos', width: 80, renderValue: this.renderBool},
            {key: 'url', name: 'Link', width: 120, cellValueWrapperFn: this.renderLink},
            {key: 'developerAppCount', name: 'App count', width: 80, renderValue: this.appCount},
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                    <div className="count-emails">
                        <div>Developers: {developersCount}</div>
                        <div>Emails: {emailsCount}</div>
                        <div>Records: {recordsCount}</div>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportAppStore}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        className="appStore-table"
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchAppStore}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.appStore.list,
        status: state.appStore.status,
        total: state.appStore.total,
        limit: state.appStore.limit,
        offset: state.appStore.offset,
        search: state.appStore.search,
        genres: state.appStore.genres,
        languages: state.appStore.languages,
        lastUpdate: state.appStore.lastUpdate,
        reviews: state.appStore.reviews,
        score: state.appStore.score,
        countriesCount: state.appStore.countriesCount,
        languagesCount: state.appStore.languagesCount,

        selectedLanguages: state.appStore.selectedLanguages,
        selectedGenres: state.appStore.selectedGenres,
        releasedDateRange: state.appStore.releasedDateRange,
        updatedDateRange: state.appStore.updatedDateRange,
        defaultDateRange: state.appStore.defaultDateRange,
        selectedCountry: state.appStore.selectedCountry,
        countryOptions: state.appStore.countryOptions,
        count: state.appStore.count,
        startDate: state.appStore.startDate,
        hasVideo: state.appStore.hasVideo,
        selectedHasVideo: state.appStore.selectedHasVideo,
        isOnlyChosenLanguages: state.appStore.isOnlyChosenLanguages,
        isOnlyChosenMarkets: state.appStore.isOnlyChosenMarkets,
        isCountLoading: state.appStore.isCountLoading,
        maxReviews: state.appStore.maxReviews,
        maxAppCount: state.appStore.maxAppCount,
        isFiltersSelected: state.appStore.isFiltersSelected,
        fieldsList: state.appStore.fieldsList,
        price: state.appStore.price,
        selectedPrice: state.appStore.selectedPrice,
        appCount: state.appStore.appCount,
        isRecordsCountLoading: state.appStore.isRecordsCountLoading,
        isDevelopersAndEmailsCountLoading: state.appStore.isDevelopersAndEmailsCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initAppStore: () => dispatch(initAppStore()),
        exportAppStoreRecords: (fields) => dispatch(exportAppStoreRecords(fields)),
        refreshAppStore: () => dispatch(refreshAppStore()),
        searchAppStore: (count) => dispatch(searchAppStore(count)),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterDataAppStore: () => dispatch(getFilterDataAppStore()),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        getExportCount: (fields) => dispatch(getExportCount(fields)),

        //filters
        setSearch: (search) => dispatch(setSearchAsync(search)),
        setLanguage: languages => dispatch(setLanguageAsync(languages)),
        setLanguageCount: count => dispatch(setLanguageCountAsync(count)),
        setCountryCount: count => dispatch(setCountryCountAsync(count)),
        setGenres: genres => dispatch(setGenresAsync(genres)),
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        setUpdated: (updated) => dispatch(setUpdatedAsync(updated)),
        setReviews: (reviews) => dispatch(setReviewsAsync(reviews)),
        setAppCount: (appCount) => dispatch(setAppCountAsync(appCount)),
        setScore: (score) => dispatch(setScoreAsync(score)),
        clearFilters: () => dispatch(clearFilters()),
        setCountry: (country) => dispatch(setCountryAsync(country)),
        getCount: () => dispatch(getCount()),
        setHasVideo: hasVideo => dispatch(setHasVideoAsync(hasVideo)),
        setIsOnlyChosenMarkets: isOnlyChosenMarkets => dispatch(setIsOnlyChosenMarkets(isOnlyChosenMarkets)),
        setIsOnlyChosenLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenLanguages(isOnlyChosenLanguages)),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
        getCountDevelopersAndEmails: () => dispatch(getCountDevelopersAndEmails()),
        setPrice: (price) => dispatch(setPriceAsync(price)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(appStore)