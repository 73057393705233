import React, {Component} from 'react';
import {connect} from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import join from "lodash/join";
import capitalize from "lodash/capitalize";
import replace from "lodash/replace";
import {setInitContainer, setTitle} from "../App/actions";
import {DATE_RANGE, DOWNLOAD_COUNT, SELECT} from "../../constants/FilterTypes";
import {SEARCH_GOOGLE_PLAY_REQUESTED} from "../../store/StatusTypes";
import {convertDateFormat, convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import Link from "../../components/Link";
import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import {
    refreshPlay, searchPlay, initPlay,
    setCategoryAsync, setPriceAsync, setOptionsCountAsync,
    setReleasedAsync, exportGooglePlay, setCountryAsync, setDownloadsAsync,
    setSearchAsync, getCountPlayRecords, getLastUpdate, getFilterData, getPlayExportCount,
    setUpdatedAsync, setHasVideoAsync, clearFiltersAsync, setScoreAsync, setAppCountAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";
import CategorySelect from "../../components/Select/CategorySelect";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getGooglePlayCategoriesOption, getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";
import ExportPopup from "../../components/Popup/components/ExportPopup";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import {setIsOnlyChosenMarkets} from "./actions";

const CONTAINER_NAME = 'Google Play';
const PAID = 'Paid';
const FREE = 'Free';

class GooglePlay extends Component {
    componentDidMount() {
        const {setTitle, setInitContainer, initContainers, getLastUpdate, status, getFilterData, initPlay} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initPlay();
            getLastUpdate();
            getFilterData();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
            //getCountPlayRecords();
        }
    }

    componentWillUnmount() {
        const {clearFilters} = this.props;
        clearFilters();
    }

    handleClearFilters = () => {
        const {clearFilters, initPlay} = this.props;
        clearFilters().then(() => initPlay());
    }

    onExportGooglePlayEmails = () => {
        const {exportGooglePlay, showPopup, fieldsList, getPlayExportCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getPlayExportCount}/>
        }

        const handleApply = (fields) => {
            exportGooglePlay(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    getCategorySelect = (props) => {
        const {categories} = this.props;
        const categoriesOption = getGooglePlayCategoriesOption(categories);
        return <CategorySelect {...props} options={categoriesOption}/>
    }

    getCountrySelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getCategoryValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getMarketsValue = (selectedOptions, options) => {
        const {countriesCount} = this.props;
        let countriesValue = '';
        if (selectedOptions.length === 0) {
            countriesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            countriesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            countriesValue = join(nameValues, ', ');
        }
        const countriesCountFrom = countriesCount.from ? `From ${countriesCount.from}` : '';
        const countriesCountTo = countriesCount.to ? `To ${countriesCount.to}` : '';
        if (countriesCountFrom && countriesCountTo) {
            return `${countriesCountFrom} ${countriesCountTo}; ${countriesValue}`;
        }
        else if (countriesCountFrom && !countriesCountTo) {
            return `${countriesCountFrom}; ${countriesValue}`;
        }
        else if (!countriesCountFrom && countriesCountTo) {
            return `${countriesCountTo}; ${countriesValue}`;
        }
        else {
            return countriesValue;
        }
    }

    onClearReleased = () => {
        const {setReleased, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => this.handleRefresh());
    }

    onClearUpdated = () => {
        const {setUpdated, startDate} = this.props;
        const defaultUpdated = getDefaultReleased(startDate);
        setUpdated(defaultUpdated).then(() => this.handleRefresh());
    }

    getFiltersOptions = () => {
        const {categories, selectedCountry, selectedCategories, setCategory, releasedDateRange, updatedDateRange, setHasVideo, countriesCount, isOnlyChosenMarkets,
            setReleased, countryOptions, setCountry, defaultDateRange, downloads, setDownloads, setUpdated, hasVideo, selectedHasVideo, setOptionsCount,
            setIsOnlyChosenMarkets, selectedPrice, setPrice, price, downloadsMax, score, setScore, appCount, setAppCount} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Categories',
                options: categories,
                customOptionsComponent: this.getCategorySelect,
                renderValue: this.getCategoryValue,
                selectedOptions: selectedCategories,
                popoverClassName: "popover-languages-filter",
                onChange: setCategory,
                submitButtonName: 'Apply',
            },
            {
                type: SELECT,
                title: 'Markets',
                options: countryOptions,
                customOptionsComponent: this.getCountrySelect,
                renderValue: this.getMarketsValue,
                selectedOptions: selectedCountry,
                onChange: setCountry,
                optionCount: countriesCount,
                setOptionsCount: setOptionsCount,
                filterName: "markets",
                popoverClassName: "popover-languages-filter",
                isOnlyChosen: isOnlyChosenMarkets,
                setIsOnlyChosen: setIsOnlyChosenMarkets,
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: DATE_RANGE,
                title: 'Updated',
                customWrapperClassName: 'date-input',
                initDates: updatedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearUpdated,
                onChange: setUpdated
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Downloads',
                value: downloads,
                onChange: setDownloads,
                className: 'download-count-filter',
                placeholder: {from: 0, to: downloadsMax}
            },
            {
                type: SELECT,
                title: 'Videos',
                options: hasVideo,
                renderValue: this.getCategoryValue,
                selectedOptions: selectedHasVideo,
                onChange: setHasVideo,
                columnsCount: 1,
            },
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getCategoryValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Score',
                value: score,
                onChange: setScore,
                rateValidate: true,
                submitButtonName: 'Submit',
                popoverClassName: 'download-count-filter',
                placeholder: {from: 1, to: 5}
            },
            {
                type: DOWNLOAD_COUNT,
                title: 'Count apps',
                value: appCount,
                onChange: setAppCount,
                submitButtonName: 'Submit',
                placeholder: {from: 0, to: ''}
            },
        ];

        return filtersOptions;
    };

    getLoading = () => {
        const {status, isLoadingPlayRecords, isLoadingCount} = this.props;
        return status === null
            || isLoadingPlayRecords
            || isLoadingCount
            || status === SEARCH_GOOGLE_PLAY_REQUESTED;
    }

    handleRefresh = () => {
        const {refreshPlay, getCountPlayRecords, categories, countriesCount, score, selectedCategories, search, downloads, releasedDateRange, selectedPrice,
            defaultDateRange, selectedCountry, updatedDateRange, selectedHasVideo} = this.props;
        const isDefaultCategory = categories.length === selectedCategories.length;
        const isDefaultSearch = isEmpty(search);
        const isDefaultDownloads = (downloads.hasOwnProperty("from") && (isEmpty(downloads.from) && isEmpty(downloads.to))) || isEmpty(downloads);
        const isDefaultReleased = defaultDateRange.endDateInput === releasedDateRange.endDateInput && defaultDateRange.startDateInput === releasedDateRange.startDateInput;
        const isDefaultUpdated = defaultDateRange.endDateInput === updatedDateRange.endDateInput && defaultDateRange.startDateInput === updatedDateRange.startDateInput;
        const isDefaultCountry = isEmpty(selectedCountry);
        const isDefaultHasVideo = selectedHasVideo.length === 2;
        const isDefaultPrice = selectedPrice.length === 2;
        const isDefaultCountriesCount = (countriesCount.hasOwnProperty("from") && (isEmpty(countriesCount.from) && isEmpty(countriesCount.to))) || isEmpty(countriesCount);
        const isDefaultScore = (score.hasOwnProperty("from") && (isEmpty(score.from) && isEmpty(score.to))) || isEmpty(score);

        const isDefaultFilter = isDefaultCategory && isDefaultSearch && isDefaultDownloads && isDefaultReleased && isDefaultCountry && isDefaultUpdated && isDefaultHasVideo && isDefaultPrice && isDefaultCountriesCount && isDefaultScore;

        refreshPlay();
        !isDefaultFilter && getCountPlayRecords();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderPriceCell = (row, column, value) => value ? FREE : PAID;

    renderCategoryCell = (row, column, value) => capitalize(replace(value, /_/g, ' '));

    renderUpdateValue = (row, column, value) => convertDateFormat(value, CONST.DATE_FORMAT_LL);

    renderReleasedValue = (row, column, value) => {
        return convertTimestampFormat(value, CONST.DATE_FORMAT_LL);
    }

    renderLink = (value) => <Link path={value} value={value}/>;

    renderBool = (row, column, value) => {
        if (value) {
            return value.value ? 'Yes' : 'No';
        }
        else {
            return '';
        }
    }

    appCount = (row, column, value) => {
        return value || '-';
    }

    render() {
        const {list, total, limit, offset, searchPlay, search, setSearch, lastUpdate, title, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'title', name: 'Name',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'category', name: 'Category', width: 200,
                renderValue: this.renderCategoryCell
            },
            {key: 'score', name: 'Score', width: 70},
            {key: 'rating', name: 'Downloads', width: 102,
                renderValue: renderBigNumbers
            },
            {key: 'free', name: 'Price', width: 64,
                renderValue: this.renderPriceCell
            },
            {key: 'developer', name: 'Developer', width: 250},
            {key: 'email', name: 'Email', width: 220},
            {key: 'developerWebsite', name: 'Website', width: 220,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'released', name: 'Released', width: 120,
                renderValue: this.renderReleasedValue
            },
            {key: 'updated', name: 'Updated', width: 120,
                renderValue: this.renderUpdateValue
            },
            {key: 'url', name: 'URL', width: 300,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'developerAddress', name: 'Address', width: 300},
            {key: 'store', name: 'Markets', width: 150},
            {key: 'hasVideo', name: 'Videos', width: 80, renderValue: this.renderBool},
            {key: 'developerAppCount', name: 'App count', width: 80, renderValue: this.appCount},
        ]

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportGooglePlayEmails}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchPlay}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.googlePlay.list,
        status: state.googlePlay.status,
        total: state.googlePlay.total,
        limit: state.googlePlay.limit,
        offset: state.googlePlay.offset,
        isLoadingPlayRecords: state.googlePlay.isLoadingPlayRecords,
        isLoadingCount: state.googlePlay.isLoadingCount,

        downloads: state.googlePlay.downloads,
        selectedCategories: state.googlePlay.selectedCategories,
        releasedDateRange: state.googlePlay.releasedDateRange,
        updatedDateRange: state.googlePlay.updatedDateRange,
        defaultDateRange: state.googlePlay.defaultDateRange,
        selectedCountry: state.googlePlay.selectedCountry,
        search: state.googlePlay.search,
        lastUpdate: state.googlePlay.lastUpdate,
        categories: state.googlePlay.categories,
        fieldsList: state.googlePlay.fieldsList,
        countriesCount: state.googlePlay.countriesCount,
        startDate: state.googlePlay.startDate,
        hasVideo: state.googlePlay.hasVideo,
        selectedHasVideo: state.googlePlay.selectedHasVideo,
        isOnlyChosenMarkets: state.googlePlay.isOnlyChosenMarkets,
        price: state.googlePlay.price,
        selectedPrice: state.googlePlay.selectedPrice,
        downloadsMax: state.googlePlay.downloadsMax,
        isFiltersSelected: state.googlePlay.isFiltersSelected,
        score: state.googlePlay.score,
        appCount: state.googlePlay.appCount,

        countryOptions: state.app.countryOptions,
        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCountPlayRecords: () => dispatch(getCountPlayRecords()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        searchPlay: (count) => dispatch(searchPlay(count)),
        refreshPlay: () => dispatch(refreshPlay()),
        setCategory: (category) => dispatch(setCategoryAsync(category)),
        setCountry: (country) => dispatch(setCountryAsync(country)),
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        setUpdated: (updated) => dispatch(setUpdatedAsync(updated)),
        setPrice: (price) => dispatch(setPriceAsync(price)),
        setDownloads: (downloads) => dispatch(setDownloadsAsync(downloads)),
        setSearch: search => dispatch(setSearchAsync(search)),
        exportGooglePlay: (fields) => dispatch(exportGooglePlay(fields)),
        getFilterData: () => dispatch(getFilterData()),
        getPlayExportCount: fields => dispatch(getPlayExportCount(fields)),
        setOptionsCount: count => dispatch(setOptionsCountAsync(count)),
        setHasVideo: hasVideo => dispatch(setHasVideoAsync(hasVideo)),
        initPlay: () => dispatch(initPlay()),
        clearFilters: () => dispatch(clearFiltersAsync()),
        setIsOnlyChosenMarkets: isOnlyChosenMarkets => dispatch(setIsOnlyChosenMarkets(isOnlyChosenMarkets)),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        setScore: score => dispatch(setScoreAsync(score)),
        setAppCount: (appCount) => dispatch(setAppCountAsync(appCount)),

        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GooglePlay)