import {
    getServiceHealthRequest,
    getAllServicesHealthRequest,
    getContactsPagedRequest
} from "../../services/grpcBufServices";
import {
    closePopup,
    requestedGetHealth, showPopup,
    succeedGetHealth,
    succeedInitApp,
    succeedGetInfo,
    requestedGetAllHealth,
    succeedGetAllHealth,
    succeedIsMagnetFailed
} from "./actions";
import {
    getUserInfoRequest,
    googleAuthRequest
} from "../../services/grpcServices";
import {onGoogleAuthError} from "../../helpers/catchErrorUtils";
import {getSortOrder} from "../../helpers/utils";

export const initApp = (tokenId) =>
    async (dispatch) => {
        const response = await googleAuthRequest(tokenId, onGoogleAuthError);
        dispatch(succeedInitApp(response));
        return response;
    };

export const getUserInfo = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getUserInfoRequest(token);
        dispatch(succeedGetInfo(response));
    };

export const getHealth = (service) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestedGetHealth(service));
        const healthService = await getServiceHealthRequest(token, service);
        dispatch(succeedGetHealth(healthService, service));
    };

export const getAllHealth = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestedGetAllHealth());
        await getAllServicesHealthRequest(token, dispatch, succeedGetHealth);
        dispatch(succeedGetAllHealth());
    };

export const showPopupRequest = (popup) =>
    async (dispatch, getState) => {
        dispatch(showPopup(popup));
    };

export const closePopupRequest = () =>
    async (dispatch, getState) => {
        dispatch(closePopup());
    };

export const checkIsFailedStatus = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const failedStatus = 5;
        const order = getSortOrder('asc');
        const salesFailedContacts = await getContactsPagedRequest(token, 10, 0, '', 1, failedStatus, order);
        const vendorsFailedContacts = await getContactsPagedRequest(token, 10, 0, '', 2, failedStatus, order);
        const isMagnetSalesHealthFailed = !!salesFailedContacts.total;
        const isMagnetVendorsHealthFailed = !!vendorsFailedContacts.total;
        dispatch(succeedIsMagnetFailed(isMagnetSalesHealthFailed, isMagnetVendorsHealthFailed));
    };