import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";
import {appStoreFieldsList} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    reviews: {},
    score: {},
    appCount: {},
    count: '',
    isCountLoading: false,
    isRecordsCountLoading: false,
    isDevelopersAndEmailsCountLoading: false,
    maxReviews: '',
    maxAppCount: '',
    isFiltersSelected: false,
    fieldsList: appStoreFieldsList,
    price: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],
    selectedPrice: [
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'}
    ],

    genres: [],
    selectedGenres: [],

    languagesCount: {from: '', to: ''},
    countriesCount: {from: '', to: ''},
    languages: [],
    selectedLanguages: [],
    selectedCountry: [],
    countryOptions: [],
    hasVideo: [
        {id: 1, name: "Yes"},
        {id: 2, name: "No"},
    ],
    selectedHasVideo: [
        {id: 1, name: "Yes"},
        {id: 2, name: "No"},
    ],
    isOnlyChosenMarkets: false,
    isOnlyChosenLanguages: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    updatedDateRange: getDefaultReleased(),
    startDate: null
};

export default function appStore(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_APP_STORE:
            return {
                ...state,
                status: statuses.INIT_APP_STORE_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_APP_STORE:
            return {
                ...state,
                status: statuses.INIT_APP_STORE_SUCCEEDED,
                list: action.response.result
            }
        case actions.REQUEST_APP_STORE_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: {},
            }
        case actions.SUCCEED_APP_STORE_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_REFRESH_APP_STORE:
            return {
                ...state,
                status: statuses.INIT_APP_STORE_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_REFRESH_APP_STORE:
            return {
                ...state,
                status: statuses.INIT_APP_STORE_SUCCEEDED,
                list: action.response.result
            }
        case actions.REQUEST_SEARCH_APP_STORE:
            return {
                ...state,
                status: statuses.SEARCH_APP_STORE_REQUESTED,
                limit: action.limit,
                offset: action.offset,
                isCountLoading: true,
            }
        case actions.SUCCEED_SEARCH_APP_STORE:
            return {
                ...state,
                status: statuses.SEARCH_APP_STORE_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                isCountLoading: false,
            }
        case actions.SET_APP_STORE_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_SELECTED_LANGUAGES_APP_STORE:
            return {
                ...state,
                selectedLanguages: action.selectedLanguage,
            }
        case actions.SET_SELECTED_GENRES_APP_STORE:
            return {
                ...state,
                selectedGenres: action.selectedGenres,
            }
        case actions.SET_LANGUAGE_COUNT_APP_STORE:
            return {
                ...state,
                languagesCount: action.languagesCount,
            }
        case actions.SET_COUNTRY_COUNT_APP_STORE:
            return {
                ...state,
                countriesCount: action.countriesCount,
            }
        case actions.SET_APP_STORE_UPDATED:
            return {
                ...state,
                updatedDateRange: action.updated,
            }
        case actions.SET_APP_STORE_RELEASED:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_APP_STORE_REVIEWS:
            return {
                ...state,
                reviews: action.reviews,
            }
        case actions.SET_APP_STORE_APP_COUNT:
            return {
                ...state,
                appCount: action.appCount,
            }
        case actions.SET_APP_STORE_SCORE:
            return {
                ...state,
                score: action.score,
            }
        case actions.CLEAR_FILTERS_APP_STORE:
            return {
                ...state,
                reviews: {},
                score: {},
                appCount: {},
                selectedGenres: state.genres,
                selectedLanguages: state.languages,
                selectedCountry: state.countryOptions,
                releasedDateRange: getDefaultReleased(state.startDate),
                updatedDateRange: getDefaultReleased(state.startDate),
                defaultDateRange: getDefaultReleased(state.startDate),
                selectedHasVideo: state.hasVideo,
                languagesCount: {from: '', to: ''},
                countriesCount: {from: '', to: ''},
                isOnlyChosenLanguages: false,
                selectedPrice: [
                    {id: 1, name: 'Free'},
                    {id: 2, name: 'Paid'}
                ],
            }
        case actions.SUCCEED_APP_STORE_FILTER_DATA:
            const startDate = action.response.releasedStartDate;
            return {
                ...state,
                languages: action.response.languages,
                selectedLanguages: action.response.languages,
                selectedCountry: action.response.countries,
                genres: action.response.genres,
                selectedGenres: action.response.genres,
                countryOptions: action.response.countries,
                releasedDateRange: getDefaultReleased(startDate),
                updatedDateRange: action.isInsightsRequest ? state.updatedDateRange : getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                startDate: startDate,
                maxReviews: action.response.maxReviews,
                maxAppCount: action.response.maxAppCount,
            }
        case actions.SET_APP_STORE_COUNTRY:
            return {
                ...state,
                selectedCountry: action.selectedCountry,
            }
        case actions.REQUEST_GET_COUNT_APP_STORE:
            return {
                ...state,
                isCountLoading: true,
                isRecordsCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_APP_STORE:
            return {
                ...state,
                total: action.count?.records,
                isCountLoading: false,
                isRecordsCountLoading: false
            }
        case actions.SET_APP_STORE_HAS_VIDEO:
            return {
                ...state,
                selectedHasVideo: action.hasVideo,
            }
        case actions.SET_IS_ONLY_CHOSEN_LANGUAGES_APP_STORE:
            return {
                ...state,
                isOnlyChosenLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_ONLY_CHOSEN_MARKETS_APP_STORE:
            return {
                ...state,
                isOnlyChosenMarkets: action.isOnlyChosenMarkets,
            }
        case actions.SET_IS_FILTER_SELECTED_APP_STORE:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.REQUEST_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE:
            return {
                ...state,
                isDevelopersAndEmailsCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE:
            return {
                ...state,
                count: action.count,
                isDevelopersAndEmailsCountLoading: false
            }
        case actions.SET_APP_STORE_PRICE:
            return {
                ...state,
                selectedPrice: action.selectedPrice,
            }
        default:
            return state;
    }
}
