import {
    removeTwitterAudienceRequest,
    createTwitterAudienceDonorRequest,
    exportTwitterAudienceRequest,
    getTwitterAudienceByNameRequest,
    getTwitterAudienceDonorsRequest,
    removeTwitterAudienceDonorRequest,
    sendAudienceToTwitterRequest,
    renameTwitterAudienceDonorRequest
} from "../../services/grpcBufServices";
import {
    requestGetAudienceByName,
    requestRemoveAudience,
    requestSearchTwitterDonors,
    succeedGetAudienceByName,
    succeedRemoveAudience,
    succeedSearchTwitterDonors,
    setSearchTwitterDonors,
    requestCreateTwitterDonor,
    succeedCreateTwitterDonor,
    requestRemoveTwitterDonor,
    succeedRemoveTwitterDonor,
    requestSendAudienceToTwitter,
    succeedSendAudienceToTwitter,
    requestRenameTwitterAudience,
    succeedRenameTwitterAudience,
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";

export const initTwitterItem = (name) =>
    async (dispatch, getState) => {
        const {app: {token}, twitterAudience: {limit, offset, search}} = getState();
        dispatch(requestGetAudienceByName());
        const response = await getTwitterAudienceByNameRequest(token, name);
        const donors = await getTwitterAudienceDonorsRequest(token, limit, offset, search, response.audience.id);
        dispatch(succeedGetAudienceByName(response.audience, donors));
    };

export const refreshTwitterDonors = () =>
    async (dispatch, getState) => {
        const {app: {token}, twitterAudience: {audience, limit, offset, search}} = getState();
        dispatch(requestGetAudienceByName());
        const donors = await getTwitterAudienceDonorsRequest(token, limit, offset, search, audience.id);
        dispatch(succeedGetAudienceByName(audience, donors));
    };

export const addTwitterDonor = (name) =>
    async (dispatch, getState) => {
        const {app: {token}, twitterAudience: {audience: {id}}} = getState();
        dispatch(requestCreateTwitterDonor());
        const response = await createTwitterAudienceDonorRequest(token, name, id);
        dispatch(succeedCreateTwitterDonor(response));
    };

export const removeTwitterDonor = (donorId) =>
    async (dispatch, getState) => {
        const {app: {token}, twitterAudience: {audience: {id}}} = getState();
        dispatch(requestRemoveTwitterDonor(donorId));
        const response = await removeTwitterAudienceDonorRequest(token, donorId, id);
        dispatch(succeedRemoveTwitterDonor(response));
    };

export const renameTwitterAudience = (id, name) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestRenameTwitterAudience(id, name));
        await renameTwitterAudienceDonorRequest(token, id, name);
        dispatch(succeedRenameTwitterAudience());
    };

export const sendAudienceToTwitter = (id) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestSendAudienceToTwitter());
        await sendAudienceToTwitterRequest(token, id);
        dispatch(succeedSendAudienceToTwitter());
    };

export const searchTwitterDonors = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, twitterAudience: {audience, search, limit, offset}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchTwitterDonors(updateLimit, updateOffset));
        const donors = await getTwitterAudienceDonorsRequest(token, updateLimit, updateOffset, search, audience.id);
        dispatch(succeedSearchTwitterDonors(donors));
    };

export const removeAudienceItem = (id) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestRemoveAudience());
        const response = await removeTwitterAudienceRequest(token, id);
        dispatch(succeedRemoveAudience(response, id));
    };

export const exportAudience = (id, exportType) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestExport());
        const exportId = await exportTwitterAudienceRequest(token, id, exportType);
        dispatch(succeedExport(exportId.task.id));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearchTwitterDonors(search));
    };