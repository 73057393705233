import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';

import './css/snackbar.css';

const UpdateAppSnackbar = () => {
    const [isLatestVersion, setIsLatestVersion] = useState(true);
    const [appVersion, setAppVersion] = useState('');
    const [isToggle, setIsToggle] = useState(true);

    const fetchMeta = useCallback(() => {
        const origin = window.location.origin;
        const jsonPathFrom = origin + '/meta.json';
        fetch(jsonPathFrom, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'cache': 'no-store'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then((meta) => {
                            const newVersion = meta.buildDate;
                            const isUpdated = newVersion === appVersion;
                            if (!isUpdated && !appVersion) {
                                setIsToggle(!isToggle);
                                setAppVersion(newVersion);
                            } else if (!isUpdated && appVersion) {
                                setIsLatestVersion(false);
                            } else {
                                setIsLatestVersion(true);
                                setIsToggle(!isToggle);
                            }
                        })
                        .catch((e)=>{
                            console.log('Error parsing JSON: ', e)
                        })
                } else {
                    throw new Error('Error getting JSON: ')
                }
            })
            .catch((e) => {
                console.log(e)
            });
    }, [appVersion, isToggle]);

    useEffect(() => {
        const duration = 60 * 1000;
        const fetchCacheTimeout = setInterval(() => {
            fetchMeta();
        }, duration);
        return () => {
            clearInterval(fetchCacheTimeout);
        };
    }, [isToggle, fetchMeta]);

    const containerClassNames = classNames("snackbar__container update-app-snackbar__container position content", {
        "show": !isLatestVersion
    });

    const handleReloadPage = () => {
        window.location.reload();
    };

    const handleCloseNotification = () => {
        setIsLatestVersion(true);
    }

    return (
        <div className={containerClassNames}>
            <div className="title">New version is availabale. Please refresh!</div>
            <div className="button-snackbar" onClick={handleReloadPage}>Refresh</div>
            <div className="close-snackbar" onClick={handleCloseNotification}/>
        </div>
    );
}

export default UpdateAppSnackbar;