import React from "react";
import ReactDOM from "react-dom";

class LastUpdatePortal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.classList.add("last-update-text");
    }

    componentDidMount() {
        const {className} = this.props;
        const modalRoot = document.getElementById('last-update');
        modalRoot && modalRoot.appendChild(this.el);
        className && modalRoot.classList.add(className);
    }

    componentWillUnmount() {
        const modalRoot = document.getElementById('last-update');
        modalRoot && modalRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}

export default LastUpdatePortal;