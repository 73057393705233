import isEqual from "lodash/isEqual";
import {
    getLastUpdateSteamPublishersRequest,
    steamPublishersRequest,
    exportSteamPublishersRequest,
    getSteamFilterData
} from "../../services/grpcBufServices";
import {
    requestInitSteamPublishers,
    requestSearchSteamPublishers,
    succeedInitSteamPublishers,
    succeedSearchSteamPublishers,
    setSearch, setReleased,
    requestLastUpdate, succeedLastUpdate,
    setSteamPublishersFilterData,
    setProductCount, clearFiltersSteamPublishers
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";
import {getReleasedValue} from "../../helpers/dateUtils";

export const initSteamPublishers = () =>
    async (dispatch, getState) => {
        const {app: {token}, steamPublishers: {limit, offset, search, releasedDateRange}} = getState();
        dispatch(requestInitSteamPublishers());
        const released = getReleasedValue(releasedDateRange);
        const response = await steamPublishersRequest(token, limit, offset, search, released);
        dispatch(succeedInitSteamPublishers(response));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getSteamFilterData(token);
        dispatch(setSteamPublishersFilterData(response))
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getLastUpdateSteamPublishersRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const refreshSteamPublishers = () =>
    async (dispatch, getState) => {
        const {app: {token}, steamPublishers: {limit, offset, defaultDateRange, search, releasedDateRange, productCount}} = getState();
        dispatch(requestInitSteamPublishers());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const response = await steamPublishersRequest(token, limit, offset, search, releasedDate, productCount);

        dispatch(succeedInitSteamPublishers(response));
    };

export const searchSteamPublishers = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, steamPublishers: {limit, offset, defaultDateRange, search, productCount, releasedDateRange}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchSteamPublishers(updateLimit, updateOffset));
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const response = await steamPublishersRequest(token, updateLimit, updateOffset, search, releasedDate, productCount);

        dispatch(succeedSearchSteamPublishers(response));
    };

export const exportSteamPublishers = () =>
    async (dispatch, getState) => {
        const {app: {token}, steamPublishers: {limit, offset, defaultDateRange, search, productCount, releasedDateRange}} = getState();
        dispatch(requestExport());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const exportId = await exportSteamPublishersRequest(token, limit, offset, search, releasedDate, productCount);
        dispatch(succeedExport(exportId.task.id));
    };

export const setSearchAsync = search =>
    async (dispatch, getState) => {
        dispatch(setSearch(search));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setProductCountAsync = (productCount) =>
    async (dispatch, getState) => {
        dispatch(setProductCount(productCount));
    };

export const clearFilters = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersSteamPublishers());
    };