import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";

const NavigationItem = ({icon, name, isActive, isAdminRoute, isAdmin, healthName, isHasChildren, isLastDatabases,
                            onNavigate, route, nestedRoutes, activeKey, isHover, allHealth}) => {

    const [isOpen, setOpen] = useState(false);

    const getHealthError = (healthName) => {
        if (typeof healthName === "object") {
            return map(healthName, item => {
                if (item && !isEmpty(allHealth)) {
                    const serviceHealth = allHealth[item]?.healthArray;
                    if (serviceHealth && serviceHealth.length) {
                        return checkHealthFailed(serviceHealth);
                    }
                }
            })
        }
        else if (healthName && !isEmpty(allHealth)) {
            const serviceHealth = allHealth[healthName]?.healthArray;
            if (serviceHealth && serviceHealth.length) {
                return checkHealthFailed(serviceHealth);
            }
        }
    }

    const checkHealthFailed = (health) => {
        let styleList = [];
        forEach(health, item => {
            let style = "";
            const {lastExecutionStatus, lastFailedCount} = item;
            if (lastExecutionStatus === 2) {
                style = 'failed';
            } else if (lastExecutionStatus === 0 && lastFailedCount > 0) {
                style = 'warning';
            }
            styleList.push(style);
        })
        return includes(styleList, "failed")
            ? "failed"
            : includes(styleList, "warning")
                ? "warning"
                : "";
    };

    useEffect(() => {
        isActive ? setOpen(true) : setOpen(false);
    }, [isHover, isActive]);

    const containerClassName = classNames('navigation-menu__item', {
        'active-route': isActive,
        'hidden': !isAdmin && isAdminRoute,
        'visible-nested': isOpen && isHasChildren,
        'last-databases-item': isLastDatabases,
    });

    const handleClick = () => {
        isHasChildren
            ? setOpen(!isOpen)
            : onNavigate(route);
    }

    const getNestedRoutes = () => {
        return map(nestedRoutes, item => {
            const {name, route, permissionKey, healthName} = item;
            const isHealthError = getHealthError(healthName);
            const healthClassName = classNames('warning-icon', isOpen && isHealthError);
            const isActive = includes(activeKey, permissionKey);
            const nestedContainerClassName = classNames('navigation-menu__item', 'nested-route', {
                'visible': isOpen,
                'active-route': isActive,
                'hidden': !isAdmin && isAdminRoute
            });
            return <div className={nestedContainerClassName} onClick={() => onNavigate(route)} key={name}>
                <div className={healthClassName}/>
                <div className="navigation-menu__item-name">
                    {name}
                </div>
            </div>
        })
    }

    const healthError = getHealthError(healthName);
    const iconClassName = classNames('navigation-menu__icon', icon);
    const healthClassName = classNames('warning-icon', healthError);
    const arrowClassName = classNames('arrow-close-icon');
    const renderNestedRoutes = nestedRoutes && getNestedRoutes();

    return (
        <>
            <div className={containerClassName} onClick={handleClick}>
                <div className={healthClassName}/>
                <div className={iconClassName}/>
                <div className="navigation-menu__item-name">
                    {name}
                </div>
                {isHasChildren && <div className={arrowClassName}/>}
            </div>
            {renderNestedRoutes}
        </>
    );
}

export default (NavigationItem);