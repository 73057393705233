import {requestGetMagnetMessage, succeedGetMagnetMessage} from "./actions";
import {getMagnetMessageRequest, approveContactInCloseRequest, rejectMagnetContactRequest, skipMagnetContactRequest} from "../../services/grpcBufServices";
import {onBlockUi, onUnblockUi} from '../App/actions';
import {handleServiceError} from "../../helpers/catchErrorUtils";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";

export const initMagnetDecide = (account) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetMagnetMessage(account));
        const response = await getMagnetMessageRequest(token, account);
        let message = {};
        let error = {};
        forEach(response, res => {
            if (res?.result.case === 'message') {
                message = res;
            }
            else if (res?.result.case === 'error') {
                error = res;
            }
        })
        if (!isEmpty(error)) {
            handleServiceError(0, error?.result.value.failReason);
        }
        !isEmpty(message)
            ? dispatch(succeedGetMagnetMessage(message))
            : dispatch(succeedGetMagnetMessage(error));
    };

export const refreshMagnetDecide = () =>
    async (dispatch, getState) => {
        const {app: {token}, magnetVendorsDecide: {magnetAccount}} = getState();
        dispatch(requestGetMagnetMessage(magnetAccount));
        const response = await getMagnetMessageRequest(token, magnetAccount);
        let message = {};
        let error = {};
        forEach(response, res => {
            if (res?.result.case === 'message') {
                message = res;
            }
            else if (res?.result.case === 'error') {
                error = res;
            }
        })
        if (!isEmpty(error)) {
            handleServiceError(0, error?.result.value.failReason);
        }
        !isEmpty(message)
            ? dispatch(succeedGetMagnetMessage(message))
            : dispatch(succeedGetMagnetMessage(error));
    };

export const approveContactInClose = (email) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetVendorsDecide: {magnetAccount}} = getState();
        dispatch(onBlockUi());
        const response = await approveContactInCloseRequest(token, magnetAccount, email);
        dispatch(onUnblockUi());
        return response;
    };

export const skipMagnetContact = (email) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetVendorsDecide: {magnetAccount}} = getState();
        dispatch(onBlockUi());
        const response = await skipMagnetContactRequest(token, magnetAccount, email);
        dispatch(onUnblockUi());
        return response;
    };

export const rejectMagnetContact = (email) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetVendorsDecide: {magnetAccount}} = getState();
        dispatch(onBlockUi());
        const response = await rejectMagnetContactRequest(token, magnetAccount, email);
        dispatch(onUnblockUi());
        return response;
    };