import {
    getCloseRecordsRequest, getCloseFilterDataRequest, exportCloseEmailsRequest,
    getCloseLastUpdateRequest, exportCloseRequest, getCountClose
} from "../../services/grpcBufServices";
import {
    requestInitClose,
    requestSearchClose, setSearch,
    succeedInitClose,
    succeedSearchClose,
    requestLastUpdateClose,
    succeedLastUpdateClose,
    succeedCloseFilterData,
    succeedGetCountClose,
    setCategory,
    clearFiltersClose,
    requestGetCountClose
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";

export const initClose = () =>
    async (dispatch, getState) => {
        const {app: {token}, close: {limit, offset, search}} = getState();
        dispatch(requestInitClose());
        const response = await getCloseRecordsRequest(token, limit, offset, search);
        dispatch(succeedInitClose(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateClose());
        const lastUpdate = await getCloseLastUpdateRequest(token);
        dispatch(succeedLastUpdateClose(lastUpdate));
    };

export const refreshClose = () =>
    async (dispatch, getState) => {
        const {app: {token}, close: {limit, offset, search, selectedCategory, categoriesList}} = getState();
        const categoriesOptions = selectedCategory.length === categoriesList.length ? [] : selectedCategory;
        dispatch(requestInitClose());
        const response = await getCloseRecordsRequest(token, limit, offset, search, categoriesOptions);
        dispatch(succeedInitClose(response));
    };

export const searchClose = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, close: {limit, offset, search, selectedCategory, categoriesList}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const categoriesOptions = selectedCategory.length === categoriesList.length ? [] : selectedCategory;
        dispatch(requestSearchClose(updateLimit, updateOffset));
        const response = await getCloseRecordsRequest(token, updateLimit, updateOffset, search, categoriesOptions);
        dispatch(succeedSearchClose(response));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getCloseFilterDataRequest(token);
        dispatch(succeedCloseFilterData(response));
    };

export const exportClose = () =>
    async (dispatch, getState) => {
        const {app: {token}, close: {limit, offset, search, selectedCategory, categoriesList}} = getState();
        const categoriesOptions = selectedCategory.length === categoriesList.length ? [] : selectedCategory;
        dispatch(requestExport());
        const exportId = await exportCloseRequest(token, limit, offset, search, categoriesOptions);
        dispatch(succeedExport(exportId.task.id));
    };

export const exportCloseEmails = () =>
    async (dispatch, getState) => {
        const {app: {token}, close: {search, selectedCategory, categoriesList}} = getState();
        const categoriesOptions = selectedCategory.length === categoriesList.length ? [] : selectedCategory;
        dispatch(requestExport());
        const exportId = await exportCloseEmailsRequest(token, search, categoriesOptions);
        dispatch(succeedExport(exportId.task.id));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearch(search))
    };

export const setCategoryAsync = (selectedCategory) =>
    async (dispatch, getState) => {
        dispatch(setCategory(selectedCategory))
    };

export const getCount = () =>
    async (dispatch, getState) => {
        dispatch(requestGetCountClose());
        const {app: {token}, close: {search, selectedCategory, categoriesList}} = getState();
        const categoriesOptions = selectedCategory.length === categoriesList.length ? [] : selectedCategory;
        const count = await getCountClose(token, search, categoriesOptions);
        dispatch(succeedGetCountClose(count));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersClose());
    };