export const REQUEST_INIT_EU_STARTUPS = 'REQUEST_INIT_EU_STARTUPS';
export const SUCCEED_INIT_EU_STARTUPS = 'SUCCEED_INIT_EU_STARTUPS';
export const REQUEST_LAST_UPDATE_EU_STARTUPS = 'REQUEST_LAST_UPDATE_EU_STARTUPS';
export const SUCCEED_LAST_UPDATE_EU_STARTUPS = 'SUCCEED_LAST_UPDATE_EU_STARTUPS';
export const REQUEST_SEARCH_EU_STARTUPS = 'REQUEST_SEARCH_EU_STARTUPS';
export const SUCCEED_SEARCH_EU_STARTUPS = 'SUCCEED_SEARCH_EU_STARTUPS';
export const GET_EU_STARTUPS_FILTER_DATA = 'GET_EU_STARTUPS_FILTER_DATA';
export const SET_EU_STARTUPS_SEARCH = 'SET_EU_STARTUPS_SEARCH';
export const SET_EU_STARTUPS_COUNTRY = 'SET_EU_STARTUPS_COUNTRY';
export const REQUEST_GET_COUNT_EU_STARTUPS = 'REQUEST_GET_COUNT_EU_STARTUPS';
export const SUCCEED_GET_COUNT_EU_STARTUPS = 'SUCCEED_GET_COUNT_EU_STARTUPS';
export const CLEAR_FILTERS_EU_STARTUPS = 'CLEAR_FILTERS_EU_STARTUPS';
export const SET_IS_FILTER_SELECTED_EU_STARTUPS = 'SET_IS_FILTER_SELECTED_EU_STARTUPS';