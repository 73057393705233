import * as types from "./constants";

export function requestInitEpic() {
    return {
        type: types.REQUEST_INIT_EPIC
    };
}

export function succeedInitEpic(response) {
    return {
        type: types.SUCCEED_INIT_EPIC,
        response
    };
}

export function setPrice(selectedPrice) {
    return {
        type: types.SET_PRICE_EPIC,
        selectedPrice
    };
}

export function requestSearchEpic(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_EPIC,
        limit, offset
    };
}

export function succeedSearchEpic(response) {
    return {
        type: types.SUCCEED_SEARCH_EPIC,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_EPIC_SEARCH,
        search
    };
}

export function setEpicSelectedAudioLanguages(selectedLanguages) {
    return {
        type: types.SET_SELECTED_AUDIO_LANGUAGES_EPIC,
        selectedLanguages
    };
}

export function setEpicSelectedTextLanguages(selectedLanguages) {
    return {
        type: types.SET_SELECTED_TEXT_LANGUAGES_EPIC,
        selectedLanguages
    };
}

export function setAudioLanguageCount(languagesCount) {
    return {
        type: types.SET_AUDIO_LANGUAGES_COUNT_EPIC,
        languagesCount
    };
}

export function setTextLanguageCount(languagesCount) {
    return {
        type: types.SET_TEXT_LANGUAGES_COUNT_EPIC,
        languagesCount
    };
}

export function setEpicSelectedTags(selectedTags) {
    return {
        type: types.SET_SELECTED_TAGS_EPIC,
        selectedTags
    };
}

export function setReleased(released) {
    return {
        type: types.SET_RELEASED_EPIC,
        released
    };
}

export function requestLastUpdateEpic() {
    return {
        type: types.REQUEST_LAST_UPDATE_EPIC,
    };
}

export function succeedLastUpdateEpic(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_EPIC,
        lastUpdate
    };
}

export function succeedGetEpicFilterData(response) {
    return {
        type: types.GET_EPIC_FILTER_DATA,
        response
    };
}

export function requestGetCountEpic() {
    return {
        type: types.REQUEST_GET_COUNT_EPIC,
    };
}

export function succeedGetCountEpic(count) {
    return {
        type: types.SUCCEED_GET_COUNT_EPIC,
        count
    };
}

export function setIsOnlyChosenAudioLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_AUDIO_LANGUAGES_EPIC,
        isOnlyChosenLanguages
    };
}

export function setIsOnlyChosenTextLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_TEXT_LANGUAGES_EPIC,
        isOnlyChosenLanguages
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_EPIC,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_EPIC,
        isFiltersSelected
    };
}