import React, {Component} from 'react';
import {connect} from "react-redux";
import Loader from "./../Loader";
import {getUserInfo} from "../../containers/App/actionsAsync";
import '../Loader/css/loader.css';

class AuthProvider extends Component {

    state = {
        isShowLoadingTitle: true,
    };

    componentDidMount() {
        const {getUserInfo} = this.props;
        getUserInfo().then(() => {
            this.toggleShowLoading();
        });
    }

    toggleShowLoading = () => {
        this.setState((state) => {
            return {
                isShowLoadingTitle: !state.isShowLoadingTitle
            }
        })
    }

    render() {
        const {isShowLoadingTitle} = this.state;
        const {children} = this.props;

        return isShowLoadingTitle ? <Loader/> : <React.Fragment>{children}</React.Fragment>;
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUserInfo: () => dispatch(getUserInfo()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthProvider);