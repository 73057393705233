import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/search-input.css';

class SearchInput extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onMouseDown: PropTypes.func,
    }

    state = {
        value: this.props.value || ''
    }

    onChange = (e) => {
        const {onChange, isSearchOnEmpty} = this.props;
        const value = e.target.value;
        if (value === '' && isSearchOnEmpty) {
            this.onClearClick();
        }
        this.setState({value}, () => {
            onChange && onChange(value);
        })
    }

    onSearchClick = () => {
        const {value} = this.state;
        const {onSearch, refreshPage} = this.props;
        onSearch && onSearch(value).then(() => {
            refreshPage && refreshPage();
        })
    }

    handleKeyDown = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
            this.onSearchClick();
        }
    }

    onClearClick = () => {
        const {onChange, onSearch, refreshPage} = this.props;
        this.setState({
            value: ''
        }, () => {
            onChange && onChange('');
        });
        onSearch && onSearch('').then(() => {
            refreshPage && refreshPage();
        })
    }

    render() {
        const {value} = this.state;
        const {value: valueProps, placeholder, className, onMouseDown} = this.props;

        const inputClassName = classNames("search-input-root search-input-text", className);

        return (
            <div className={inputClassName}>
                <input
                    className="kraken-search-input"
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={this.handleKeyDown}
                    onMouseDown={onMouseDown}
                    onChange={this.onChange}
                />
                {!!valueProps
                    ? <div className="search-input-close-icon" onClick={this.onClearClick}/>
                    : <div className="search-input-icon" onClick={this.onSearchClick}/>
                }
            </div>
        );
    }
}

export default (SearchInput);
