import React, {Component} from 'react';

import './css/loading.css';

class Loading extends Component {

    render() {
        return (
            <div className="loading"/>
        );
    }
}

export default (Loading);
