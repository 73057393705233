import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckboxItem from "./CheckboxItem";
import map from "lodash/map";
import intersectionBy from "lodash/intersectionBy";
import forEach from "lodash/forEach";
import findIndex from "lodash/findIndex";
import uniqBy from "lodash/uniqBy";
import differenceBy from "lodash/differenceBy";

import './css/select.css';

class CategorySelect extends Component {

    static propTypes = {
        selectedOptions: PropTypes.array,
        title: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }

    isChecked = (id) => {
        const {selectedOptions} = this.props;
        return findIndex(selectedOptions, option => option.id === id) > -1;
    }

    onChange = (option) => (e, checked, id) => {
        const {onChange} = this.props;
        onChange && onChange(e, checked, id, option);
    }

    getRenderItems = (options) => {
        return map(options, option => {
            const {id, name} = option;

            const checked = this.isChecked(id);
            return (
                <CheckboxItem key={id} id={id} name={name} checked={checked} onChange={this.onChange(option)}/>
            )
        });
    }

    allCheckboxChecked = () => {
        const {selectedOptions} = this.props;
        const flattenOptions = this.getFlattenOptions();

        return selectedOptions.length === flattenOptions.length;
    }

    partCheckboxChecked = options => {
        const {selectedOptions} = this.props;
        const selectTypedOptions = intersectionBy(selectedOptions, options, 'id');

        return options.length === selectTypedOptions.length;
    }

    typedCheckboxChange = options => (e, checked) => {
        const {selectedOptions, setSelectedOptions} = this.props;
        let changeOptions = [];
        if (checked) {
            changeOptions = uniqBy([...selectedOptions, ...options], 'id');
        } else {
            changeOptions = differenceBy(selectedOptions, options, 'id');
        }

        setSelectedOptions(changeOptions);
    }

    allCheckboxChange = (e, checked) => {
        const {setSelectedOptions} = this.props;
        const flattenOptions = this.getFlattenOptions();
        let changeOptions = [];
        if (checked) {
            changeOptions = flattenOptions;
        }

        setSelectedOptions(changeOptions);
    }

    getFlattenOptions = () => {
        const {options} = this.props;
        let flattenOptions = [];
        forEach(options, item => {
            flattenOptions = [...flattenOptions, ...item.options];
        })
        return flattenOptions;
    }

    calculateCategoryHeight = (options, isCalculate) => {
        if (isCalculate) {
            const elementHeight = 26;
            return {height: Math.ceil(options.length / 2) * elementHeight};
        }
    }

    getRenderOptions = () => {
        const {options} = this.props;
        const optionBlocks = map(options, item => {
            const {id, name, checkboxName, optionsClassName, options} = item;
            const renderItems = this.getRenderItems(options);
            const isCalculate = id === 'common';
            const categoryOptionsHeight = this.calculateCategoryHeight(options, isCalculate);

            const partCheckboxChecked = this.partCheckboxChecked(options);

            const blockTitleClassName = classNames("select-items__block-title");

            const optionClassName = classNames("category-options__options", optionsClassName)

            return (
                <div key={id} className="select-items__block">
                    {name && <div className={blockTitleClassName}>{name}</div>}
                    <CheckboxItem key={id} id={id} name={checkboxName} className='all-checkbox' checked={partCheckboxChecked} onChange={this.typedCheckboxChange(options)}/>
                    <div className={optionClassName} style={categoryOptionsHeight}>
                        {renderItems}
                    </div>
                </div>
            )
        });

        return optionBlocks;
    }

    render() {
        const options = this.getRenderOptions();
        const allCheckboxChecked = this.allCheckboxChecked();

        return (
            <>
                <CheckboxItem key="all" id="all" name="All categories" className='all-checkbox' checked={allCheckboxChecked} onChange={this.allCheckboxChange}/>
                <div className="category-options__container">
                    {options}
                </div>
            </>
        );
    }
}

export default (CategorySelect);