export const REQUEST_INIT_APP_STORE = 'REQUEST_INIT_APP_STORE';
export const SUCCEED_INIT_APP_STORE = 'SUCCEED_INIT_APP_STORE';
export const REQUEST_REFRESH_APP_STORE = 'REQUEST_REFRESH_APP_STORE';
export const SUCCEED_REFRESH_APP_STORE = 'SUCCEED_REFRESH_APP_STORE';
export const REQUEST_SEARCH_APP_STORE = 'REQUEST_SEARCH_APP_STORE';
export const SUCCEED_SEARCH_APP_STORE = 'SUCCEED_SEARCH_APP_STORE';
export const REQUEST_APP_STORE_LAST_UPDATE = 'REQUEST_APP_STORE_LAST_UPDATE';
export const SUCCEED_APP_STORE_LAST_UPDATE = 'SUCCEED_APP_STORE_LAST_UPDATE';
export const REQUEST_APP_STORE_FILTER_DATA = 'REQUEST_APP_STORE_FILTER_DATA';
export const SUCCEED_APP_STORE_FILTER_DATA = 'SUCCEED_APP_STORE_FILTER_DATA';
export const REQUEST_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE = 'REQUEST_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE';
export const SUCCEED_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE = 'SUCCEED_GET_COUNT_DEVELOPER_AND_EMAILS_APP_STORE';

export const SET_APP_STORE_SEARCH = 'SET_APP_STORE_SEARCH';
export const SET_SELECTED_LANGUAGES_APP_STORE = 'SET_SELECTED_LANGUAGES_APP_STORE';
export const SET_SELECTED_GENRES_APP_STORE = 'SET_SELECTED_GENRES_APP_STORE';
export const SET_LANGUAGE_COUNT_APP_STORE = 'SET_LANGUAGE_COUNT_APP_STORE';
export const SET_APP_STORE_RELEASED = 'SET_APP_STORE_RELEASED';
export const SET_APP_STORE_REVIEWS = 'SET_APP_STORE_REVIEWS';
export const SET_APP_STORE_SCORE = 'SET_APP_STORE_SCORE';
export const CLEAR_FILTERS_APP_STORE = 'CLEAR_FILTERS_APP_STORE';
export const SET_APP_STORE_COUNTRY = 'SET_APP_STORE_COUNTRY';
export const SET_APP_STORE_UPDATED = 'SET_APP_STORE_UPDATED';
export const REQUEST_GET_COUNT_APP_STORE = 'REQUEST_GET_COUNT_APP_STORE';
export const SUCCEED_GET_COUNT_APP_STORE = 'SUCCEED_GET_COUNT_APP_STORE';
export const SET_APP_STORE_HAS_VIDEO = 'SET_APP_STORE_HAS_VIDEO';
export const SET_COUNTRY_COUNT_APP_STORE = 'SET_COUNTRY_COUNT_APP_STORE';
export const SET_IS_ONLY_CHOSEN_MARKETS_APP_STORE = 'SET_IS_ONLY_CHOSEN_MARKETS_APP_STORE';
export const SET_IS_ONLY_CHOSEN_LANGUAGES_APP_STORE = 'SET_IS_ONLY_CHOSEN_LANGUAGES_APP_STORE';
export const SET_IS_FILTER_SELECTED_APP_STORE = 'SET_IS_FILTER_SELECTED_APP_STORE';
export const SET_APP_STORE_APP_COUNT = 'SET_APP_STORE_APP_COUNT';
export const SET_APP_STORE_PRICE = 'SET_APP_STORE_PRICE';