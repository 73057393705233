import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';

import Popover from "../Popover";
import Button from "../Button";
import Options from "./Options";

import './css/select.css';

class Select extends Component {

    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        submitButtonName: PropTypes.string,
        optionCount: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: '',
            selectedOptions: []
        }

        this.input = React.createRef();
    }

    componentDidMount() {
        const {selectedOptions} = this.props;

        this.setSelectedOptions(selectedOptions)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedOptions} = this.props;
        if (selectedOptions.length !== prevProps.selectedOptions.length) {
            this.setSelectedOptions(selectedOptions);
        }
    }

    onToggleMenu = () => {
        this.setState((state, props) => {
            return {
                isOpen: !state.isOpen,
                selectedOptions: props.selectedOptions,
            }
        });
    }

    onChangeCheckbox = (e, checked, id, item) => {
        const {selectedOptions} = this.state;
        let changeOptions = [];
        if (checked) {
            changeOptions = [item, ...selectedOptions];
        } else {
            changeOptions = filter(selectedOptions, option => option.id !== id);
        }

        this.setSelectedOptions(changeOptions);
    }

    setSelectedOptions = (selectedOptions) => {
        this.setState({selectedOptions})
    }

    getRenderOptions = () => {
        const {selectedOptions, isOpen} = this.state;
        const {options, customOptionsComponent} = this.props;

        const props = {...this.props, isOpen: isOpen, options: options,
            selectedOptions: selectedOptions,
            setSelectedOptions: this.setSelectedOptions,
            onChange: this.onChangeCheckbox}

        if (customOptionsComponent) {
            return customOptionsComponent(props);
        }

        return <Options {...props} />
    }

    getValue = () => {
        const {selectedOptions} = this.state;
        const {value, renderValue, options} = this.props;
        if (renderValue) {
            return renderValue(selectedOptions, options);
        }

        return value;
    }

    onCancel = () => {
        const {onChange, options, defaultOption, optionCount, setOptionsCount} = this.props;
        const resetOption = defaultOption ? defaultOption.value : options;
        this.setSelectedOptions(resetOption);

        if (!isUndefined(optionCount)) {
            setOptionsCount({from: '', to: ''});
        }

        onChange && onChange(resetOption);
        this.onToggleMenu();
    }

    onSubmit = () => {
        const {selectedOptions} = this.state;
        const {onChange} = this.props;
        onChange && onChange(selectedOptions);

        this.onToggleMenu();
    }

    render() {
        const {isOpen} = this.state;
        const {title, popoverClassName, submitButtonName} = this.props;

        const value = this.getValue();
        const options = this.getRenderOptions();
        const submitButton = submitButtonName || 'Submit';

        const inputClassNames = classNames("select__input", {
            "active": isOpen
        });

        const popoverClassNames = classNames("select-items__popover select__popover", popoverClassName);

        const inputRef = this.input && this.input.current;

        return (
            <div className="select__container">
                <div className="select__title">{title}</div>
                <div ref={this.input} className={inputClassNames} onClick={this.onToggleMenu}>
                    <div className="select__input-value" title={value}>
                        {value}
                    </div>
                    <div className="select__arrow down-arrow-icon"/>
                </div>

                <Popover anchorEl={inputRef} className={popoverClassNames} open={isOpen} withArrow={true} onClosePopover={this.onToggleMenu}>
                    <div className="select-items__container">
                        {options}
                    </div>
                    <div className="select-items__buttons">
                        <Button className="cancel-button" title="Remove filters" onClick={this.onCancel}/>
                        <Button className="submit-button" title={submitButton} onClick={this.onSubmit}/>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default (Select);