import * as types from "./constants";

export function requestInitSubscribeList() {
    return {
        type: types.REQUEST_INIT_SUBSCRIBE_LIST
    };
}

export function succeedInitSubscribeList(response) {
    return {
        type: types.SUCCEED_INIT_SUBSCRIBE_LIST,
        response
    };
}

export function requestSearchSubscribeList(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_SUBSCRIBE_LIST,
        limit, offset
    };
}

export function succeedSearchSubscribeList(response) {
    return {
        type: types.SUCCEED_SEARCH_SUBSCRIBE_LIST,
        response
    };
}

export function requestRefreshSubscribeList() {
    return {
        type: types.REQUEST_REFRESH_SUBSCRIBE_LIST
    };
}

export function succeedRefreshSubscribeList(response) {
    return {
        type: types.SUCCEED_REFRESH_SUBSCRIBE_LIST,
        response
    };
}

export function requestUpdateSubscribeList(updatedItem) {
    return {
        type: types.REQUEST_UPDATE_SUBSCRIBE_LIST,
        blockUi: true,
        updatedItem
    };
}

export function successUpdateSubscribeList(response) {
    return {
        type: types.SUCCEED_UPDATE_SUBSCRIBE_LIST,
        blockUi: false,
        response
    };
}

export function setSearchSubscribeList(search) {
    return {
        type: types.SET_SEARCH_SUBSCRIBE_LIST,
        search
    };
}

export function setSortSubscribeList(field, order) {
    return {
        type: types.SET_SORT_SUBSCRIBE_LIST,
        field, order
    };
}

export function setDecisionStatus(selectedDecisionStatus) {
    return {
        type: types.SET_DECISION_STATUS_SUBSCRIBE,
        selectedDecisionStatus
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_SUBSCRIBE,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_SUBSCRIBE,
        isFiltersSelected
    };
}