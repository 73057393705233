import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import assign from 'lodash/assign';
import {setPageTitle} from "../../helpers/utils";
import {languageOptions} from "../../constants/Language";
import {allCountry} from "../../constants/Country";
import {getCookieByName, removeCookieByName} from "../../helpers/cookieUtils";

const COOKIES_KEY_AUTH = 'auth';
const token = getAuthToken();

const initialState = {
    title: '',
    headerElement: null,
    email: '',
    userName: '',
    isAdmin: false,
    status: null,
    token: token,
    blockUi: false,
    waitUi: false,
    initContainers: {},
    health: {},
    lastUpdate: {},
    allHealth: {},

    popup: {
        open: false,
        onApply: () => {}
    },

    languageOptions: languageOptions,
    countryOptions: allCountry,

    playSalesType: [
        {id: 'all', name: 'All'},
        {id: 'free', name: 'Free'},
        {id: 'paid', name: 'Paid'},
        {id: 'newFree', name: 'New Free'},
        {id: 'newPaid', name: 'New Paid'},
    ],
    snoozeOptions: [
        {id: '1', name: '1 month'},
        {id: '3', name: '3 months'},
        {id: '6', name: '6 months'},
    ],
    priceOptions: [
        {id: 0, name: 'All'},
        {id: 1, name: 'Free'},
        {id: 2, name: 'Paid'},
    ],
    healthServices: {
        "AppStore": [],
        "Close": [],
        "Crowdin": [],
        "Crunchbase": [],
        "Epic": [],
        "GooglePlay": [],
        "Href": [],
        "ItchIo": [],
        "Magnet for Sales": [],
        "Magnet for Vendors": [],
        "Nintendo": [],
        "Snovio": [],
        "Steam": [],
        "Steam Publishers": [],
        "Subscribe": [],
        "Twitter": [],
        "EU Startups": [],
        "gog.com": [],
        "Meet to Match": []
    },
    isHasAllHealth: false,
    isMagnetSalesWarning: false,
    isMagnetVendorsWarning: false,
    isMagnetSalesHealthFailed: false,
    isMagnetVendorsHealthFailed: false
};

export default function app(state = initialState, action) {
    switch (action.type) {

        case actions.SIGN_OUT:
            removeCookieByName(COOKIES_KEY_AUTH);
            return {
                ...state,
                token: null,
            };

        case actions.REQUEST_AUTH:
            return {
                ...state,
            };
        case actions.SUCCEED_AUTH:
            return {
                ...state,
            };
        case actions.FAIL_AUTH:
            return {
                ...state,
            };
        case actions.INIT_APP:
            return {
                ...state,
                token: action.response.token,
            }
        case actions.SUCCEED_GET_USER_INFO:
            return {
                ...state,
                email: action.response.email,
                userName: action.response.name,
                isAdmin: action.response.isAdmin,
            }
        case actions.SET_INIT_CONTAINER:
            return {
                ...state,
                initContainers: assign(state.initContainers, {[action.containerName]: true})
            };
        case actions.SET_TITLE:
            setPageTitle(action.title);
            return {
                ...state,
                title: action.title,
                headerElement: action.headerElement,
            };
        case actions.UPDATE_TITLE:
            setPageTitle(action.title);
            return {
                ...state,
                title: action.title,
            };
        case actions.BLOCK_UI:
            return {
                ...state,
                blockUi: true
            };

        case actions.UNBLOCK_UI:
            return {
                ...state,
                blockUi: false
            };
        case actions.WAIT_UI:
            return {
                ...state,
                waitUi: true
            };

        case actions.UNWAIT_UI:
            return {
                ...state,
                waitUi: false
            };
        case actions.REQUEST_GET_HEALTH:
            const healthService = state.healthServices;
            healthService[action.service].healthArray = [];
            return {
                ...state,
                status: statuses.GET_HEALTH_REQUESTED,
                healthServices: healthService
            };

        case actions.SUCCEED_GET_HEALTH:
            const healthServices = state.healthServices;
            healthServices[action.service].healthArray = action.healthService;
            if (action.service === "Magnet for Sales" && state.isMagnetSalesHealthFailed) {
                healthServices[action.service].healthArray[0].lastExecutionStatus = 2;
            }
            if (action.service === "Magnet for Vendors" && state.isMagnetVendorsHealthFailed) {
                healthServices[action.service].healthArray[0].lastExecutionStatus = 2;
            }
            else if ((action.service === "Magnet for Sales" && state.isMagnetSalesWarning) || (action.service === "Magnet for Vendors" && state.isMagnetVendorsWarning)) {
                healthServices[action.service].healthArray[0].lastExecutionStatus = 0;
                healthServices[action.service].healthArray[0].lastFailedCount = 1;
            }
            return {
                ...state,
                status: statuses.GET_HEALTH_SUCCEEDED,
                healthServices: healthServices
            };
        case actions.SET_MAGNET_SALES_HEALTH_WARNING:
            const updatedMagnetSalesHealthServices = state.healthServices;
            if (updatedMagnetSalesHealthServices["Magnet for Sales"]?.healthArray[0]?.lastExecutionStatus) {
                updatedMagnetSalesHealthServices["Magnet for Sales"].healthArray[0].lastExecutionStatus = 0;
                updatedMagnetSalesHealthServices["Magnet for Sales"].healthArray[0].lastFailedCount = 1;
            }
            return {
                ...state,
                isMagnetSalesWarning: true,
                healthServices: updatedMagnetSalesHealthServices
            };
        case actions.SET_MAGNET_VENDORS_HEALTH_WARNING:
            const updatedMagnetVendorsHealthServices = state.healthServices;
            if (updatedMagnetVendorsHealthServices["Magnet for Vendors"]?.healthArray[0]?.lastExecutionStatus) {
                updatedMagnetVendorsHealthServices["Magnet for Vendors"].healthArray[0].lastExecutionStatus = 0;
                updatedMagnetVendorsHealthServices["Magnet for Vendors"].healthArray[0].lastFailedCount = 1;
            }
            return {
                ...state,
                isMagnetVendorsWarning: true,
                healthServices: updatedMagnetVendorsHealthServices
            };
        case actions.SUCCEED_IS_MAGNET_FAILED:
            const updatedHealthServices = state.healthServices;
            if (updatedHealthServices["Magnet for Sales"].healthArray && updatedHealthServices["Magnet for Sales"]?.healthArray[0]?.lastExecutionStatus && action.isMagnetSalesHealthFailed) {
                updatedHealthServices["Magnet for Sales"].healthArray[0].lastExecutionStatus = 2;
            }
            if (updatedHealthServices["Magnet for Vendors"].healthArray && updatedHealthServices["Magnet for Vendors"]?.healthArray[0]?.lastExecutionStatus && action.isMagnetVendorsHealthFailed) {
                updatedHealthServices["Magnet for Vendors"].healthArray[0].lastExecutionStatus = 2;
            }
            return {
                ...state,
                isMagnetSalesHealthFailed: action.isMagnetSalesHealthFailed,
                isMagnetVendorsHealthFailed: action.isMagnetVendorsHealthFailed,
                healthServices: updatedHealthServices
            }
        case actions.REQUEST_GET_ALL_HEALTH:
            return {
                ...state,
            };

        case actions.SUCCEED_GET_ALL_HEALTH:
            return {
                ...state,
                status: statuses.GET_HEALTH_SUCCEEDED,
                isHasAllHealth: true
            };
        case actions.SHOW_POPUP:
            return {
                ...state,
                popup: {
                    ...state.popup,
                    ...action.popup,
                }
            }
        case actions.CLOSE_POPUP:
            return {
                ...state,
                popup: {
                    open: false,
                    onApply: () => {}
                }
            }

        default:
            return state;
    }
}

function getAuthToken () {
    const token = getCookieByName(COOKIES_KEY_AUTH);
    return !!token ? token : null;
}
