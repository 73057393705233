import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {deleteItemFromArray, updateItemInArray} from "../../helpers/utils";

const initialState = {
    limit: 10,
    offset: 0,
    status: null,
    search: '',
    list: [],
    total: 0,
    hasMore: false,
    sortOrder: 'asc',
    sortField: 'checkpoint',
    magnetAccountsAccount: 2,
};

export default function magnetVendorsAccounts(state = initialState, action) {
    switch (action.type) {

        case actions.REQUEST_INIT_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_ACCOUNTS_REQUESTED,
                list: [],
                total: 0,
                hasMore: false
            }
        case actions.SUCCEED_INIT_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_ACCOUNTS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
                hasMore: action.response.hasMore,
            }
        case actions.REQUEST_SEARCH_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_ACCOUNTS_REQUESTED,
                limit: action.limit,
                offset: action.offset,
            }
        case actions.SUCCEED_SEARCH_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.SEARCH_MAGNET_ACCOUNTS_SUCCEEDED,
                list: [...state.list, ...action.response.result],
            }
        case actions.REQUEST_REFRESH_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_ACCOUNTS_REQUESTED,
                list: [],
                total: 0,
                hasMore: false,
            }
        case actions.SUCCEED_REFRESH_MAGNET_ACCOUNTS:
            return {
                ...state,
                status: statuses.INIT_MAGNET_ACCOUNTS_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
                hasMore: action.response.hasMore,
            }
        case actions.SET_MAGNET_SEARCH:
            return {
                ...state,
                search: action.search
            }
        case actions.SET_SORT_MAGNET_ACCOUNTS:
            return {
                ...state,
                sortOrder: action.order,
                sortField: action.sortField,
                limit: state.limit + state.offset,
                offset: 0
            }
        case actions.SUCCEED_CREATE_MAGNET_ACCOUNT:
            return {
                ...state,
                list: [action.user.record, ...state.list],
                limit: state.limit + 1,
                total: state.total + 1
            }
        case actions.REQUEST_DELETE_MAGNET_ACCOUNT:
            return {
                ...state,
                list: deleteItemFromArray(state.list, action.id),
                total: state.total - 1,
                limit: state.limit - 1
            }
        case actions.REQUEST_UPDATE_MAGNET_ACCOUNT:
            return {
                ...state,
                list: updateItemInArray(state.list, action.updatedItem),
            }
        default:
            return state;
    }
}