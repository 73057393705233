import {
    requestInitMeetToMatchConference,
    succeedInitMeetToMatchConference,
    requestSearchMeetToMatchConference,
    succeedSearchMeetToMatchConference,
    requestGetFilterData,
    succeedGetFilterData,
    setSearch,
    setAttendeeTags,
    setPreferredDealSizes,
    setInvestmentTypes,
    setLookingFor,
    setIndustry,
    setPlatform,
    setCountries,
    setCompanySize,
    setGreenFlag,
    setRedFlag,
    clearFiltersMeetToMatch
} from "./actions";
import {
    getMeetToMatchPeopleRequest,
    getFilterDataMeetToMatchConferenceRequest,
    exportMeetToMatchPeopleRequest
} from "../../services/grpcBufServices";
import {getRequestOptions} from "../../helpers/utils";
import {requestExport, succeedExport} from "../App/actions";

export const initMeetToMatchConference = () =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatchConference: {eventId, limit, offset}} = getState();
        dispatch(requestInitMeetToMatchConference());
        const response = await getMeetToMatchPeopleRequest(eventId, token, limit, offset);
        dispatch(succeedInitMeetToMatchConference(response));
    };

export const searchMeetToMatchConference = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatchConference: {eventId, limit, offset, search, attendeeTags, selectedAttendeeTags, selectedPreferredDealSizes, preferredDealSizes,
            lookingFor, selectedLookingFor, industry, selectedIndustry, platform, selectedPlatform, countries, selectedCountries, companySize,
            selectedCompanySize, selectedGreenFlag, selectedRedFlag, selectedInvestmentTypes, investmentTypes}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const attendeeTagsOptions = getRequestOptions(selectedAttendeeTags, attendeeTags);
        const preferredDealSizesOptions = getRequestOptions(selectedPreferredDealSizes, preferredDealSizes);
        const lookingForOption = getRequestOptions(selectedLookingFor, lookingFor);
        const industryOption = getRequestOptions(selectedIndustry, industry);
        const platformOption = getRequestOptions(selectedPlatform, platform);
        const countriesOption = getRequestOptions(selectedCountries, countries);
        const companySizeOption = getRequestOptions(selectedCompanySize, companySize);
        const investmentTypesOption = getRequestOptions(selectedInvestmentTypes, investmentTypes);
        const greenFlagOption = selectedGreenFlag.length > 1 ? '' : selectedGreenFlag[0].id;
        const redFlagOption = selectedRedFlag.length > 1 ? '' : selectedRedFlag[0].id;

        dispatch(requestSearchMeetToMatchConference(updateLimit, updateOffset));
        const response = await getMeetToMatchPeopleRequest(eventId, token, updateLimit, updateOffset, search, attendeeTagsOptions, preferredDealSizesOptions,
            lookingForOption, industryOption, platformOption, countriesOption, companySizeOption, greenFlagOption, redFlagOption, investmentTypesOption);
        dispatch(succeedSearchMeetToMatchConference(response));
    };

export const refreshMeetToMatchConference = () =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatchConference: {eventId, limit, offset, search, selectedAttendeeTags, attendeeTags, preferredDealSizes, selectedPreferredDealSizes,
            lookingFor, selectedLookingFor, selectedIndustry, industry, platform, selectedPlatform, selectedCountries, countries, selectedCompanySize,
            companySize, selectedGreenFlag, selectedRedFlag, selectedInvestmentTypes, investmentTypes}} = getState();
        const attendeeTagsOptions = getRequestOptions(selectedAttendeeTags, attendeeTags);
        const preferredDealSizesOptions = getRequestOptions(selectedPreferredDealSizes, preferredDealSizes);
        const lookingForOption = getRequestOptions(selectedLookingFor, lookingFor);
        const industryOption = getRequestOptions(selectedIndustry, industry);
        const platformOption = getRequestOptions(selectedPlatform, platform);
        const countriesOption = getRequestOptions(selectedCountries, countries);
        const companySizeOption = getRequestOptions(selectedCompanySize, companySize);
        const investmentTypesOption = getRequestOptions(selectedInvestmentTypes, investmentTypes);
        const greenFlagOption = selectedGreenFlag.length > 1 ? '' : selectedGreenFlag[0].id;
        const redFlagOption = selectedRedFlag.length > 1 ? '' : selectedRedFlag[0].id;

        dispatch(requestInitMeetToMatchConference());
        const response = await getMeetToMatchPeopleRequest(eventId, token, limit, offset, search, attendeeTagsOptions, preferredDealSizesOptions, lookingForOption,
            industryOption, platformOption, countriesOption, companySizeOption, greenFlagOption, redFlagOption, investmentTypesOption);
        dispatch(succeedInitMeetToMatchConference(response));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetFilterData());
        const response = await getFilterDataMeetToMatchConferenceRequest(token);
        dispatch(succeedGetFilterData(response));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearch(search))
    };

export const setAttendeeTagsAsync = (attendeeTags) =>
    async (dispatch, getState) => {
        dispatch(setAttendeeTags(attendeeTags))
    };

export const setPreferredDealSizesAsync = (preferredDealSizes) =>
    async (dispatch, getState) => {
        dispatch(setPreferredDealSizes(preferredDealSizes))
    };

export const setInvestmentTypesAsync = (investmentTypes) =>
    async (dispatch, getState) => {
        dispatch(setInvestmentTypes(investmentTypes))
    };

export const setLookingForAsync = (lookingFor) =>
    async (dispatch, getState) => {
        dispatch(setLookingFor(lookingFor))
    };

export const setIndustryAsync = (industry) =>
    async (dispatch, getState) => {
        dispatch(setIndustry(industry))
    };

export const setPlatformAsync = (platform) =>
    async (dispatch, getState) => {
        dispatch(setPlatform(platform))
    };

export const setCountriesAsync = (countries) =>
    async (dispatch, getState) => {
        dispatch(setCountries(countries))
    };

export const setCompanySizeAsync = (companySize) =>
    async (dispatch, getState) => {
        dispatch(setCompanySize(companySize))
    };

export const setGreenFlagAsync = (greenFlag) =>
    async (dispatch, getState) => {
        dispatch(setGreenFlag(greenFlag))
    };

export const setRedFlagAsync = (redFlag) =>
    async (dispatch, getState) => {
        dispatch(setRedFlag(redFlag))
    };

export const clearFilters = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersMeetToMatch());
    };

export const exportMeetToMatch = () =>
    async (dispatch, getState) => {
        const {app: {token}, meetToMatchConference: {eventId, limit, offset, search, selectedAttendeeTags, attendeeTags, preferredDealSizes, selectedPreferredDealSizes,
            lookingFor, selectedLookingFor, selectedIndustry, industry, platform, selectedPlatform, selectedCountries, countries, selectedCompanySize,
            companySize, selectedGreenFlag, selectedRedFlag, selectedInvestmentTypes, investmentTypes}} = getState();
        const attendeeTagsOptions = getRequestOptions(selectedAttendeeTags, attendeeTags);
        const preferredDealSizesOptions = getRequestOptions(selectedPreferredDealSizes, preferredDealSizes);
        const lookingForOption = getRequestOptions(selectedLookingFor, lookingFor);
        const industryOption = getRequestOptions(selectedIndustry, industry);
        const platformOption = getRequestOptions(selectedPlatform, platform);
        const countriesOption = getRequestOptions(selectedCountries, countries);
        const companySizeOption = getRequestOptions(selectedCompanySize, companySize);
        const investmentTypesOption = getRequestOptions(selectedInvestmentTypes, investmentTypes);
        const greenFlagOption = selectedGreenFlag.length > 1 ? '' : selectedGreenFlag[0].id;
        const redFlagOption = selectedRedFlag.length > 1 ? '' : selectedRedFlag[0].id;

        dispatch(requestExport());
        const exportId = await exportMeetToMatchPeopleRequest(eventId, token, limit, offset, search, attendeeTagsOptions, preferredDealSizesOptions, lookingForOption,
            industryOption, platformOption, countriesOption, companySizeOption, greenFlagOption, redFlagOption, investmentTypesOption);
        dispatch(succeedExport(exportId.task.id));
    };

