import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import includes from "lodash/includes";

import GooglePlay from "../GooglePlay";
import Close from "../Close";
import Crunchbase from "../Crunchbase";
import AppStore from "../AppStore";
import SteamGames from "../SteamGames";
import Twitter from "../Twitter";
import TwitterAudience from "../TwitterAudience";
import SubscribeDecide from "../SubscribeDecide";
import SubscribeList from "../SubscribeList";
import Epic from "../Epic";
import Nintendo from "../Nintendo";
import Href from "../Href";
import Magnet from "../Magnet";
import SteamPublishers from "../SteamPublishers";
import Crowdin from "../Crowdin";
import Snovio from "../Snovio";
import ItchGames from "../ItchGames";
import ItchAuthors from "../ItchAuthors";
import EuStartups from "../EuStartups";
import Gog from "../Gog";
import MeetToMatch from "../MeetToMatch";
import MeetToMatchConference from "../MeetToMatchConference";
import Dashboard from "../Dashboard";

import SideMenu from "../../components/SideMenu";
import Header from "../../components/Header";
import Scrollbar from "../../components/Scrollbar";
import AuthProvider from "../../components/AuthProvider";

import {healthServicesName} from "../../helpers/utils";

export class AppRoot extends Component {

    render() {
        const {history} = this.props;
        const {pathname} = history.location;
        const isDashboard = pathname === '/';
        const isSubscribeDecide = pathname === '/subscribe/decide';
        const twitterService = "/twitter";
        const meetToMatchService = "/meet-to-match";
        const healthServiceName = includes(pathname, twitterService)
            ? healthServicesName[twitterService]
            : includes(pathname, meetToMatchService)
                ? healthServicesName[meetToMatchService]
                : healthServicesName[pathname];

        return (
            <AuthProvider>
                <div className="app-root">
                    {!isDashboard && <SideMenu/>}
                    <div className="app-root-body">
                        <Scrollbar>
                            <div className="app-root-scroll">
                                {!isDashboard && <Header isSubscribeDecide={isSubscribeDecide} healthServiceName={healthServiceName}/>}
                                <Switch>
                                    <Route path={`/play`} component={GooglePlay}/>

                                    <Route path={`/close`} component={Close}/>
                                    <Route path={`/crowdin`} component={Crowdin}/>
                                    <Route path={`/crunchbase`} component={Crunchbase}/>
                                    <Route path={`/appstore`} component={AppStore}/>
                                    <Route path={`/steam/games`} component={SteamGames}/>
                                    <Route path={`/steam/publishers`} component={SteamPublishers}/>
                                    <Route path={`/twitter/:name`} component={TwitterAudience}/>
                                    <Route path={`/twitter`} component={Twitter}/>
                                    <Route path={`/subscribe/decide`} component={SubscribeDecide}/>
                                    <Route path={`/subscribe/list`} component={SubscribeList}/>
                                    <Route path={`/epic`} component={Epic}/>
                                    <Route path={`/nintendo`} component={Nintendo}/>
                                    <Route path={`/html`} component={Href}/>
                                    <Route path={`/snovio`} component={Snovio}/>
                                    <Route path={`/itchio/games`} component={ItchGames}/>
                                    <Route path={`/itchio/authors`} component={ItchAuthors}/>
                                    <Route path={`/eustartups`} component={EuStartups}/>
                                    <Route path={`/gog`} component={Gog}/>
                                    <Route path={`/meet-to-match/:id`} component={MeetToMatchConference}/>
                                    <Route path={`/meet-to-match`} component={MeetToMatch}/>

                                    <Route path={`/magnet/sales`} render={props => <Magnet account={1} {...props}/>}/>
                                    <Route path={`/magnet/vendors`} render={props => <Magnet account={2} {...props}/>}/>

                                    <Route path={`/`} component={Dashboard}/>
                                </Switch>
                            </div>
                        </Scrollbar>
                      </div>
                </div>
            </AuthProvider>
        );
    }
}

export default (AppRoot);
