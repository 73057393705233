export const REQUEST_GET_DECIDE_RECORD = 'REQUEST_GET_DECIDE_RECORD';
export const SUCCEED_GET_DECIDE_RECORD = 'SUCCEED_GET_DECIDE_RECORD';
export const REQUEST_UPDATE_SUBSCRIBE_RECORD = 'REQUEST_UPDATE_SUBSCRIBE_RECORD';
export const SUCCEED_UPDATE_SUBSCRIBE_RECORD = 'SUCCEED_UPDATE_SUBSCRIBE_RECORD';
export const REQUEST_REFRESH_DECIDE_RECORD = 'REQUEST_REFRESH_DECIDE_RECORD';
export const SUCCEED_REFRESH_DECIDE_RECORD = 'SUCCEED_REFRESH_DECIDE_RECORD';
export const SUCCEED_GET_SUBSCRIBE_COUNTS = 'SUCCEED_GET_SUBSCRIBE_COUNTS';
export const REQUEST_GET_SUBSCRIBE_CURRENT_FILTER = 'REQUEST_GET_SUBSCRIBE_CURRENT_FILTER';
export const SUCCEED_GET_SUBSCRIBE_CURRENT_FILTER = 'SUCCEED_GET_SUBSCRIBE_CURRENT_FILTER';
export const REQUEST_UPDATE_SUBSCRIBE_FILTER = 'REQUEST_UPDATE_SUBSCRIBE_FILTER';
export const SUCCEED_UPDATE_SUBSCRIBE_FILTER = 'SUCCEED_UPDATE_SUBSCRIBE_FILTER';
export const UPDATE_PROCESSING_MESSAGE = 'UPDATE_PROCESSING_MESSAGE';