import * as types from "./constants";

export function requestInitMeetToMatchConference() {
    return {
        type: types.REQUEST_INIT_MEET_TO_MATCH_CONFERENCE
    };
}

export function succeedInitMeetToMatchConference(response) {
    return {
        type: types.SUCCEED_INIT_MEET_TO_MATCH_CONFERENCE,
        response
    };
}

export function requestSearchMeetToMatchConference(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_MEET_TO_MATCH_CONFERENCE,
        limit, offset
    };
}

export function succeedSearchMeetToMatchConference(response) {
    return {
        type: types.SUCCEED_SEARCH_MEET_TO_MATCH_CONFERENCE,
        response
    };
}

export function requestGetFilterData() {
    return {
        type: types.REQUEST_MEET_TO_MATCH_CONFERENCE_FILTER_DATA,
    };
}

export function succeedGetFilterData(response) {
    return {
        type: types.SUCCEED_MEET_TO_MATCH_CONFERENCE_FILTER_DATA,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_SEARCH,
        search
    }
}

export function setAttendeeTags(attendeeTags) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_ATTENDEE_TAG,
        attendeeTags
    }
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_MEET_TO_MATCH_CONFERENCE,
        isFiltersSelected
    };
}

export function setPreferredDealSizes(preferredDealSizes) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_PREFERRED_DEAD_SIZES,
        preferredDealSizes
    }
}

export function setInvestmentTypes(investmentTypes) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_INVESTMENT_TYPES,
        investmentTypes
    }
}

export function setLookingFor(lookingFor) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_LOOKING_FOR,
        lookingFor
    }
}

export function setIndustry(industry) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_INDUSTRY,
        industry
    }
}

export function setPlatform(platform) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_PLATFORM,
        platform
    }
}

export function setCountries(countries) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_COUNTRIES,
        countries
    }
}

export function setCompanySize(companySize) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_COMPANY_SIZE,
        companySize
    }
}

export function setGreenFlag(greenFlag) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_GREEN_FLAG,
        greenFlag
    }
}

export function setRedFlag(redFlag) {
    return {
        type: types.SET_MEET_TO_MATCH_CONFERENCE_RED_FLAG,
        redFlag
    }
}

export function clearFiltersMeetToMatch() {
    return {
        type: types.CLEAR_FILTERS_MEET_TO_MATCH,
    }
}

export function setEventId(eventId) {
    return {
        type: types.SET_EVENT_ID_MEET_TO_MATCH_CONFERENCE,
        eventId
    }
}

