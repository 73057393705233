import {addMargetUserRequest, deleteMargetUserRequest, getUsersPagedRequest, updateMargetUserRequest} from "../../services/grpcBufServices";
import {
    requestCreateMagnetAccounts, requestDeleteMagnetAccounts,
    requestInitMagnetAccounts,
    requestRefreshMagnetAccounts, requestSearchMagnetAccounts, requestUpdateMagnetAccounts,
    setMagnetSearch, setSortMagnetAccounts, succeedCreateMagnetAccounts, succeedDeleteMagnetAccounts,
    succeedInitMagnetAccounts, succeedRefreshMagnetAccounts, succeedSearchMagnetAccounts, succeedUpdateMagnetAccounts
} from "./actions";
import {setMagnetSalesHealthWarning} from "../App/actions";
import {getSortOrder, checkMagnetCheckpoint} from "../../helpers/utils";

export const initMagnetAccounts = (account) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesAccounts: {search, sortOrder, sortField}} = getState();
        dispatch(requestInitMagnetAccounts(account));
        const order = getSortOrder(sortOrder);
        const response = await getUsersPagedRequest(token, 10, 0, search, account, order, sortField);
        const isHealthWarning = checkMagnetCheckpoint(response.result[0].checkpoint);
        isHealthWarning && dispatch(setMagnetSalesHealthWarning());
        dispatch(succeedInitMagnetAccounts(response));
    };

export const refreshMagnetAccounts = () =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesAccounts: {limit, offset, search, magnetAccountsAccount, sortOrder, sortField}} = getState();
        dispatch(requestRefreshMagnetAccounts());
        const order = getSortOrder(sortOrder);
        const response = await getUsersPagedRequest(token, limit, offset, search, magnetAccountsAccount, order, sortField);
        dispatch(succeedRefreshMagnetAccounts(response));
    };

export const searchMagnetAccounts = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesAccounts: {limit, offset, search, magnetAccountsAccount, sortOrder, sortField}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const order = getSortOrder(sortOrder);
        dispatch(requestSearchMagnetAccounts(updateLimit, updateOffset));
        const response = await getUsersPagedRequest(token, updateLimit, updateOffset, search, magnetAccountsAccount, order, sortField);
        dispatch(succeedSearchMagnetAccounts(response));
    };

export const createMagnetAccount = (id) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesAccounts: {magnetAccountsAccount}} = getState();
        dispatch(requestCreateMagnetAccounts());
        const response = await addMargetUserRequest(token, id, magnetAccountsAccount);
        dispatch(succeedCreateMagnetAccounts(response));
    };

export const deleteMagnetAccount = (id) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesAccounts: {magnetAccountsAccount}} = getState();
        dispatch(requestDeleteMagnetAccounts(id));
        await deleteMargetUserRequest(token, id, magnetAccountsAccount);
        dispatch(succeedDeleteMagnetAccounts());
    };

export const updateMagnetAccount = (item, cellKey, checked) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const updateItem = {...item, [cellKey]: checked};
        dispatch(requestUpdateMagnetAccounts(updateItem));
        await updateMargetUserRequest(token, updateItem);
        dispatch(succeedUpdateMagnetAccounts());
    };

export const setSearchMagnetAccounts = (search) =>
    async (dispatch, getState) => {
        dispatch(setMagnetSearch(search));
    };

export const setSortAsync = (order, sortField) =>
    async (dispatch, getState) => {
        dispatch(setSortMagnetAccounts(order, sortField));
    };