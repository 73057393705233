import React, {Component} from 'react';
import {connect} from "react-redux";
import join from "lodash/join";
import find from "lodash/find";
import {setInitContainer, setTitle} from "../App/actions";
import {
    initEuStartups,
    getLastUpdate,
    getFilterData,
    refreshEuStartups,
    searchEuStartups,
    setSearchAsync,
    setCountryAsync,
    exportEuStartupsCompany,
    exportEuStartupsEmails,
    exportEuStartupsTwitter,
    getCount,
    clearFiltersAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";

import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import Link from "../../components/Link";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import {getLastUpdateTitle} from "../../helpers/utils";
import {INIT_EU_STARTUPS_REQUESTED, SEARCH_EU_STARTUPS_REQUESTED} from "../../store/StatusTypes";
import RadioButtonSelect from "../../components/Select/RadioButtonSelect";
import {SELECT} from "../../constants/FilterTypes";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'EU Startups';

class EuStartups extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initEuStartups, getLastUpdate, getFilterData, status, getCount} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initEuStartups();
            getFilterData();
            getLastUpdate();
            getCount();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleRefresh = () => {
        const {refreshEuStartups, getCount} = this.props;
        refreshEuStartups();
        getCount();
    }

    getCountrySelect = props => {
        return <RadioButtonSelect {...props} />
    }

    getCountryValue = (selectedOptions, options) => {
        const item = find(options, option => option.id === selectedOptions);
        return item && item.name;
    }

    handleClearFilters = () => {
        const {clearFilters, initEuStartups, getCount} = this.props;
        clearFilters().then(() => {
            initEuStartups();
            getCount();
        });
    }

    getFiltersOptions = () => {
        const {countriesList, selectedCountry, setCountry} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Country',
                submitButtonName: 'Apply',
                options: countriesList,
                renderValue: this.getCountryValue,
                customOptionsComponent: this.getCountrySelect,
                selectedOptions: selectedCountry,
                onChange: setCountry,
                radioSelectClassName: "snovio-filter-container",
                defaultOption: {value: 'All'},
                columnsCount: 3
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_EU_STARTUPS_REQUESTED
            || status === SEARCH_EU_STARTUPS_REQUESTED;
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderList = (row, column, value) => join(value, ', ');

    renderLink = (value) => <Link path={value} value={value}/>;

    render() {
        const {lastUpdate, title, list, total, limit, offset, searchEuStartups, setSearch, search, isFiltersSelected, setIsFiltersSelected,
            exportEuStartupsCompany, exportEuStartupsTwitter, exportEuStartupsEmails, count, isCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const emailsCount = isCountLoading ? <Loading/> : count.countEmails;
        const twittersCount = isCountLoading ? <Loading/> : count.countTwitter;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Name', width: 150},
            {key: 'country', name: 'Country', width: 150},
            {key: 'city', name: 'City', width: 150},
            {key: 'founded', name: 'Founded', width: 150},
            {key: 'website', name: 'Website', width: 150, cellValueWrapperFn: this.renderLink},
            {key: 'twitter', name: 'Twitter', width: 150, renderValue: this.renderList},
        ];

        const dropdownExportArray = [
            {
                onClick: exportEuStartupsCompany,
                title: "All data",
                icon: "export-icon__all-data"
            },
            {
                onClick: exportEuStartupsTwitter,
                title: "Twitters",
                icon: "export-icon__twitter"
            },
            {
                onClick: exportEuStartupsEmails,
                title: "Emails",
                icon: "export-icon__emails"
            },
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Twitters: {twittersCount}</div>
                            <div>Emails: {emailsCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 dropdownExport={dropdownExportArray}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchEuStartups}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.euStartups.list,
        status: state.euStartups.status,
        total: state.euStartups.total,
        limit: state.euStartups.limit,
        offset: state.euStartups.offset,
        search: state.euStartups.search,
        selectedCountry: state.euStartups.selectedCountry,
        countriesList: state.euStartups.countriesList,
        lastUpdate: state.euStartups.lastUpdate,
        count: state.euStartups.count,
        isFiltersSelected: state.euStartups.isFiltersSelected,
        isCountLoading: state.euStartups.isCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initEuStartups: () => dispatch(initEuStartups()),
        refreshEuStartups: () => dispatch(refreshEuStartups()),
        searchEuStartups: (count) => dispatch(searchEuStartups(count)),
        getFilterData: () => dispatch(getFilterData()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        setSearch: search => dispatch(setSearchAsync(search)),
        setCountry: country => dispatch(setCountryAsync(country)),
        exportEuStartupsCompany: () => dispatch(exportEuStartupsCompany()),
        exportEuStartupsTwitter: () => dispatch(exportEuStartupsTwitter()),
        exportEuStartupsEmails: () => dispatch(exportEuStartupsEmails()),
        getCount: () => dispatch(getCount()),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EuStartups)