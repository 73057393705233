import React, {Component} from 'react';
import find from "lodash/find";
import {connect} from "react-redux";
import {
    initSnovio, searchSnovio, refreshSnovio, getLastUpdate, getFilterData, setSourceAsync, exportSnovioEmails, clearFiltersAsync, getCount
} from "./actionsAsync";
import {setInitContainer, setTitle} from "../App/actions";
import AppstoreFilters from "../../components/Filters/PageFilters";
import {setIsFiltersSelected} from "./actions";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import Table from "../../components/Table";
import {INIT_SNOVIO_REQUESTED, SEARCH_SNOVIO_REQUESTED} from "../../store/StatusTypes";
import Link from "../../components/Link";
import {getLastUpdateTitle} from "../../helpers/utils";
import {SELECT} from "../../constants/FilterTypes";
import RadioButtonSelect from "../../components/Select/RadioButtonSelect";
import ExportPopup from "../../components/Popup/components/ExportPopup";
import {closePopupRequest, showPopupRequest} from "../App/actionsAsync";

const CONTAINER_NAME = 'Snovio';

class Snovio extends Component {

    componentDidMount() {
        const {setTitle, getCount, initContainers, setInitContainer, initSnovio, getLastUpdate, status, getFilterData} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initSnovio();
            getLastUpdate();
            getFilterData();
            getCount();
        }
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleClearFilters = () => {
        const {clearFilters, initSnovio} = this.props;
        clearFilters().then(() => initSnovio());
    }

    handleRefresh = () => {
        const {refreshSnovio, getCount} = this.props;
        refreshSnovio();
        getCount();
    }

    getSourcesValue = (selectedOptions, options) => {
        const item = find(options, option => option.id === selectedOptions);
        return item && item.name;
    }

    getSourcesSelect = props => {
        return <RadioButtonSelect {...props} />
    }

    getFiltersOptions = () => {
        const {sourceList, selectedSource, setSource} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Source',
                submitButtonName: 'Apply',
                options: sourceList,
                renderValue: this.getSourcesValue,
                customOptionsComponent: this.getSourcesSelect,
                selectedOptions: selectedSource,
                onChange: setSource,
                radioSelectClassName: "snovio-filter-container",
                defaultOption: {value: 'All'}
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_SNOVIO_REQUESTED
            || status === SEARCH_SNOVIO_REQUESTED;
    }

    onExportSnovio = () => {
        const {exportSnovioEmails, showPopup, fieldsList, getCount} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <ExportPopup closePopup={closePopup} onApply={handleApply} fieldsList={fieldsList} getExportCount={getCount}/>
        }

        const handleApply = (fields) => {
            exportSnovioEmails(fields);
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    renderLink = (value) => <Link path={value} value={value}/>;

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    render() {
        const {list, total, limit, isLoading, offset, lastUpdate, title, searchSnovio, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Name',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'email', name: 'Email', width: 200},
            {key: 'position', name: 'Position', width: 100},
            {key: 'companyName', name: 'Company', width: 100},
            {key: 'source', name: 'Source', width: 100},
            {key: 'url', name: 'URL', width: 200, cellValueWrapperFn: this.renderLink}
        ]

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 onApplyFilters={this.handleRefresh}
                                 onExport={this.onExportSnovio}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchSnovio}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        status: state.snovio.status,
        lastUpdate: state.snovio.lastUpdate,
        list: state.snovio.list,
        total: state.snovio.total,
        limit: state.snovio.limit,
        offset: state.snovio.offset,
        sourceList: state.snovio.sourceList,
        selectedSource: state.snovio.selectedSource,
        isFiltersSelected: state.snovio.isFiltersSelected,
        fieldsList: state.snovio.fieldsList,
        isLoading: state.snovio.isLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
        popup: state.app.popup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initSnovio: () => dispatch(initSnovio()),
        searchSnovio: (count) => dispatch(searchSnovio(count)),
        refreshSnovio: () => dispatch(refreshSnovio()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterData: () => dispatch(getFilterData()),
        setSource: selectedSources => dispatch(setSourceAsync(selectedSources)),
        exportSnovioEmails: (fields) => dispatch(exportSnovioEmails(fields)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
        getCount: (fields) => dispatch(getCount(fields)),

        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Snovio)