import * as types from "./constants";

export function requestInitClose() {
    return {
        type: types.REQUEST_INIT_CLOSE
    };
}

export function succeedInitClose(response) {
    return {
        type: types.SUCCEED_INIT_CLOSE,
        response
    };
}

export function requestSearchClose(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_CLOSE,
        limit, offset
    };
}

export function succeedSearchClose(response) {
    return {
        type: types.SUCCEED_SEARCH_CLOSE,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_CLOSE_SEARCH,
        search
    }
}
export function requestLastUpdateClose() {
    return {
        type: types.REQUEST_LAST_UPDATE_CLOSE,
    };
}

export function succeedLastUpdateClose(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_CLOSE,
        lastUpdate
    };
}

export function succeedCloseFilterData(response) {
    return {
        type: types.SUCCEED_CLOSE_FILTER_DATA,
        response
    };
}

export function setCategory(selectedCategory) {
    return {
        type: types.SET_CLOSE_CATEGORY,
        selectedCategory
    };
}

export function requestGetCountClose() {
    return {
        type: types.REQUEST_GET_COUNT_CLOSE
    };
}

export function succeedGetCountClose(count) {
    return {
        type: types.SUCCEED_GET_COUNT_CLOSE,
        count
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_CLOSE,
        isFiltersSelected
    };
}

export function clearFiltersClose() {
    return {
        type: types.CLEAR_FILTERS_CLOSE,
    };
}


