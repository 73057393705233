import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import {setInitContainer, setTitle} from "../App/actions";

import Filters from "../../components/Filters";
import MagnetDecideLetter from "../../components/MagnetDecideLetter";
import Loading from "../../components/Loading";
import MagnetClosePopup from "../../components/Popup/components/MagnetClosePopup";

import {BUTTON_ELEMENT} from "../../constants/FilterTypes";

import {
    approveContactInClose,
    initMagnetDecide,
    refreshMagnetDecide,
    rejectMagnetContact,
    skipMagnetContact
} from "./actionsAsync";
import {showPopupRequest, closePopupRequest} from "../App/actionsAsync";
import {
    GET_MAGNET_MESSAGE_REQUESTED,
} from "../../store/StatusTypes";

import '../../css/magnet-decide.css';

class MagnetDecideSales extends Component {

    componentDidMount() {
        const {initMagnetDecide, account, status} = this.props;
        status === null && initMagnetDecide(account);
    }

    getFiltersOptions = () => {
        const filtersOptions = [
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'magnet-decide__button skip',
                title: 'Skip',
                onClick: this.onSkipMagnetContact
            },
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'magnet-decide__button non-close',
                title: 'Non-Close',
                onClick: this.onRejectMagnetContact
            },
            {
                type: BUTTON_ELEMENT,
                customWrapperClassName: 'magnet-decide__button next',
                title: 'Close',
                onClick: this.onMagnetContactInClose
            },
        ];

        return filtersOptions
    };

    onMagnetContactInClose = () => {
        const {message: {email}, refreshMagnetDecide, approveContactInClose} = this.props;
        approveContactInClose(email).then(response => {
            const {message} = response;
            if (message) {
                this.showClosePopup(message);
            } else {
                refreshMagnetDecide();
            }
        });
    }

    showClosePopup = (message) => {
        const {showPopup, closePopup} = this.props;
        const popupMessage = `${message}. `;
        const getPopupComponent = (onApply, closePopup) => {
            return <MagnetClosePopup closePopup={closePopup} message={popupMessage}/>
        }

        const handleApply = () => {
            closePopup();
        }

        const popup = {
            component: getPopupComponent,
            open: true,
            onApply: handleApply
        }
        showPopup(popup);
    }

    onSkipMagnetContact = () => {
        const {message: {email}, refreshMagnetDecide, skipMagnetContact} = this.props;
        skipMagnetContact(email).then(response => {
            refreshMagnetDecide();
        });
    }

    onRejectMagnetContact = () => {
        const {message: {email}, refreshMagnetDecide, rejectMagnetContact} = this.props;
        rejectMagnetContact(email).then(response => {
            refreshMagnetDecide();
        });
    }

    getLoading = () => {
        const {message, status} = this.props;
        return isEmpty(message)
            || status === GET_MAGNET_MESSAGE_REQUESTED;
    }

    render() {
        const {message = {headers: {}}, totalLeft} = this.props;
        const {id, userId, body, email, headers, isFailed} = message;
        const filtersOptions = this.getFiltersOptions();
        const filterClassName = classNames("magnet-decide__filter", {
            "hidden": totalLeft === 0,
        });
        const isLoading = this.getLoading();
        const magnetLoadingClassName = classNames("magnet-decide__loading", {
            "magnet-decide_hidden": !isLoading,
        });

        const magnetContainerClassName = classNames("magnet-decide", {
            "magnet-decide_hidden": isLoading,
        });

        return (
            <React.Fragment>
                <div className={magnetLoadingClassName}><Loading/></div>

                {!isLoading && <div className={magnetContainerClassName}>
                    <div className="magnet-decide__filters">
                        <div className="magnet-decide__contacts-title">{totalLeft} contacts to process</div>
                        <Filters containerClassName={filterClassName} filtersOptions={filtersOptions} />
                    </div>
                    <div className="app-root__table magnet-decide__table">
                        <MagnetDecideLetter
                            id={id}
                            userId={userId}
                            totalLeft={totalLeft}
                            bodyHtml={body}
                            email={email}
                            headers={headers}
                            isDisplayLetterLink={false}
                            isFailed={isFailed}
                        />
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        message: state.magnetSalesDecide.message,
        status: state.magnetSalesDecide.status,
        totalLeft: state.magnetSalesDecide.totalLeft,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),

        initMagnetDecide: (account) => dispatch(initMagnetDecide(account)),
        refreshMagnetDecide: () => dispatch(refreshMagnetDecide()),
        approveContactInClose: (email) => dispatch(approveContactInClose(email)),
        skipMagnetContact: (email) => dispatch(skipMagnetContact(email)),
        rejectMagnetContact: (email) => dispatch(rejectMagnetContact(email)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MagnetDecideSales)