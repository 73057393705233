import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';

import Button from '../Button';
import SelectedAppstoreFilters from "./SelectedAppstoreFilters";
import Select from '../Select';
import ItemSelect from "../ItemSelect";
import DateRange from "../DateRange";
import DownloadCount from "../DownloadCount";
import SearchInput from "../SearchInput";
import AddInput from "../AddInput";
import LanguagesCount from "../LanguagesCount";
import FiltersDropdown from "./FiltersDropdown";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import './css/filters.css';

const itemsSideBar = {
    button: Button,
    select: Select,
    itemSelect: ItemSelect,
    dateRange: DateRange,
    downloadCount: DownloadCount,
    addElement: AddInput,
    languagesCount: LanguagesCount
};

function PageFilters(props) {
    const {containerClassName, filtersOptions, currentPage, search, setSearch, onRefresh, onExport, dropdownExport, onApplyFilters, onClearFilters, totalItems, isFiltersSelected, setIsFiltersSelected} = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [disabledValue, setDisabledValue] = useState(true)
    const exportDropdownButton = useRef();
    const exportDropdownContainer = useRef();

    const isHrefPage = currentPage === 'href';
    const isBlockingConditions = isHrefPage && (filtersOptions[0].selectedOptions.length === filtersOptions[0].options.length || filtersOptions[0].selectedOptions.length === 0);

    useOnClickOutside(exportDropdownButton, () => setIsDropdownOpen(false), exportDropdownContainer);

    const setItemSideBar = (type) => {
        return itemsSideBar[type]
    };

    const handleChange = (onChange) => options => {
        const {onRefresh} = props;
        if (onChange) {
            onChange(options).then(() => {
                onRefresh && onRefresh();
            })
        }
    }

    const renderFilters = () => {
        const {filtersOptions} = props;
        const filters = map(filtersOptions, (option, index) => {
            const {type, customWrapperClassName, onChange} = option;

            const ItemSideBar = setItemSideBar(type);

            const itemSideBarProps = {
                ...option,
                onChange: handleChange(onChange),
            };

            const itemWrapperClassNames = classNames('filter__item_wrapper', customWrapperClassName);

            return <div className={itemWrapperClassNames} key={index}><ItemSideBar {...itemSideBarProps}/></div>;
        });
        return filters;
    };

    const onToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const exitFilters = () => {
        setIsFiltersOpen(false);
    }

    const renderExportDropdown = () => {
        const {dropdownExport} = props;
        return map(dropdownExport, item => {
            const {onClick, title, icon} = item;
            return <div key={title} className="export-dropdown-item" onClick={onClick}>
                {icon && <div className={icon}/>}
                <div>{title}</div>
            </div>;
        });
    }

    const handleApplyFilters = () => {
        onApplyFilters();
        setIsFiltersSelected(true);
        if (isHrefPage && !isBlockingConditions) {
            setDisabledValue(false);
        } else if (isHrefPage && isBlockingConditions) {
            setDisabledValue(true);
        }
    }

    const handleCloseFilters = () => {
        setIsFiltersOpen(false);
        setIsFiltersSelected(false);
        (isHrefPage && setDisabledValue(true))
        onClearFilters();
    }

    const handleEditFilters = () => {
        setIsFiltersOpen(true);
        (isHrefPage && setDisabledValue(true))
        setIsFiltersSelected(false);
    }

    const filters = renderFilters();

    const containerClassNames = classNames("filters__container", "appstore-filter__container", containerClassName);
    const dropdownItems = renderExportDropdown();
    const exportDropdownContainerStyle = classNames("filters__export-dropdown-container", {"open": isDropdownOpen});
    const refreshPage = onApplyFilters || onRefresh;
    const conditionsForDisable = isHrefPage ? disabledValue : '';
    const exportButtonStyle = classNames({"disabled": totalItems === 0 || conditionsForDisable});
    const exportDropdownButtonStyle = classNames("export-dropdown-button", exportButtonStyle);


        return (
        <div className={containerClassNames}>
            <div className="header-export-container">
                {setSearch && <SearchInput className="filter-search search-with-filters-icon"
                                           placeholder="Search"
                                           value={search}
                                           isSearchOnEmpty={true}
                                           onSearch={setSearch}
                                           refreshPage={refreshPage}/>}
                {onExport && <div className="filters__export">
                    <Button className={exportButtonStyle} title='Export' onClick={onExport}
                            icon={<div className='dropdown-export-icon'/>}/>
                </div>}
                {dropdownExport && <div className="filters__export">
                    <div ref={exportDropdownButton}>
                        <Button className={exportDropdownButtonStyle} title='Export' onClick={onToggleDropdown}
                                icon={<div className='dropdown-export-icon'/>}/>
                    </div>
                    <div ref={exportDropdownContainer} className={exportDropdownContainerStyle}>
                        {dropdownItems}
                    </div>
                </div>}
            </div>
            {!isFiltersOpen && !isFiltersSelected &&
            <Button className="add-filter-button__container" title='Add filter' onClick={() => setIsFiltersOpen(true)}
                    icon={<div className='add-filter-icon'/>}/>}

            <SelectedAppstoreFilters filters={filters} onEdit={handleEditFilters} onClose={handleCloseFilters}
                                     isVisible={isFiltersSelected}/>
            <FiltersDropdown filters={filters} onApply={handleApplyFilters} onClear={onClearFilters}
                             exitFilters={exitFilters} isOpen={isFiltersOpen && !isFiltersSelected}/>
        </div>
    );
}

PageFilters.propTypes = {
    search: PropTypes.string,
    setSearch: PropTypes.func,
    onExport: PropTypes.func,
    onRefresh: PropTypes.func,
    filtersOptions: PropTypes.array,
    containerClassName: PropTypes.string,
    dropdownExport: PropTypes.array
}

export default (PageFilters);
