export const REQUEST_INIT_SNOVIO = 'REQUEST_INIT_SNOVIO';
export const SUCCEED_INIT_SNOVIO = 'SUCCEED_INIT_SNOVIO';
export const REQUEST_SEARCH_SNOVIO = 'REQUEST_SEARCH_SNOVIO';
export const SUCCEED_SEARCH_SNOVIO = 'SUCCEED_SEARCH_SNOVIO';
export const REQUEST_LAST_UPDATE_SNOVIO = 'REQUEST_LAST_UPDATE_SNOVIO';
export const SUCCEED_LAST_UPDATE_SNOVIO = 'SUCCEED_LAST_UPDATE_SNOVIO';
export const REQUEST_GET_FILTER_DATA = 'REQUEST_GET_FILTER_DATA';
export const SUCCEED_GET_FILTER_DATA = 'SUCCEED_GET_FILTER_DATA';
export const SET_SELECTED_SOURCE = 'SET_SELECTED_SOURCE';
export const CLEAR_FILTERS_SNOVIO = 'CLEAR_FILTERS_SNOVIO';
export const SET_IS_FILTER_SELECTED_SNOVIO = 'SET_IS_FILTER_SELECTED_SNOVIO';
export const REQUEST_GET_COUNT_SNOVIO = 'REQUEST_GET_COUNT_SNOVIO';
export const SUCCEED_GET_COUNT_SNOVIO = 'SUCCEED_GET_COUNT_SNOVIO';