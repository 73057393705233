import * as types from "../ActionTypes";

export function showNotification(status, msg, typeNotification, timer, classNames, customNotification) {
    return {
        type: types.SHOW_NOTIFICATION,
        blockUi: false,
        status,
        statusMsg: msg,
        typeNotification,
        timer: timer,
        classNames: classNames,
        customNotification: customNotification
    };
}

export function showNotificationComplete() {
    return {
        type: types.SHOW_NOTIFICATION_COMPLETE
    };
}
