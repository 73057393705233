import React, {useState} from "react";
import classNames from 'classnames';
import Popover from "../Popover";

import './css/legend.css';

function Legend({className, value}) {

    const [isOpen, setIsOpen] = useState(false);

    const onToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const openMenu = () => {
        setIsOpen(true);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const containerClassName = classNames("legend", className);

    return (
        <div className={containerClassName}>
            <div className="legend__icon" onMouseEnter={openMenu} onMouseLeave={closeMenu} />
            <Popover position="left" className="legend-popover" open={isOpen} onClosePopover={onToggleMenu}>
                <div className="legend-value">
                    {value}
                </div>
            </Popover>
        </div>
    )
}

export default Legend;