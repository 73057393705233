import {useEffect} from "react";

function useOnClickOutside(ref, handleClickOutside, additionalRef) {
    useEffect(
        () => {
            if (ref.current) {
                const listener = (event) => {
                    const isOutsideClick = additionalRef
                        ? !ref.current.contains(event.target) && !additionalRef.current.contains(event.target)
                        : !ref.current.contains(event.target);
                    if (isOutsideClick) {
                        handleClickOutside(event);
                    }
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            }
        },
        [ref, handleClickOutside, additionalRef]
    );
}

export default useOnClickOutside;