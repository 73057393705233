import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import join from "lodash/join";
import {setInitContainer, setTitle} from "../App/actions";
import {
    INIT_CLOSE_REQUESTED,
    SEARCH_CLOSE_REQUESTED,
} from "../../store/StatusTypes";
import {exportClose, initClose, refreshClose, searchClose, setSearchAsync, getLastUpdate, getFilterData, setCategoryAsync, getCount, exportCloseEmails, clearFiltersAsync} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";

import AppstoreFilters from "../../components/Filters/PageFilters";
import Table from "../../components/Table";
import RadioButtonSelect from "../../components/Select/RadioButtonSelect";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import Loading from "../../components/Loading/index";

import {getLastUpdateTitle} from "../../helpers/utils";
import {SELECT} from "../../constants/FilterTypes";

import '../../css/close.css';

const CONTAINER_NAME = 'Close';

class CloseIo extends Component {

    componentDidMount() {
        const {setTitle, initClose, setInitContainer, initContainers, getLastUpdate, status, getFilterData, getCount} = this.props;

        setTitle(CONTAINER_NAME);
        if (status === null) {
            initClose();
            getLastUpdate();
            getFilterData();
            getCount();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getCategorySelect = props => {
        return <RadioButtonSelect {...props} />
    }

    getCategoryValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {categoriesList, selectedCategory, setCategory} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Categories',
                options: categoriesList,
                onChange: setCategory,
                selectedOptions: selectedCategory,
                renderValue: this.getCategoryValue,
                columnsCount: 1,
                submitButtonName: 'Apply',
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status, isEmailsCountLoading} = this.props;
        return status === null
            || status === INIT_CLOSE_REQUESTED
            || status === SEARCH_CLOSE_REQUESTED
            || isEmailsCountLoading;
    }

    handleClearFilters = () => {
        const {clearFilters, initClose, getCount} = this.props;
        clearFilters().then(() => {
            initClose();
            getCount()
        });
    }

    onShowMore = () => {
        const {searchClose} = this.props;
        searchClose();
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    handleRefresh = () => {
        const {refreshClose, getCount} = this.props;
        refreshClose();
        getCount();
    }

    render() {
        const {lastUpdate, title, list, total, limit, offset, searchClose, search, setSearch, emails, exportClose, exportCloseEmails,
            setIsFiltersSelected, isFiltersSelected, isEmailsCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const emailsCount = isEmailsCountLoading ? <Loading/> : emails;

        const columns = [
            {key: 'id', name: '#', width: 60,
                headerClassName: 'close-index-cell',
                cellClassName: 'close-index-cell',
                renderValue: this.renderIndexCell
            },
            {key: 'email', name: 'Email'},
            {key: 'url', name: 'Website'},
            {key: 'category', name: 'Category'},
            {key: 'leadName', name: 'Name'},
        ]

        const dropdownExportArray = [
            {
                onClick: exportClose,
                title: "All data",
                icon: "export-icon__all-data"
            },
            {
                onClick: exportCloseEmails,
                title: "Emails",
                icon: "export-icon__emails"
            },
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Emails: {emailsCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 dropdownExport={dropdownExportArray}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        className="close-table"
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchClose}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.close.list,
        status: state.close.status,
        total: state.close.total,
        limit: state.close.limit,
        offset: state.close.offset,
        search: state.close.search,
        lastUpdate: state.close.lastUpdate,
        selectedCategory: state.close.selectedCategory,
        categoriesList: state.close.categoriesList,
        emails: state.close.emails,
        isFiltersSelected: state.close.isFiltersSelected,
        isEmailsCountLoading: state.close.isEmailsCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
        setSearch: (search) => dispatch(setSearchAsync(search)),

        initClose: () => dispatch(initClose()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        searchClose: (count) => dispatch(searchClose(count)),
        refreshClose: () => dispatch(refreshClose()),
        exportClose: () => dispatch(exportClose()),
        exportCloseEmails: () => dispatch(exportCloseEmails()),
        getFilterData: () => dispatch(getFilterData()),
        setCategory: (category) => dispatch(setCategoryAsync(category)),
        getCount: () => dispatch(getCount()),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CloseIo)