import React from "react";
import {showPopupRequest, closePopupRequest} from "../../containers/App/actionsAsync";
import DownloadCsvPopup from "../../components/Popup/components/DownloadCsvPopup";

const DOWNLOAD_POPUP_PROPERTY = 'showDownloadPopup';

const showDownloadPopupMiddleware = ({dispatch}) => next => action => {
    if (DOWNLOAD_POPUP_PROPERTY in action) {
        const getMessageComponent = (onApply, closePopup) => {
            return <DownloadCsvPopup closePopup={closePopup} exportId={action.exportId}/>
        }

        const handleApply = () => {
            dispatch(closePopupRequest);
        }

        const popup = {
            component: getMessageComponent,
            open: true,
            onApply: handleApply
        }

        dispatch(showPopupRequest(popup));
    }
    next(action);
};

export default showDownloadPopupMiddleware;
