import * as types from "./constants";

export function requestInitTwitterAudiences() {
    return {
        type: types.REQUEST_INIT_TWITTER_AUDIENCES
    };
}

export function succeedInitTwitterAudiences(response) {
    return {
        type: types.SUCCEED_INIT_TWITTER_AUDIENCES,
        response
    };
}

export function requestSearchTwitterAudiences(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_TWITTER_AUDIENCES,
        limit, offset
    };
}

export function succeedSearchTwitterAudiences(response) {
    return {
        type: types.SUCCEED_SEARCH_TWITTER_AUDIENCES,
        response
    };
}

export function requestCreateTwitterAudiences() {
    return {
        type: types.REQUEST_CREATE_TWITTER_AUDIENCES,
        blockUi: true
    };
}

export function succeedCreateTwitterAudiences(response) {
    return {
        type: types.SUCCEED_CREATE_TWITTER_AUDIENCES,
        blockUi: false,
        response
    };
}

export function setSearchTwitterAudiences(search) {
    return {
        type: types.SET_SEARCH_TWITTER_AUDIENCES,
        search
    };
}

export function requestSendAudienceToTwitter() {
    return {
        type: types.REQUEST_SEND_AUDIENCE_TO_TWITTER,
        blockUi: true
    };
}

export function succeedSendAudienceToTwitter() {
    return {
        type: types.SUCCEED_SEND_AUDIENCE_TO_TWITTER,
        blockUi: false
    };
}