import * as types from "./constants";

export function requestInitMagnetAccounts(account) {
    return {
        type: types.REQUEST_INIT_MAGNET_ACCOUNTS,
        account
    };
}

export function succeedInitMagnetAccounts(response) {
    return {
        type: types.SUCCEED_INIT_MAGNET_ACCOUNTS,
        response
    };
}

export function requestRefreshMagnetAccounts() {
    return {
        type: types.REQUEST_REFRESH_MAGNET_ACCOUNTS
    };
}

export function succeedRefreshMagnetAccounts(response) {
    return {
        type: types.SUCCEED_REFRESH_MAGNET_ACCOUNTS,
        response
    };
}

export function requestSearchMagnetAccounts(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_MAGNET_ACCOUNTS,
        limit, offset
    };
}

export function succeedSearchMagnetAccounts(response) {
    return {
        type: types.SUCCEED_SEARCH_MAGNET_ACCOUNTS,
        response
    };
}

export function setMagnetSearch(search) {
    return {
        type: types.SET_MAGNET_SEARCH,
        search
    }
}

export function setSortMagnetAccounts(order, sortField) {
    return {
        type: types.SET_SORT_MAGNET_ACCOUNTS,
        order, sortField
    };
}

export function requestCreateMagnetAccounts() {
    return {
        type: types.REQUEST_CREATE_MAGNET_ACCOUNT,
        blockUi: true,
    }
}

export function succeedCreateMagnetAccounts(user) {
    return {
        type: types.SUCCEED_CREATE_MAGNET_ACCOUNT,
        blockUi: false,
        user
    }
}

export function requestDeleteMagnetAccounts(id) {
    return {
        type: types.REQUEST_DELETE_MAGNET_ACCOUNT,
        blockUi: true,
        id
    }
}

export function succeedDeleteMagnetAccounts() {
    return {
        type: types.SUCCEED_DELETE_MAGNET_ACCOUNT,
        blockUi: false,
    }
}

export function requestUpdateMagnetAccounts(updatedItem) {
    return {
        type: types.REQUEST_UPDATE_MAGNET_ACCOUNT,
        updatedItem
    }
}

export function succeedUpdateMagnetAccounts() {
    return {
        type: types.SUCCEED_UPDATE_MAGNET_ACCOUNT
    }
}