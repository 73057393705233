import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {INIT_SNOVIO_REQUESTED} from "../../store/StatusTypes";
import {convertToArrayOfObjects, snovioFieldsList} from "../../helpers/utils";

const initialState = {
    status: null,
    lastUpdate: null,
    limit: 10,
    total: 0,
    list: [],
    offset: 0,
    sourceList: [],
    selectedSource: 'All',
    isFiltersSelected: false,
    fieldsList: snovioFieldsList,
    isLoading: true,
};

export default function snovio(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_SNOVIO:
            return {
                ...state,
                status: INIT_SNOVIO_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_SNOVIO:
            return {
                ...state,
                status: statuses.INIT_SNOVIO_SUCCEEDED,
                list: action.response.result,
            }
        case actions.REQUEST_SEARCH_SNOVIO:
            return {
                ...state,
                status: statuses.SEARCH_SNOVIO_REQUESTED,
                limit: action.limit,
                offset: action.offset,
                isLoading: true,
            }
        case actions.SUCCEED_SEARCH_SNOVIO:
            return {
                ...state,
                status: statuses.SEARCH_SNOVIO_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                isLoading: false,
            }
        case actions.REQUEST_LAST_UPDATE_SNOVIO:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_SNOVIO:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_GET_FILTER_DATA:
            return {
                ...state,
            }
        case actions.SUCCEED_GET_FILTER_DATA:
            return {
                ...state,
                sourceList: [...state.sourceList, ...convertToArrayOfObjects(action.response.sources)]
            }
        case actions.SET_SELECTED_SOURCE:
            return {
                ...state,
                selectedSource: action.selectedSource,
            }
        case actions.SET_IS_FILTER_SELECTED_SNOVIO:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_SNOVIO:
            return {
                ...state,
                isOnlyChosenLanguages: false,
                selectedSource: 'All',
            }
        case actions.REQUEST_GET_COUNT_SNOVIO:
            return {
                ...state,
                isLoading: true,
            }
        case actions.SUCCEED_GET_COUNT_SNOVIO:
            return {
                ...state,
                total: action.count.count,
                isLoading: false,
            }
        default:
            return state;
    }
}