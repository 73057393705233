import isEqual from "lodash/isEqual";
import {
    getItchGamesRequest,
    getItchFilterDataRequest,
    getItchLastUpdateRequest,
    exportItchGamesRequest,
    getCountItch
} from '../../services/grpcBufServices';
import {
    requestInitItch,
    succeedInitItch,
    succeedGetItchFilterData,
    requestItchLastUpdate,
    succeedItchLastUpdate,
    setSelectedGenres,
    setSelectedLanguage,
    setSelectedPlatform,
    requestSearchItch,
    succeedSearchItch,
    setLanguageCount,
    setPrice,
    setReleased,
    succeedGetCountItchGames,
    requestGetCountItchGames,
    clearFilters
} from "./actions";
import {getRequestOptions} from "../../helpers/utils";
import {getReleasedValue} from "../../helpers/dateUtils";
import {requestExport, succeedExport} from "../App/actions";

export const initItch = () =>
    async (dispatch, getState) => {
        const {app: {token}, itchGames: {limit, offset}} = getState();
        dispatch(requestInitItch());
        const response = await getItchGamesRequest(token, limit, offset);
        dispatch(succeedInitItch(response));
    };

export const refreshItch = () =>
    async (dispatch, getState) => {
        const {app: {token}, itchGames: {limit, offset, selectedGenres, genres: genresOptions, defaultDateRange, languagesCount, isOnlyChosenLanguages, platform, selectedLanguages, languages, selectedPlatform, selectedPrice, releasedDateRange}} = getState();
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const platformsOption = platform?.length === selectedPlatform?.length ? [] : selectedPlatform;
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        dispatch(requestInitItch());
        const response = await getItchGamesRequest(token, limit, offset, genreOptions, languageOptions, languagesCountValue, platformsOption, selectedPrice, releasedDate);
        dispatch(succeedInitItch(response));
    };

export const searchItch = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, itchGames: {limit, offset, selectedGenres, defaultDateRange, genres: genresOptions, languagesCount, isOnlyChosenLanguages, selectedLanguages, languages, platform, selectedPlatform, selectedPrice, releasedDateRange}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const platformsOption = platform?.length === selectedPlatform?.length ? [] : selectedPlatform;
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        dispatch(requestSearchItch(updateLimit, updateOffset));
        const response = await getItchGamesRequest(token, updateLimit, updateOffset, genreOptions, languageOptions, languagesCountValue, platformsOption, selectedPrice, releasedDate);
        dispatch(succeedSearchItch(response));
    };

export const getItchFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getItchFilterDataRequest(token);
        dispatch(succeedGetItchFilterData(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestItchLastUpdate());
        const lastUpdate = await getItchLastUpdateRequest(token);
        dispatch(succeedItchLastUpdate(lastUpdate));
    };

export const exportItchGames = () =>
    async (dispatch, getState) => {
        const {app: {token}, itchGames: {limit, offset, selectedGenres, defaultDateRange, genres: genresOptions, platform, isOnlyChosenLanguages, languagesCount, selectedLanguages, languages, selectedPlatform, selectedPrice, releasedDateRange}} = getState();
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const platformsOption = platform?.length === selectedPlatform?.length ? [] : selectedPlatform;
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        dispatch(requestExport());
        const exportId = await exportItchGamesRequest(token, limit, offset, genreOptions, languageOptions, languagesCountValue, platformsOption, selectedPrice, releasedDate);
        dispatch(succeedExport(exportId.task.id));
    };

export const setGenresAsync = genres =>
    async (dispatch, getState) => {
        dispatch(setSelectedGenres(genres));
    };

export const setLanguageAsync = languages =>
    async (dispatch, getState) => {
        dispatch(setSelectedLanguage(languages));
    };

export const setPlatformAsync = platform =>
    async (dispatch, getState) => {
        dispatch(setSelectedPlatform(platform));
    };

export const setLanguageCountAsync = languagesCount =>
    async (dispatch, getState) => {
        dispatch(setLanguageCount(languagesCount));
    };

export const setPriceAsync = price =>
    async (dispatch, getState) => {
        dispatch(setPrice(price));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        const {app: {token}, itchGames: {selectedGenres, genres: genresOptions, defaultDateRange, languagesCount, selectedLanguages, isOnlyChosenLanguages, platform, languages, selectedPlatform, selectedPrice, releasedDateRange}} = getState();
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languageOptions = getRequestOptions(selectedLanguages, languages);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const platformsOption = platform?.length === selectedPlatform?.length ? [] : selectedPlatform;
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguages.length, to: selectedLanguages.length} : languagesCount;
        dispatch(requestGetCountItchGames());
        const count = await getCountItch(token, genreOptions, languageOptions, languagesCountValue, platformsOption, selectedPrice, releasedDate);
        dispatch(succeedGetCountItchGames(count));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };
