import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from "lodash/map";
import Loading from "../Loading";
import RadioButton from "../RadioButton";

import './css/select.css';

class RadioButtonSelect extends Component {

    static propTypes = {
        selectedOptions: PropTypes.any,
        title: PropTypes.string,
        radioSelectClassName: PropTypes.string,
        isLoading: PropTypes.bool,
        columnsCount: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            value: '',
        }
    }

    setValue = value => {
        this.setState({value: value})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedOptions} = this.props;
        const {selectedOptions: prevSelectedOptions} = prevProps;

        if (selectedOptions !== prevSelectedOptions) {
            this.setValue(selectedOptions)
        }
    }

    onChange = (option) => (e, checked, id) => {
        const {onChange} = this.props;
        onChange && onChange(e, checked, id, option);
    }

    onChangeValue = (e, id) => {
        const {setSelectedOptions} = this.props;
        this.setValue(id);
        setSelectedOptions(id);
    }

    getRenderOptions = () => {
        const {value} = this.state;
        const {options} = this.props;
        //  проверяем наличие радиобатона All, чтобы не добавить его несколько раз
        if (options[0]?.id !== 'All') {
            // добавляем радиобатон All
            options.unshift({id: 'All', name: 'All'});
        }
        return map(options, item => {
            const {id, name} = item;
            const checked = id === value;
            const allButtonClassName = classNames("radio-select-options__item", {"all-radiobutton": id === 'All'});
            return (
                <div key={id} className={allButtonClassName}>
                    <RadioButton id={id} checked={checked} onChange={this.onChangeValue}/>
                    <div className="radio-select-options__item-title" onClick={(e) => this.onChangeValue(e, id)}>{name}</div>
                </div>
            )
        });
    }

    calculateContainerWidth = () => {
        const {columnsCount} = this.props;
        const columnWidth = 250;
        return columnsCount ? columnWidth * columnsCount : 'auto';
    }

    render() {
        const {radioSelectClassName, isLoading} = this.props;
        const renderOptions = this.getRenderOptions();
        const containerWidth = this.calculateContainerWidth();

        return (
            <React.Fragment>
                <div className={radioSelectClassName} style={{width: containerWidth}}>
                    {isLoading
                        ? <div className="loading-spinner-center"><Loading/></div>
                        : renderOptions}
                </div>
            </React.Fragment>
        );
    }
}

export default (RadioButtonSelect);