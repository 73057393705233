import React, {Component} from 'react';
import {connect} from "react-redux";
import {setInitContainer, setTitle} from "../App/actions";
import Table from "../../components/Table";
import Checkbox from "../../components/Checkbox";
import {
    getListSubscribe,
    refreshListSubscribe,
    searchSubscribeList,
    setSearchAsync, setSortAsync,
    updateSubscribeList,
    setDecisionStatusAsync,
    clearFiltersAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions"
import {convertTimestampFormat, diffBetweenTodayAndDate} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import {
    INIT_SUBSCRIBE_LIST_REQUESTED,
    REFRESH_SUBSCRIBE_LIST_REQUESTED, SEARCH_SUBSCRIBE_LIST_SALES_REQUESTED,
} from "../../store/StatusTypes";

import '../../css/subscribe-list.css';
import HoverButton from "../../components/HoverButton";
import {getDecisionValue} from "../../helpers/utils";
import SortingTitle from "../../components/Table/SortingTitle";
import AppstoreFilters from "../../components/Filters/PageFilters";
import {SELECT} from "../../constants/FilterTypes";
import find from "lodash/find";
import RadioButtonSelect from "../../components/Select/RadioButtonSelect";

const CONTAINER_NAME = 'Subscribe list';

class SubscribeDecide extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, getListSubscribe, status} = this.props;

        setTitle(CONTAINER_NAME);
        status === null && getListSubscribe();
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    handleClearFilters = () => {
        const {clearFilters, getListSubscribe} = this.props;
        clearFilters().then(() => getListSubscribe());
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_SUBSCRIBE_LIST_REQUESTED
            || status === SEARCH_SUBSCRIBE_LIST_SALES_REQUESTED
            || status === REFRESH_SUBSCRIBE_LIST_REQUESTED;
    }

    renderEnCheckbox = (value, cellKey, row) => {
        const {isDoNotDisturb} = row;
        return <Checkbox checked={value} disabled={isDoNotDisturb} onChange={(e, checked, id) => this.onSubscribeListChange(checked, row, 'isEn', 'bool')}/>
    }

    renderRuCheckbox = (value, cellKey, row) => {
        const {isDoNotDisturb} = row;
        return <Checkbox checked={value} disabled={isDoNotDisturb} onChange={(e, checked, id) => this.onSubscribeListChange(checked, row, 'isRu', 'bool')}/>
    }

    renderDoNotDisturbCheckbox = (value, cellKey, row) => {
        return <Checkbox checked={value} onChange={(e, checked, id) => this.onSubscribeListChange(checked, row, 'isDoNotDisturb', 'bool')}/>
    }

    renderDecisionButton = (value, cellKey, row) => {
        return value === 'New'
            ? <div className="hover-button-value">{value}</div>
            : <HoverButton key={row.id} buttonTitle='Redecide' value={value} onClick={() => this.onSubscribeListChange(null, row, 'redecide', 'null')}/>
    }

    onSubscribeListChange = (value, item, key, type) => {
        const {updateSubscribeList} = this.props;
        const updateItem = {...item, [key]: value};
        updateSubscribeList(updateItem, key, type, value);
    }

    renderDecisionValue = (row, column, value) => getDecisionValue(value);

    renderDateValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderSnoozeValue = (row, column, value) => {
        if (value) {
            return `${diffBetweenTodayAndDate(value, CONST.DATE_FORMAT_LL)} days`;
        }
    }

    renderImportedDateValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_WITH_TIME);

    handleSetSort = (field, order) => {
        const {setSort, refreshListSubscribe} = this.props;
        setSort(field, order).then(() => {
            refreshListSubscribe();
        })
    }

    subscribeListSortingTitleRender = (key, name) => {
        const {sortField, sortOrder} = this.props;

        return <SortingTitle
            columnId={key}
            sortField={sortField}
            sortOrder={sortOrder}
            value={name}
            onClick={this.handleSetSort}/>
    }

    subscribeListCountTitleRender = (key, name, keyCount) => {
        const {countSubscribe} = this.props;
        const title = countSubscribe && countSubscribe[keyCount];
        return <div className="subscribe-list__count-header">
            {name}
            <div className="subscribe-list__count-header-title">{title}</div>
        </div>
    }

    getDecisionStatusSelect = props => {
        return <RadioButtonSelect {...props} />
    }

    getDecisionStatusValue = (selectedOptions, options) => {
        const item = find(options, option => option.id === selectedOptions);
        return item && item.name;
    }

    getFiltersOptions = () => {
        const {selectedDecisionStatus, decisionStatus, setDecisionStatus} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Decision status',
                submitButtonName: 'Apply',
                options: decisionStatus,
                renderValue: this.getDecisionStatusValue,
                customOptionsComponent: this.getDecisionStatusSelect,
                selectedOptions: selectedDecisionStatus,
                onChange: setDecisionStatus,
                radioSelectClassName: "snovio-filter-container",
                defaultOption: {value: 'All'}
            },
        ];

        return filtersOptions
    };

    render() {
        const {list, total, limit, offset, search, searchSubscribeList, setSearch, refreshListSubscribe, isFiltersSelected, setIsFiltersSelected} = this.props;
        const countItems = offset + limit;
        const isLoading = this.getLoading();
        const filtersOptions = this.getFiltersOptions();

        const columns = [
            {key: 'imported', name: 'Imported', width: 180,
                renderValue: this.renderImportedDateValue,
                headerClassName: "header-sort-column",
                headerValueWrapperFn: this.subscribeListSortingTitleRender,
            },
            {key: 'email', name: 'Email', width: 180},
            {key: 'name', name: 'Name', width: 120},
            {key: 'title', name: 'Title', width: 140},
            {key: 'company', name: 'Company', width: 105, headerClassName: 'fixed-cell-divider',
                cellClassName: "fixed-cell-divider",
            },
            {key: 'isEn', name: 'EN', width: 120,
                cellClassName: "subscribe-checkbox-cell",
                headerValueWrapperFn: (key, name) => this.subscribeListCountTitleRender(key, name, 'enCount'),
                cellValueWrapperFn: this.renderEnCheckbox},
            {key: 'isRu', name: 'RU', width: 120,
                cellClassName: "subscribe-checkbox-cell",
                headerValueWrapperFn: (key, name) => this.subscribeListCountTitleRender(key, name, 'ruCount'),
                cellValueWrapperFn: this.renderRuCheckbox},
            {key: 'isDoNotDisturb', name: 'Do not disturb', width: 160,
                cellClassName: "subscribe-checkbox-cell",
                headerValueWrapperFn: (key, name) => this.subscribeListCountTitleRender(key, name, 'doNotDisturbCount'),
                cellValueWrapperFn: this.renderDoNotDisturbCheckbox},
            {key: 'decision', name: 'Decision', width: 155,
                cellClassName: "subscribe-button-cell",
                renderValue: this.renderDecisionValue,
                headerValueWrapperFn: (key, name) => this.subscribeListCountTitleRender(key, name, 'decisionCount'),
                cellValueWrapperFn: this.renderDecisionButton
            },
            {key: 'decisionBy', name: 'Decision by', width: 140},
            {key: 'snooze', name: 'Snooze', width: 150,
                headerValueWrapperFn: (key, name) => this.subscribeListCountTitleRender(key, name, 'snoozeCount'),
                renderValue: this.renderSnoozeValue
            },
            {key: 'decisionTime', name: 'Decision time', width: 140,
                renderValue: this.renderDateValue,
                headerClassName: "header-sort-column",
                headerValueWrapperFn: this.subscribeListSortingTitleRender,
            },
        ];

        return (
            <div className="app-root__table-container">
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onApplyFilters={refreshListSubscribe}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <Table
                    columns={columns}
                    data={list}
                    totalItems={total}
                    countItems={countItems}
                    isLoading={isLoading}
                    onShowMore={searchSubscribeList}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        list: state.subscribeList.list,
        limit: state.subscribeList.limit,
        offset: state.subscribeList.offset,
        total: state.subscribeList.total,
        status: state.subscribeList.status,
        search: state.subscribeList.search,
        sortField: state.subscribeList.sortField,
        sortOrder: state.subscribeList.sortOrder,
        countSubscribe: state.subscribeList.countSubscribe,
        selectedDecisionStatus: state.subscribeList.selectedDecisionStatus,
        decisionStatus: state.subscribeList.decisionStatus,
        isFiltersSelected: state.subscribeList.isFiltersSelected,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        setSearch: search => dispatch(setSearchAsync(search)),
        setSort: (field, order) => dispatch(setSortAsync(field, order)),
        getListSubscribe: () => dispatch(getListSubscribe()),
        searchSubscribeList: (count) => dispatch(searchSubscribeList(count)),
        setDecisionStatus: (decisionStatus) => dispatch(setDecisionStatusAsync(decisionStatus)),
        refreshListSubscribe: () => dispatch(refreshListSubscribe()),
        updateSubscribeList: (updateItem, key, type, value) => dispatch(updateSubscribeList(updateItem, key, type, value)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeDecide)