import React from 'react';
import map from 'lodash/map';
import classNames from 'classnames';

import './css/navigation-tabs.css';

function NavigationTabs({className, activeItem, items, onChange}) {
    const getRenderItems = () => {
        return map(items, item => {
           const {id, name, isHidden} = item;
           const itemClassName = classNames("navigation-tabs__item", {
               "navigation-tabs__item_active": id === activeItem,
               "navigation-tabs__item_hidden": isHidden
           });
           return (
               <div key={id} className={itemClassName} onClick={() => onChange(id)}>
                   {name}
               </div>
           )
        });
    };

    const renderItem = getRenderItems();
    const containerClassName = classNames("navigation-tabs", className);

    return (
        <div className={containerClassName}>
            {renderItem}
        </div>
    );
}

export default NavigationTabs;