export const REQUEST_INIT_ITCH_GAMES = 'REQUEST_INIT_ITCH_GAMES';
export const SUCCEED_INIT_ITCH_GAMES = 'SUCCEED_INIT_ITCH_GAMES';
export const REQUEST_SEARCH_ITCH_GAMES = 'REQUEST_SEARCH_ITCH_GAMES';
export const SUCCEED_SEARCH_ITCH_GAMES = 'SUCCEED_SEARCH_ITCH_GAMES';
export const SUCCEED_GET_ITCH_GAMES_FILTER_DATA = 'SUCCEED_GET_ITCH_GAMES_FILTER_DATA';
export const REQUEST_ITCH_GAMES_LAST_UPDATE = 'REQUEST_ITCH_GAMES_LAST_UPDATE';
export const SUCCEED_ITCH_GAMES_LAST_UPDATE = 'SUCCEED_ITCH_GAMES_LAST_UPDATE';
export const REQUEST_EXPORT_ITCH_GAMES_GAMES = 'REQUEST_EXPORT_ITCH_GAMES_GAMES';
export const SUCCEED_EXPORT_ITCH_GAMES_GAMES = 'SUCCEED_EXPORT_ITCH_GAMES_GAMES';
export const REQUEST_EXPORT_ITCH_GAMES_AUTHORS = 'REQUEST_EXPORT_ITCH_GAMES_AUTHORS';
export const SUCCEED_EXPORT_ITCH_GAMES_AUTHORS = 'SUCCEED_EXPORT_ITCH_GAMES_AUTHORS';
export const SET_IS_ONLY_CHOSEN_LANGUAGES_ITCH_GAMES = 'SET_IS_ONLY_CHOSEN_LANGUAGES_ITCH_GAMES';

export const SET_SELECTED_GENRES_ITCH_GAMES = 'SET_SELECTED_GENRES_ITCH_GAMES';
export const SET_SELECTED_LANGUAGES_ITCH_GAMES = 'SET_SELECTED_LANGUAGES_ITCH_GAMES';
export const SET_SELECTED_PLATFORM_ITCH_GAMES = 'SET_SELECTED_PLATFORM_ITCH_GAMES';
export const SET_LANGUAGE_COUNT_ITCH_GAMES = 'SET_LANGUAGE_COUNT_ITCH_GAMES';
export const SET_PRICE_ITCH_GAMES = 'SET_PRICE_ITCH_GAMES';
export const SET_ITCH_GAMES_RELEASED = 'SET_ITCH_GAMES_RELEASED';
export const REQUEST_GET_COUNT_ITCH_GAMES = 'REQUEST_GET_COUNT_ITCH_GAMES';
export const SUCCEED_GET_COUNT_ITCH_GAMES = 'SUCCEED_GET_COUNT_ITCH_GAMES';
export const CLEAR_FILTERS_ITCH_GAMES = 'CLEAR_FILTERS_ITCH_GAMES';
export const SET_IS_FILTER_SELECTED_ITCH_GAMES = 'SET_IS_FILTER_SELECTED_ITCH_GAMES';