import React, {Component} from 'react';
import {connect} from "react-redux";
import join from "lodash/join";
import map from "lodash/map";
import {
    initCrowdin, searchCrowdin, setSearchAsync, refreshCrowdin, setReleasedAsync, getFilterData, setLastActivityAsync,
    setProjectTypeAsync, setTargetLanguagesCountAsync, exportCrowdin, getLastUpdate, clearFiltersAsync
} from "./actionsAsync";
import {setIsFiltersSelected} from "./actions";
import {setInitContainer, setTitle} from "../App/actions";
import AppstoreFilters from "../../components/Filters/PageFilters";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import Table from "../../components/Table";
import {DATE_RANGE, LANGUAGES_COUNT, SELECT} from "../../constants/FilterTypes";
import {crowdinProjectTypes} from "../../constants/CrowdinProjectTypes";
import {SEARCH_CROWDIN_REQUESTED, INIT_CROWDIN_REQUESTED} from "../../store/StatusTypes";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import Link from "../../components/Link";
import {getLastUpdateTitle, renderBigNumbers} from "../../helpers/utils";

const CONTAINER_NAME = 'Crowdin';

class Crowdin extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, initCrowdin, getLastUpdate, status, getFilterData} = this.props;
        setTitle(CONTAINER_NAME);
        if (status === null) {
            initCrowdin();
            getLastUpdate();
            getFilterData();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    onShowMore = () => {
        const {searchCrowdin} = this.props;
        searchCrowdin();
    }

    handleRefresh = () => {
        const {refreshCrowdin} = this.props;

        refreshCrowdin();
    }

    onClearReleased = () => {
        const {setReleased, firstCreatedDate} = this.props;
        const defaultReleased = getDefaultReleased(firstCreatedDate);
        setReleased(defaultReleased).then(() => this.handleRefresh());
    }

    onExportCrowdin = () => {
        const {exportCrowdin} = this.props;
        exportCrowdin();
    }

    handleClearFilters = () => {
        const {clearFilters, initCrowdin} = this.props;
        clearFilters().then(() => initCrowdin());
    }

    getProjectTypeValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    getFiltersOptions = () => {
        const {releasedDateRange, defaultDateRange, setReleased, selectedProjectType, setProjectType, targetLanguagesCount, setTargetLanguagesCount,
            lastActivityDateRange, setLastActivity} = this.props;

        const filtersOptions = [
            {
                type: DATE_RANGE,
                title: 'Created',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
            {
                type: SELECT,
                title: 'Project type',
                options: crowdinProjectTypes,
                onChange: setProjectType,
                selectedOptions: selectedProjectType,
                renderValue: this.getProjectTypeValue,
                columnsCount: 1,
                submitButtonName: 'Apply',
            },
            {
                type: LANGUAGES_COUNT,
                title: 'Number of languages',
                value: targetLanguagesCount,
                onChange: setTargetLanguagesCount,
                popoverClassName: "crowdin-number-of-languages"

            },
            {
                type: DATE_RANGE,
                title: 'Last activity',
                customWrapperClassName: 'date-input',
                initDates: lastActivityDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setLastActivity
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_CROWDIN_REQUESTED
            || status === SEARCH_CROWDIN_REQUESTED;
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderTimeValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderList = (row, column, value) => join(value, ', ');

    renderLink = (value) => <Link path={value} value={value}/>;

    render() {
        const {list, total, limit, offset, lastUpdate, title, searchCrowdin, search, setSearch, setIsFiltersSelected, isFiltersSelected} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Name',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'targetLanguageCount', name: 'Languages', width: 100},
            {key: 'targetLanguages', name: 'Target Languages', width: 100, renderValue: this.renderList},
            {key: 'sourceLang', name: 'Source Language', width: 100},
            {key: 'created', name: 'Created', width: 100,
                renderValue: this.renderTimeValue
            },
            {key: 'wordsCount', name: 'Words', width: 80,
                renderValue: renderBigNumbers
            },
            {key: 'platform', name: 'Type', width: 110, renderValue: this.renderList},
            {key: 'lastActivity', name: 'Activity', width: 110, renderValue: this.renderTimeValue},
            {key: 'description', name: 'Description', width: 200},
            {key: 'managers', name: 'Managers', width: 180, renderValue: this.renderList},
            {key: 'url', name: 'Url', width: 220, cellValueWrapperFn: this.renderLink},
        ]

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal>
                    {renderLastUpdate}
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 search={search}
                                 setSearch={setSearch}
                                 onExport={this.onExportCrowdin}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchCrowdin}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        status: state.crowdin.status,
        lastUpdate: state.crowdin.lastUpdate,
        list: state.crowdin.list,
        search: state.crowdin.search,
        total: state.crowdin.total,
        limit: state.crowdin.limit,
        offset: state.crowdin.offset,
        releasedDateRange: state.crowdin.releasedDateRange,
        defaultDateRange: state.crowdin.defaultDateRange,
        selectedProjectType: state.crowdin.selectedProjectType,
        targetLanguagesCount: state.crowdin.targetLanguagesCount,
        firstCreatedDate: state.crowdin.firstCreatedDate,
        isFiltersSelected: state.crowdin.isFiltersSelected,
        lastActivityDateRange: state.crowdin.lastActivityDateRange,

        initContainers: state.app.initContainers,
        title: state.app.title
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initCrowdin: () => dispatch(initCrowdin()),
        setSearch: search => dispatch(setSearchAsync(search)),
        searchCrowdin: (count) => dispatch(searchCrowdin(count)),
        refreshCrowdin: () => dispatch(refreshCrowdin()),
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        setProjectType: (projectType) => dispatch(setProjectTypeAsync(projectType)),
        setTargetLanguagesCount: (targetLanguagesCount) => dispatch(setTargetLanguagesCountAsync(targetLanguagesCount)),
        exportCrowdin: () => dispatch(exportCrowdin()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        getFilterData: () => dispatch(getFilterData()),
        setIsFiltersSelected: (isFiltersSelected) => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),
        setLastActivity: (lastActivity) => dispatch(setLastActivityAsync(lastActivity)),

        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Crowdin);