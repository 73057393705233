import * as types from "./constants";

export function requestInitCrowdin() {
    return {
        type: types.REQUEST_INIT_CROWDIN
    };
}

export function succeedInitCrowdin(response) {
    return {
        type: types.SUCCEED_INIT_CROWDIN,
        response
    };
}

export function requestSearchCrowdin(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_CROWDIN,
        limit, offset
    };
}

export function succeedSearchCrowdin(response) {
    return {
        type: types.SUCCEED_SEARCH_CROWDIN,
        response
    };
}

export function setSearch(search) {
    return {
        type: types.SET_CROWDIN_SEARCH,
        search
    };
}

export function setReleased(released) {
    return {
        type: types.SET_CROWDIN_RELEASED,
        released
    };
}

export function setLastActivity(lastActivity) {
    return {
        type: types.SET_CROWDIN_LAST_ACTIVITY,
        lastActivity
    };
}

export function setProjectType(projectType) {
    return {
        type: types.SET_CROWDIN_PROJECT_TYPE,
        projectType
    };
}

export function setTargetLanguagesCount(targetLanguagesCount) {
    return {
        type: types.SET_CROWDIN_TARGET_LANGUAGES_COUNT,
        targetLanguagesCount
    };
}

export function requestLastUpdateCrowdin() {
    return {
        type: types.REQUEST_LAST_UPDATE_CROWDIN,
    };
}

export function succeedLastUpdateCrowdin(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_CROWDIN,
        lastUpdate
    };
}

export function succeedGetFilterData(response) {
    return {
        type: types.SUCCEED_GET_FILTER_DATA_CROWDIN,
        response
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_CROWDIN,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_CROWDIN,
        isFiltersSelected
    };
}

