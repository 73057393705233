import {addDomainBlacklistRecordRequest, deleteDomainBlacklistRecordRequest, getDomainBlacklistPagedRequest} from "../../services/grpcBufServices";
import {
    requestAddMagnetBlacklist,
    requestDeleteMagnetBlacklist,
    requestInitMagnetBlacklist, requestRefreshMagnetBlacklist, requestSearchMagnetBlacklist,
    setMagnetBlacklistSearch, succeedAddMagnetBlacklist, succeedDeleteMagnetBlacklist,
    succeedInitMagnetBlacklist,
    succeedRefreshMagnetBlacklist, succeedSearchMagnetBlacklist
} from "./actions";

export const initMagnetBlacklist = (account) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesBlacklist: {limit, offset, search}} = getState();
        dispatch(requestInitMagnetBlacklist(account));
        const response = await getDomainBlacklistPagedRequest(token, limit, offset, search, account);
        dispatch(succeedInitMagnetBlacklist(response));
    };

export const refreshMagnetBlacklist = () =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesBlacklist: {limit, offset, search, magnetBlacklistAccount}} = getState();
        dispatch(requestRefreshMagnetBlacklist());
        const response = await getDomainBlacklistPagedRequest(token, limit, offset, search, magnetBlacklistAccount);
        dispatch(succeedRefreshMagnetBlacklist(response));
    };

export const searchMagnetBlacklist = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesBlacklist: {limit, offset, search, magnetBlacklistAccount}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchMagnetBlacklist(updateLimit, updateOffset));
        const response = await getDomainBlacklistPagedRequest(token, updateLimit, updateOffset, search, magnetBlacklistAccount);
        dispatch(succeedSearchMagnetBlacklist(response));
    };

export const addDomainBlacklistRecord = (value) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesBlacklist: {magnetBlacklistAccount}} = getState();
            dispatch(requestAddMagnetBlacklist());
            const response = await addDomainBlacklistRecordRequest(token, value, magnetBlacklistAccount);
            dispatch(succeedAddMagnetBlacklist(response));
    };

export const deleteDomainBlacklistRecord = (id, value) =>
    async (dispatch, getState) => {
        const {app: {token}, magnetSalesBlacklist: {magnetBlacklistAccount}} = getState();
        dispatch(requestDeleteMagnetBlacklist(id));
        await deleteDomainBlacklistRecordRequest(token, id, value, magnetBlacklistAccount);
        dispatch(succeedDeleteMagnetBlacklist());
    };

export const setSearchMagnetBlacklist = (search) =>
    async (dispatch, getState) => {
        dispatch(setMagnetBlacklistSearch(search));
    };