import isEqual from "lodash/isEqual";
import {
    getEpicRecordsRequest,
    getLastUpdateEpicRequest,
    getEpicFilterDataRequest,
    exportEpicRecordsRequest,
    getCountEpic
} from '../../services/grpcBufServices';
import {
    requestInitEpic,
    requestSearchEpic,
    setReleased,
    setSearch,
    succeedInitEpic,
    succeedSearchEpic,
    succeedGetEpicFilterData,
    setEpicSelectedAudioLanguages,
    setEpicSelectedTextLanguages,
    setEpicSelectedTags,
    setAudioLanguageCount,
    setTextLanguageCount,
    requestLastUpdateEpic,
    succeedLastUpdateEpic,
    succeedGetCountEpic,
    clearFilters,
    setPrice,
    requestGetCountEpic
} from "./actions";
import {
    getIdsList,
    getRequestOptions
} from "../../helpers/utils";
import {getReleasedValue} from "../../helpers/dateUtils";
import {requestExport, succeedExport} from "../App/actions";

export const initEpic = () =>
    async (dispatch, getState) => {
        const {app: {token}, epic: {limit, offset, epicTags, selectedEpicTags, defaultDateRange, releasedDateRange, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages,
            selectedAudioLanguages, selectedTextLanguages, languages, audioLanguagesCount, textLanguagesCount, search, selectedPrice}} = getState();
        dispatch(requestInitEpic());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const tagsOptions = getRequestOptions(selectedEpicTags, epicTags);
        const audioLanguagesOptions = getRequestOptions(selectedAudioLanguages, languages);
        const textLanguagesOptions = getRequestOptions(selectedTextLanguages, languages);
        const audioLanguagesIds = getIdsList(audioLanguagesOptions);
        const textLanguagesIds = getIdsList(textLanguagesOptions);
        const audioLanguagesCountValue = isOnlyChosenAudioLanguages ? {from: selectedAudioLanguages.length, to: selectedAudioLanguages.length} : audioLanguagesCount;
        const textLanguagesCountValue = isOnlyChosenTextLanguages ? {from: selectedTextLanguages.length, to: selectedTextLanguages.length} : textLanguagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const epicRecordsResponse = await getEpicRecordsRequest(token, limit, offset, tagsOptions, releasedDate, audioLanguagesIds, textLanguagesIds, audioLanguagesCountValue, textLanguagesCountValue, priceOption, search);
        dispatch(succeedInitEpic(epicRecordsResponse));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateEpic());
        const lastUpdate = await getLastUpdateEpicRequest(token);
        dispatch(succeedLastUpdateEpic(lastUpdate));
    };

export const getEpicFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getEpicFilterDataRequest(token);
        dispatch(succeedGetEpicFilterData(response));
    };

export const refreshEpic = () =>
    async (dispatch, getState) => {
        const {app: {token}, epic: {limit, offset, epicTags, selectedEpicTags, defaultDateRange, releasedDateRange, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages,
            selectedAudioLanguages, selectedTextLanguages, languages, audioLanguagesCount, textLanguagesCount, search, selectedPrice}} = getState();
        dispatch(requestInitEpic());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const tagsOptions = getRequestOptions(selectedEpicTags, epicTags);
        const audioLanguagesOptions = getRequestOptions(selectedAudioLanguages, languages);
        const textLanguagesOptions = getRequestOptions(selectedTextLanguages, languages);
        const audioLanguagesIds = getIdsList(audioLanguagesOptions);
        const textLanguagesIds = getIdsList(textLanguagesOptions);
        const audioLanguagesCountValue = isOnlyChosenAudioLanguages ? {from: selectedAudioLanguages.length, to: selectedAudioLanguages.length} : audioLanguagesCount;
        const textLanguagesCountValue = isOnlyChosenTextLanguages ? {from: selectedTextLanguages.length, to: selectedTextLanguages.length} : textLanguagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const epicRecordsResponse = await getEpicRecordsRequest(token, limit, offset, tagsOptions, releasedDate, audioLanguagesIds, textLanguagesIds, audioLanguagesCountValue, textLanguagesCountValue, priceOption, search);

        dispatch(succeedInitEpic(epicRecordsResponse));
    };

export const searchEpic = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, epic: {limit, offset, epicTags, selectedEpicTags, defaultDateRange, releasedDateRange, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages,
            selectedAudioLanguages, selectedTextLanguages, languages, textLanguagesCount, audioLanguagesCount, search, selectedPrice}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchEpic(updateLimit, updateOffset));
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const tagsOptions = getRequestOptions(selectedEpicTags, epicTags);
        const audioLanguagesOptions = getRequestOptions(selectedAudioLanguages, languages);
        const textLanguagesOptions = getRequestOptions(selectedTextLanguages, languages);
        const audioLanguagesIds = getIdsList(audioLanguagesOptions);
        const textLanguagesIds = getIdsList(textLanguagesOptions);
        const audioLanguagesCountValue = isOnlyChosenAudioLanguages ? {from: selectedAudioLanguages.length, to: selectedAudioLanguages.length} : audioLanguagesCount;
        const textLanguagesCountValue = isOnlyChosenTextLanguages ? {from: selectedTextLanguages.length, to: selectedTextLanguages.length} : textLanguagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const epicRecordsResponse = await getEpicRecordsRequest(token, updateLimit, updateOffset, tagsOptions, releasedDate, audioLanguagesIds, textLanguagesIds, audioLanguagesCountValue, textLanguagesCountValue, priceOption, search);

        dispatch(succeedSearchEpic(epicRecordsResponse));
    };

export const exportEpic = () =>
    async (dispatch, getState) => {
        const {app: {token}, epic: {limit, offset, epicTags, selectedEpicTags, defaultDateRange, releasedDateRange, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages,
            selectedAudioLanguages, selectedTextLanguages, languages, audioLanguagesCount, textLanguagesCount, search, selectedPrice}} = getState();
        dispatch(requestExport());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const tagsOptions = getRequestOptions(selectedEpicTags, epicTags);
        const audioLanguagesOptions = getRequestOptions(selectedAudioLanguages, languages);
        const textLanguagesOptions = getRequestOptions(selectedTextLanguages, languages);
        const audioLanguagesIds = getIdsList(audioLanguagesOptions);
        const textLanguagesIds = getIdsList(textLanguagesOptions);
        const audioLanguagesCountValue = isOnlyChosenAudioLanguages ? {from: selectedAudioLanguages.length, to: selectedAudioLanguages.length} : audioLanguagesCount;
        const textLanguagesCountValue = isOnlyChosenTextLanguages ? {from: selectedTextLanguages.length, to: selectedTextLanguages.length} : textLanguagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const exportId = await exportEpicRecordsRequest(token, limit, offset, tagsOptions, releasedDate, audioLanguagesIds, textLanguagesIds, audioLanguagesCountValue, textLanguagesCountValue, priceOption, search);
        dispatch(succeedExport(exportId.task.id));
    };

export const setSearchAsync = (search) =>
    async (dispatch) => {
        dispatch(setSearch(search));
    };

export const setAudioLanguagesAsync = (selectedLanguages) =>
    async (dispatch) => {
        dispatch(setEpicSelectedAudioLanguages(selectedLanguages));
    };

export const setTextLanguagesAsync = (selectedLanguages) =>
    async (dispatch) => {
        dispatch(setEpicSelectedTextLanguages(selectedLanguages));
    };

export const setAudioLanguageCountAsync = (languagesCount) =>
    async (dispatch) => {
        dispatch(setAudioLanguageCount(languagesCount));
    };

export const setTextLanguageCountAsync = (languagesCount) =>
    async (dispatch) => {
        dispatch(setTextLanguageCount(languagesCount));
    };

export const setTagsAsync = (tags) =>
    async (dispatch) => {
        dispatch(setEpicSelectedTags(tags));
    };

export const setReleasedAsync = (released) =>
    async (dispatch) => {
        dispatch(setReleased(released));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        const {app: {token}, epic: {epicTags, selectedEpicTags, releasedDateRange, defaultDateRange, isOnlyChosenAudioLanguages, isOnlyChosenTextLanguages,
            selectedAudioLanguages, selectedTextLanguages, languages, textLanguagesCount, audioLanguagesCount, search, selectedPrice}} = getState();
        dispatch(requestGetCountEpic());
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const tagsOptions = getRequestOptions(selectedEpicTags, epicTags);
        const audioLanguagesOptions = getRequestOptions(selectedAudioLanguages, languages);
        const textLanguagesOptions = getRequestOptions(selectedTextLanguages, languages);
        const audioLanguagesIds = getIdsList(audioLanguagesOptions);
        const textLanguagesIds = getIdsList(textLanguagesOptions);
        const audioLanguagesCountValue = isOnlyChosenAudioLanguages ? {from: selectedAudioLanguages.length, to: selectedAudioLanguages.length} : audioLanguagesCount;
        const textLanguagesCountValue = isOnlyChosenTextLanguages ? {from: selectedTextLanguages.length, to: selectedTextLanguages.length} : textLanguagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;

        const count = await getCountEpic(token, tagsOptions, releasedDate, audioLanguagesIds, textLanguagesIds, audioLanguagesCountValue, textLanguagesCountValue, priceOption, search);
        dispatch(succeedGetCountEpic(count));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };

export const setPriceAsync = (price) =>
    async (dispatch) => {
        dispatch(setPrice(price));
    };