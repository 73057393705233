import {
    getEuStartupsRecordsRequest,
    getEuStartupsLastUpdateRequest,
    getEuStartupsFilterDataRequest,
    exportEuStartupsCompanyRequest,
    exportEuStartupsEmailsRequest,
    exportEuStartupsTwitterRequest,
    getCountEuStartupsRequest
} from "../../services/grpcBufServices";
import {
    requestInitEuStartups,
    succeedInitEuStartups,
    requestLastUpdateEuStartups,
    succeedLastUpdateEuStartups,
    succeedGetEuStartupsFilterData,
    requestSearchEuStartups,
    succeedSearchEuStartups,
    setSearch,
    setCountry,
    succeedGetCountEuStartups,
    clearFilters,
    requestGetCountEuStartups
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";

export const initEuStartups = () =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset}} = getState();
        dispatch(requestInitEuStartups());
        const response = await getEuStartupsRecordsRequest(token, limit, offset);
        dispatch(succeedInitEuStartups(response));
    };

export const refreshEuStartups = () =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset, search, selectedCountry}} = getState();
        dispatch(requestInitEuStartups());
        const response = await getEuStartupsRecordsRequest(token, limit, offset, search, selectedCountry);
        dispatch(succeedInitEuStartups(response));
    };

export const searchEuStartups = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset, search, selectedCountry}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchEuStartups(updateLimit, updateOffset));
        const response = await getEuStartupsRecordsRequest(token, updateLimit, updateOffset, search, selectedCountry);
        dispatch(succeedSearchEuStartups(response));
    };

export const exportEuStartupsCompany = () =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset, search, selectedCountry}} = getState();
        dispatch(requestExport());
        const exportId = await exportEuStartupsCompanyRequest(token, limit, offset, search, selectedCountry);
        dispatch(succeedExport(exportId.task.id));
    };

export const exportEuStartupsTwitter = () =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset, search, selectedCountry}} = getState();
        dispatch(requestExport());
        const exportId = await exportEuStartupsTwitterRequest(token, limit, offset, search, selectedCountry);
        dispatch(succeedExport(exportId.task.id));
    };

export const exportEuStartupsEmails = () =>
    async (dispatch, getState) => {
        const {app: {token}, euStartups: {limit, offset, search, selectedCountry}} = getState();
        dispatch(requestExport());
        const exportId = await exportEuStartupsEmailsRequest(token, limit, offset, search, selectedCountry);
        dispatch(succeedExport(exportId.task.id));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateEuStartups());
        const lastUpdate = await getEuStartupsLastUpdateRequest(token);
        dispatch(succeedLastUpdateEuStartups(lastUpdate));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getEuStartupsFilterDataRequest(token);
        dispatch(succeedGetEuStartupsFilterData(response));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        dispatch(requestGetCountEuStartups());
        const {app: {token}, euStartups: {search, selectedCountry}} = getState();
        const count = await getCountEuStartupsRequest(token, search, selectedCountry);
        dispatch(succeedGetCountEuStartups(count));
    };

export const setSearchAsync = (search) =>
    async (dispatch) => {
        dispatch(setSearch(search));
    };

export const setCountryAsync = (country) =>
    async (dispatch) => {
        dispatch(setCountry(country));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };