import * as actions from "../ActionTypes";

const initialState = {
    display: false,
    msg: '',
    typeNotification: '',
    timer: 3000,
    classNames: '',
    customNotification: null
};

export default function notification(state = initialState, action) {
    switch (action.type) {
        case actions.SHOW_NOTIFICATION:
            return {
                ...state,
                display: true,
                msg: action.statusMsg,
                typeNotification: action.typeNotification,
                timer: action.timer,
                classNames: action.classNames,
                customNotification: action.customNotification
            };

        case actions.SHOW_NOTIFICATION_COMPLETE:
            return {
                ...state,
                display: false,
                status: null,
                msg: '',
                customNotification: null
            };
        default:
            return state;
    }
}
