import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from "../Popover";
import Button from "../Button";
import Input from "../Input";

import './css/download-count.css';
import './../Select/css/select.css';

const FROM_COUNT = 'fromCount';
const TO_COUNT = 'toCount';
const DEFAULT_FILTER_WIDTH = 135;

class DownloadCount extends Component {

    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.any,
        className: PropTypes.string,
        onClick: PropTypes.func,
        rateValidate: PropTypes.bool,
        submitButtonName: PropTypes.string,
        placeholder: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            focusedInput: '',
            from: '',
            to: '',
        }

        this.input = React.createRef();
    }

    onToggleMenu = () => {
            this.setState((state, props) => {
                return {
                    isOpen: !state.isOpen,
                    from: props.value.from || '',
                    to: props.value.to || ''
                }
            });
    }

    getValue = () => {
        const {value} = this.props;
        const {from, to} = value;
        if (from || to) {
            return `${from} - ${to}`
        } else {
            return 'All';
        }
    }

    isCountValid = count => {
        const {rateValidate} = this.props;
        const ONLY_NUMBERS = /^[0-9]+$/;
        const RATE_REGEX = /^[0-4]?(\.\d?)?$|^[5]\.?[0]?$/;
        const validateRegex = rateValidate ? RATE_REGEX : ONLY_NUMBERS;
        return validateRegex.test(count) || count === '';
    };

    onChangeFrom = (e) => {
        const value = e.target.value;
        const isValid = this.isCountValid(value);
        isValid && this.setState({
            from: value
        })
    };

    onChangeTo = (e) => {
        const value = e.target.value;
        const isValid = this.isCountValid(value);
        isValid && this.setState({
            to: value
        })
    };

    onFocusesInputChange = (focusedInput) => {
        this.setState({
            focusedInput
        });
    };

    onClickFromCountInput = () => {
        this.onFocusesInputChange(FROM_COUNT);
    };

    onClickToCountInput = () => {
        this.onFocusesInputChange(TO_COUNT);
    };

    onCancel = () => {
        const {onChange} = this.props;
        this.setState({
            from: '',
            to: '',
        }, () => {
            onChange({});
            this.onToggleMenu();
        })
    }

    onSubmit = () => {
        const {from, to} = this.state;
        const {onChange} = this.props;
        onChange({from, to});
        this.onToggleMenu();
    }

    calculateFilterWidth = () => {
        const {from, to} = this.state;
        const {placeholder} = this.props;
        const letterWidth = 16;
        const numberLengthPlaceholder = placeholder?.to && String(placeholder.to).length;
        const numberLengthFrom = from && String(from).length;
        const numberLengthTo = to && String(to).length;
        const numberLength = Math.max(numberLengthPlaceholder, numberLengthFrom, numberLengthTo);
        return numberLength ? {width: numberLength * letterWidth + DEFAULT_FILTER_WIDTH} : {};
    }

    render() {
        const {isOpen, from, to, focusedInput} = this.state;
        const {title, popoverClassName, submitButtonName, placeholder, className} = this.props;
        const filterWidth = this.calculateFilterWidth();

        const value = this.getValue();
        const submitButton = submitButtonName || 'Submit';

        const inputClassNames = classNames("select__input download-count__value", {
            "active": isOpen
        });

        const popoverClassNames = classNames("select-items__popover download-popover", popoverClassName);
        const fromCountInputWrapperClassNames = classNames('number-languages__input', {
            'download-count__input-focused' : focusedInput === FROM_COUNT,
        });

        const toCountInputWrapperClassNames = classNames('number-languages__input', {
            'download-count__input-focused' : focusedInput === TO_COUNT,
        });

        const containerClassNames = classNames("select__container", className);

        const inputRef = this.input && this.input.current;

        return (
            <div className={containerClassNames}>
                <div className="select__title">{title}</div>
                <div ref={this.input} className={inputClassNames} onClick={this.onToggleMenu}>
                    <div className="select__input-value" title={value}>
                        {value}
                    </div>
                    <div className="select__arrow down-arrow-icon"/>
                </div>

                <Popover anchorEl={inputRef} className={popoverClassNames} open={isOpen} withArrow={true} onClosePopover={this.onToggleMenu}>
                    <div className="number-languages__inputs" style={filterWidth}>
                        <div className="number-languages__input-container">
                            <div className="title">From</div>
                            <Input className={fromCountInputWrapperClassNames}
                                   onMouseDown={() => this.onFocusesInputChange(FROM_COUNT)}
                                   onChange={(e) => this.onChangeFrom(e)}
                                   placeholder={placeholder ? placeholder.from : ''}
                                   value={from}/>
                        </div>
                        <div className="number-languages__input-container">
                            <div className="title">To</div>
                            <Input className={toCountInputWrapperClassNames}
                                   onMouseDown={() => this.onFocusesInputChange(TO_COUNT)}
                                   onChange={(e) => this.onChangeTo(e)}
                                   placeholder={placeholder ? placeholder.to : ''}
                                   value={to}/>
                        </div>
                    </div>
                    <div className="select-items__buttons">
                        <Button className="cancel-button-download" title="Clear" onClick={this.onCancel}/>
                        <Button className="submit-button-download" title={submitButton} onClick={this.onSubmit}/>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default (DownloadCount);
