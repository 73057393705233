import * as types from "./constants";

export function requestInitSnovio() {
    return {
        type: types.REQUEST_INIT_SNOVIO
    };
}

export function succeedInitSnovio(response) {
    return {
        type: types.SUCCEED_INIT_SNOVIO,
        response
    };
}

export function requestSearchSnovio(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_SNOVIO,
        limit, offset
    };
}

export function succeedSearchSnovio(response) {
    return {
        type: types.SUCCEED_SEARCH_SNOVIO,
        response
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_SNOVIO,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_SNOVIO,
        lastUpdate
    };
}

export function requestGetFilterData() {
    return {
        type: types.REQUEST_GET_FILTER_DATA,
    };
}

export function succeedGetFilterData(response) {
    return {
        type: types.SUCCEED_GET_FILTER_DATA,
        response
    };
}

export function setSource(selectedSource) {
    return {
        type: types.SET_SELECTED_SOURCE,
        selectedSource
    };
}

export function clearFilters() {
    return {
        type: types.CLEAR_FILTERS_SNOVIO,
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_SNOVIO,
        isFiltersSelected
    };
}

export function requestGetCountSnovio() {
    return {
        type: types.REQUEST_GET_COUNT_SNOVIO,
    };
}

export function succeedGetCountSnovio(count) {
    return {
        type: types.SUCCEED_GET_COUNT_SNOVIO,
        count
    };
}


