import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Login from "./containers/Login";
import AppRootComponent from "./containers/App";
import PrivateRoute from "./components/PrivateRoute";
import AuthPage from "./components/AuthPage";
import BlockUi from "./components/BlockUi";
import Snackbar from "./components/Snackbar";
import UpdateAppSnackbar from "./components/Snackbar/UpdateAppSnackbar";
import Popup from "./components/Popup";
import withClearCache from "./ClearCache";

import './css/icons.css';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp(props) {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/auth" component={AuthPage}/>
                    <PrivateRoute path="/" component={AppRootComponent}/>
                </Switch>
            </Router>

            <BlockUi />
            <Snackbar />
            <UpdateAppSnackbar />
            <Popup />
        </div>
    );
}

export default App;