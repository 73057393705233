import isEqual from "lodash/isEqual";
import {
    getPlayRecordsRequest,
    getCountPlayRecordsRequest,
    exportGooglePlayRequest,
    getLastUpdateGooglePlayRequest,
    getGooglePlayFilterData,
    getPlayExportCountRequest,
    getPlayInsightsRequest
} from "../../services/grpcBufServices";
import {
    requestInitGooglePlay,
    requestSearchGooglePlay,
    setCategory,
    setPrice,
    setReleased,
    succeedInitGooglePlay,
    succeedSearchGooglePlay,
    setCountry,
    setDownloads,
    setSearch,
    requestGetCountGooglePlay,
    succeedGetCountGooglePlay,
    requestLastUpdate,
    succeedLastUpdate,
    succeedGetFilterData,
    setCountriesCount,
    setUpdated,
    setHasVideo,
    clearFilters,
    setScore,
    setAppCount
} from "./actions";
import {
    getCategoryValue,
    getReleasedValue,
} from "../../helpers/dateUtils";
import {getExportFieldValues, getGooglePlayCategoriesOption, getRequestOptions} from "../../helpers/utils";
import {requestExport, succeedExport} from "../App/actions";

export const initPlay = () =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, selectedCategories, defaultDateRange, countryOptions, releasedDateRange, score, selectedPrice, selectedCountry, downloads, search, categories, countriesCount, updatedDateRange, selectedHasVideo, isOnlyChosenMarkets}} = getState();
        dispatch(requestInitGooglePlay());
        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const category = getCategoryValue(categoryOptions);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;
        const response = await getPlayRecordsRequest(limit, offset, category, releasedDate, priceOption, countryOption, downloads, search, marketsCountValue, token, updatedDate, hasVideoOption, score);
        dispatch(succeedInitGooglePlay(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getLastUpdateGooglePlayRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const getCountPlayRecords = () =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, appCount, selectedCategories, score, defaultDateRange, countryOptions, releasedDateRange, selectedPrice, selectedCountry, isOnlyChosenMarkets, downloads, search, categories, countriesCount, updatedDateRange, selectedHasVideo}} = getState();
        dispatch(requestGetCountGooglePlay());
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const selectedCategoriesWithDivision = getGooglePlayCategoriesOption(categoryOptions);
        const allCategoriesWithDivision = getGooglePlayCategoriesOption(categories);
        let categoryType = 0;
        let category = [];
        if (allCategoriesWithDivision[0].options.length === selectedCategoriesWithDivision[0].options.length && !selectedCategoriesWithDivision[1].options.length) {
            categoryType = 2;
        }
        else if (allCategoriesWithDivision[1].options.length === selectedCategoriesWithDivision[1].options.length && !selectedCategoriesWithDivision[0].options.length) {
            categoryType = 1;
        }
        else {
            category = getCategoryValue(categoryOptions);
        }

        const countRecords = await getCountPlayRecordsRequest(limit, offset, category, releasedDate, priceOption, countryOption, downloads, search, marketsCountValue, token, updatedDate, hasVideoOption, score, appCount, categoryType);
        dispatch(succeedGetCountGooglePlay(countRecords));
    };

export const refreshPlay = () =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, appCount, selectedCategories, score, defaultDateRange, countryOptions, releasedDateRange, selectedPrice, selectedCountry, isOnlyChosenMarkets, downloads, search, categories, countriesCount, updatedDateRange, selectedHasVideo}} = getState();
        dispatch(requestInitGooglePlay());
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const selectedCategoriesWithDivision = getGooglePlayCategoriesOption(categoryOptions);
        const allCategoriesWithDivision = getGooglePlayCategoriesOption(categories);
        let categoryType = 0;
        let category = [];
        if (allCategoriesWithDivision[0].options.length === selectedCategoriesWithDivision[0].options.length && !selectedCategoriesWithDivision[1].options.length) {
            categoryType = 2;
        }
        else if (allCategoriesWithDivision[1].options.length === selectedCategoriesWithDivision[1].options.length && !selectedCategoriesWithDivision[0].options.length) {
            categoryType = 1;
        }
        else {
            category = getCategoryValue(categoryOptions);
        }

        const response = await getPlayRecordsRequest(limit, offset, category, releasedDate, priceOption, countryOption, downloads, search, marketsCountValue, token, updatedDate, hasVideoOption, score, appCount, categoryType);
        dispatch(succeedInitGooglePlay(response));
    };

export const searchPlay = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, appCount, selectedCategories, score, defaultDateRange, countryOptions, releasedDateRange, selectedPrice, selectedCountry, isOnlyChosenMarkets, downloads, search, categories, countriesCount, updatedDateRange, selectedHasVideo}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchGooglePlay(updateLimit, updateOffset));
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const selectedCategoriesWithDivision = getGooglePlayCategoriesOption(categoryOptions);
        const allCategoriesWithDivision = getGooglePlayCategoriesOption(categories);
        let categoryType = 0;
        let category = [];
        if (allCategoriesWithDivision[0].options.length === selectedCategoriesWithDivision[0].options.length && !selectedCategoriesWithDivision[1].options.length) {
            categoryType = 2;
        }
        else if (allCategoriesWithDivision[1].options.length === selectedCategoriesWithDivision[1].options.length && !selectedCategoriesWithDivision[0].options.length) {
            categoryType = 1;
        }
        else {
            category = getCategoryValue(categoryOptions);
        }

        const response = await getPlayRecordsRequest(updateLimit, updateOffset, category, releasedDate, priceOption, countryOption, downloads, search, marketsCountValue, token, updatedDate, hasVideoOption, score, appCount, categoryType);
        dispatch(succeedSearchGooglePlay(response));
    };

export const getPlayExportCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, appCount, selectedCategories, defaultDateRange, releasedDateRange, countryOptions, selectedPrice, isOnlyChosenMarkets, downloads, search, selectedCountry, categories, score, updatedDateRange, countriesCount, selectedHasVideo}} = getState();
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const fieldValues = getExportFieldValues(fields);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const selectedCategoriesWithDivision = getGooglePlayCategoriesOption(categoryOptions);
        const allCategoriesWithDivision = getGooglePlayCategoriesOption(categories);
        let categoryType = 0;
        let category = [];
        if (allCategoriesWithDivision[0].options.length === selectedCategoriesWithDivision[0].options.length && !selectedCategoriesWithDivision[1].options.length) {
            categoryType = 2;
        }
        else if (allCategoriesWithDivision[1].options.length === selectedCategoriesWithDivision[1].options.length && !selectedCategoriesWithDivision[0].options.length) {
            categoryType = 1;
        }
        else {
            category = getCategoryValue(categoryOptions);
        }
        const count = await getPlayExportCountRequest(limit, offset, category, releasedDate, priceOption, countryOption, downloads, search, token, fieldValues, marketsCountValue, updatedDate, hasVideoOption, score, appCount, categoryType);
        return count.count;
    };

export const getFilterData = (isInsightsRequest) =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getGooglePlayFilterData(token);
        dispatch(succeedGetFilterData(response, isInsightsRequest));
    };

export const setCategoryAsync = (category) =>
    async (dispatch, getState) => {
        dispatch(setCategory(category));
    };

export const setCountryAsync = (country) =>
    async (dispatch, getState) => {
        dispatch(setCountry(country));
    };

export const setReleasedAsync = (released) =>
    async (dispatch, getState) => {
        dispatch(setReleased(released));
    };

export const setUpdatedAsync = (updated) =>
    async (dispatch, getState) => {
        dispatch(setUpdated(updated));
    };

export const setPriceAsync = (price) =>
    async (dispatch, getState) => {
        dispatch(setPrice(price));
    };

export const setDownloadsAsync = (downloads) =>
    async (dispatch, getState) => {
        dispatch(setDownloads(downloads));
    };

export const setSearchAsync = (search) =>
    async (dispatch, getState) => {
        dispatch(setSearch(search));
    };

export const exportGooglePlay = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, appCount, selectedCategories, defaultDateRange, releasedDateRange, countryOptions, selectedPrice, score, selectedCountry, isOnlyChosenMarkets, downloads, search, categories, countriesCount, updatedDateRange, selectedHasVideo}} = getState();
        dispatch(requestExport());
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const countryOption = getRequestOptions(selectedCountry, countryOptions);
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const fieldValues = getExportFieldValues(fields);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        const marketsCountValue = isOnlyChosenMarkets ? {from: selectedCountry.length, to: selectedCountry.length} : countriesCount;

        const categoryOptions = getRequestOptions(selectedCategories, categories);
        const selectedCategoriesWithDivision = getGooglePlayCategoriesOption(categoryOptions);
        const allCategoriesWithDivision = getGooglePlayCategoriesOption(categories);
        let categoryType = 0;
        let category = [];
        if (allCategoriesWithDivision[0].options.length === selectedCategoriesWithDivision[0].options.length && !selectedCategoriesWithDivision[1].options.length) {
            categoryType = 2;
        }
        else if (allCategoriesWithDivision[1].options.length === selectedCategoriesWithDivision[1].options.length && !selectedCategoriesWithDivision[0].options.length) {
            categoryType = 1;
        }
        else {
            category = getCategoryValue(categoryOptions);
        }

        const exportId = await exportGooglePlayRequest(limit, offset, category, releasedDate, priceOption, countryOption, downloads, search, token, fieldValues, marketsCountValue, updatedDate, hasVideoOption, score, appCount, categoryType);
        dispatch(succeedExport(exportId.task.id));
    };

export const setOptionsCountAsync = countriesCount =>
    async (dispatch, getState) => {
        dispatch(setCountriesCount(countriesCount));
    };

export const setHasVideoAsync = hasVideo =>
    async (dispatch, getState) => {
        dispatch(setHasVideo(hasVideo));
    };

export const getPlayInsights = () =>
    async (dispatch, getState) => {
        const {app: {token}, googlePlay: {limit, offset, releasedDateRange, defaultDateRange, updatedDateRange, downloads, selectedHasVideo}} = getState();
        const released = getReleasedValue(releasedDateRange);
        const updated = getReleasedValue(updatedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const updatedDate = isEqual(updated, defaultDate) ? null : getReleasedValue(updatedDateRange);
        const hasVideoOption = selectedHasVideo.length > 1 ? '' : selectedHasVideo[0].name;
        dispatch(requestInitGooglePlay());
        const response = await getPlayInsightsRequest(token, limit, offset, releasedDate, updatedDate, downloads, hasVideoOption);
        dispatch(succeedInitGooglePlay(response));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFilters());
    };

export const setScoreAsync = (score) =>
    async (dispatch, getState) => {
        dispatch(setScore(score));
    };

export const setAppCountAsync = (score) =>
    async (dispatch, getState) => {
        dispatch(setAppCount(score));
    };

