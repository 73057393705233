import React, {Component} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from 'react-router-dom';
import {setInitContainer, setTitle, updateTitle} from "../App/actions";
import {
    addTwitterDonor, exportAudience,
    initTwitterItem,
    refreshTwitterDonors,
    removeAudienceItem, renameTwitterAudience, removeTwitterDonor,
    searchTwitterDonors, sendAudienceToTwitter,
    setSearchAsync
} from "./actionsAsync";

import Filters from "../../components/Filters";
import Table from "../../components/Table";
import CellTimeAgo from "../../components/CellTimeAgo";
import HeaderWithSettings from "../../components/HeaderWithSettings";
import BackArrowPortal from "../../components/Header/BackArrowPortal";
import Button from "../../components/Button";

import {
    INIT_TWITTER_BY_NAME_REQUESTED, SEARCH_TWITTER_DONORS_REQUESTED
} from "../../store/StatusTypes";
import {ADD_ELEMENT} from "../../constants/FilterTypes";
import {getLocalizedTime} from "../../helpers/dateUtils";
import {renderBigNumbers} from "../../helpers/utils";

import '../../css/twitter-audience.css';

const CONTAINER_NAME = 'Twitter Audience';

class TwitterAudience extends Component {

    componentDidMount() {
        const {setTitle, initTwitterItem, initContainers, setInitContainer, match: {params: {name}}} = this.props;

        setTitle(CONTAINER_NAME);
        if (name) {
            const title = <HeaderWithSettings name={name} onDelete={this.onDeleteAudience} onEdit={this.onEditAudience}/>;
            setTitle(name, title);
            initTwitterItem(name);
        }
        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    onDeleteAudience = () => {
        const {audience: {id}, removeAudienceItem, history} = this.props;
        removeAudienceItem(id).then(() => {
            history.push('/twitter');
        });
    };

    onEditAudience = (e, value) => {
        const {audience: {id}, updateTitle, renameTwitterAudience} = this.props;
        window.history.replaceState(null, null, `/twitter/${value}`);
        updateTitle(value);
        renameTwitterAudience(id, value);
    };

    handleBackToTwitter = () => {
        const {history} = this.props;
        history.push('/twitter');
    };

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderUpdateValue = (row, column, value) => {
        return <CellTimeAgo row={row} column={column} value={value}/>
    };

    renderStatusValue = (row, column, value) => {
        if (value < 100) {
            const {estimatedTime, queueName} = row;
            const renderEstimatedTime = getLocalizedTime(estimatedTime);
            return `${value}% (${renderEstimatedTime}) (${queueName})`;
        } else if (value === 100) {
            return <div className="twitter-table__completed-status-cell"/>
        }
    };

    getFiltersOptions = () => {
        const {addTwitterDonor} = this.props;

        const filtersOptions = [
            {
                type: ADD_ELEMENT,
                isValidate: true,
                validateText: `It's allowed to use spaces, digits (0-9), letters (A-Z, a-z), and a few special characters ( "-" , "." , "_")`,
                customWrapperClassName: '',
                placeholder: 'alconost',
                buttonTitle: 'Add donor',
                startAdornment: <div className="add-input__twitter-adornment">@</div>,
                onClick: addTwitterDonor
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_TWITTER_BY_NAME_REQUESTED
            || status === SEARCH_TWITTER_DONORS_REQUESTED;
    };

    renderRemoveButton = (value, cellKey, row) => {
        const {removeTwitterDonor} = this.props;
        const onClick = () => {
            removeTwitterDonor(row.id);
        };
        return <Button title='Remove' onClick={onClick}/>
    };

    handleClickSendToTwitter = () => {
        const {sendAudienceToTwitter, audience: {id}} = this.props;
        sendAudienceToTwitter(id);
    };

    handleExportTwitterAudience = (exportType) => {
        const {exportAudience, audience: {id}} = this.props;
        exportAudience(id, exportType);
    };

    render() {
        const {audience, list, total, limit, offset, search, setSearch, refreshTwitterDonors, searchTwitterDonors} = this.props;
        const {donorsCount, followersCount, status, estimatedTime} = audience;

        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Donor', width: 140},
            {key: 'followersCount', name: 'Followers', width: 140,
                renderValue: renderBigNumbers
            },
            {key: 'status', name: 'Status', width: 120,
                renderValue: this.renderStatusValue,
            },
            {key: 'updated', name: 'Updated', width: 150,
                isHiddenTitle: true,
                renderValue: this.renderUpdateValue,
            },
            {key: 'button', name: '', width: 131,
                cellClassName: "twitter-remove-button",
                cellValueWrapperFn: this.renderRemoveButton
            },
        ];

        const dropdownExportArray = [
            {
                onClick: () => this.handleExportTwitterAudience(1),
                title: "Ids",
            },
            {
                onClick: () => this.handleExportTwitterAudience(2),
                title: "Usernames",
            },
        ];

        const sentToTwitterTitle = <div className="twitter-button">Send to<div className="twitter-button__icon"/></div>;
        const renderEstimatedTime = status < 100
            ? `(${getLocalizedTime(estimatedTime)})`
            : '';

        return (
            <React.Fragment>
                <div className="twitter-audience">
                    <BackArrowPortal>
                        <div className="twitter-audience__back" onClick={this.handleBackToTwitter}>
                            <div className="twitter-audience__back-arrow"/>
                            Twitter
                        </div>
                    </BackArrowPortal>
                    <div className="twitter-audience__title-container">
                        <div className="twitter-audience__title">
                            <div className="twitter-audience__item">
                                Donors: {donorsCount}
                            </div>
                            <div className="twitter-audience__item">
                                Followers: {followersCount}
                            </div>
                            <div className="twitter-audience__item">
                                Status: {status}% {renderEstimatedTime}
                            </div>
                            <div className="twitter-audience__item">
                                Sent to Twitter:
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-root__table-container twitter-donors-container">
                    <Filters filtersOptions={filtersOptions}
                             search={search}
                             setSearch={setSearch}
                             dropdownExport={dropdownExportArray}
                             twitterButton={<Button className="export-dropdown-button twitter-button" title={sentToTwitterTitle} onClick={this.handleClickSendToTwitter}/>}
                             onRefresh={refreshTwitterDonors}/>
                    <div className="app-root__table">
                        <Table
                            className="twitter-table"
                            columns={columns}
                            data={list}
                            totalItems={total}
                            countItems={countItems}
                            isLoading={isLoading}
                            onShowMore={searchTwitterDonors}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initContainers: state.app.initContainers,

        audience: state.twitterAudience.audience,
        list: state.twitterAudience.list,
        total: state.twitterAudience.total,
        limit: state.twitterAudience.limit,
        offset: state.twitterAudience.offset,
        status: state.twitterAudience.status,
        search: state.twitterAudience.search,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (name, title) => dispatch(setTitle(name, title)),
        updateTitle: (name) => dispatch(updateTitle(name)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        initTwitterItem: name => dispatch(initTwitterItem(name)),
        removeAudienceItem: id => dispatch(removeAudienceItem(id)),
        refreshTwitterDonors: () => dispatch(refreshTwitterDonors()),
        searchTwitterDonors: (count) => dispatch(searchTwitterDonors(count)),
        addTwitterDonor: (name) => dispatch(addTwitterDonor(name)),
        removeTwitterDonor: (id) => dispatch(removeTwitterDonor(id)),
        renameTwitterAudience: (id, name) => dispatch(renameTwitterAudience(id, name)),
        sendAudienceToTwitter: (id) => dispatch(sendAudienceToTwitter(id)),
        exportAudience: (id, exportType) => dispatch(exportAudience(id, exportType)),

        setSearch: search => dispatch(setSearchAsync(search)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TwitterAudience);