import React, {Component} from 'react';
import {connect} from "react-redux";
import join from "lodash/join";
import map from "lodash/map";
import AppstoreFilters from "../../components/Filters/PageFilters";
import LastUpdatePortal from "../../components/Header/LastUpdatePortal";
import Table from "../../components/Table";
import {setInitContainer, setTitle} from "../App/actions";
import {
    initItch, getItchFilterData, searchItch, setGenresAsync, setLanguageAsync, setPlatformAsync, refreshItch, exportItchTwitters,
    getLastUpdate, exportItchAuthors, setLanguageCountAsync, setPriceAsync, setReleasedAsync,
    getCount, clearFiltersAsync
} from "./actionsAsync";
import {setIsOnlyChosenLanguages, setIsFiltersSelected} from "./actions";
import {
    GET_COUNT_ITCH_AUTHORS_REQUESTED
} from "../../store/StatusTypes";
import {convertTimestampFormat, getDefaultReleased} from "../../helpers/dateUtils";
import CONST from "../../constants/Constants";
import Link from "../../components/Link";
import {getLastUpdateTitle} from "../../helpers/utils";
import {DATE_RANGE, SELECT} from "../../constants/FilterTypes";
import LanguagesFilter from "../../components/Select/LanguagesFilter";
import Loading from "../../components/Loading";

const CONTAINER_NAME = 'Itch.io authors';

class ItchAuthors extends Component {

    componentDidMount() {
        const {setTitle, initItch, status, getItchFilterData, getLastUpdate, lastUpdate, getCount} = this.props;
        setTitle(CONTAINER_NAME);
        if (status === null) {
            initItch();
            getItchFilterData();
            getCount();
        }
        lastUpdate === null && getLastUpdate();
    }

    getLanguageValue = (selectedOptions, options) => {
        const {languagesCount} = this.props;
        let languagesValue = '';
        if (selectedOptions.length === 0) {
            languagesValue = 'None';
        }
        else if (selectedOptions.length === options.length) {
            languagesValue = 'All';
        }
        else {
            const nameValues = map(selectedOptions, option => option.name);
            languagesValue = join(nameValues, ', ');
        }
        const languagesCountFrom = languagesCount.from ? `From ${languagesCount.from}` : '';
        const languagesCountTo = languagesCount.to ? `To ${languagesCount.to}` : '';
        if (languagesCountFrom && languagesCountTo) {
            return `${languagesCountFrom} ${languagesCountTo}; ${languagesValue}`;
        }
        else if (languagesCountFrom && !languagesCountTo) {
            return `${languagesCountFrom}; ${languagesValue}`;
        }
        else if (!languagesCountFrom && languagesCountTo) {
            return `${languagesCountTo}; ${languagesValue}`;
        }
        else {
            return languagesValue;
        }
    }

    handleClearFilters = () => {
        const {clearFilters, initItch, getCount} = this.props;
        clearFilters().then(() => {
            initItch();
            getCount();
        });
    }

    getLanguageSelect = (props) => {
        return <LanguagesFilter {...props}/>
    }

    getGenresValue = (selectedOptions, options) => {
        if (selectedOptions.length === options.length) {
            return 'All'
        } else {
            const nameValues = map(selectedOptions, option => option.name);
            return join(nameValues, ', ');
        }
    }

    onClearReleased = () => {
        const {setReleased, refreshItch, startDate} = this.props;
        const defaultReleased = getDefaultReleased(startDate);
        setReleased(defaultReleased).then(() => refreshItch());
    }

    getFiltersOptions = () => {
        const {
            languages, selectedLanguages, genres, selectedGenres, platform, selectedPlatform, setLanguage, setIsOnlyChosenLanguages,
            setGenres, setPlatform, setLanguageCount, setPrice, price, selectedPrice, setReleased, isOnlyChosenLanguages,
            releasedDateRange, defaultDateRange, languagesCount} = this.props;

        const filtersOptions = [
            {
                type: SELECT,
                title: 'Languages',
                options: languages,
                customOptionsComponent: this.getLanguageSelect,
                renderValue: this.getLanguageValue,
                selectedOptions: selectedLanguages,
                onChange: setLanguage,
                setOptionsCount: setLanguageCount,
                filterName: "languages",
                optionCount: languagesCount,
                isOnlyChosen: isOnlyChosenLanguages,
                setIsOnlyChosen: setIsOnlyChosenLanguages,
            },
            {
                type: SELECT,
                title: 'Genres',
                popoverClassName: 'steam-genre-popover',
                options: genres,
                renderValue: this.getGenresValue,
                selectedOptions: selectedGenres,
                onChange: setGenres,
            },
            {
                type: SELECT,
                title: 'Platform',
                options: platform,
                renderValue: this.getGenresValue,
                selectedOptions: selectedPlatform,
                onChange: setPlatform,
                columnsCount: 1,
            },
            {
                type: SELECT,
                title: 'Price',
                options: price,
                renderValue: this.getGenresValue,
                selectedOptions: selectedPrice,
                onChange: setPrice,
                columnsCount: 1,
            },
            {
                type: DATE_RANGE,
                title: 'Released',
                customWrapperClassName: 'date-input',
                initDates: releasedDateRange,
                defaultDateRange: defaultDateRange,
                onClear: this.onClearReleased,
                onChange: setReleased
            },
        ];

        return filtersOptions
    };

    getLoading = () => {
        const {status} = this.props;
        return status === null || status === GET_COUNT_ITCH_AUTHORS_REQUESTED;
    }

    renderIndexCell = (row, column, value, rowIndex) => rowIndex + 1;

    renderTimeValue = (row, column, value) => convertTimestampFormat(value, CONST.DATE_FORMAT_LL);

    renderList = (row, column, value) => {
        return join(value, ', ');
    }

    priceValue = (row, column, value) => {
        return value ? "Paid" : "Free";
    }

    statusValue = (row, column, value) => {
        const statusNames = {
            0: "",
            1: "Released",
            2: "In development",
            3: "On hold",
            4: "Prototype",
        }

        return statusNames[value];
    }

    renderLink = (value) => <Link className="td-content-row" path={value} value={value}/>;

    renderGamesInfo = (row, column) => {
        const {key, parentName, type} = column;
        return map(row[parentName], item => {
            let value = item[key];
            let renderValue = value;
            if (type === 'url') {
                renderValue = <Link path={value} value={value}/>;
            }
            if (type === 'time') {
                renderValue = convertTimestampFormat(value, CONST.DATE_FORMAT_LL);
            }
            if (type === 'status') {
                value = this.statusValue(row, column, value);
                renderValue = value;
            }
            if (type === 'list') {
                renderValue = this.renderList(row, column, value);
            }
            if (type === 'price') {
                value = this.priceValue(row, column, value);
                renderValue = value;
            }
            return <div className="td-content-row" title={value}>{renderValue}</div>
        })
    }

    handleRefresh = () => {
        const {refreshItch, getCount} = this.props;
        refreshItch();
        getCount();
    }

    render() {
        const {list, total, limit, offset, lastUpdate, title, searchItch, exportItchAuthors, exportItchTwitters, count, isFiltersSelected,
            setIsFiltersSelected, isCountLoading} = this.props;
        const countItems = offset + limit;
        const filtersOptions = this.getFiltersOptions();
        const isLoading = this.getLoading();
        const renderLastUpdate = getLastUpdateTitle(lastUpdate, title);
        const authorsCount = isCountLoading ? <Loading/> : count.authors;
        const gamesCount = isCountLoading ? <Loading/> : count.games;
        const twittersCount = isCountLoading ? <Loading/> : count.twitters;

        const columns = [
            {key: 'id', name: '#', width: 60, fixedColumn: {left: 0},
                renderValue: this.renderIndexCell
            },
            {key: 'name', name: 'Author',
                fixedColumn: {left: 60},
                headerClassName: 'fixed-cell-divider',
                cellClassName: 'fixed-cell-divider',
            },
            {key: 'authorUrl', name: 'Author URL', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'twitter', name: 'Social URLs', width: 120,
                cellValueWrapperFn: this.renderLink
            },
            {key: 'title', parentName: "games", name: 'Game', width: 180,
                renderValue: this.renderGamesInfo
            },
            {key: 'published', parentName: "games", name: 'Published', width: 100,
                renderValue: this.renderGamesInfo, type: 'time'
            },
            {key: 'updated', parentName: "games", name: 'Updated', width: 100,
                renderValue: this.renderGamesInfo, type: 'time'
            },
            {key: 'status', parentName: "games", name: 'Status', width: 100,
                renderValue: this.renderGamesInfo, type: 'status'
            },
            {key: 'platforms', parentName: "games", name: 'Platforms', width: 120,
                renderValue: this.renderGamesInfo, type: 'list'
            },
            {key: 'rating', parentName: "games", name: 'Rating', width: 70,
                renderValue: this.renderGamesInfo
            },
            {key: 'genres', parentName: "games", name: 'Genre', width: 120,
                renderValue: this.renderGamesInfo
            },
            {key: 'gameLink', parentName: "games", name: 'Game URL', width: 120,
                renderValue: this.renderGamesInfo, type: 'url'
            },
            {key: 'isPaid', parentName: "games", name: 'Price', width: 70,
                renderValue: this.renderGamesInfo, type: 'price'
            },
            {key: 'languages', parentName: "games", name: 'Languages', width: 120,
                renderValue: this.renderGamesInfo, type: 'list'
            }
        ];

        const dropdownExportArray = [
            {
                onClick: exportItchAuthors,
                title: "All data",
                icon: "export-icon__all-data"
            },
            {
                onClick: exportItchTwitters,
                title: "Twitter",
                icon: "export-icon__twitter"
            }
        ];

        return (
            <div className="app-root__table-container">
                <LastUpdatePortal className="emails-count__last-update">
                    {renderLastUpdate}
                    <div className="count-emails">
                        <>
                            <div>Authors: {authorsCount}</div>
                            <div>Games: {gamesCount}</div>
                            <div>Twitters: {twittersCount}</div>
                        </>
                    </div>
                </LastUpdatePortal>
                <AppstoreFilters filtersOptions={filtersOptions}
                                 dropdownExport={dropdownExportArray}
                                 onApplyFilters={this.handleRefresh}
                                 onClearFilters={this.handleClearFilters}
                                 totalItems={total}
                                 isFiltersSelected={isFiltersSelected}
                                 setIsFiltersSelected={setIsFiltersSelected}
                />
                <div className="app-root__table">
                    <Table
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchItch}
                        className="itchio-table"
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.itchAuthors.list,
        status: state.itchAuthors.status,
        total: state.itchAuthors.total,
        limit: state.itchAuthors.limit,
        offset: state.itchAuthors.offset,
        languages: state.itchAuthors.languages,
        selectedLanguages: state.itchAuthors.selectedLanguages,
        genres: state.itchAuthors.genres,
        selectedGenres: state.itchAuthors.selectedGenres,
        platform: state.itchAuthors.platform,
        selectedPlatform: state.itchAuthors.selectedPlatform,
        lastUpdate: state.itchAuthors.lastUpdate,
        price: state.itchAuthors.price,
        selectedPrice: state.itchAuthors.selectedPrice,
        releasedDateRange: state.itchAuthors.releasedDateRange,
        defaultDateRange: state.itchAuthors.defaultDateRange,
        count: state.itchAuthors.count,
        startDate: state.itchAuthors.startDate,
        isOnlyChosenLanguages: state.itchAuthors.isOnlyChosenLanguages,
        isFiltersSelected: state.itchAuthors.isFiltersSelected,
        languagesCount: state.itchAuthors.languagesCount,
        isCountLoading: state.itchAuthors.isCountLoading,

        initContainers: state.app.initContainers,
        title: state.app.title
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initItch: () => dispatch(initItch()),
        searchItch: (count) => dispatch(searchItch(count)),
        refreshItch: () => dispatch(refreshItch()),
        getItchFilterData: () => dispatch(getItchFilterData()),
        getLastUpdate: () => dispatch(getLastUpdate()),
        exportItchAuthors: () => dispatch(exportItchAuthors()),
        exportItchTwitters: () => dispatch(exportItchTwitters()),
        //filters
        setLanguage: languages => dispatch(setLanguageAsync(languages)),
        setGenres: genres => dispatch(setGenresAsync(genres)),
        setPlatform: platform => dispatch(setPlatformAsync(platform)),
        setLanguageCount: count => dispatch(setLanguageCountAsync(count)),
        setPrice: price => dispatch(setPriceAsync(price)),
        setReleased: (released) => dispatch(setReleasedAsync(released)),
        getCount: () => dispatch(getCount()),
        setIsOnlyChosenLanguages: isOnlyChosenLanguages => dispatch(setIsOnlyChosenLanguages(isOnlyChosenLanguages)),
        setIsFiltersSelected: isFiltersSelected => dispatch(setIsFiltersSelected(isFiltersSelected)),
        clearFilters: () => dispatch(clearFiltersAsync()),

        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItchAuthors)