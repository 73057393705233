import * as types from "./constants";

export function requestGetMagnetMessage(account) {
    return {
        type: types.REQUEST_GET_MAGNET_SALES_MESSAGE,
        account
    }
}

export function succeedGetMagnetMessage(response) {
    return {
        type: types.SUCCESS_GET_MAGNET_SALES_MESSAGE,
        response
    }
}