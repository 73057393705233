import React, {Component} from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFoot from "./TableFoot";

import {renderBigNumbers} from "../../helpers/utils";

import './css/table.css';

class Table extends Component {

    static propTypes = {
        columns: PropTypes.array,
        data: PropTypes.array,
        isLoading: PropTypes.bool,
        isHiddenHead: PropTypes.bool,
        onShowMore: PropTypes.func,
        totalItems: PropTypes.number,
        countItems: PropTypes.number,
        className: PropTypes.string,
        onRowClick: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.tableScroll = React.createRef();
        this.tableContainer = React.createRef();
    }

    state = {
        addedWidth: 0
    }

    componentDidMount() {
        this.setAddedWidthToTable();
        window.addEventListener('resize', this.setAddedWidthToTable);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.length !== this.props.data.length) {
            this.setAddedWidthToTable();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setAddedWidthToTable);
    }

    setAddedWidthToTable = () => {
        const {columns} = this.props;
        const tableLessContainer = this.checkIsTableLessContainer();

        const filterColumn = filter(columns, column => !column.fixedColumn && !isUndefined(column.width));
        this.setState({
            addedWidth: tableLessContainer / filterColumn.length
        })
    }

    checkIsTableLessContainer = () => {
        let tableLessContainer = 0;
        const tableContainerRect = this.tableContainer && this.tableContainer.current && this.tableContainer.current.getBoundingClientRect();
        const tableWidth = this.getTableWidth();
        const tableContainerWidth = tableContainerRect && tableContainerRect.width;
        if (tableContainerWidth > tableWidth) {
            tableLessContainer = tableContainerWidth - tableWidth;
        }
        return tableLessContainer;
    }

    getTableWidth = () => {
        const {columns} = this.props;
        let width = 0
        forEach(columns, column => {
            if (column.width) {
                width += column.width;
            } else {
                //todo диковинное решение, но оно работает
                const item = document.getElementById(`column-${column.key}`);
                if (item && item.offsetWidth) {
                    width += item.offsetWidth;
                }
            }

        });
        return width;
    }

    render() {
        const {addedWidth} = this.state;
        const {columns, data, className, isLoading, totalItems, countItems, onShowMore, isHiddenHead, onRowClick} = this.props;
        const totalItemsValue = renderBigNumbers('', '', totalItems);
        const containerClassName = classNames("kraken-table__container", className)
        return (
            <div className={containerClassName} ref={this.tableContainer}>
                <div className="kraken-table-scroll">
                    <table className="kraken-table" ref={this.tableScroll}>
                        <TableHead
                            columns={columns}
                            isHiddenHead={isHiddenHead}
                            addedWidth={addedWidth}
                        />
                        <TableBody
                            addedWidth={addedWidth}
                            columns={columns}
                            data={data}
                            onRowClick={onRowClick}
                        />
                    </table>
                </div>
                <TableFoot
                    tableRef={this.tableScroll}
                    isLoading={isLoading}
                    totalItems={totalItemsValue}
                    countItems={countItems}
                    onShowMore={onShowMore}
                />
            </div>
        );
    }
}

export default (Table);
