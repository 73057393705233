import React, {useState} from "react";
import classNames from 'classnames';
import map from "lodash/map";
import some from "lodash/some";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";

import CheckboxItem from "../../Select/CheckboxItem";
import Loading from "../../Loading";

import '../css/export-popup.css';

function ExportPopup({onApply, getExportCount, fieldsList, isGog}) {
    const [selectedFields, setSelectedFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState('');

    const onChange = (field, checked) =>  {
        let selectedFieldsList = checked
            ? filter(selectedFields, item => item.name !== field.name)
            : [...selectedFields, field];
        if (isGog && field.id === 'email') {
            selectedFieldsList = checked ? [] : [field];
        }
        setSelectedFields(selectedFieldsList);
    }

    const isChecked = (name) => findIndex(selectedFields, field => field.name === name) > -1;

    const isDisabled = (uniqId, name, id) => {
        if (isGog && some(selectedFields, ['id', 'email'])) {
            return id !== 'email';
        }
        else {
            return findIndex(selectedFields, field => field.uniqId && field.uniqId === uniqId && field.name !== name) > -1;
        }
    }

    const getRenderCheckbox = () => {
        return map(fieldsList, field => {
            const {name, uniqId, id} = field;
            const checked = isChecked(name);
            const disabled = isDisabled(uniqId, name, id);
            return <CheckboxItem key={name} id={name} name={name} onChange={() => onChange(field, checked)} checked={checked} disabled={disabled}/>
        });
    }

    const getCount = () => {
        setIsLoading(true);
        getExportCount(selectedFields).then((response) => {
            setIsLoading(false);
            setCount(response);
        });
    }

    const exportEmails = () => {
        !isLoading && onApply(selectedFields);
    }

    const renderCheckboxList = getRenderCheckbox();
    const applyButtonStyle = classNames("popup-component__button", {"disabled-button": isLoading});

    return (
        <div className="export-popup__container">
            <div className="popup-component__title">Export</div>
            <div className="export-popup__fields">
                {fieldsList.length ? renderCheckboxList : <div className="fields-spinner"><Loading/></div>}
            </div>
            <div className="export-popup__buttons">
                <div className={applyButtonStyle} onClick={exportEmails}>
                    <div className="popup-component__button-title">Export {count} records</div>
                </div>
                <div className="popup-component__count-button" onClick={getCount}>
                    <div className="popup-component__button-count-title">Count the records</div>
                </div>
                {isLoading && <Loading/>}
            </div>
        </div>
    )
}

export default ExportPopup;