export const REQUEST_INIT_CRUCHBASE = 'REQUEST_INIT_CRUCHBASE';
export const SUCCEED_INIT_CRUCHBASE = 'SUCCEED_INIT_CRUCHBASE';
export const REQUEST_SEARCH_CRUCHBASE = 'REQUEST_SEARCH_CRUCHBASE';
export const SUCCEED_SEARCH_CRUCHBASE = 'SUCCEED_SEARCH_CRUCHBASE';
export const SET_CRUNCHBASE_PROJECT_TYPE = 'SET_CRUNCHBASE_PROJECT_TYPE';
export const GET_CRUNCHBASE_FILTER_DATA_REQUEST = 'GET_CRUNCHBASE_FILTER_DATA_REQUEST';
export const GET_CRUNCHBASE_FILTER_DATA_SUCCEEDED = 'GET_CRUNCHBASE_FILTER_DATA_SUCCEEDED';
export const SET_SELECTED_CRUNCHBASE_LOCATIONS = 'SET_SELECTED_CRUNCHBASE_LOCATIONS';
export const SET_CRUNCHBASE_RANK = 'SET_CRUNCHBASE_RANK';
export const SET_CRUNCHBASE_CREATED = 'SET_CRUNCHBASE_CREATED';
export const REQUEST_LAST_UPDATE_CRUNCHBASE = 'REQUEST_LAST_UPDATE_CRUNCHBASE';
export const SUCCEED_LAST_UPDATE_CRUNCHBASE = 'SUCCEED_LAST_UPDATE_CRUNCHBASE';
export const REQUEST_GET_COUNT_CRUNCHBASE = 'REQUEST_GET_COUNT_CRUNCHBASE';
export const SUCCEED_GET_COUNT_CRUNCHBASE = 'SUCCEED_GET_COUNT_CRUNCHBASE';
export const SET_IS_FILTER_SELECTED_CRUNCHBASE = 'SET_IS_FILTER_SELECTED_CRUNCHBASE';
export const CLEAR_FILTERS_CRUNCHBASE = 'CLEAR_FILTERS_CRUNCHBASE';