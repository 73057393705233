import {getConnectRpcHeaders} from "./grpsUtils";
import {createConnectTransport} from "@connectrpc/connect-web";
import {createPromiseClient} from "@connectrpc/connect";
import {signOutAction} from "../helpers/catchErrorUtils";
import {AuthService} from "@alconost/kraken-services-proto/src/proto/kraken/v1alpha1/auth_connect";
import {UserService} from "@alconost/kraken-services-proto/src/proto/kraken/v1alpha1/user_connect";
import {GoogleRequest} from "@alconost/kraken-services-proto/src/proto/kraken/v1alpha1/auth_pb";
import {MeRequest} from "@alconost/kraken-services-proto/src/proto/kraken/v1alpha1/user_pb";

export const ALCONOST_URL = process.env.NODE_ENV === 'production' ? 'https://kraken.alconost.com' : 'http://localhost:3000';

const HOST = 'https://services.kraken.alconost.com/';
const googleAuthTransport = createConnectTransport({
    baseUrl: HOST,
});
const googleClient = createPromiseClient(AuthService, googleAuthTransport);

const userTransport = createConnectTransport({
    baseUrl: HOST,
});
const userClient = createPromiseClient(UserService, userTransport);

export const googleAuthRequest = async (token, onGoogleAuthError) => {
    const headers = getConnectRpcHeaders(token);
    const googleRequest = new GoogleRequest();

    googleRequest.token = token;

    return googleClient.google(googleRequest, headers).then(res => res).catch(err => {
        onGoogleAuthError(err.code, err.message);
    });
};

export const getUserInfoRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const meRequest = new MeRequest();

    return userClient.me(meRequest, headers).then(res => res).catch(() => {
        signOutAction();
    });
};