export const REQUEST_INIT_SUBSCRIBE_LIST = 'REQUEST_INIT_SUBSCRIBE_LIST';
export const SUCCEED_INIT_SUBSCRIBE_LIST = 'SUCCEED_INIT_SUBSCRIBE_LIST';
export const REQUEST_SEARCH_SUBSCRIBE_LIST = 'REQUEST_SEARCH_SUBSCRIBE_LIST';
export const SUCCEED_SEARCH_SUBSCRIBE_LIST = 'SUCCEED_SEARCH_SUBSCRIBE_LIST';
export const REQUEST_REFRESH_SUBSCRIBE_LIST = 'REQUEST_REFRESH_SUBSCRIBE_LIST';
export const SUCCEED_REFRESH_SUBSCRIBE_LIST = 'SUCCEED_REFRESH_SUBSCRIBE_LIST';
export const SUCCEED_UPDATE_SUBSCRIBE_RECORD = 'SUCCEED_UPDATE_SUBSCRIBE_RECORD';

export const REQUEST_UPDATE_SUBSCRIBE_LIST = 'REQUEST_UPDATE_SUBSCRIBE_LIST';
export const SUCCEED_UPDATE_SUBSCRIBE_LIST = 'SUCCEED_UPDATE_SUBSCRIBE_LIST';
export const SET_SEARCH_SUBSCRIBE_LIST = 'SET_SEARCH_SUBSCRIBE_LIST';
export const SET_SORT_SUBSCRIBE_LIST = 'SET_SORT_SUBSCRIBE_LIST';
export const SET_DECISION_STATUS_SUBSCRIBE = 'SET_DECISION_STATUS_SUBSCRIBE';
export const CLEAR_FILTERS_SUBSCRIBE = 'CLEAR_FILTERS_SUBSCRIBE';
export const SET_IS_FILTER_SELECTED_SUBSCRIBE = 'SET_IS_FILTER_SELECTED_SUBSCRIBE';