import React, {Component} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {setInitContainer, setTitle} from "../App/actions";
import {
    setSortAsync, initMeetToMatch, searchMeetToMatch
} from "./actionsAsync";

import Table from "../../components/Table";
import SortingTitle from "../../components/Table/SortingTitle";

import {
    INIT_MEET_TO_MATCH_REQUESTED,
    SEARCH_MEET_TO_MATCH_REQUESTED,
} from "../../store/StatusTypes";

import '../../css/meet-to-match.css';

const CONTAINER_NAME = 'MeetToMatch';

class MeetToMatch extends Component {

    componentDidMount() {
        const {setTitle, initContainers, setInitContainer, status, initMeetToMatch} = this.props;
        setTitle(CONTAINER_NAME);

        if (status === null) {
            initMeetToMatch();
        }

        if (!initContainers[CONTAINER_NAME]) {
            setInitContainer(CONTAINER_NAME);
        }
    }

    getLoading = () => {
        const {status} = this.props;
        return status === null
            || status === INIT_MEET_TO_MATCH_REQUESTED
            || status === SEARCH_MEET_TO_MATCH_REQUESTED;
    }

    onRowClick = (e, item) => {
        const {history} = this.props;
        const {id} = item;
        history.push(`/meet-to-match/${id}`);
    };

    handleSetSort = (field, order) => {
        const {setSort, initMeetToMatch} = this.props;
        setSort(order, field).then(() => {
            initMeetToMatch();
        })
    }

    sortingRender = (key, name) => {
        const {sortOrder, sortField} = this.props;

        return <SortingTitle
            columnId={key}
            sortOrder={sortOrder}
            sortField={sortField}
            value={name}
            onClick={this.handleSetSort}/>
    }

    render() {
        const {list, total, limit, offset, searchMeetToMatch} = this.props;
        const countItems = offset + limit;
        const isLoading = this.getLoading();

        const columns = [
            {key: 'name', name: 'Conference',
                headerClassName: "header-sort-column",
                width: 230,
                headerValueWrapperFn: this.sortingRender,
            },
           /* {key: 'attendees', name: 'Attendees',
                headerClassName: "header-sort-column",
                width: 100,
                headerValueWrapperFn: this.sortingRender,
                renderValue: renderBigNumbers
            },
            {key: 'status', name: 'Status',
                headerClassName: "header-sort-column",
                width: 140,
            },
            {key: 'lastUpdate', name: 'Last update',
                headerClassName: "header-sort-column",
                width: 120,
            },*/
        ];

        return (
            <div className="app-root__table-container meet-to-match-container">
                <div className="app-root__table">
                    <Table
                        className="twitter-table"
                        columns={columns}
                        data={list}
                        totalItems={total}
                        countItems={countItems}
                        isLoading={isLoading}
                        onShowMore={searchMeetToMatch}
                        onRowClick={this.onRowClick}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        list: state.meetToMatch.list,
        status: state.meetToMatch.status,
        total: state.meetToMatch.total,
        limit: state.meetToMatch.limit,
        offset: state.meetToMatch.offset,
        search: state.meetToMatch.search,
        sortOrder: state.meetToMatch.sortOrder,
        sortField: state.meetToMatch.sortField,

        initContainers: state.app.initContainers,
        title: state.app.title
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: title => dispatch(setTitle(title)),
        setInitContainer: (containerName) => dispatch(setInitContainer(containerName)),

        setSort: (order, sortField) => dispatch(setSortAsync(order, sortField)),
        initMeetToMatch: () => dispatch(initMeetToMatch()),
        searchMeetToMatch: (count) => dispatch(searchMeetToMatch(count)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MeetToMatch);