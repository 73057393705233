import isEqual from "lodash/isEqual";
import {
    getCrunchBaseRecordsRequest,
    getCrunchbaseFilterData,
    exportOrganizationsRequest,
    exportSocialsRequest,
    getlastUpdateCrunchBaseRequest,
    getCountCrunchbase
} from "../../services/grpcBufServices";
import {
    requestInitCrunchbase,
    requestSearchCrunchbase,
    succeedInitCrunchbase,
    succeedSearchCrunchbase,
    setProjectType,
    requestGetCrunchbaseFilterData,
    succeedGetCrunchbaseFilterData,
    setCrunchbaseLocations,
    setCrunchbaseRank,
    setCreated,
    requestLastUpdateCrunchbase,
    succeedLastUpdateCrunchbase,
    succeedGetCountCrunchbase,
    clearFiltersCrunchbase,
    requestGetCountCrunchbase
} from "./actions";
import {requestExport, succeedExport} from "../App/actions";
import {getReleasedValue} from "../../helpers/dateUtils";

export const initCrunchbase = () =>
    async (dispatch, getState) => {
        const {app: {token}, crunchbase: {limit, offset}} = getState();
        dispatch(requestInitCrunchbase());
        const response = await getCrunchBaseRecordsRequest(token, limit, offset);
        dispatch(succeedInitCrunchbase(response));
    };

export const refreshCrunchbase = () =>
    async (dispatch, getState) => {
        const {app: {token}, crunchbase: {limit, offset, selectedProjectType, defaultDateRange, selectedLocations, rank, createdDateRange, projectTypes}} = getState();
        dispatch(requestInitCrunchbase());
        const created = getReleasedValue(createdDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const createdDate = isEqual(created, defaultDate) ? null : getReleasedValue(createdDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const response = await getCrunchBaseRecordsRequest(token, limit, offset, projectTypeOptions, selectedLocations, rank, createdDate);
        dispatch(succeedInitCrunchbase(response));
    };

export const searchCrunchbase = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, crunchbase: {limit, offset, selectedProjectType, defaultDateRange, selectedLocations, rank, createdDateRange, projectTypes}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchCrunchbase(updateLimit, updateOffset));
        const created = getReleasedValue(createdDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const createdDate = isEqual(created, defaultDate) ? null : getReleasedValue(createdDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const response = await getCrunchBaseRecordsRequest(token, updateLimit, updateOffset, projectTypeOptions, selectedLocations, rank, createdDate);
        dispatch(succeedSearchCrunchbase(response));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestGetCrunchbaseFilterData());
        const filterData = await getCrunchbaseFilterData(token);
        dispatch(succeedGetCrunchbaseFilterData(filterData));
    };

export const exportOrganizations = () =>
    async (dispatch, getState) => {
        const {app: {token}, crunchbase: {limit, offset, selectedProjectType, defaultDateRange, projectTypes, selectedLocations, rank, createdDateRange}} = getState();
        dispatch(requestExport());
        const created = getReleasedValue(createdDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const createdDate = isEqual(created, defaultDate) ? null : getReleasedValue(createdDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const exportId = await exportOrganizationsRequest(token, limit, offset, projectTypeOptions, selectedLocations, rank, createdDate);
        dispatch(succeedExport(exportId.task.id));
    };

export const exportSocials = social =>
    async (dispatch, getState) => {
        const {app: {token}, crunchbase: {limit, offset, selectedProjectType, defaultDateRange, projectTypes, selectedLocations, rank, createdDateRange}} = getState();
        dispatch(requestExport());
        const created = getReleasedValue(createdDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const createdDate = isEqual(created, defaultDate) ? null : getReleasedValue(createdDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const exportId = await exportSocialsRequest(token, social, limit, offset, projectTypeOptions, selectedLocations, rank, createdDate);
        dispatch(succeedExport(exportId.task.id));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdateCrunchbase());
        const lastUpdate = await getlastUpdateCrunchBaseRequest(token);
        dispatch(succeedLastUpdateCrunchbase(lastUpdate));
    };

export const setProjectTypeAsync = projectType =>
    async (dispatch, getState) => {
        dispatch(setProjectType(projectType));
    };

export const setLocationsAsync = selectedLocations =>
    async (dispatch, getState) => {
        dispatch(setCrunchbaseLocations(selectedLocations));
    };

export const setRankAsync = rank =>
    async (dispatch, getState) => {
        dispatch(setCrunchbaseRank(rank));
    };

export const setCreatedAsync = (created) =>
    async (dispatch, getState) => {
        dispatch(setCreated(created));
    };

export const getCount = () =>
    async (dispatch, getState) => {
        dispatch(requestGetCountCrunchbase());
        const {app: {token}, crunchbase: {selectedProjectType, projectTypes, defaultDateRange, selectedLocations, rank, createdDateRange}} = getState();
        const created = getReleasedValue(createdDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const createdDate = isEqual(created, defaultDate) ? null : getReleasedValue(createdDateRange);
        const projectTypeOptions = selectedProjectType.length === projectTypes.length ? [] : selectedProjectType;
        const count = await getCountCrunchbase(token, projectTypeOptions, selectedLocations, rank, createdDate);
        dispatch(succeedGetCountCrunchbase(count));
    };

export const clearFiltersAsync = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersCrunchbase());
    };