import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {getDefaultReleased} from "../../helpers/dateUtils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    isFiltersSelected: false,

    languages: [],
    selectedLanguage: [],
    genres: [],
    selectedGenres: [],
    availabilities: [],
    selectedAvailabilities: [],
    languagesCount: {from: '', to: ''},
    isOnlyChosenLanguages: false,

    releasedDateRange: getDefaultReleased(),
    defaultDateRange: getDefaultReleased(),
    startDate: null,
};

export default function nintendo(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_NINTENDO:
            return {
                ...state,
                status: statuses.INIT_NINTENDO_REQUESTED,
                list: [],
                limit: 10,
                offset: 0,
            }
        case actions.SUCCEED_INIT_NINTENDO:
            return {
                ...state,
                status: statuses.INIT_NINTENDO_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_LAST_UPDATE_NINTENDO:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_NINTENDO:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.REQUEST_SEARCH_NINTENDO:
            return {
                ...state,
                status: statuses.SEARCH_NINTENDO_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_NINTENDO:
            return {
                ...state,
                status: statuses.SEARCH_NINTENDO_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.SET_NINTENDO_FILTER_DATA:
            const startDate = action.response.releasedFirstDate;
            return {
                ...state,
                languages: action.response.languages,
                selectedLanguage: action.response.languages,
                genres: action.response.genres,
                selectedGenres: action.response.genres,
                availabilities: action.response.availabilities,
                selectedAvailabilities: action.response.availabilities,
                releasedDateRange: getDefaultReleased(startDate),
                defaultDateRange: getDefaultReleased(startDate),
                startDate: startDate
            }
        case actions.SET_SELECTED_LANGUAGES_NINTENDO:
            return {
                ...state,
                selectedLanguage: action.selectedLanguage,
            }
        case actions.SET_RELEASED_NINTENDO:
            return {
                ...state,
                releasedDateRange: action.released,
            }
        case actions.SET_COUNT_LANGUAGES_NINTENDO:
            return {
                ...state,
                languagesCount: action.languagesCount,
            }
        case actions.SET_NINTENDO_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SET_NINTENDO_SELECTED_GENRES:
            return {
                ...state,
                selectedGenres: action.selectedGenres,
            }
        case actions.SET_NINTENDO_SELECTED_AVAILABILITIES:
            return {
                ...state,
                selectedAvailabilities: action.selectedAvailabilities,
            }
        case actions.SET_IS_ONLY_CHOSEN_LANGUAGES_NINTENDO:
            return {
                ...state,
                isOnlyChosenLanguages: action.isOnlyChosenLanguages,
            }
        case actions.SET_IS_FILTER_SELECTED_NINTENDO:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_NINTENDO:
            return {
                ...state,
                selectedStatus: 'All',
                releasedDateRange: state.defaultDateRange,
                selectedAvailabilities: state.availabilities,
                selectedGenres: state.genres,
                selectedLanguage: state.languages,
                languagesCount: {from: '', to: ''},
                isOnlyChosenLanguages: false,
            }
        default:
            return state;
    }
}